import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useRebelContext = () => {
  const user = useSelector((state) => state.user);

  const [helperContext, setHelperContext] = useState({
    // hasUserConsented: false,
    isUserLoggedIn: false,
    userId: user?.get('id'),
    lang: user?.get('user_preference')?.get('language') ?? 'ENG',
  });

  useEffect(() => {
    // const userConsent = user.get('user_consent');
    const context = {
      ...helperContext,
      // hasUserConsented:
      //   (userConsent &&
      //     userConsent.get('allow_data_transmission') &&
      //     userConsent.get('allow_data_processing') &&
      //     userConsent.get('allow_cookies')) ||
      //   false,
      isUserLoggedIn: user.get('jwt'),
      userId: user.get('id'),
      lang: user?.get('user_preference')?.get('language') ?? 'ENG',
    };
    setHelperContext(context);
  }, [user]);
  return helperContext;
};

export default useRebelContext;
