import React, { Suspense, lazy, useState, useEffect } from 'react';
import { LastLocationProvider } from 'react-router-last-location';
import {useDispatch, useSelector} from 'react-redux';
import { experimentalStyled } from '@mui/material';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TopbarHeight } from 'theme/Theme-variable';
import Cookie from 'js-cookie';
import Alert from 'components/Alert';
import Snackbar from "@mui/material/Snackbar";
import { hideAlert } from 'store/rebel-alert/rebelAlertActions';
import Loading from '../../Loading/Loading';
import Footer from '../partials/footer/Footer';
import Header from '../partials/header/Header';
import Sidebar from '../partials/sidebar/Sidebar';

const MainWrapper = experimentalStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100%',
    width: '100%',
  };
});

const isLoggedIn = !!Cookie.get('token');

const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  [theme.breakpoints.down('md')]: {
    paddingBottom: isLoggedIn ? TopbarHeight : '0',
  }
}));

const MainLayout = ({ children }) => {
  const sideNav = useSelector((state) => state.sideNav);
  const alert = useSelector(state => state.rebelAlert);
  const distpatch = useDispatch();
  const [isSideNavOpen, setSideNavOpen] = useState(sideNav.get('menuOpen'));
  const customizer = useSelector((state) => state.themeReducer);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const TellUs = lazy(() => import('components/SignupProcess/TellUs/TellUs'));
  useEffect(() => {
    setSideNavOpen(sideNav.get('menuOpen'));
  }, [sideNav]);

  const handleClose = () => {
    distpatch(hideAlert());
  };

  return (
    <>
      <LastLocationProvider>
        <MainWrapper
          className={customizer.activeMode === 'dark' ? 'darkbg' : ''}
        >
          <Loading />
          <Sidebar />
          <Header
            sx={{
              paddingLeft: isSideNavOpen && lgUp ? '224px' : '',
              top: { lg: 0, md: 0, sm: 'auto', xs: 'auto' },
              bottom: { lg: 'auto', md: 'auto', sm: 0, xs: 0 },
              zIndex: 10,
            }}
          />
          <PageWrapper>
            <Container
              maxWidth={false}
              sx={{
                paddingTop: '20px',
                paddingLeft: isSideNavOpen && lgUp ? '224px !important' : '',
              }}
            >
              {children}
              <Suspense fallback={<Loading />}>
                <TellUs />
              </Suspense>
            </Container>
          </PageWrapper>
          <Snackbar anchorOrigin={alert?.anchorOrigin || {
            vertical: 'top',
            horizontal: 'right',
          }} open={alert?.open} autoHideDuration={alert?.hideDuration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alert?.severity} sx={{ width: '100%' }}>
              {alert?.message}
            </Alert>
          </Snackbar>
        </MainWrapper>
        {!isLoggedIn && <Footer />}
      </LastLocationProvider>
    </>
  );
};
export default MainLayout;
