import React, {MouseEvent, useEffect, useState} from "react";
import {ListItemIcon, ListItemText, MenuItem, MenuList, Typography} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from '@mui/material/Tooltip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import { BuilderIcon, ProjectPageIcon } from 'assets';
import { Theme } from '@mui/material/styles';
import { RebelBaseRole, RoleFactory } from 'Factory/RoleFactory';
import TeamMembersMenu, { TeamMember } from './TeamMembersMenu';
import NavLink from '../NavLink/NavLink';

export interface ProjectMenuProps {
    id?: string | number;
    projectName: string;
    isDefault: boolean;
    selected: boolean;
    setMyDefaultProject: (event: MouseEvent) => void,
    members: Array<TeamMember>;
    roleType: string;
    level: number;
    // TODO avoid any type
    onMenuClick: (id: number | string | undefined, url: string) => void;
    addTeamMember: (event: React.MouseEvent<HTMLDivElement>, projectId: number) => void
}

const ProjectMenu = (props: ProjectMenuProps) => {

    const [open, setOpen] = useState(false);

    const {
        id,
        projectName,
        level,
        isDefault,
        setMyDefaultProject,
        members,
        roleType,
        onMenuClick,
        addTeamMember
    } = props;

    const hasAddMemberRights = roleType !== 'Support Member';

    const userType: RebelBaseRole = RoleFactory(level);

    const { t } = useTranslation(['translation.navigation']);

    useEffect(() => {
        if (props?.selected) {
            setOpen(props?.selected);
        }
    }, [props?.selected]);

    return (
        <MenuList key={id} sx={{
            '& .MuiMenuItem-root': {
                minHeight: '40px !important',
                alignItems: 'center',
                '&:hover': {
                    backgroundColor: (theme) =>
                        theme.palette.secondary.light,
                },
                '&.Mui-selected': {
                    backgroundColor: (theme: Theme) => theme?.palette?.secondary?.dark
                }
            },
            '& .MuiListItemIcon-root': {
                minWidth: 'auto !important',
                color: (theme) => theme?.palette?.common?.white,
            },
            '& .MuiListItemText-root': {
                '.MuiTypography-root': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            }
        }}>
            <MenuItem
                onClick={() => setOpen(!open)}
                sx={{ p: (theme: Theme) => theme?.spacing(0, 2 , 0, 1)}}>
                <ListItemIcon
                    sx={{ px: 1 }}>
                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </ListItemIcon>
                <ListItemText>
                    <Typography variant="h5">
                        {projectName}
                    </Typography>
                </ListItemText>
                {
                    isDefault && (
                        <ListItemIcon sx={{
                            p: (theme) => theme.spacing(0, 0, 0, 1)
                        }}>
                            <Tooltip title={t('changeDefaultProject')}>
                                <FiberManualRecordIcon
                                    sx={{
                                        color: (theme) => theme?.palette?.warning?.main,
                                        border: (theme) => `1px solid ${theme?.palette?.warning?.main}`,
                                        borderRadius: '50%',
                                    }}
                                    onClick={setMyDefaultProject}
                                />
                            </Tooltip>
                        </ListItemIcon>
                    )
                }
            </MenuItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MenuList
                    component="div"
                    disablePadding>
                    {
                        userType !== RebelBaseRole.SUPPORT_USERS && (
                            <MenuItem
                                component={NavLink}
                                to={`/project/${id}/builders`}
                                onClick={() => onMenuClick(id, `/project/${id}/builders`)}
                                exact
                                sx={{
                                    pl: 3,
                                    color: (theme) => theme?.palette?.primary?.light
                                }}>
                                <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                    <BuilderIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="h5">
                                        {t('ProjectBuilders')}
                                    </Typography>
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                    <MenuItem
                        component={NavLink}
                        to={`/project/${id}`}
                        onClick={() => onMenuClick(id, `/project/${id}`)}
                        exact
                        sx={{
                            pl: 3,
                            color: (theme) => theme?.palette?.primary?.light
                        }}>
                        <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                            <ProjectPageIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="h5">
                                {t('ProjectPage')}
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                    <TeamMembersMenu
                        projectId={Number(id)}
                        addTeamMember={addTeamMember}
                        hasAddMemberRights={hasAddMemberRights}
                        members={members}
                    />
                </MenuList>
            </Collapse>
        </MenuList>
    )
}

export default React.memo(ProjectMenu);