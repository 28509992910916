/**
 * API
 */
export const API_PUT = 'API_PUT';
export const API_POST = 'API_POST';
export const API_DELETE = 'API_DELETE';
export const API_GET = 'API_GET';
export const API_POST_IMAGE = 'API_POST_IMAGE';
export const API_PUT_POST_IMAGE = 'API_PUT_POST_IMAGE';
export const API_QUILL_IMAGE = 'API_QUILL_IMAGE';
