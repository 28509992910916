import { Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'react-i18next';

const WelcomeRebel: React.FC<{ t: TFunction }> = ({ t }) => {
  const history = useHistory();
  return (
    <Card
      sx={{
        m: {
          xs: -4,
          sm: 0,
        },
        mb: { xs: 4, sm: 4 },
      }}
      raised
    >
      <CardContent
        sx={{ textAlign: 'center', p: (theme) => theme.spacing(3, 2, 2, 2) }}
      >
        <Typography variant="h1" sx={{ mb: 3, textAlign: 'center' }}>
          Welcome, Rebel!
        </Typography>
        <Typography variant="h3" sx={{ mb: 4, textAlign: 'center' }}>
          {t('LetsGetStarted')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mb: 3 }}
          onClick={() => {
            history.push('/project/create-project');
          }}
        >
          {t('CreateFirstProject')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default WelcomeRebel;
