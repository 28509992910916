/* eslint-disable no-console */
/* eslint-disable camelcase */
import { reset } from 'redux-form';
import { request } from 'functions';
import { removePopups } from 'store/popup/popupActions';
import {
  SET_PROJECT_DATA_LOADED,
  doRedirect,
  RESET_PROJECT_DATA_LOADED,
} from 'store/metadata/metadataActions';
import { fetchBuildersOverview } from 'store/builderData/builderDataActions';
import { invitationSentPopup } from 'functions/popup/invitationSentPopup';
import { triggerErrorPopup } from 'functions/popup/triggerErrorPopup';
import { subjectChangedPopup } from 'functions/popup/subjectChangedPopup';

// invitations
export const SET_INVITATIONS = 'SET_INVITATIONS';
export const SET_INVITATION = 'SET_INVITATION';

// USER PROJECT PREFERENCES
export const SET_USER_PROJECT_PREFERENCES =
  'projectData/SET_USER_PROJECT_PREFERENCES';
export const SET_USER_PROJECT_PREFERENCE =
  'projectData/SET_USER_PROJECT_PREFERENCE';

function fetchTeammateInvitations(
  url = `${
    (window.env || process.env).REACT_APP_API_ENDPOINT
  }/api/v2/rebel/project/invitations`
) {
  return (dispatch, getState) => {
    const { user } = getState();
    request(url, user.get('jwt')).then((response) => {
      dispatch({ type: SET_INVITATIONS, data: response.data });
    });
  };
}

export function sendInvitation(projectId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch(removePopups());
    const { user, userEmailAddresses, form, projectData } = getState();
    const project_id = parseInt(projectId, 10);
    const email_address = form.InviteTeammateForm.values.email.toLowerCase();
    const user_project_role_type_id =
      form.InviteTeammateForm.values.role_type_id;
    const { invitations } = getState().projectData;

    // validate
    if (
      userEmailAddresses.find(
        (uea) => uea.get('email_address') === email_address
      )
    ) {
      // cannot invite yourself
      triggerErrorPopup('TryingToInviteYourself', dispatch);
      return;
    }

    if (
      projectData.teammates
        .filter(
          (tm) =>
            tm.get('project_id') === project_id &&
            tm.get('email_address') === email_address
        )
        .count() > 0
    ) {
      // already in team
      triggerErrorPopup('PersonAlreadyInYourTeam', dispatch);
      return;
    }

    if (
      invitations.find(
        (i) =>
          i.get('email_address') === email_address &&
          i.get('project_id') === project_id
      )
    ) {
      // already invited
      triggerErrorPopup('InvitationAlreadySent', dispatch);

      return;
    }

    const jwt = user.get('jwt');
    const payload = {
      project_invitation: {
        project_id,
        email_address,
        user_project_role_type_id,
      },
    };
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/invitations`;
    request(url, jwt, 'POST', payload)
      .then((response) => {
        if (response.status === 422) {
          triggerErrorPopup('UserAlreadyPartOfProject', dispatch);
        } else {
          dispatch({ type: SET_INVITATION, data: response.data });
          invitationSentPopup('InvitationSent', dispatch);
          dispatch(reset('InviteTeammateForm'));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function resendInvitation(invitationId, projectId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const payload = {
      resend_invitation: {
        project_id: parseInt(projectId, 10),
        invitation_id: invitationId,
      },
    };
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/invitations/`;
    request(url, jwt, 'POST', payload)
      .then(() => {
        invitationSentPopup('InvitationResent', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

export const REMOVE_INVITATION = 'REMOVE_INVITATION';
export function deleteInvitation(invitationId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/invitations/${invitationId}`;
    request(url, jwt, 'DELETE')
      .then(() => {
        dispatch({ type: REMOVE_INVITATION, id: invitationId });
      })
      .catch((err) => console.log(err));
  };
}

// teammates
export const SET_TEAMMATES = 'SET_TEAMMATES';
export const SET_TEAMMATE = 'SET_TEAMMATE';

function fetchTeammates(
  url = `${
    (window.env || process.env).REACT_APP_API_ENDPOINT
  }/api/v2/rebel/project/teammates`
) {
  return (dispatch, getState) => {
    const { user } = getState();
    request(url, user.get('jwt')).then((response) => {
      dispatch({ type: SET_TEAMMATES, data: response.data });
    });
  };
}

// Permissions
export function changeTeammateRole(teammateId, values) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user } = getState();
    const newRoleValue = parseInt(values[`permission_id-${teammateId}`], 10);
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/teammates/${teammateId}`;
    const payload = {
      user_project_role: {
        user_project_role_type_id: newRoleValue,
      },
    };
    request(url, user.get('jwt'), 'PUT', payload)
      .then((response) => {
        dispatch({ type: SET_TEAMMATE, data: response.data });
        // reset form field
        dispatch({
          type: '@@redux-form/CHANGE',
          meta: { field: `permission_id-${teammateId}`, form: 'ProjectForm' },
          payload: null,
        });
      })
      .then(() => subjectChangedPopup('RoleChanged', dispatch));
  };
}

// titles
export function changeTeammateTitle(teammateId, values) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user } = getState();
    const title = (values && values[`title-${teammateId}`]) || values;
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/teammates/${teammateId}`;
    const payload = {
      user_project_role: {
        title,
      },
    };
    request(url, user.get('jwt'), 'PUT', payload)
      .then((response) => {
        dispatch({ type: SET_TEAMMATE, data: response.data });
      })
      .then(() => subjectChangedPopup('TitleChanged', dispatch));
  };
}

// Online Teammates
export const SET_ONLINE_TEAMMATES = 'SET_ONLINE_TEAMMATES';

export function setOnlineTeammates(teammates) {
  return {
    type: SET_ONLINE_TEAMMATES,
    teammates,
  };
}

export const REMOVE_OFFLINE_TEAMMATES = 'REMOVE_OFFLINE_TEAMMATES';

export function removeOfflineTeammates(teammates) {
  return {
    type: REMOVE_OFFLINE_TEAMMATES,
    teammates,
  };
}

function updateUserProjectPreference(payload) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/user-project-preferences`;

    return request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_USER_PROJECT_PREFERENCE, data: response.data });
        dispatch(fetchBuildersOverview(response.data.project_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateSelectedTrack(projectId, challengeTrackBlueprintId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const payload = {
      user_project_preference: {
        user_id: user.get('id'),
        project_id: parseInt(projectId, 10),
        is_track_view: true,
        selected_challenge_track_blueprint_id: parseInt(
          challengeTrackBlueprintId,
          10
        ),
        is_group_view: false,
        selected_hub_group_id: null,
      },
    };

    dispatch(updateUserProjectPreference(payload)).then(
      dispatch(doRedirect(`/project/${projectId}/builders`))
    );
  };
}

export function updateSelectedGroup(projectId, groupId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const payload = {
      user_project_preference: {
        user_id: user.get('id'),
        project_id: parseInt(projectId, 10),
        is_track_view: false,
        selected_challenge_track_blueprint_id: null,
        is_group_view: true,
        selected_hub_group_id: parseInt(groupId, 10),
      },
    };

    dispatch(updateUserProjectPreference(payload)).then(
      dispatch(doRedirect(`/project/${projectId}/builders`))
    );
  };
}

export function toggleChallengeGroupingView(projectId, viewBool) {
  return (dispatch, getState) => {
    const { user } = getState();

    const payload = {
      user_project_preference: {
        user_id: user.get('id'),
        is_track_view: viewBool,
        project_id: parseInt(projectId, 10),
      },
    };

    dispatch(updateUserProjectPreference(payload));
  };
}

export function fetchUserProjectPreferences() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/project/user-project-preferences`;
    request(url, jwt).then((response) => {
      dispatch({ type: SET_USER_PROJECT_PREFERENCES, data: response.data });
    });
  };
}

export function fetchProjectData() {
  return (dispatch) => {
    dispatch({ type: RESET_PROJECT_DATA_LOADED });
    Promise.all([
      dispatch(fetchTeammateInvitations()),
      dispatch(fetchTeammates()),
      // dispatch(fetchUserProjectPreferences()),
    ]).then(() => {
      dispatch({ type: SET_PROJECT_DATA_LOADED });
    });
  };
}
