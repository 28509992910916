import { popup } from 'store/popup/popupActions';

export const successPopup = (
  message = null,
  autoDismiss = 5,
  level = 'success',
  title = null,
  position = 'tc',
  dismissable = true,
  children = null,
  uid = null
) => {
  return (dispatch) => {
    return dispatch(
      popup(
        message,
        level,
        title,
        position,
        autoDismiss,
        dismissable,
        children,
        uid
      )
    );
  };
};
