import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import Profile from 'Pages/Profile';
import CreateHub from 'Pages/CreateHub';
import ResetPassword from 'Pages/Auth/ResetPassword';
import EmailVerification from 'Pages/Auth/EmailVerification';
import ForgotPasswordContainer from 'Pages/Auth/ForgotPassword/ForgotPasswordContainer';
import ResearchData from 'Pages/ResearchData/ResearchData';
import NoMatch from 'Pages/NoMatch';
import Dashboard from 'Pages/Dashboard';
import useRebelContext from 'hooks/useRebelContext';
import useSetInviteToken from 'hooks/useSetInviteToken';
// import ConsentPage from 'views/account/consentPage/ConsentPage';
import MainLayout from 'components/AppLayout/MainLayout/MainLayout';
import SignInContainer from 'Pages/Auth/SignIn/SignInContainer';
import Cookie from 'js-cookie';
import LoadingComponent from 'components/Loading/loadingComponent';
import Invitation from 'Pages/Accept/Invitation';
import { invitationTypes } from 'store/invitations/invitationActions';
import * as Lazy from './lazyRoutes';
import { AppRoute } from './components/RouteWrapper';
import {
  RebelEvents,
  RebelProjects,
  RebelHubs,
  RebelDesignGuides,
} from './components/RebelRouter';

const RouterComponent = () => {
  const {
    // hasUserConsented,
    userId,
    lang,
  } = useRebelContext();

  const { pathname, search } = window.location;
  const isLoggedIn = !!Cookie.get('token');

  const viewLoginPage = pathname === '/';
  const viewProfilePage =
    isLoggedIn && (viewLoginPage || pathname.includes('/auth'));

  // if (isLoggedIn && !hasUserConsented) {
  //   return <ConsentPage />;
  // }

  // eslint-disable-next-line no-unused-vars
  const email = useSetInviteToken(pathname, search);

  if (viewProfilePage && isLoggedIn) {
    if (userId) return <Redirect to="/dashboard" />;
    return <Redirect to="/" />;
  }

  if (!isLoggedIn && viewLoginPage) {
    return <Redirect to="/auth/login" />;
  }

  const contactUsPath = {
    ENG: 'https://rebelbase.co/contact-us',
    ESP: 'https://rebelbase.co/es/contact-us/',
  };

  if (pathname === '/contact-us') {
    window.location.replace(contactUsPath[lang]);
    return <LoadingComponent />;
  }

  return (
    <Switch>
      <Route
        path="/accept/create-hub-invitation"
        render={(props) => (
          <Invitation invitationType={invitationTypes.createHub} {...props} />
        )}
      />
      <Route
        path="/accept/hub-event-invitation"
        render={(props) => (
          <Invitation
            invitationType={invitationTypes.hubEventInvitation}
            {...props}
          />
        )}
      />
      <Route
        path="/accept/hub-invitation"
        render={(props) => (
          <Invitation
            invitationType={invitationTypes.hubInvitation}
            {...props}
          />
        )}
      />
      <Route
        path="/accept/project-invitation"
        render={(props) => (
          <Invitation
            invitationType={invitationTypes.projectInvitation}
            {...props}
          />
        )}
      />
      <Route path="/email-verification" component={EmailVerification} />
      <AppRoute
        path="/forgot-password"
        component={ForgotPasswordContainer}
        onEmptyLayout
      />
      <AppRoute
        path="/accept/reset-password"
        component={ResetPassword}
        onEmptyLayout
      />
      <Route path="/research-data-2020" component={ResearchData} />

      {/* Auth Routes*/}
      <AppRoute path="/auth/login" component={SignInContainer} onEmptyLayout />
      <AppRoute path="/auth/sign-up" component={Lazy.SignUp} onEmptyLayout />

      <Route
        render={() => (
          <MainLayout>
            <Switch>
              {/* Events Routes */}
              <Route path="/events" component={RebelEvents} />
              {/* Project Routes */}
              <Route path="/project" component={RebelProjects} />
              {/* Hubs Routes */}
              <Route path="/hubs" component={RebelHubs} />
              <AppRoute path="/profile/:u_id" component={Profile} />
              <AppRoute
                path="/notifications"
                component={Lazy.Notifications}
                isProtected
              />
              <AppRoute
                path="/invitations"
                component={Lazy.Invitations}
                isProtected
              />
              <AppRoute
                path="/settings"
                component={Lazy.Settings}
                exact
                isProtected
              />
              <AppRoute
                path="/create-hub/:h_id/:step"
                component={CreateHub}
                isProtected
              />
              <AppRoute
                path="/create-hub/:h_id"
                component={CreateHub}
                isProtected
              />
              <AppRoute path="/dashboard" component={Dashboard} isProtected />
              <AppRoute path="/browse" component={Lazy.Browse} />
              <Route path="/design-guides" component={RebelDesignGuides} />

              {/* 404 */}
              <Route component={NoMatch} />
            </Switch>
          </MainLayout>
        )}
      />
    </Switch>
  );
};

export default withLastLocation(RouterComponent);
