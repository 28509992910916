import React from 'react';
import {
  HubActivity,
  HubActivityTypes,
  ActivityReplyTypes,
  ActivityReply,
} from 'utils/types/alert';
import { WithTranslation } from 'react-i18next';
import { CustomLink, SenderUsers, Props } from '../Components';

const createNotificationString = (
  countObj: { [key: number]: number },
  activitesObjArr: HubActivity[] | ActivityReply[],
  enumToCheck: typeof HubActivityTypes | typeof ActivityReplyTypes,
  t: WithTranslation['t']
): string => {
  const counts = countObj;
  activitesObjArr.forEach((activity) => {
    counts[activity.typeId] += 1;
  });
  return Object.entries(counts)
    .filter((entry) => entry[1] > 0)
    .map(
      (entry) =>
        `${entry[1]} ${t((enumToCheck as any)[entry[0]])}${
          entry[1] > 1 ? 's' : ''
        }`
    )
    .join(', ');
};

const activitiesString = (
  activities: HubActivity[],
  t: WithTranslation['t']
): string => {
  const counts: { [key: number]: number } = {
    [HubActivityTypes.Post]: 0,
    [HubActivityTypes.Idea]: 0,
    [HubActivityTypes.Question]: 0,
    [HubActivityTypes.Offer]: 0,
  };
  return createNotificationString(counts, activities, HubActivityTypes, t);
};

const ideaReplyString = (
  activityReplies: ActivityReply[],
  t: WithTranslation['t']
): string => {
  if (activityReplies.length === 1)
    return `a ${ActivityReplyTypes[activityReplies[0].typeId]}`;
  const counts: { [key: number]: number } = {
    [ActivityReplyTypes.Brainstorm]: 0,
    [ActivityReplyTypes.Question]: 0,
    [ActivityReplyTypes.Offer]: 0,
  };
  return createNotificationString(
    counts,
    activityReplies,
    ActivityReplyTypes,
    t
  );
};

export const HubAnnouncementCreated: React.FC<Props> = ({ alert, t }) => (
  <>
    <span>{t('announcementCreated')} </span>
    <CustomLink
      to={`/profile/${alert.senderUsers[0]?.userId}`}
    >{`${alert.senderUsers[0]?.firstName} ${alert.senderUsers[0]?.lastName}`}</CustomLink>
  </>
);

export const HubActivityCreated: React.FC<Props> = ({ alert, t }) => (
  <span>
    {activitiesString(alert.hubActivities, t)} {t('lastBeen')}
  </span>
);

interface HubActityReplyProps extends Props {
  userId: number;
}
export const HubActivityReply: React.FC<HubActityReplyProps> = ({
  alert,
  userId,
  t,
}) => {
  const body = React.useMemo(() => {
    if (
      alert.activityReplies &&
      alert.activityReplies[0]?.parentReplyId &&
      alert.activityReplies[0]?.parentReplyTypeId
    ) {
      const article =
        alert.activityReplies[0]?.parentReplyTypeId ===
          ActivityReplyTypes.Answer ||
        alert.activityReplies[0]?.parentReplyTypeId === ActivityReplyTypes.Offer
          ? t('an')
          : t('a');
      return ` ${t('repliedTo')} ${article} ${t(
        ActivityReplyTypes[alert.activityReplies[0]?.parentReplyTypeId]
      )}`;
    }
    if (
      alert.hubActivities &&
      alert.hubActivities[0]?.typeId === HubActivityTypes.Question
    ) {
      return ` ${
        alert.hubActivities[0]?.ownerUserId === userId
          ? t('answeredYourQuestion')
          : t('answeredAQuestion')
      }`;
    }
    if (
      alert.hubActivities &&
      alert.hubActivities[0]?.typeId === HubActivityTypes.Idea
    ) {
      return ` ${t('added')} ${ideaReplyString(alert.activityReplies, t)}`;
    }
    const article =
      alert.hubActivities &&
      alert.hubActivities[0].typeId === HubActivityTypes.Post
        ? t('a')
        : t('an');
    return ` ${t('repliedTo')} ${
      alert.hubActivities[0]?.ownerUserId === userId ? 'your' : article
    } ${
      alert.hubActivities && alert.hubActivities[0]?.typeId
        ? t(HubActivityTypes[alert.hubActivities[0].typeId])
        : ''
    }`;
  }, [alert]);

  return (
    <>
      <SenderUsers users={alert.senderUsers} />
      <span>{body}</span>
    </>
  );
};

export const HubActivityKudos: React.FC<Props> = ({ alert, t }) => (
  <>
    <SenderUsers users={alert.senderUsers} />
    <span>{` ${t('gave')} ${
      alert.senderUsers.length === 1 ? `${t('a')} ` : ''
    }${t('kudosToYour')} ${t(
      HubActivityTypes[alert.hubActivities[0]?.typeId]
    )}`}</span>
  </>
);

export const HubActivityReplyKudos: React.FC<Props> = ({ alert, t }) => (
  <>
    <SenderUsers users={alert.senderUsers} />
    {alert.activityReplies[0].typeId === ActivityReplyTypes.Answer ? (
      <span>{` ${t('updvotedYourAns')}`}</span>
    ) : (
      <span>{` ${t('gave')} ${
        alert.senderUsers.length === 1 ? `${t('a')} ` : ''
      }${t('kudosToYour')} ${t(
        ActivityReplyTypes[alert.activityReplies[0]?.typeId]
      )}`}</span>
    )}
  </>
);
