import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import emptyJudgebio from 'images/empty_judge_bio.png';
import emptyJudgeView from 'images/empty_judge_view.png';
import loader from 'images/loader.gif';
import { updateJudgeBio } from 'store/events-v1/eventActions';
import TextAreaAutoResize from 'FormInputs/TextAreaAutoResize';
import {
  fetchJudgeBio,
  postJudgeBio,
} from '../../../store/profile/profileActions';

class JudgeBio extends React.Component {
  static renderLoadingState() {
    return (
      <div className="judge">
        <p className="contentInfo__title">Judge Bio for Events</p>
        loading...
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = { isEditingJudgeBio: false };
  }

  componentDidMount() {
    this.props.getJudgeBio();
  }

  renderSaveButton() {
    const { isSavingJudgeBio, t } = this.props;
    if (isSavingJudgeBio) {
      return <img alt="loading icon" src={loader} />;
    }
    return (
      <button className="userProfile__save-btn" type="submit">
        {t('save')}
      </button>
    );
  }

  renderEmptyState() {
    const { isUsersProfile, t } = this.props;

    // when viewing your own - allows edit
    if (isUsersProfile) {
      return (
        <div className="contentInfo__wrap">
          <div className="judge">
            <div className="userProfile__edit">
              <p className="contentInfo__title">{t('JudgeBio')}</p>
              <button
                className="edit-pen__btn edit-pen__judge"
                onClick={() => this.setState({ isEditingJudgeBio: true })}
              >
                <span className="edit-pen" />
              </button>
            </div>
            <div className="judge__empty">
              <div>
                <img
                  src={emptyJudgebio}
                  alt="empty bio prompt"
                  className="judge__empty"
                />
              </div>
              <div className="judge__empty__prompt">
                <span>{t('whoAreYou')}</span>
                <button
                  tabIndex="0"
                  className="judge__btn btn-link"
                  onClick={() => this.setState({ isEditingJudgeBio: true })}
                >
                  {t('EditBio')}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // when viewing someone else's
    return (
      <div className="contentInfo__wrap">
        <div className="judge">
          <div className="userProfile__edit">
            <p className="contentInfo__title">{t('JudgeBio')}</p>
          </div>
          <div className="judge__empty">
            <div>
              <img
                src={emptyJudgeView}
                alt="empty character waving hi"
                className="judge__empty"
              />
            </div>
            <div className="judge__empty__prompt">
              <span>{t('NoJudgeBio')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderViewState() {
    const { bio, title, isUsersProfile, t } = this.props;
    return (
      <div className="contentInfo__wrap">
        <div className="judge">
          <div className="userProfile__edit">
            <p className="contentInfo__title">{t('JudgeBio')}</p>
            {isUsersProfile && (
              <button
                className="edit-pen__btn edit-pen__judge"
                onClick={() => this.setState({ isEditingJudgeBio: true })}
              >
                <span className="edit-pen" />
              </button>
            )}
          </div>
          <div>
            <p>{title}</p>
          </div>
          <div>
            <p>{bio}</p>
          </div>
        </div>
      </div>
    );
  }

  renderEditState() {
    const { title, bio, judgeUpdate, t } = this.props;
    return (
      <div className="contentInfo__wrap">
        <div className="judge">
          <div className="userProfile__edit">
            <p className="contentInfo__title">{t('JudgeBio')}</p>
            <button
              onClick={() => this.setState({ isEditingJudgeBio: false })}
              className="btn-x"
            >
              x
            </button>
          </div>
          <Formik
            initialValues={{
              title: title || '',
              bio: bio || '',
            }}
            validationSchema={Yup.object({
              title: Yup.string().required('Title is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              judgeUpdate(values.title, values.bio, this.props.getJudgeBio);
              this.setState({ isEditingJudgeBio: false });
              setSubmitting(false);
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Field
                    type="text"
                    name="title"
                    placeholder={t('Title')}
                    onBlur={props.handleBlur}
                    maxLength="50"
                  />
                  {props.errors.title && props.touched.title && (
                    <div className="errors">
                      <ErrorMessage name="title" />
                    </div>
                  )}

                  <Field
                    component={TextAreaAutoResize}
                    name="bio"
                    placeholder={t('JudgeBioPhrase')}
                  />

                  <div className="userProfile__save">
                    {this.renderSaveButton()}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }

  render() {
    const { isJudge, isLoaded, bio, title } = this.props;
    if (!isJudge) {
      return null;
    }

    if (isLoaded) {
      if (this.state.isEditingJudgeBio) {
        return this.renderEditState();
      }
      if (bio || title) {
        // incase there is no bio or title, check
        return this.renderViewState();
      }
      return this.renderEmptyState();
    }
    return this.constructor.renderLoadingState();
  }
}

const mapDispatchToProps = (dispatch) => ({
  save(event, component) {
    event.preventDefault();
    dispatch(postJudgeBio(true)).then(() =>
      component.setState({ isEditingJudgeBio: false })
    );
  },

  getJudgeBio() {
    dispatch(fetchJudgeBio());
  },

  judgeUpdate(title, bio, onSuccess) {
    dispatch(updateJudgeBio(title, bio, onSuccess));
  },
});

const mapStateToProps = (state, props) => {
  if (props.isUsersProfile) {
    const { userEventRoles } = state.hubs;
    const { userEventRoleTypes } = state.appData;

    const isJudge = !!userEventRoles.find(
      (uer) =>
        uer.get('user_id') === state.user.get('id') &&
        userEventRoleTypes
          .find((uert) => uert.get('id') === uer.get('user_event_role_type_id'))
          .get('name') === 'Judge'
    );
    if (!isJudge) {
      return {
        isJudge,
      };
    }

    const { judgeBio, pageMetaData } = state.profile;
    const isLoaded = pageMetaData.get('judgeBioLoaded');
    const bio = judgeBio.get('bio');
    const title = judgeBio.get('title');
    const initialValues = { bio, title };
    const isSavingJudgeBio = pageMetaData.get('isSavingSummary');
    return {
      bio,
      title,
      isJudge,
      isLoaded,
      initialValues,
      isSavingJudgeBio,
    };
  }

  const { browse } = state;
  const judgeBio = browse.browseProfile.get('judgeBio');

  const isJudge = judgeBio ? !!judgeBio.get('bio') : false;

  return {
    isJudge,
    bio: isJudge ? judgeBio.get('bio') : null,
    title: isJudge ? judgeBio.get('title') : null,
    isLoaded: true,
  };
};

export default withTranslation(['translation.Profile'])(
  connect(mapStateToProps, mapDispatchToProps)(JudgeBio)
);
