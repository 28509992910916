/* eslint-disable no-console */
import { request } from 'functions';
import { BEGIN_REQUEST, FINISH_REQUEST } from 'store/metadata/metadataActions';
import { API_POST } from './middlewareConstants';

const apiPostMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type !== API_POST) {
    return next(action);
  }
  const {
    payload: { url, success, body, onSuccess, token, onError },
  } = action;

  dispatch({ type: BEGIN_REQUEST });

  request(url, token, 'POST', body)
      .then((res) => {
        if (success && (typeof success === 'function')) {
          dispatch(success(res.data));
        }
        if (onSuccess && (typeof onSuccess === 'function')) {
          onSuccess(res.data);
        }
      })
      .then(() => dispatch({ type: FINISH_REQUEST }))
      .catch((err) => {
        if(onError && (typeof onError === 'function')) {
          onError({ error: err.status });
        }
        console.error(err);
        dispatch({ type: FINISH_REQUEST });
      });

  return false;
};

export default apiPostMiddleware;
