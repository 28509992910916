import { fromJS, Map } from 'immutable';
import { combineReducers } from 'redux';

import { SET_DELTA, SET_TEXT, FLUSH_DELTAS, FLUSH_TEXTS } from './quillActions';

const initialDelta = Map();
function delta(state = initialDelta, action = {}) {
  switch (action.type) {
    case SET_DELTA:
      return state.set(action.name, fromJS({ ...action.delta }));
    case FLUSH_DELTAS:
      return initialDelta;
    default:
      return state;
  }
}

const initialText = Map();
function text(state = initialText, action = {}) {
  switch (action.type) {
    case SET_TEXT:
      return state.set(action.name, action.textValue);
    case FLUSH_TEXTS:
      return initialText;
    default:
      return state;
  }
}

export default combineReducers({
  delta,
  text,
});
