import {
  industries,
  userProjectRoleTypes,
  userHubRoleTypes,
  userEventRoleTypes,
} from 'utils/data';
import { request } from '../../functions';
// import { fetchExperience } from '../profile/profileActions';
import {
  SET_APP_DATA_LOADED,
  SET_CHALLENGE_WORKSHOPS_LOADED,
  RESET_APP_DATA_LOADED,
} from '../metadata/metadataActions';

export const SET_INDUSTRIES = 'SET_INDUSTRIES';
export const SET_PURPOSES = 'SET_PURPOSES';

const RECEIVE_EXPERIENCE = 'RECEIVE_EXPERIENCE';

function receiveExperience(data) {
  return {
    type: RECEIVE_EXPERIENCE,
    data: {
      ...data,
    },
  };
}

function fetchExperience() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const expUrl = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v1/rebel/user_profile/experiences`;

    request(expUrl, jwt, 'GET').then((json) =>
      dispatch(receiveExperience(json))
    );
  };
}

export function setIndustries(data) {
  return {
    type: SET_INDUSTRIES,
    data,
  };
}

export function fetchIndustries() {
  return (dispatch) => {
    dispatch(setIndustries(industries));
  };
}

// CHALLENGE BUCKET BLUEPRINTS
export const SET_CHALLENGE_BUCKET_BLUEPRINTS =
  'SET_CHALLENGE_BUCKET_BLUEPRINTS';

export function fetchChallengeBucketBlueprints() {
  return (dispatch) => {
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/public/challenge-builder/challenge-bucket-blueprints`;
    return request(url).then((response) =>
      dispatch({
        type: SET_CHALLENGE_BUCKET_BLUEPRINTS,
        data: response.data,
      })
    );
  };
}

// CHALLENGE TRACK BLUEPRINTS
export const SET_CHALLENGE_TRACK_BLUEPRINTS = 'SET_CHALLENGE_TRACK_BLUEPRINTS';

export function fetchChallengeTrackBlueprints() {
  return (dispatch) => {
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/public/challenge-builder/challenge-track-blueprints`;
    return request(url).then((response) =>
      dispatch({
        type: SET_CHALLENGE_TRACK_BLUEPRINTS,
        data: response.data,
      })
    );
  };
}

// CHALLENGE BLUEPRINTS
export const SET_CHALLENGE_BLUEPRINTS = 'SET_CHALLENGE_BLUEPRINTS';

export function fetchChallengeBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    if (jwt) {
      const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
        }/api/v2/rebel/challenge-builder/challenge-blueprints`;

      return request(url, jwt).then((response) =>
        dispatch({
          type: SET_CHALLENGE_BLUEPRINTS,
          data: response.data,
        })
      );
    }
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/public/challenge-builder/challenge-blueprints`;
    return request(url).then((response) =>
      dispatch({
        type: SET_CHALLENGE_BLUEPRINTS,
        data: response.data,
      })
    );
  };
}

// TOPIC BLUEPRINTS
export const SET_TOPIC_BLUEPRINTS = 'SET_TOPIC_BLUEPRINTS';

export function fetchTopicBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');

    if (jwt) {
      const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
        }/api/v2/rebel/challenge-builder/topic-blueprints`;
      return request(url, jwt).then((response) =>
        dispatch({
          type: SET_TOPIC_BLUEPRINTS,
          data: response.data,
        })
      );
    }
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/public/challenge-builder/topic-blueprints`;
    return request(url).then((response) =>
      dispatch({
        type: SET_TOPIC_BLUEPRINTS,
        data: response.data,
      })
    );
  };
}

// ANSWER BLUEPRINTS
export const SET_ANSWER_BLUEPRINTS = 'SET_ANSWER_BLUEPRINTS';

export function fetchAnswerBlueprints() {
  return (dispatch) => {
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/public/challenge-builder/answer-blueprints`;
    return request(url).then((response) =>
      dispatch({
        type: SET_ANSWER_BLUEPRINTS,
        data: response.data,
      })
    );
  };
}

// SECTION BLUEPRINTS
export const SET_SECTION_BLUEPRINTS = 'appData/SET_SECTION_BLUEPRINTS';

export function fetchSectionBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/challenge-builder/section-blueprints`;

    return request(url, jwt).then((response) =>
      dispatch({
        type: SET_SECTION_BLUEPRINTS,
        data: response.data,
      })
    );
  };
}

// TOPIC SECTIONS
export const SET_TOPIC_SECTIONS = 'appData/SET_TOPIC_SECTIONS';

// TODO Remove this api calls and remove related stores from code base once refactor done in both builder and hubs
export function fetchTopicSections() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/challenge-builder/topic-sections`;

    return request(url, jwt).then((response) =>
      dispatch({
        type: SET_TOPIC_SECTIONS,
        data: response.data,
      })
    );
  };
}

export function getTopicSectionById(sectionId, projectId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/projects/${projectId}/foundry/sections/${sectionId}`;

    return request(url, jwt);
  };
}

export function fetchBuilderTopicInfo(projectId, builderId, topicId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/projects/${projectId}/foundry/builders/${builderId}/topics/${topicId}`;

    return request(url, jwt);
  };
}

// USER PROJECT ROLE TYPES
export const SET_USER_PROJECT_ROLE_TYPES =
  'appData/SET_USER_PROJECT_ROLE_TYPES';

export function fetchUserProjectRoleTypes() {
  return (dispatch) => {
    dispatch({
      type: SET_USER_PROJECT_ROLE_TYPES,
      data: userProjectRoleTypes,
    });
  };
}

// Organization Types
export const SET_ORGANIZATION_TYPES = 'appData/SET_ORGANIZATION_TYPES';
export function fetchOrganizationTypes() {
  return (dispatch) => {

    const data = [
      {
        "id": 1,
        "label": "Accelerator"
      },
      {
        "id": 2,
        "label": "Alumni Group"
      },
      {
        "id": 3,
        "label": "College"
      },
      {
        "id": 4,
        "label": "For-Profit"
      },
      {
        "id": 5,
        "label": "Non-Profit"
      },
      {
        "id": 6,
        "label": "Government"
      }
    ]
    dispatch({
      type: SET_ORGANIZATION_TYPES,
      data,
    })
  };
}

// USER HUB ROLE TYPES
export const SET_USER_HUB_ROLE_TYPES = 'appData/SET_USER_HUB_ROLE_TYPES';

export function fetchUserHubRoleTypes() {
  return (dispatch) => {
    dispatch({
      type: SET_USER_HUB_ROLE_TYPES,
      data: userHubRoleTypes,
    });
  };
}

// event types
export const SET_EVENT_TYPES = 'appData/SET_EVENT_TYPES';

export function fetchEventTypes() {
  return (dispatch) => {
    dispatch({
      type: SET_EVENT_TYPES,
      data: [
        'competition',
        'selection',
        'meetup',
        'assessment',
        'challenge',
        'other',
      ],
    });
  };
}

// user event role types
export const SET_USER_EVENT_ROLE_TYPES = 'appData/SET_USER_EVENT_ROLE_TYPES';
export function fetchUserEventRoleTypes() {
  return (dispatch) => {
    dispatch({ type: SET_USER_EVENT_ROLE_TYPES, data: userEventRoleTypes });
  };
}

export const fetchSectionChallengeTopicBlueprints = () => (dispatch) => {
  Promise.all([
    // dispatch(fetchChallengeBlueprints()),
    // dispatch(fetchTopicBlueprints()),
    // dispatch(fetchSectionBlueprints()),
  ]).then(() => {
    dispatch({ type: SET_APP_DATA_LOADED });
    dispatch({ type: SET_CHALLENGE_WORKSHOPS_LOADED });
  });
};

export function fetchAppData() {
  return (dispatch) => {
    dispatch({ type: RESET_APP_DATA_LOADED });
    dispatch(fetchOrganizationTypes());
    dispatch(fetchIndustries());
    dispatch(fetchExperience());
    // dispatch(fetchChallengeBucketBlueprints());
    // dispatch(fetchChallengeTrackBlueprints());
    // dispatch(fetchAnswerBlueprints());
    dispatch(fetchUserProjectRoleTypes());
    dispatch(fetchUserHubRoleTypes());
    dispatch(fetchEventTypes());
    dispatch(fetchUserEventRoleTypes());
    // dispatch(fetchTopicSections());
  };
}

export function fetchPublicAppData() {
  return (dispatch) => {
    dispatch({ type: RESET_APP_DATA_LOADED });
    Promise.all([
      dispatch(fetchIndustries()),
      // dispatch(fetchChallengeBucketBlueprints()),
      // dispatch(fetchChallengeTrackBlueprints()),
      // dispatch(fetchChallengeBlueprints()),
      // dispatch(fetchTopicBlueprints()),
      // dispatch(fetchAnswerBlueprints()),
      dispatch(fetchUserProjectRoleTypes()),
      dispatch(fetchOrganizationTypes()),
      dispatch(fetchUserEventRoleTypes()),
    ]).then(() => dispatch({ type: SET_APP_DATA_LOADED }));
  };
}
