export const userHubRoleTypes = [
  {
    description: 'Lorem Ipsum.',
    id: 1,
    is_assignable: true,
    label: 'Owner',
    level: 100,
    name: 'Owner',
  },
  {
    description: 'Lorem Ipsum.',
    id: 2,
    is_assignable: true,
    label: 'Manager',
    level: 90,
    name: 'Manager',
  },
  {
    description: 'Lorem Ipsum.',
    id: 3,
    is_assignable: true,
    label: 'Member',
    level: 50,
    name: 'Member',
  },
];
