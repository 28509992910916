
export enum RebelBaseRole {
    OWNER_USERS,
    ADMIN_USERS,
    MEMBER_USERS,
    SUPPORT_USERS
}

/**
 * return users role type
 * @param level
 * @constructor
 */
export function RoleFactory (level: number): RebelBaseRole {

    if (level > 90) {
        return RebelBaseRole.OWNER_USERS;
    }

    if (level > 50) {
        return RebelBaseRole.ADMIN_USERS;
    }

    if (level > 30) {
        return RebelBaseRole.MEMBER_USERS;
    }

    return RebelBaseRole.SUPPORT_USERS;
}