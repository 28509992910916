/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { WebSocketConnect, WebSocketConnectClass } from 'socket';
import {
  setOnlineTeammates,
  removeOfflineTeammates,
  SET_TEAMMATE,
} from 'store/projectData/projectDataActions';
import { fetchProjectsOnUpdate } from 'store/project/projectActions';
// import { processChannelTopicUpdate } from './projectProfileActions';
import {
  SET_PROJECT_TOPIC_NOTE,
  DELETE_PROJECT_TOPIC_NOTE,
  SET_TEAMMATE_ON_TOPIC,
  REMOVE_TEAMMATE_ON_TOPIC,
  SET_BROWSE_PROJECT_CHANNEL_LOADED,
  SET_PROJECT_CHANNEL,
} from './projectProfileConstants';

export function initializeRebelProjectChannel(projectId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const channelName = WebSocketConnectClass.makeChannelName(WebSocketConnectClass.REBEL_PROJECT_ROOM_LOBBY, projectId);
    const channel = WebSocketConnect.channel(user.get('jwt'), channelName);

    channel
      .join()
      .receive('ok', () =>
        dispatch({ type: SET_PROJECT_CHANNEL, projectId, channel })
      )
      .receive('error', (error) => {
        console.log('error', error);
      });

    channel.on('presence_state', (state) => {
      dispatch(setOnlineTeammates(state));
    });

    // Event sent from server that tells us there is diff of users in channel
    channel.on('presence_diff', (payload) => {
      if (payload.joins) {
        dispatch(setOnlineTeammates(payload.joins));
      }

      if (payload.leaves) {
        dispatch(removeOfflineTeammates(payload.leaves));
      }
    });

    // update project data
    channel.on('project_profile_updated', () => {
      dispatch(fetchProjectsOnUpdate());
    });

    // if a user is on a challenge topic
    channel.on('joined_project_challenge_topic', (payload) => {
      // if userid is not current users, add to user list
      dispatch({
        type: SET_TEAMMATE_ON_TOPIC,
        topicId: payload.topicId,
        isEditing: payload.isEditing,
        userId: payload.userId,
      });
    });

    // if a user is on a challenge topic
    channel.on('editing_project_topic', (payload) => {
      // if userid is not current users, add to user list
      dispatch({
        type: SET_TEAMMATE_ON_TOPIC,
        topicId: Number(payload.topicId),
        isEditing: payload.isEditing,
        userId: payload.userId,
      });
    });

    // if a user leaves a topic
    channel.on('left_project_challenge_topic', (payload) => {
      dispatch({
        type: REMOVE_TEAMMATE_ON_TOPIC,
        userId: payload.userId,
        topicId: payload.topicId,
      });
      // if userid is not current users, add to user list
      // if (payload.userId !== user.get('id')) {
      //   dispatch(removeTeammateOnTopic(payload.topicId, payload.userId));
      // }
    });

    // // when a topic is saved
    // channel.on('topic_updated', (payload) => {
    //   dispatch(processChannelTopicUpdate(payload));
    // });

    // when a user in the project has been updated
    channel.on('user_project_role_updated', (payload) => {
      dispatch({ type: SET_TEAMMATE, data: payload.data });
    });
  };
}

export function broadcastJoinProjectChallengeTopic(projectId, topicId) {
  return (dispatch, getState) => {
    const channel = getState().projectProfilev1.projectChannels.get(
      parseInt(projectId, 10)
    );

    if(channel) {
      channel.push(`join:project_topic:${topicId}`, { topicId });
    }
  };
}

export function broadcastEditProjectChallengeTopic(projectId, topicId) {
  return (dispatch, getState) => {
    const channel = getState().projectProfilev1.projectChannels.get(
      parseInt(projectId, 10)
    );
    if(channel) {
      channel.push(`edit:project_topic:${topicId}`, { topicId });
    }
  };
}

export function broadcastLeaveProjectChallengeTopic(projectId, topicId) {
  return (dispatch, getState) => {
    const channel = getState().projectProfilev1.projectChannels.get(
      parseInt(projectId, 10)
    );
    if(channel) {
      channel.push(`leave:project_topic:${topicId}`, { topicId });
    }
  };
}
//

export function broadcastTopicAnswerUpdated(projectId, topicId, payload) {
  return (dispatch, getState) => {
    const channel = getState().projectProfilev1.projectChannels.get(
        parseInt(projectId, 10)
    );
    if(channel) {
      channel.push(`rebel_project_room:lobby:${projectId}:project_topic:${topicId}`, { payload });
    }
  };
}

// this function is to join a project channel when viewing other people's projects
export function initializeProjectProfileChannel(projectId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const channelName = WebSocketConnectClass.makeChannelName(WebSocketConnectClass.PROJECT_PROFILE_ROOM, projectId);
    const channel = WebSocketConnect.channel(user.get('jwt'), channelName);

    channel
      .join()
      .receive('ok', () =>
        dispatch({ type: SET_BROWSE_PROJECT_CHANNEL_LOADED })
      )
      .receive('error', (error) => console.log('error', error));

    // when a new note is posted
    channel.on('new:project_topic_note', (payload) => {
      // add note to store
      dispatch({ type: SET_PROJECT_TOPIC_NOTE, data: [payload.data] });
    });

    // when a note is deleted
    channel.on('delete:project_topic_note', (payload) => {
      // delete the note from store
      dispatch({
        type: DELETE_PROJECT_TOPIC_NOTE,
        projectTopicNoteId: payload.project_topic_note_id,
      });
    });
  };
}
