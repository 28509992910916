/* eslint-disable no-console */
import { combineReducers } from 'redux';
import { Map, List, fromJS } from 'immutable';
import {
  SET_PROJECTPROFILEABOUT_QUILL_DELTA,
  SET_PROJECTPROFILEABOUT_QUILL_HTML,
  SET_PROJECT_TOPIC_KUDOS,
  SET_PROJECT_ALL_TOPIC_KUDOS,
  REMOVE_PROJECT_TOPIC_KUDOS,
  OPEN_PROJECT_TOPIC_NOTES,
  CLOSE_PROJECT_TOPIC_NOTES,
  SET_PROJECT_TOPIC_NOTES,
  SET_PROJECT_TOPIC_NOTE,
  DELETE_PROJECT_TOPIC_NOTE,
  SET_PROJECT_CHALLENGES,
  SET_CHALLENGES,
  UPDATE_CHALLENGE,
  SET_PROJECT_TOPICS,
  SET_TOPICS,
  SET_TOPIC,
  SET_ANSWER,
  SET_ANSWERS,
  SET_PROJECT_ANSWERS,
  SET_CONFLICTING_ANSWERS,
  RESOLVE_CONFLICT,
  SET_TEAMMATE_ON_TOPIC,
  REMOVE_TEAMMATE_ON_TOPIC,
  SET_BROWSE_ALL_PROJECTS,
  SET_FILTERED_PROJECTS,
  SET_FILTER,
  REMOVE_FILTER,
  CLEAR_FILTERS,
  SET_PROJECT_DETAILS,
  SET_PROJECT_TEAM,
  SET_BROWSE_PROJECT_CHANNEL_LOADED,
  CLEAR_BROWSE_PROJECT_DATA,
  CLOSE_PROJECT_NOTES,
  SET_PROJECT_CHANNEL, SET_FILTER_QUERY,
} from './projectProfileConstants';
import { REMOVE_OFFLINE_TEAMMATES } from '../projectData/projectDataActions';

function quill(state = { loading: true, delta: {}, html: '' }, action) {
  try {
    switch (action.type) {
      case SET_PROJECTPROFILEABOUT_QUILL_DELTA:
        return {
          ...state,
          loading: false,
          delta: { ...action.data },
        };

      case SET_PROJECTPROFILEABOUT_QUILL_HTML:
        return {
          ...state,
          loading: false,
          html: action.data,
        };
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
  }

  return false;
}

const initProjectTopicKudos = List();
const initProjectTopicNotes = List();
const initOpenedProjectTopicNotes = List();

function projectTopicKudos(state = initProjectTopicKudos, action) {
  switch (action.type) {
    case SET_PROJECT_ALL_TOPIC_KUDOS:
      return initProjectTopicKudos.concat(fromJS(action.data));
    case SET_PROJECT_TOPIC_KUDOS:
      return state
        .filterNot((kudos) => kudos.get('id') === action.data)
        .concat(fromJS(action.data));
    case REMOVE_PROJECT_TOPIC_KUDOS:
      return state.filterNot(
        (kudos) => kudos.get('id') === action.projectTopicKudosId
      );
    case CLEAR_BROWSE_PROJECT_DATA:
      return initProjectTopicKudos;
    default:
      return state;
  }
}

function projectTopicNotes(state = initProjectTopicNotes, action) {
  switch (action.type) {
    case SET_PROJECT_TOPIC_NOTES:
      return initProjectTopicNotes
        .filterNot((note) => note.get('id') === action.projectTopicNoteId)
        .concat(fromJS(action.data));
    case SET_PROJECT_TOPIC_NOTE:
      return state
        .filterNot((note) => note.get('id') === action.data[0].id)
        .concat(fromJS(action.data));
    case DELETE_PROJECT_TOPIC_NOTE:
      return state.filterNot(
        (note) => note.get('id') === action.projectTopicNoteId
      );
    case CLEAR_BROWSE_PROJECT_DATA:
      return initProjectTopicNotes;
    default:
      return state;
  }
}

function openedProjectTopicNotes(state = initOpenedProjectTopicNotes, action) {
  switch (action.type) {
    case OPEN_PROJECT_TOPIC_NOTES:
      return state.push(action.topicId);
    case CLOSE_PROJECT_TOPIC_NOTES:
      return state.filterNot((t) => t === action.topicId);
    case CLOSE_PROJECT_NOTES:
      return initOpenedProjectTopicNotes;
    case CLEAR_BROWSE_PROJECT_DATA:
      return initProjectTopicNotes;
    default:
      return state;
  }
}

const initChallengesBrowseChallenges = List();
function browseChallenges(state = initChallengesBrowseChallenges, action) {
  switch (action.type) {
    case SET_PROJECT_CHALLENGES:
      return fromJS(action.data);
    case CLEAR_BROWSE_PROJECT_DATA:
      return initChallengesBrowseChallenges;
    default:
      return state;
  }
}

const initChallengesProjectChallenges = List();
function projectChallenges(state = initChallengesProjectChallenges, action) {
  switch (action.type) {
    case SET_CHALLENGES:
      return fromJS(action.data);
    case UPDATE_CHALLENGE:
      return state.set(
        state.findKey((c) => c.get('id') === action.data.id),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

const initBrowseTopics = List();
function browseTopics(state = initBrowseTopics, action) {
  switch (action.type) {
    case SET_PROJECT_TOPICS:
      return initBrowseTopics.merge(fromJS(action.data));
    case CLEAR_BROWSE_PROJECT_DATA:
      return initBrowseTopics;
    default:
      return state;
  }
}

const initProjectTopics = List();
function projectTopics(state = initProjectTopics, action) {
  switch (action.type) {
    case SET_TOPICS:
      return state.merge(fromJS(action.data));
    case SET_TOPIC:
      return state.set(
        state.findIndex((t) => t.get('id') === action.data.id),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

const initProjectAnswers = List();
function projectAnswers(state = initProjectAnswers, action) {
  switch (action.type) {
    case SET_ANSWERS:
      return state.merge(fromJS(action.data));
    case SET_ANSWER: {
      const answerIndex = state.findIndex((a) => a.get('id') === action.id);
      const newAnswer = state.get(answerIndex).merge(fromJS(action.data));
      return state.set(answerIndex, newAnswer);
    }
    default:
      return state;
  }
}

const initBrowseAnswers = List();
function browseAnswers(state = initBrowseAnswers, action) {
  switch (action.type) {
    case SET_PROJECT_ANSWERS: {
      const stateDataExist = state.findIndex(
        (a) => a.get('id') === action.data[0].id
      );
      if (stateDataExist === -1) return state.merge(fromJS(action.data));
      return state;
    }
    case CLEAR_BROWSE_PROJECT_DATA:
      return initBrowseAnswers;
    default:
      return state;
  }
}

const initConflictingAnswers = List();
function conflictingAnswers(state = initConflictingAnswers, action) {
  switch (action.type) {
    case SET_CONFLICTING_ANSWERS:
      return initConflictingAnswers.merge(fromJS(action.data));
    case RESOLVE_CONFLICT:
      return initConflictingAnswers;
    default:
      return state;
  }
}

const initTeammatesOnTopic = Map();
function teammatesOnTopic(state = initTeammatesOnTopic, action) {
  switch (action.type) {
    case SET_TEAMMATE_ON_TOPIC: {
      const topic = state.get(action.topicId)
        ? state
            .get(action.topicId)
            .filterNot((item) => item.get('userId') === action.userId)
            .push(Map({ userId: action.userId, isEditing: action.isEditing }))
        : List().push(
            Map({ userId: action.userId, isEditing: action.isEditing })
          );
      return state.set(action.topicId, topic);
    }
    case REMOVE_TEAMMATE_ON_TOPIC: {
      const topic = state.get(action.topicId)
        ? state
            .get(action.topicId)
            .filterNot((item) => item.get('userId') === action.userId)
        : null;
      return state.set(action.topicId, topic);
    }
    case REMOVE_OFFLINE_TEAMMATES: {
      const listOfUserIdsWhoLeft = Object.keys(action.teammates);
      return state.map((topic) =>
        topic.filterNot((u) => listOfUserIdsWhoLeft.includes(u.get('userId')))
      );
    }
    default:
      return state;
  }
}

const initAllProjects = List();
function allProjects(state = initAllProjects, action) {
  switch (action.type) {
    case SET_BROWSE_ALL_PROJECTS:
      return initAllProjects.merge(fromJS(action.data));
    default:
      return state;
  }
}

const initFilteredProjects = List();
function filteredProjects(state = initFilteredProjects, action) {
  switch (action.type) {
    case SET_FILTERED_PROJECTS:
      return action.filteredProjects;
    default:
      return state;
  }
}

// filters
const initFilters = Map(
  fromJS({
    industries: List(),
    query: ''
  })
);
function filters(state = initFilters, action) {
  switch (action.type) {
    case SET_FILTER:
      return state.set(
        action.filterType,
        initFilters.get(action.filterType).push(action.value)
      );
    case REMOVE_FILTER:
      return state.set(
        action.filterType,
        state.get(action.filterType).filterNot((v) => v === action.value)
      );
    case SET_FILTER_QUERY:
        return state.set('query', action.value || '');
    case CLEAR_FILTERS:
      return initFilters;
    default:
      return state;
  }
}

// when viewing a single project
const initProject = Map();
function project(state = initProject, action) {
  switch (action.type) {
    case SET_PROJECT_DETAILS:
      return state.merge(fromJS(action.data));
    case CLEAR_BROWSE_PROJECT_DATA:
      return initProject;
    default:
      return state;
  }
}

const initProjectTeam = List();
function projectTeam(state = initProjectTeam, action) {
  switch (action.type) {
    case SET_PROJECT_TEAM:
      return List().merge(fromJS(action.data));
    case CLEAR_BROWSE_PROJECT_DATA:
      return initProjectTeam;
    default:
      return state;
  }
}

const initProjectLoaded = Map(
  fromJS({
    challenges: false,
    topics: false,
    answers: false,
    channel: false,
  })
);
function projectLoaded(state = initProjectLoaded, action) {
  switch (action.type) {
    case SET_PROJECT_TOPICS:
      return state.set('topics', true);
    case SET_PROJECT_CHALLENGES:
      return state.set('challenges', true);
    case SET_PROJECT_ANSWERS:
      return state.set('answers', true);
    case SET_BROWSE_PROJECT_CHANNEL_LOADED:
      return state.set('channel', true);
    case CLEAR_BROWSE_PROJECT_DATA:
      return initProjectLoaded;
    default:
      return state;
  }
}

export function projectChannels(state = Map(), action) {
  switch (action.type) {
    case SET_PROJECT_CHANNEL:
      return state.set(action.projectId, action.channel);
    default:
      return state;
  }
}

export default combineReducers({
  quill,
  projectTopicKudos,
  projectTopicNotes,
  openedProjectTopicNotes,
  browseChallenges,
  projectChallenges,
  browseTopics,
  projectTopics,
  projectAnswers,
  browseAnswers,
  conflictingAnswers,
  teammatesOnTopic,
  allProjects,
  filteredProjects,
  filters,
  project,
  projectLoaded,
  projectTeam,
  projectChannels,
});
