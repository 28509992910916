import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NoMatch from 'Pages/NoMatch';
import { AppRoute, LazyModal } from './RouteWrapper';
import * as Lazy from '../lazyRoutes';

export const RebelEvents = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Switch>
            <AppRoute
                exact
                path={`${requestedUrl}`}
                component={Lazy.MyEvents}
                isProtected
            />
            <AppRoute
                path={`${requestedUrl}/:e_id/select-project`}
                component={Lazy.HubEvent}
                isProtected
            />
            <AppRoute
                path={`${requestedUrl}/:e_id/welcome-judge`}
                component={Lazy.HubEvent}
                isProtected
            />
            <AppRoute
                path={`${requestedUrl}/:e_id/welcome-resource`}
                component={Lazy.HubEvent}
                isProtected
            />
            <AppRoute
                path={`${requestedUrl}/:e_id/scoring/:r_id`}
                component={Lazy.ScoringContainer}
                isProtected
            />
            <AppRoute path={`${requestedUrl}/:e_id`} component={Lazy.HubEvent}/>
            <Route component={NoMatch}/>
        </Switch>
    );
};

export const RebelProjects = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <>
            <Switch>
                <AppRoute
                    path={`${requestedUrl}/create-project`}
                    component={Lazy.CreateProject}
                    isProtected
                />
                <AppRoute
                    exact
                    path={`${requestedUrl}/:p_id`}
                    component={Lazy.ProjectProfile}
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:cb_id/topics/:tb_id/:ts_type/:ts_id`}
                    component={Lazy.BuilderTopic}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:cb_id/topics/:tb_id/:ts_type`}
                    component={Lazy.BuilderTopic}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:cb_id/topics/:tb_id`}
                    component={Lazy.BuilderTopic}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:cb_id/intro`}
                    component={Lazy.BuilderIntro}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:cb_id/overview`}
                    component={Lazy.BuilderOverview}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:cb_id/review`}
                    component={Lazy.BuilderReview}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders/:c_id/:t_id`}
                    component={Lazy.BuilderTopic}
                    isProtected
                />
                <AppRoute
                    path={`${requestedUrl}/:p_id/builders`}
                    component={Lazy.MyProjectBuilders}
                    isProtected
                />
                <Route component={NoMatch}/>
            </Switch>
            <LazyModal
                path={`${requestedUrl}/:p_id/builders/select-track`}
                component={Lazy.SelectTrack}
            />
        </>
    );
};

// V7.3.10
// export const RebelHubs = ({match}) => {
//     const requestedUrl = match.url.replace(/\/$/, '');
//     return (
//         <>
//             <Switch>
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders/:cb_id/topics/:tb_id/:ts_type/:ts_id`}
//                     component={Lazy.HubViewBuilderTopic}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders/:cb_id/topics/:tb_id/:ts_type`}
//                     component={Lazy.HubViewBuilderTopic}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders/:cb_id/topics/:tb_id`}
//                     component={Lazy.HubViewBuilderTopic}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders/:cb_id/intro`}
//                     component={Lazy.HubViewBuilderIntro}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders/:cb_id/overview`}
//                     component={Lazy.HubViewBuilderOverview}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders/:c_id/:t_id`}
//                     component={Lazy.HubViewBuilderTopic}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/builders`}
//                     component={Lazy.HubViewBuilders}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/members`}
//                     component={Lazy.HubMembers}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/manage-members`}
//                     component={Lazy.HubManageMembers}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/brain`}
//                     component={Lazy.BrainLayout}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/groups`}
//                     component={Lazy.HubGroups}
//                     exact
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/groups/:g_id/schedule`}
//                     component={Lazy.HubGroupSchedule}
//                     exact
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/groups/:g_id`}
//                     component={Lazy.HubGroupLayout}
//                     exact
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/events`}
//                     component={Lazy.HubEvents}
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/activity`}
//                     component={Lazy.HubActivity}
//                     exact
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id/activities/:activity_id`}
//                     component={Lazy.SingleActivity}
//                     exact
//                     isProtected
//                 />
//                 <AppRoute
//                     path={`${requestedUrl}/:h_id`}
//                     component={Lazy.HubActivity}
//                     isProtected
//                 />
//                 <Route component={NoMatch}/>
//             </Switch>
//             <LazyModal
//                 path={`${requestedUrl}/:h_id/builders/select-track-for-hub`}
//                 component={Lazy.HubSelectTrack}
//             />
//             <LazyModal
//                 path={`${requestedUrl}/:h_id/members/:u_id`}
//                 component={Lazy.HubMember}
//             />
//             <LazyModal
//                 path={`${requestedUrl}/:h_id/members/:u_id/edit`}
//                 component={Lazy.EditHubMembership}
//             />
//             <LazyModal
//                 path={`${requestedUrl}/:h_id/welcome`}
//                 component={Lazy.HubJoinModal}
//             />
//         </>
//     );
// };

export const RebelHubs = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <>
      <Switch>
        {/*<AppRoute*/}
        {/*  path={`${requestedUrl}/:h_id/builders/:cb_id/topics/:tb_id/:ts_type/:ts_id`}*/}
        {/*  component={Lazy.HubViewBuilderTopic}*/}
        {/*  isProtected*/}
        {/*/>*/}
        {/*<AppRoute*/}
        {/*  path={`${requestedUrl}/:h_id/builders/:cb_id/topics/:tb_id/:ts_type`}*/}
        {/*  component={Lazy.HubViewBuilderTopic}*/}
        {/*  isProtected*/}
        {/*/>*/}
        {/*<AppRoute*/}
        {/*  path={`${requestedUrl}/:h_id/builders/:cb_id/topics/:tb_id`}*/}
        {/*  component={Lazy.HubViewBuilderTopic}*/}
        {/*  isProtected*/}
        {/*/>*/}
        <AppRoute
          path={`${requestedUrl}/:h_id/library/:builder_id/builders`}
          component={Lazy.HubLibraryLayout}
          isProtected
        />
        <AppRoute
            path={`${requestedUrl}/:h_id/library`}
            component={Lazy.HubLibraryLayout}
            isProtected
        />

        <AppRoute
          path={`${requestedUrl}/:h_id/members`}
          component={Lazy.HubMembers}
          isProtected
        />
        <AppRoute
            path={`${requestedUrl}/:h_id/manage-members`}
            component={Lazy.HubManageMembers}
            isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/brain`}
          component={Lazy.BrainLayout}
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/groups`}
          component={Lazy.HubGroups}
          exact
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/groups/:g_id/schedule`}
          component={Lazy.HubGroupSchedule}
          exact
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/groups/:g_id`}
          component={Lazy.HubGroupLayout}
          exact
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/events`}
          component={Lazy.HubEvents}
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/activity`}
          component={Lazy.HubActivity}
          exact
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id/activities/:activity_id`}
          component={Lazy.SingleActivity}
          exact
          isProtected
        />
        <AppRoute
          path={`${requestedUrl}/:h_id`}
          component={Lazy.HubActivity}
          isProtected
        />
        <Route component={NoMatch} />
      </Switch>
      <LazyModal
        path={`${requestedUrl}/:h_id/builders/select-track-for-hub`}
        component={Lazy.HubSelectTrack}
      />
      <LazyModal
        path={`${requestedUrl}/:h_id/members/:u_id`}
        component={Lazy.HubMember}
      />
      <LazyModal
        path={`${requestedUrl}/:h_id/members/:u_id/edit`}
        component={Lazy.EditHubMembership}
      />
      <LazyModal
        path={`${requestedUrl}/:h_id/welcome`}
        component={Lazy.HubJoinModal}
      />
    </>
  );
};

export const RebelDesignGuides = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <>
            <Switch>
                <AppRoute
                    path={`${requestedUrl}/typography`}
                    component={Lazy.CustomTypography}
                />
                <AppRoute
                    path={`${requestedUrl}/buttons`}
                    component={Lazy.CustomButtons}
                />
                <AppRoute
                    path={`${requestedUrl}/custom-form`}
                    component={Lazy.CustomForm}
                />
                <AppRoute
                    path={`${requestedUrl}/custom-spacing`}
                    component={Lazy.CustomSpacing}
                />
                <Route component={NoMatch}/>
            </Switch>
        </>
    );
};
