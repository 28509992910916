/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';

import {
  SET_GROUPS,
  SET_SINGLE_GROUP,
  SET_TEMPLATE,
  SET_GROUP_NAME,
  SET_ACTIVATION,
  REMOVE_DELETE,
  SET_CHANGED_PROJECT,
  SET_USER_PROJECT,
  SET_INITIAL_BUILDERS,
  ADD_BUILDER,
  REMOVE_BUILDER,
  CLEAR_BUILDER,
  ADD_FETCHED_BUILDER,
  ADD_TEMPLATE,
  ADD_TEMPLATE_WITHOUT_SORT,
  REMOVE_TEMPLATE_FLAG,
  DATE_CHANGED_FALSE,
  DATE_CHANGED_TRUE,
  TIME_CHANGED_TRUE,
  TIME_CHANGED_FALSE,
  BUILDER_CHANGED_TRUE,
  BUILDER_CHANGED_FALSE,
  CLEAR_DATA,
  CLEAR_FETCHED,
  CLEAR_TEMPLATES,
  SAME_TEMPLATE_FALSE,
  SAME_TEMPLATE_TRUE,
  SHOW_SWITCH_PROJECT,
  HIDE_SWITCH_PROJECT,
  SET_LOADER,
  SET_ADMIN_VIEW_TAB_INDEX,
} from './constants';

function groups(
  state = {
    loading: true,
    is_user_hub_admin: false,
    single: {},
    overview: [],
    showSwitchProject: false,
  },
  action
) {
  switch (action.type) {
    case SHOW_SWITCH_PROJECT:
      return {
        ...state,
        showSwitchProject: true,
      };
    case HIDE_SWITCH_PROJECT:
      return {
        ...state,
        showSwitchProject: false,
      };

    case SET_GROUPS:
      return {
        ...state,
        loading: false,
        is_user_hub_admin: action.data.is_user_hub_admin,
        overview: [...action.data.hub_groups],
      };

    case SET_LOADER:
      return {
        ...state,
        loading: true,
      };

    case SET_ACTIVATION:
      const updateObjectInArray = (array, data) => {
        return array.map((item) => {
          if (item.id !== data.id) {
            return item;
          }
          return {
            ...item,
            is_active: data.is_active,
          };
        });
      };
      return {
        ...state,
        loading: false,
        overview: updateObjectInArray(state.overview, action.data),
      };

    case SET_SINGLE_GROUP:
      if (action.data !== null) {
        return {
          ...state,
          loading: false,
          is_user_hub_admin: action.data.is_user_hub_admin,
          single: { ...action.data.hub_group },
        };
      }
      return { ...state };

    case SET_GROUP_NAME:
      return {
        ...state,
        loading: false,
        single: { ...state.single, name: action.data.name },
      };

    case SET_CHANGED_PROJECT:
      return {
        ...state,
        loading: false,
        single: {
          ...state.single,
          user_project: {
            ...state.single.user_project,
            project: [action.data.project],
          },
        },
      };

    case SET_USER_PROJECT:
      return {
        ...state,
        loading: false,
        single: {
          ...state.single,
          user_project: {
            ...state.single.user_project,
            project: action.data,
          },
        },
      };

    case REMOVE_DELETE:
      const newState = state.overview.filter((val) => val.id !== action.data);
      return {
        ...state,
        loading: false,
        overview: [...newState],
      };

    default:
      return state;
  }
}

function templates(state = { loading: true, data: [] }, action) {
  switch (action.type) {
    case SET_TEMPLATE:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.data],
      };

    default:
      return state;
  }
}

function builders(
  state = {
    loading: true,
    data: [],
    fetched: [],
    template: [],
    templateWithoutSort: [],
    builderChanged: false,
    dateChanged: false,
    timeChanged: false,
    templateAdded: false,
    sameTemplate: false,
  },
  action
) {
  switch (action.type) {
    case SET_INITIAL_BUILDERS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };

    case SAME_TEMPLATE_TRUE:
      return {
        ...state,
        sameTemplate: true,
      };
    case SAME_TEMPLATE_FALSE:
      return {
        ...state,
        sameTemplate: false,
      };
    case ADD_BUILDER:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.data],
      };

    case DATE_CHANGED_TRUE:
      return {
        ...state,
        dateChanged: true,
      };

    case CLEAR_FETCHED:
      return {
        ...state,
        fetched: [],
      };

    case CLEAR_DATA:
      return {
        ...state,
        data: [],
      };

    case CLEAR_TEMPLATES:
      return {
        ...state,
        template: [],
      };
    case DATE_CHANGED_FALSE:
      return {
        ...state,
        dateChanged: false,
      };

    case TIME_CHANGED_TRUE:
      return {
        ...state,
        timeChanged: true,
      };

    case TIME_CHANGED_FALSE:
      return {
        ...state,
        timeChanged: false,
      };

    case BUILDER_CHANGED_TRUE:
      return {
        ...state,
        builderChanged: true,
      };

    case BUILDER_CHANGED_FALSE:
      return {
        ...state,
        builderChanged: false,
      };

    case ADD_FETCHED_BUILDER:
      return {
        ...state,
        loading: false,
        fetched: [...state.fetched, ...action.data],
      };

    case ADD_TEMPLATE:
      return {
        ...state,
        loading: false,
        templateAdded: true,
        template: [...action.data],
      };
    case ADD_TEMPLATE_WITHOUT_SORT:
      return {
        ...state,
        loading: false,
        templateWithoutSort: [...action.data],
      };

    case REMOVE_TEMPLATE_FLAG:
      return {
        ...state,
        loading: false,
        templateAdded: false,
        template: [],
      };

    case CLEAR_BUILDER:
      return {
        ...state,
        loading: false,
        data: [],
        fetched: [],
      };

    case REMOVE_BUILDER:
      const newState = state.data.filter((val) => val !== action.data);
      const newFetched = state.fetched.filter((val) => val !== action.data);
      const newTemplateWithoutSort = state.templateWithoutSort.filter(
        (val) => val !== action.data
      );
      const newTemplate = state.template.filter(
        (val) => val.id !== action.data
      );
      return {
        ...state,
        data: [...newState],
        fetched: [...newFetched],
        templateWithoutSort: [...newTemplateWithoutSort],
        template: [...newTemplate],
      };

    default:
      return state;
  }
}

const adminViewTabIndex = (state = 0, action) => {
  switch (action.type) {
    case SET_ADMIN_VIEW_TAB_INDEX:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  groups,
  templates,
  builders,
  adminViewTabIndex,
});
