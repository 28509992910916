/**
 * Fetching Event
 */
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';

/**
 * Sponsors
 */
export const SET_EVENT_SPONSORS = 'SET_EVENT_SPONSORS';
export const DELETE_EVENT_SPONSOR = 'DELETE_EVENT_SPONSOR';
export const ADD_EVENT_SPONSOR = 'ADD_EVENT_SPONSOR';
export const UPDATE_EVENT_SPONSOR = 'UPDATE_EVENT_SPONSOR';

/**
 * Awards
 */
export const ADD_NEW_AWARD = 'ADD_NEW_AWARD';
export const SET_EVENT_AWARDS = 'SET_AWARDS';

/**
 * Judge
 */
export const SET_EVENT_JUDGES = 'SET_EVENT_JUDGES';
export const UPDATE_ROUND = 'UPDATE_ROUND';
export const SET_JUDGE_BIO_PUBLISHED_STATE = 'SET_JUDGE_BIO_PUBLISHED_STATE';
export const UPDATE_JUDGE_BIO = 'UPDATE_JUDGE_BIO';
export const UPDATE_JUDGE_BIO_SUCCESS = 'UPDATE_JUDGE_BIO_SUCCESS';

/**
 * API
 */
export const API_PUT = 'API_PUT';
export const API_POST = 'API_POST';
export const API_DELETE = 'API_DELETE';
export const API_GET = 'API_GET';
export const API_POST_IMAGE = 'API_POST_IMAGE';
export const API_PUT_POST_IMAGE = 'API_PUT_POST_IMAGE';

/**
 * Quill
 */
export const API_QUILL_IMAGE = 'API_QUILL_IMAGE';
export const SET_EVENT_QUILL_DELTA = 'SET_EVENT_QUILL_DELTA';
export const SAVE_EVENT_QUILL = 'SAVE_EVENT_QUILL';
export const SET_EVENT_QUILL_HTML = 'SET_EVENT_QUILL_HTML';

/**
 * Users
 */
export const SET_EVENT_USERS = 'SET_EVENT_USERS';
export const UPDATE_EVENT_USERS = 'UPDATE_EVENT_USERS';
export const UPDATE_USER_EVENT_ROLE = 'UPDATE_USER_EVENT_ROLE';

/**
 * Rounds
 */
export const SET_EVENT_ROUNDS = 'SET_EVENT_ROUNDS';
export const SET_EVENT_ROUND = 'SET_EVENT_ROUND';

/**
 * Posts
 */
export const SET_EVENT_POSTS_LOADING = 'SET_EVENT_POSTS_LOADING';
export const SET_EVENT_POSTS = 'SET_EVENT_POSTS';
export const SET_EVENT_POST = 'SET_EVENT_POST';
export const REMOVE_EVENT_POST = 'REMOVE_EVENT_POST';
export const SET_POSTS_ERROR = 'SET_POSTS_ERROR';
export const SET_EVENT_POST_BOOST = 'SET_EVENT_POST_BOOST';
export const REMOVE_EVENT_POST_BOOST = 'REMOVE_EVENT_POST_BOOST';

/**
 * Invitations
 */
export const SET_EVENT_INVITATIONS = 'SET_EVENT_INVITATIONS';
export const SET_EVENT_INVITATIONS_LOADING = 'SET_EVENT_INVITATIONS_LOADING';
export const SET_EVENT_INVITATIONS_ERROR = 'SET_EVENT_INVITATIONS_ERROR';
export const ADD_EVENT_INVITATIONS = 'ADD_EVENT_INVITATIONS';
export const REMOVE_EVENT_INVITATION = 'REMOVE_EVENT_INVITATION';

/**
 * Event
 */
export const SET_EVENT_HUB_INFO = 'SET_EVENT_HUB_INFO';
export const SET_EVENT_INFO = 'SET_EVENT_INFO';
export const SET_EVENT_PROJECTS = 'SET_EVENT_PROJECTS';
export const UPDATE_EVENT_PROJECTS = 'UPDATE_EVENT_PROJECTS';
export const EVENT_INVITE = 'EVENT_INVITE';
export const UPDATE_HUB_SUCCESS = 'UPDATE_HUB_SUCCESS';
export const EVENT_INVITE_SUCCESS = 'EVENT_INVITE_SUCCESS';

/**
 * Create project from hub group
 */

export const SET_PROJECT_CREATED_FROM_HUB_GROUP =
  'SET_PROJECT_CREATED_FROM_HUB_GROUP';
export const RESET_PROJECT_CREATED_FROM_HUB_GROUP =
  'RESET_PROJECT_CREATED_FROM_HUB_GROUP';
