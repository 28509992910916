import {
  READ_ALL_ALERTS,
  MARK_AS_READ_ALERTS,
  READING_ALERTS,
  SET_ALERTS_USER_ID,
  UPDATE_NEW_ALERTS,
} from './alertsAction';

const INIT_STATE = {
  alerts: [],
  loading: false,
  userId: null,
  totalAlertsCount: 0,
  totalUnreadAlertsCount: 0,
};

const alertsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_ALL_ALERTS:
      // eslint-disable-next-line no-case-declarations
      let newAlerts = [...state.alerts];
      newAlerts = newAlerts.concat(action.payload.alerts || []);
      return {
        ...state,
        alerts: newAlerts,
        loading: false,
        totalAlertsCount: action.payload.totalAlertsCount,
        totalUnreadAlertsCount: action.payload.totalUnreadAlertsCount,
      };
    case MARK_AS_READ_ALERTS:
      // eslint-disable-next-line no-case-declarations
      const alerts = [...state.alerts];
      return {
        ...state,
        alerts: (alerts || []).map((alert) => {
          return { ...alert, seenAt: new Date(), isSeen: true };
        }),
        totalUnreadAlertsCount: 0,
      };
    case READING_ALERTS:
      return {
        ...state,
        loading: true,
      };
    case SET_ALERTS_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case UPDATE_NEW_ALERTS:
      // eslint-disable-next-line no-case-declarations
      let updatedAlerts = [];
      updatedAlerts = updatedAlerts.concat(action.payload.alerts || []);
      return {
        ...state,
        alerts: updatedAlerts,
        loading: false,
        totalAlertsCount: action.payload.totalAlertsCount,
        totalUnreadAlertsCount: action.payload.totalUnreadAlertsCount,
      };
    default:
      return state;
  }
};

export default alertsReducer;
