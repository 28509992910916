import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoadingComponent from 'components/Loading/loadingComponent';
import Cookie from 'js-cookie';
import EmptyLayout from 'components/AppLayout/EmptyLayout/EmptyLayout';

const PublicAppRoute = ({
  component: Component,
  onEmptyLayout = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const componentWrap = (
        <Suspense fallback={<LoadingComponent />}>
          <Component {...props} />
        </Suspense>
      );
      if (!onEmptyLayout) return componentWrap;
      return <EmptyLayout>{componentWrap}</EmptyLayout>;
    }}
  />
);

const ProtectedAppRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = !!Cookie.get('token');
  if (isLoggedIn) return <PublicAppRoute component={Component} {...rest} />;
  const data = {
    from: 'protectedRoute',
    path: '/auth/login',
    previousPath: window.location.pathname,
  };
  sessionStorage.setItem('scrollDetails', JSON.stringify(data));
  return <Redirect to="/auth/login" />;
};

export const AppRoute = ({
  component: Component,
  isProtected = false,
  ...rest
}) => {
  if (!isProtected) return <PublicAppRoute component={Component} {...rest} />;
  return <ProtectedAppRoute component={Component} {...rest} />;
};

export const LazyModal = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Suspense fallback={<LoadingComponent />}>
        <Component {...props} />
      </Suspense>
    )}
  />
);
