import React from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {SenderUsers, ShortTimeIndicator, TimeIndicator} from '../Components';
import { AlertAvatar } from '../AlertAvatar';
import AlertIndicator from "../AlertIndicator";

// TODO refactor later. wrap all common functionalities into HOC
const notificationLinkStyles = {
    color: 'black',
    borderBottom: 0,
    width: '100%',
    flex: '1 0 100%',
    display: 'flex'
};

const setScrollDetails = (url, hashValue, topicId) => {
    const data = {
        previousPath: url,
        hashValue,
        topicId,
    };
    if (hashValue)
        sessionStorage.setItem('scrollDetails', JSON.stringify(data));
};

const TopicAndKudosUsers = ({users, t, close}) => {
    const [user = null, ...others] = (users || []);
    const hasMoreUsers = (others || [])?.length > 1;

    const stopPropogation = (e) => {
        e.stopPropagation();
        if (typeof close === 'function') {
            close();
        }
    };

    return (
        <>
            {
                (!hasMoreUsers && user) ? (
                    (
                        <Link
                            to={`/profile/${user.userId}`}
                            onClick={stopPropogation}
                        >{`${user.firstName} ${user.lastName}`}</Link>
                    )
                ) : (
                    <>
                        {
                            (users || [])?.map((u, i) => {
                                const userLink = (
                                    <Link
                                        to={`/profile/${u.userId}`}
                                        onClick={stopPropogation}
                                    >{`${u.firstName} ${u.lastName}`}</Link>
                                );
                                if (i === users?.length - 1)
                                    return (
                                        <>
                                            {t('and')} {userLink}
                                        </>
                                    );
                                return <>{userLink}, </>;
                            })
                        }
                    </>
                )
            }
        </>
    )
};

function ProjectNotificationWrapper (WrappedComponent) {
    return class extends React.Component {

        constructor(props) {
            super(props);
            this.stopPropogation = this.stopPropogation.bind(this);
        }

        stopPropogation = (e) => {
            e.stopPropagation();
            if (typeof this.props.close === 'function') {
                this.props.close();
            }
        }

        render() {
            const { props, stopPropogation } = this;
            return <WrappedComponent {...props} stopPropogation={stopPropogation} />;
        }
    }
}

export const ProjectTopicKudosAlert = ProjectNotificationWrapper(({ alert, close, user, t, stopPropogation }) => {
    return (
        <Link
            to={`/project/${alert.project?.id}`}
            style={notificationLinkStyles}
            onClick={(e) => {
                stopPropogation(e);
                setScrollDetails(
                    `/project/${alert.project?.id}`,
                    `#challenge-${alert.topic?.topicId}`,
                    alert.topic?.topicId
                );
            }}
        >
            <AlertAvatar
                users={alert.senderUsers}
                alertSeenByUser={alert.isSeen}
            />
            <Box sx={{
                flex: 1,
                pl: 1,
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box display="flex" justifyContent="flex-start" flexDirection="column">
                    <Typography
                        variant="body1"
                        sx={{
                            width: '100%',
                            maxWidth: '320px',
                            fontWeight: 'normal',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            '> span': {
                                wordBreak: 'break-word',
                                display: 'inline-block',
                            },
                        }}
                    >
                        <Box component="span" sx={{ fontWeight: 700 }}>
                            <TopicAndKudosUsers users={alert?.senderUsers} t={t} close={close} />
                        </Box>
                        &nbsp;
                        {user?.user_preference?.language === 'ESP' && (
                            <>
                                <Box component="span">
                                    {alert?.senderUsers?.length === 1
                                        ? t('kudosSingle')
                                        : t('kudosMultiple')}
                                </Box>
                                &nbsp;
                            </>
                        )}
                        <Box component="span">{t('kudosText')}</Box>&nbsp;
                        {alert?.topic?.title && (
                            <Box component="span" sx={{ fontWeight: 700 }}>
                                <Link
                                    to={`/project/${alert.project?.id}`}
                                    onClick={(e) => {
                                        stopPropogation(e);
                                        setScrollDetails(
                                            `/project/${alert.project?.id}`,
                                            `#challenge-${alert.topic?.topicId}`,
                                            alert.topic?.topicId
                                        );
                                    }}
                                >
                                    {(alert?.topic?.title || '').trim()}&nbsp;
                                </Link>
                            </Box>
                        )}
                        <Box component="span">{t('in')}</Box>&nbsp;
                        <Box component="span" sx={{ fontWeight: 700 }}>
                            <Link
                                to={`/project/${alert.project?.id}`}
                                onClick={stopPropogation}
                            >
                                {alert?.project?.name}
                            </Link>
                        </Box>{' '}
                        <span>{t('project')}</span>
                        {
                            !alert?.isMouseOver && <>
                                {' '}
                                <ShortTimeIndicator timeStamp={alert?.createdAt} />
                            </>
                        }
                        <Collapse in={alert?.isMouseOver}>
                            <TimeIndicator timeStamp={alert?.createdAt} />
                        </Collapse>
                    </Typography>
                </Box>
                {
                    !alert.isSeen && <AlertIndicator />
                }
            </Box>
        </Link>
    );
});

export const ProjectTopicNoteAlert = ProjectNotificationWrapper(({ alert, user, t, stopPropogation }) => {
    return (
        <Link
            to={`/project/${alert.project?.id}`}
            style={notificationLinkStyles}
            onClick={(e) => {
                stopPropogation(e);
                setScrollDetails(
                    `/project/${alert.project?.id}`,
                    `#topic-${alert.topic?.topicId}-note-${alert.noteIds[0]}`,
                    alert.topic?.topicId
                );
            }}
        >
            <AlertAvatar
                users={alert.senderUsers}
                alertSeenByUser={alert.isSeen}
            />
            <Box sx={{
                flex: 1,
                pl: 1,
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box display="flex" justifyContent="flex-start" flexDirection="column">
                    <Typography
                        variant="body1"
                        sx={{
                            width: '100%',
                            maxWidth: '320px',
                            fontWeight: 'normal',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            '> span': {
                                wordBreak: 'break-word',
                                display: 'inline-block',
                            },
                        }}
                    >
                        <SenderUsers users={alert?.senderUsers} />
                        {user?.user_preference?.language === 'ESP' && (
                            <>
                                &nbsp;
                                <Box component="span">
                                    {alert?.senderUsers?.length === 1
                                        ? t('single')
                                        : t('multiple')}
                                </Box>
                            </>
                        )}
                        &nbsp;
                        <Box component="span">{t('noteAddedOn')}</Box>&nbsp;
                        {alert?.topic?.title && (
                            <Box component="span" sx={{ fontWeight: 700 }}>
                                <Link
                                    to={`/project/${alert.project?.id}`}
                                    onClick={(e) => {
                                        stopPropogation(e);
                                        setScrollDetails(
                                            `/project/${alert.project?.id}`,
                                            `#topic-${alert.topic?.topicId}-note-${alert.noteIds[0]}`,
                                            alert.topic?.topicId
                                        );
                                    }}
                                >
                                    {(alert?.topic?.title || '').trim()}&nbsp;
                                </Link>
                            </Box>
                        )}
                        <Box component="span">{t('in')}</Box>&nbsp;
                        <Box component="span" sx={{ fontWeight: 700 }}>
                            <Link
                                to={`/project/${alert.project?.id}`}
                                onClick={stopPropogation}
                            >
                                {alert?.project?.name}
                            </Link>
                        </Box>{' '}
                        <span>{t('project')}</span>
                        {
                            !alert?.isMouseOver && <>
                                {' '}
                                <ShortTimeIndicator timeStamp={alert?.createdAt} />
                            </>
                        }
                        <Collapse in={alert?.isMouseOver}>
                            <TimeIndicator timeStamp={alert?.createdAt} />
                        </Collapse>
                    </Typography>
                </Box>
                {
                    !alert.isSeen && <AlertIndicator />
                }
            </Box>
        </Link>
    );
});

const Builders = ProjectNotificationWrapper(({ builders, projectId, t, stopPropogation, redirectToProjectPage = false }) => {
    const hasMultipleItems = (builders || []).length > 1;
    const validBuilders = (builders || []).filter(
        (builder) => !!(builder?.title || '').trim()
    );
    const lastElement = hasMultipleItems
        ? validBuilders[validBuilders.length - 1]
        : '';
    const builderLinkStyles = {
        fontWeight: 700,
    };
    const linkOnClickFn = (e, challengeBlueprintId = null) => {
        stopPropogation(e);
        if (redirectToProjectPage && challengeBlueprintId)
            setScrollDetails(
                `/project/${projectId}`,
                `#challengeBlueprint-${challengeBlueprintId}`
            );
    };
    return (
        <>
            {hasMultipleItems && (
                <>
                    {validBuilders.slice(0, validBuilders.length - 1).map((builder) => (
                        <>
                            <Link
                                onClick={(e) => linkOnClickFn(e, builder.id)}
                                to={
                                    redirectToProjectPage
                                        ? `/project/${projectId}`
                                        : `/project/${projectId}/builders/${builder?.id}/overview`
                                }
                                style={builderLinkStyles}
                            >
                                {(builder?.title || '').trim()}
                            </Link>
                            &nbsp;
                        </>
                    ))}
                    <Box component="span">{t('and')}</Box>&nbsp;
                    <Link
                        onClick={(e) => linkOnClickFn(e, lastElement?.id)}
                        to={
                            redirectToProjectPage
                                ? `/project/${projectId}`
                                : `/project/${projectId}/builders/${lastElement?.id}/overview`
                        }
                        style={builderLinkStyles}
                    >
                        {(lastElement?.title || '').trim()}
                    </Link>
                </>
            )}
            {!hasMultipleItems && (
                <>
                    {validBuilders.map((builder) => (
                        <Link
                            onClick={(e) => linkOnClickFn(e, builder.id)}
                            to={
                                redirectToProjectPage
                                    ? `/project/${projectId}`
                                    : `/project/${projectId}/builders/${builder?.id}/overview`
                            }
                            style={builderLinkStyles}
                        >
                            {(builder?.title || '').trim()}
                        </Link>
                    ))}
                </>
            )}
        </>
    );
});

export const BuilderPublishAlert = ProjectNotificationWrapper(({ alert, user, t, stopPropogation }) => {
    return (
        <Link
            to={`/project/${alert.project?.id}`}
            style={notificationLinkStyles}
            onClick={(e) => {
                stopPropogation(e);
                setScrollDetails(
                    `/project/${alert.project?.id}`,
                    `#challengeBlueprint-${alert?.builders[0].id}`
                );
            }}
        >
            <AlertAvatar
                users={alert.senderUsers}
                alertSeenByUser={alert.isSeen}
            />
            <Box sx={{
                flex: 1,
                pl: 1,
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box display="flex" justifyContent="flex-start" flexDirection="column">
                    <Typography
                        variant="body1"
                        sx={{
                            width: '100%',
                            maxWidth: '320px',
                            fontWeight: 'normal',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            '> span': {
                                wordBreak: 'break-word',
                                display: 'inline-block',
                            },
                        }}
                    >
                        <SenderUsers users={alert?.senderUsers} />&nbsp;
                        {user?.user_preference?.language === 'ESP' && (
                            <>
                                <Box component="span">
                                    {alert?.senderUsers?.length === 1
                                        ? t('single')
                                        : t('multiple')}
                                </Box>
                                &nbsp;
                            </>
                        )}
                        <Box component="span">{t('published')}</Box>&nbsp;
                        {user?.user_preference?.language === 'ENG' && (
                            <>
                                <Builders
                                    t={t}
                                    projectId={alert?.project?.id}
                                    builders={alert?.builders}
                                    redirectToProjectPage
                                />
                                &nbsp;
                                <Box component="span">{t('builder')}</Box>&nbsp;
                            </>
                        )}
                        {user?.user_preference?.language === 'ESP' && (
                            <>
                                <Box component="span">
                                    {alert.senderUsers?.length > 1
                                        ? t('multipleBuilder')
                                        : t('builder')}
                                </Box>
                                &nbsp;
                                <Box component="span" sx={{ fontWeight: 700 }}>
                                    <Builders
                                        t={t}
                                        projectId={alert?.project?.id}
                                        builders={alert?.builders}
                                        redirectToProjectPage
                                    />
                                </Box>
                                &nbsp;
                            </>
                        )}
                        <Box component="span">
                            {user?.user_preference?.language === 'ENG' ? t('for') : t('en')}
                        </Box>
                        &nbsp;
                        <Box component="span" sx={{ fontWeight: 700 }}>
                            <Link
                                to={`/project/${alert.project?.id}`}
                                onClick={stopPropogation}
                            >
                                {alert?.project?.name}
                            </Link>
                        </Box>
                        {alert.publishedFor && (
                            <>
                                &nbsp;
                                <Box component="span">
                                    {t('to')} {alert.publishedFor || ''}
                                </Box>
                            </>
                        )}{' '}
                        <span>{t('project')}</span>
                        {
                            !alert?.isMouseOver && <>
                                {' '}
                                <ShortTimeIndicator timeStamp={alert?.createdAt} />
                            </>
                        }
                        <Collapse in={alert?.isMouseOver}>
                            <TimeIndicator timeStamp={alert?.createdAt} />
                        </Collapse>
                    </Typography>
                </Box>
                {
                    !alert.isSeen && <AlertIndicator />
                }
            </Box>
        </Link>
    );
});

export const BuilderUpdateAlert = ProjectNotificationWrapper(({ alert, user, t, stopPropogation }) => {
    return (
        <Link
            to={
                alert.publishedFor
                    ? `/project/${alert.project?.id}`
                    : `/project/${alert.project?.id}/builders/${alert.builders[0]?.id}/review`
            }
            style={notificationLinkStyles}
            onClick={stopPropogation}
        >
            <AlertAvatar
                users={alert.senderUsers}
                alertSeenByUser={alert.isSeen}
            />
            <Box sx={{
                flex: 1,
                pl: 1,
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box display="flex" justifyContent="flex-start" flexDirection="column">
                    <Typography
                        variant="body1"
                        sx={{
                            width: '100%',
                            maxWidth: '320px',
                            fontWeight: 'normal',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            '> span': {
                                wordBreak: 'break-word',
                                display: 'inline-block',
                            },
                        }}
                    >
                        <SenderUsers users={alert?.senderUsers} />&nbsp;
                        {user?.user_preference?.language === 'ESP' && (
                            <>
                                <Box component="span">
                                    {alert?.senderUsers?.length === 1
                                        ? t('single')
                                        : t('multiple')}
                                </Box>
                                &nbsp;
                            </>
                        )}
                        <Box component="span">{t('updated')}</Box>&nbsp;
                        <Box component="span" sx={{ fontWeight: 700 }}>
                            <Builders
                                t={t}
                                projectId={alert?.project?.id}
                                builders={alert?.builders}
                            />
                        </Box>
                        &nbsp;
                        <Box component="span">{t('builder')}</Box>&nbsp;
                        <Box component="span">{t('for')}</Box>&nbsp;
                        <Box component="span" sx={{ fontWeight: 700 }}>
                            <Link
                                to={`/project/${alert.project?.id}`}
                                onClick={stopPropogation}
                            >
                                {alert?.project?.name}
                            </Link>
                        </Box>{' '}
                        <span>{t('project')}</span>
                        {
                            !alert?.isMouseOver && <>
                                {' '}
                                <ShortTimeIndicator timeStamp={alert?.createdAt} />
                            </>
                        }
                        <Collapse in={alert?.isMouseOver}>
                            <TimeIndicator timeStamp={alert?.createdAt} />
                        </Collapse>
                    </Typography>
                </Box>
                {
                    !alert.isSeen && <AlertIndicator />
                }
            </Box>
        </Link>
    );
});
