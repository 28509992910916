import i18n from '../../i18n';
import { successPopup } from './successPopup';

export const profileCompletedPopup = (dispatch) => {
  dispatch(
    successPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .AwsomeWorks
      }`,
      5,
      'success',
      'Profile Completed',
      'tc',
      true,
      null,
      'success_profile_completed'
    )
  );
};
