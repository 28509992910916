import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";


const RebelFormLabel = styled((props) => (
    <Typography variant="h6" {...props} component="label" htmlFor={props.htmlFor} />
))(() => ({
    marginBottom: '5px',
    marginTop: '15px',
    display: 'block',
    fontWeight:'bold',
    fontSize: '1rem'
}));


export default React.memo(RebelFormLabel);
