import { List, fromJS } from 'immutable';
import { SET_USER_EMAIL_ADDRESSES, DELETE_USER_EMAIL_ADDRESS, UPDATE_PRIMARY_USER_EMAIL_ADDRESS, ADD_USER_EMAIL_ADDRESS } from './userEmailAddressActions';

// REDUCER
const initEA = List();
function userEmailAddresses(state = initEA, action) {
  switch (action.type) {
    case SET_USER_EMAIL_ADDRESSES:
      return initEA.concat(fromJS(action.data));
    case DELETE_USER_EMAIL_ADDRESS:
      return state.filterNot(email => email.get('id') === action.emailAddressId);
    case UPDATE_PRIMARY_USER_EMAIL_ADDRESS:
      return state.map(email => email.set('is_primary', false))
        .map((email) => {
          if (email.get('id') === action.emailAddressId) {
            return email.set('is_primary', true);
          }
          return email;
        });
    case ADD_USER_EMAIL_ADDRESS:
      return state.push(fromJS(action.data));
    default:
      return state;
  }
}

export default userEmailAddresses;
