import { SET_SCORING_DATA_LOADED } from 'store/metadata/metadataActions';
import { request } from '../../functions';

export const UPDATE_USER_EVENT_ROLE = 'events/UPDATE_USER_EVENT_ROLE';
export const SET_USER_UPDATE_SCORES = 'SET_USER_UPDATE_SCORES';
export const UNSET_USER_UPDATE_SCORES = 'UNSET_USER_UPDATE_SCORES';

function setResourceLabel(data) {
  return {
    type: 'SET_RESOURCE_LABEL',
    data,
  };
}

// change the label for a resource role
export function putResourceLabel(userEventRoleId, resourceLabel = null) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/user-event-roles/${userEventRoleId}`;
    const payload = {
      user_event_role: {
        resource_label: resourceLabel,
      },
    };

    return request(url, jwt, 'PUT', payload).then((response) => {
      dispatch({ type: UPDATE_USER_EVENT_ROLE, data: response.data });
      dispatch(setResourceLabel(response.data));
    });
  };
}

export function changeResourceLabel(userEventRoleId) {
  return (dispatch, getState) => {
    const { form } = getState();

    const EventResourceForm =
      form && form.EventResourceForm && form.EventResourceForm.values
        ? form.EventResourceForm.values
        : [];

    dispatch(putResourceLabel(userEventRoleId, EventResourceForm.label));
  };
}

// SCORING
export const SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECTS =
  'events/SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECTS';

export function fetchAllHubEventScoringRoundProjects() {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-scoring-round-projects`;

    return request(url, jwt)
      .then((response) =>
        dispatch({
          type: SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECTS,
          data: response.data,
        })
      )
      .catch((err) => console.log(err));
  };
}

export const SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECT_SCORES =
  'events/SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECT_SCORES';
export const SET_HUB_EVENT_SCORING_ROUND_PROJECT_SCORE =
  'events/SET_HUB_EVENT_SCORING_ROUND_PROJECT_SCORE';

export function fetchAllHubEventScoringRoundProjectScores() {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-scoring-round-project-scores`;

    return request(url, jwt)
      .then((response) =>
        dispatch({
          type: SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECT_SCORES,
          data: response.data,
        })
      )
      .catch((err) => console.log(err));
  };
}

export function submitHubEventScoringProjectScore(
  hubEventScoringRoundProjectId
) {
  return (dispatch, getState) => {
    const { user, form } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-scoring-round-project-scores/`;

    const Form =
      form && form.HubEventScoringForm && form.HubEventScoringForm.values
        ? form.HubEventScoringForm.values
        : null;

    // if (!Form || Object.keys(Form).length < 12) {
    //   // not all questions answered
    //   dispatch(popup('Oops. Please answer all the questions.', 'error', ' ', 'tc', 5, true));
    //   return;
    // }

    const payload = {
      hub_event_scoring_round_project_score: {
        event_scoring_round_project_id: hubEventScoringRoundProjectId,
        scores: Form,
      },
    };
    dispatch({ type: SET_USER_UPDATE_SCORES });

    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({
          type: SET_HUB_EVENT_SCORING_ROUND_PROJECT_SCORE,
          data: response.data,
        });
        dispatch({ type: UNSET_USER_UPDATE_SCORES });
      })
      .catch((err) => console.log(err));
  };
}

export const fetchScoringRoundsData = () => (dispatch, getState) => {
  const { metadata } = getState();
  const scoringDataLoaded = metadata.loading.get('scoringDataLoaded');
  if (scoringDataLoaded) return;
  Promise.all([
    dispatch(fetchAllHubEventScoringRoundProjectScores()),
    dispatch(fetchAllHubEventScoringRoundProjects()),
  ]).then(() => dispatch({ type: SET_SCORING_DATA_LOADED }));
};
