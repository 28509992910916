import { combineReducers } from 'redux';
import { THEME_COLOR, NAVBAR_BG, SIDEBAR_BG, DIRECTION, DARK_THEME } from './themeAction';

const INIT_STATE = {
  activeDir: 'ltr',
  activeNavbarBg: '#0b70fb',
  activeSidebarBg: '#ffffff',
  activeMode: 'light',
  activeTheme: 'REBEL_BLUE',
  SidebarWidth: 240,
};

const themeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case DARK_THEME:
      return {
        ...state,
        activeMode: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME_COLOR:
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  themeReducer
});
