import React, { useState } from 'react';
import { Box, Typography, MenuItem } from '@mui/material';
import { Alert as AlertType, SenderUser } from 'utils/types/alert';
import Alert from './Alert';

interface Props {
  alertsList: AlertType[];
  close: Function;
  user: SenderUser;
}

// @ts-ignore
export const AlertMenuItem = ({ alert, close, user }) => {
  const [isMenuHovered, setMenuHovered] = useState<boolean | undefined>();
  // We dont show users avatars for this type of alerts. So left padding will be always 2
  const isHubActivitiesCreated =
    alert?.alertTypeName === 'HubActivitiesCreated';
  const left = alert?.senderUsers?.length > 1 ? 1 : 2;
  return (
    <>
      <MenuItem
        onMouseOver={() => setMenuHovered(true)}
        onMouseOut={() => setMenuHovered(false)}
        disableRipple
        sx={{
          p: (theme) =>
            theme.spacing(0, 2, 0, isHubActivitiesCreated ? 2 : left),
          borderBottom: 1,
          borderBottomColor: (theme) => theme?.palette?.grey?.A400,
          minHeight: '80px !important',
          height: 'max-content',
        }}
      >
        <Alert
          user={user}
          alert={{ ...alert, isMouseOver: isMenuHovered }}
          close={close}
        />
      </MenuItem>
    </>
  );
};

const Notifications: React.FC<Props> = ({ alertsList, close, user }) => {
  if (alertsList.length === 0)
    return (
      <Box
        sx={{
          p: 4,
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="body2">
            No Notifications
          </Typography>
        </Box>
      </Box>
    );
  return (
    <div>
      {alertsList.map((alert) => (
          <AlertMenuItem
              user={user}
              key={alert.alertIds?.join('_') ?? `$${alert?.alertTypeId}_${new Date().getTime()}`}
              alert={alert}
              close={close}
          />
      ))}
    </div>
  );
};

export default Notifications;
