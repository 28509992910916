import { Card, CardContent, Box, Skeleton } from '@mui/material';
import React from 'react';
import { NotificationCard } from 'Pages/Dashboard/dashboardConfig';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsBody from 'Pages/Notifications';
import { markAsReadAlerts } from 'store/alerts/alertsAction';
import { Header } from '../Carousel';

const Notifications = ({ t }) => {
  const { alerts, loading, userId } = useSelector(
    (state) => state.alertsReducer
  );
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: `${NotificationCard.width}px`,
        float: 'right',
        ml: 4,
      }}
    >
      <Header
        title={t('Notifications')}
        actionText={t('MarkAsRead')}
        onClickAction={() => {
          dispatch(markAsReadAlerts(userId));
        }}
      />
      {loading && alerts.length === 0 ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={NotificationCard.height}
          width={NotificationCard.width}
        />
      ) : (
        <Card
          sx={{
            height: `${NotificationCard.height}px`,
            overflowY: 'auto',
            borderTop: (theme) => `4px solid ${theme.palette.primary.main}`,
            '&::-webkit-scrollbar': {
              width: '5px',
              '&-track': {
                background: 'transparent',
              },
              '&-thumb': {
                background: (theme) => theme.palette.grey['600'],
                '&:hover': {
                  background: (theme) => theme.palette.grey['600'],
                },
              },
            },
          }}
          id="scrollable-dashboard-notifications-card"
          raised
        >
          <CardContent
            sx={{
              p: (theme) => theme.spacing(0),
              ':last-child': {
                p: 0,
              },
            }}
          >
            <Box>
              <NotificationsBody variant="dashboard" />
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default Notifications;
