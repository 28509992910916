import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BuildTheme } from '../theme/Theme-variable';

const useThemeSettings = () => {
    const customizer = useSelector((state) => state.themeReducer);
    const theme = BuildTheme({
        direction: customizer.themeReducer.activeDir,
        theme: customizer.themeReducer.activeTheme,
    });
    useEffect(() => {
        document.dir = customizer.themeReducer.activeDir;
    }, [customizer]);

    return theme;
};
export default useThemeSettings;
