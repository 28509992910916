import {
    ADD_USER_PROJECT, REMOVE_USER_PROJECT,
    SET_AUTH_USER,
    UPDATE_MY_DEFAULT_PROJECT
} from './authActions';

const INIT_STATE = {
    avatar_url: null,
    default_project_id: null,
    email_addresses: [],
    first_name: null,
    hubs: [],
    id: null,
    is_rb_staff: null,
    last_name: null,
    preferences: {},
    projects: [],
    tracking_id: null
};

const authUser = (
    state = INIT_STATE,
    action
) => {
    switch (action.type) {
        case SET_AUTH_USER:
            return {...state, ...(action?.payload || {})};
        case UPDATE_MY_DEFAULT_PROJECT:
            return {...state, default_project_id: action?.payload || null };
        case ADD_USER_PROJECT:
            // eslint-disable-next-line no-case-declarations
            const projectList = [...state.projects];
            // eslint-disable-next-line no-case-declarations
            const { payload } = action;
            projectList.push({
                name: payload.name,
                id: payload.id,
                role_type: "Owner",
                teammates: []
            });
        return {...state, ...{ projects: projectList } };
        case REMOVE_USER_PROJECT:
            return {...state, ...{ projects: state?.projects?.filter(p => p.id !== action?.payload) } };
        default:
            return state;
    }
};

export default authUser;
