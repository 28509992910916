export interface Alert {
  alertTypeId: number;
  alertTypeName: string;
  activityReplies: ActivityReply[];
  senderUsers: SenderUser[];
  builders: null;
  project: null;
  seenAt: Date | null;
  isSeen: boolean;
  createdAt: Date;
  publishedFor: null;
  topic: null;
  noteIds: null;
  alertIds: number[];
  hub: Hub;
  hubActivities: HubActivity[];
  event: Event;
  eventUpdates: EventUpdate[];
  eventPosts: EventPost[];
  hubGroup: HubGroup;
  isMouseOver: boolean | undefined;
}

export enum HubActivityTypes {
  Post = 1,
  Idea,
  Question,
  Offer,
  Announcement,
}

export enum ActivityReplyTypes {
  Reply = 1,
  Answer,
  Brainstorm,
  Question,
  Offer,
}

export enum EventUpdateTypes {
  location = 1,
  startDate,
  endDate,
  type,
  description,
  details,
}

export interface EventUpdate {
  typeId: number;
  value: string | null;
}

export interface Hub {
  id: number;
  name: string;
  logo: string;
}

export interface Event {
  id: number;
  name: string;
}

export interface EventPost {
  id: number;
  parentPostId: number;
  isUserParentPostOwner?: boolean | null;
}

export interface HubActivity {
  id: number;
  typeId: number;
  ownerUserId: number;
}

export interface ActivityReply {
  id: number;
  parentReplyId: number | null;
  typeId: number;
  parentReplyTypeId: number | null;
}

export interface SenderUser {
  userId: number;
  avatarUrl: string | null;
  lastName: string;
  firstName: string;
  emailAddress: string | null;
  CommunicationSchedulePreference: number;
}

export enum HubActivityAlertTypeEnum {
  HubActivityCreated = 6,
  AnnouncementCreated,
  HubActivityReply,
  HubActivityKudos,
  HubActivityReplyKudos,
}

export enum EventAlertTypeEnum {
  EventUpdated = 11,
  EventPostCreated,
  EventPostKudos,
  EventDeleted,
}

export enum HubGroupAlertTypeEnum {
  HubMemberAddedToGroup = 15,
}

interface HubGroup {
  id: number;
  name: string;
}
