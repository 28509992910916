import { successPopup } from 'functions/popup/successPopup';
import i18n from '../../i18n';

export const triggerEmailVerificationSentPopup = (emailAddress, dispatch) => {
  dispatch(
    successPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .EmailVerificationSent
      } ${emailAddress}. ${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .VerifyHere
      }`
    )
  );
};
