import {
  Stack,
  Typography,
  Box,
  Divider,
  Button,
  Card,
  IconButton,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useHistory } from 'react-router';
import { ReactComponent as ImageOne } from 'assets/images/LandingPage_Image1.svg';
import { ReactComponent as ImageTwo } from 'assets/images/LandingPage_Image2.svg';
import { ReactComponent as ImageThree } from 'assets/images/LandingPage_Image3.svg';
import Slider from 'react-slick';

const imageStyle = {
  maxHeight: '150px',
  width: '100%',
  marginBottom: '2rem',
};
const images = [
  {
    image: <ImageOne style={imageStyle} />,
    text: 'textOne',
  },
  {
    image: <ImageTwo style={imageStyle} />,
    text: 'textTwo',
  },
  {
    image: <ImageThree style={imageStyle} />,
    text: 'textThree',
  },
];

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
};

const WelcomeRebelOverlay = ({ t, handleClose = () => {} }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));
  const Images = () =>
    images.map((image) => (
      <Box>
        {image.image}
        <Typography align="center">{t(image.text)}</Typography>
      </Box>
    ));

  return (
    <Card
      sx={{
        background: (themeParam) => themeParam.palette.grey['200'],
        boxShadow: 2,
        borderRadius: { xs: 0, md: '25px' },
        height: { xs: 'unset', md: 'max-content' },
        width: '100%',
      }}
      raised
    >
      <CardContent
        sx={{
          px: { xs: 3, md: 4 },
          py: { xs: 5, md: 2 },
          ':last-child': {
            pb: 4,
          },
        }}
      >
        <Stack direction="row" sx={{ alignItems: 'flex-start', mb: 3 }}>
          <Box
            sx={{ width: '100%', pl: { xs: 0, md: 6 }, pr: { xs: 0, md: 1 } }}
          >
            <Typography variant="h1" align="center" sx={{ mb: 2 }}>
              Welcome Rebel!
            </Typography>
            <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
              {t('ReadyToTurnIdeasToReality')}
            </Typography>
          </Box>
          {!isMobile && (
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          )}
        </Stack>
        <Box sx={{ mt: 1 }}>
          {isMobile ? (
            <Card
              sx={{
                boxShadow: 2,
                borderRadius: '15px',
              }}
              raised
            >
              <CardContent
                sx={{
                  ':last-child': {
                    pb: 5,
                  },
                }}
              >
                <Slider {...settings}>{Images()}</Slider>
              </CardContent>
            </Card>
          ) : (
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between' }}
              gap={8}
            >
              <Images />
            </Stack>
          )}
        </Box>
        <Divider
          sx={{
            my: 5,
            borderWidth: '1px',
            borderColor: (themeParam) => themeParam.palette.grey['600'],
          }}
        />
        <Typography
          variant="h3"
          align="center"
          sx={{ fontWeight: 'bold', mb: 4 }}
        >
          {t('InnovationCannotWait')}
        </Typography>
        <Stack sx={{ alignItems: 'center' }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              history.push('/project/create-project');
            }}
            sx={{ mb: { xs: 4, md: 2 } }}
          >
            {t('CreateFirstProject')}
          </Button>
          {isMobile && (
            <Button
              disableRipple
              sx={{ minHeight: 0, fontWeight: 'normal' }}
              onClick={handleClose}
            >
              {t('SkipForNow')}
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default WelcomeRebelOverlay;
