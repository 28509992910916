import Cookie from 'js-cookie';
import {
  getEvent,
  getEventPosts as getPosts,
  getEventInvitations as getInvitations,
} from 'utils/api-calls';
import { setNotFound, resetNotFound } from 'store/metadata/metadataActions';
import { change, untouch } from 'redux-form';
import { request } from 'functions';
import {
  SET_EVENT_AWARDS,
  SET_EVENT_HUB_INFO,
  SET_EVENT_INFO,
  SET_EVENT_JUDGES,
  SET_EVENT_PROJECTS,
  SET_EVENT_SPONSORS,
  SET_EVENT_USERS,
  ADD_EVENT_SPONSOR,
  ADD_NEW_AWARD,
  SET_JUDGE_BIO_PUBLISHED_STATE,
  API_POST_IMAGE,
  API_PUT,
  API_DELETE,
  DELETE_EVENT_SPONSOR,
  SET_EVENT_ROUNDS,
  UPDATE_ROUND,
  API_QUILL_IMAGE,
  API_POST,
  SET_EVENT_QUILL_DELTA,
  SET_EVENT_QUILL_HTML,
  UPDATE_HUB_SUCCESS,
  API_PUT_POST_IMAGE,
  UPDATE_EVENT_SPONSOR,
  UPDATE_JUDGE_BIO_SUCCESS,
  UPDATE_EVENT_PROJECTS,
  UPDATE_EVENT_USERS,
  SET_LOADING,
  SET_ERROR,
  UNSET_LOADING,
  SET_POSTS_ERROR,
  SET_EVENT_POST,
  SET_EVENT_POSTS,
  SET_EVENT_POSTS_LOADING,
  SET_EVENT_INVITATIONS,
  SET_EVENT_INVITATIONS_LOADING,
  SET_EVENT_INVITATIONS_ERROR,
  ADD_EVENT_INVITATIONS,
  UPDATE_USER_EVENT_ROLE,
  REMOVE_EVENT_POST,
  SET_EVENT_POST_BOOST,
  REMOVE_EVENT_POST_BOOST,
} from './eventConstants';

export const DELETE_HUB_EVENT = 'hubs/DELETE_HUB_EVENT';
/**
 * Sponsor Actions
 */

export const setSponsors = (data) => ({
  type: SET_EVENT_SPONSORS,
  data,
});

export const addSponsor = (data) => {
  return {
    type: ADD_EVENT_SPONSOR,
    data,
  };
};

export const updateSponsor = (data) => {
  return {
    type: UPDATE_EVENT_SPONSOR,
    data,
  };
};

export const deleteSponsor = (data) => {
  return {
    type: DELETE_EVENT_SPONSOR,
    data,
  };
};

export const removeSponsor = (eventId, sponsorId, onSuccess) => {
  const token = Cookie.get('token');
  return {
    type: API_DELETE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}/sponsors/${sponsorId}`,
      id: sponsorId,
      success: deleteSponsor,
      onSuccess,
      token,
    },
  };
};

export const sendSponsorData = (formdata, eventId, onSuccess, showError) => {
  const token = Cookie.get('token');
  return {
    type: API_POST_IMAGE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}/sponsors`,
      body: formdata,
      success: addSponsor,
      onSuccess,
      token,
      onError: showError,
      identifier: 'hubSponsor',
    },
  };
};

export const updateSponsorData = (
  formdata,
  eventId,
  onSuccess,
  sponsorId,
  showError
) => {
  const token = Cookie.get('token');

  return {
    type: API_PUT_POST_IMAGE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}/sponsors/${sponsorId}`,
      body: formdata,
      success: updateSponsor,
      onSuccess,
      token,
      onError: showError,
      identifier: 'hubSponsor',
    },
  };
};

/**
 * Judge Actions
 */

export const setJudges = (data) => ({
  type: SET_EVENT_JUDGES,
  data,
});

export const updateJudgeBioSuccess = (data) => ({
  type: UPDATE_JUDGE_BIO_SUCCESS,
  data,
});

export const changeJudgeBioPublishedState = (data) => {
  return {
    type: SET_JUDGE_BIO_PUBLISHED_STATE,
    data,
  };
};

export const updateSupportsSuccess = (data) => {
  return {
    type: UPDATE_EVENT_USERS,
    data,
  };
};

export const updateJudgeBioPublishedState = (id, isPublished) => {
  const token = Cookie.get('token');

  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/events/user-event-roles/${id}`,
      body: {
        user_event_role: {
          is_judge_bio_published: isPublished,
        },
      },

      success: changeJudgeBioPublishedState,
      onSuccess: null,
      token,
    },
  };
};

export const updateSupportsLabel = (data, onSuccess) => {
  const { id, supports_label } = data;
  const token = Cookie.get('token');

  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/events/user-event-roles/${id}`,
      body: {
        user_event_role: {
          resource_label: supports_label,
        },
      },
      success: updateSupportsSuccess,
      onSuccess,
      token,
    },
  };
};

export const updateJudgeBio = (title, bio, onSuccess) => {
  const token = Cookie.get('token');
  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/user-profile/judge-bios`,
      body: {
        judge_bio: {
          title,
          bio,
        },
      },
      onSuccess,
      success: updateJudgeBioSuccess,
      token,
    },
  };
};

export const setUsers = (data) => ({
  type: SET_EVENT_USERS,
  data,
});

export const updateRound = (data) => ({
  type: UPDATE_ROUND,
  data,
});

export const updateProjects = (data) => ({
  type: UPDATE_EVENT_PROJECTS,
  data,
});

export const setHubInfo = (data) => ({
  type: SET_EVENT_HUB_INFO,
  data,
});

export const setEventInfo = (data) => ({
  type: SET_EVENT_INFO,
  data,
});

export const setAwards = (data) => ({
  type: SET_EVENT_AWARDS,
  data,
});

export const setRounds = (data) => ({
  type: SET_EVENT_ROUNDS,
  data,
});

export const addNewAward = (data) => {
  return {
    type: ADD_NEW_AWARD,
    data,
  };
};

export const setProjects = (data) => ({
  type: SET_EVENT_PROJECTS,
  data,
});

export const saveEventQuillDelta = (data) => ({
  type: SET_EVENT_QUILL_DELTA,
  data,
});

export const saveEventQuillHtml = (data) => ({
  type: SET_EVENT_QUILL_HTML,
  data,
});

export const updateHubEventSuccess = (data) => ({
  type: UPDATE_HUB_SUCCESS,
  data,
});

export const removeEventSuccess = (hubEventId) => {
  return {
    type: DELETE_HUB_EVENT,
    hubEventId,
  };
};

export const selectEventProject = (data, onSuccess) => {
  const { eventId, selectedProjectId } = data;
  const token = Cookie.get('token');
  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/events/hub-event-projects`,
      body: {
        hub_event_project: {
          hub_event_id: eventId,
          project_id: selectedProjectId,
        },
      },
      success: updateProjects,
      onSuccess,
      token,
    },
  };
};

export const updateHubEventScoringRound = (
  roundId,
  isOpen,
  isFinished,
  areScoresPublished
) => {
  const token = Cookie.get('token');

  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/events/hub-event-scoring-rounds/${roundId}`,
      body: {
        event_scoring_round: {
          is_open: isOpen,
          is_finished: isFinished,
          are_scores_published: areScoresPublished,
        },
      },
      onSuccess: null,
      success: updateRound,
      token,
    },
  };
};

export const updateHubEventScoringStatus = (
  roundId,
  projectList,
  judgeList,
  onSuccess
) => {
  const token = Cookie.get('token');

  return {
    type: API_PUT,
    payload: {
      body: {
        event_scoring_round: {
          is_open: true,
          is_finished: false,
          are_scores_published: false,
          event_scoring_round_projects: projectList,
          event_scoring_round_judges: judgeList,
        },
      },
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/events/hub-event-scoring-rounds/${roundId}`,
      onSuccess,
      success: updateRound,
      token,
    },
  };
};

export const sendQuillImage = (formdata, onSuccess) => {
  const token = Cookie.get('token');

  return {
    type: API_QUILL_IMAGE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/files/rich-text-image-files`,
      body: formdata,
      onSuccess,
      success: null,
      token,
    },
  };
};

export const settingEventData = (data) => {
  return (dispatch) => {
    dispatch(
      setHubInfo({
        hub_info: data.hub,
        host_user: data.host_user,
      })
    );
    dispatch(setUsers(data.users));
    dispatch(setProjects(data.projects));
    dispatch(
      setEventInfo({
        id: data.id,
        name: data.name,
        start_date: data.start_date,
        end_date: data.end_date,
        location_map: data.location_map,
        is_online: data.is_online,
        description: data.description,
        event_type: data.event_type,
        is_user_in_event: data.is_user_in_event,
        hub: data.hub,
      })
    );
    dispatch(setRounds(data.rounds));

    dispatch(saveEventQuillDelta(data.about));
    dispatch(saveEventQuillHtml(data.about_html));

    dispatch(setAwards(data.awards));
    dispatch(setJudges(data.judges));
    dispatch(setSponsors(data.sponsors));
  };
};

export const updateHubEventDescription = (eventId, aboutHtml, onSuccess) => {
  const token = Cookie.get('token');
  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}`,
      body: {
        hub_event: {
          about_html: aboutHtml,
        },
      },
      onSuccess,
      success: null,
      token,
    },
  };
};

export const getEventDetails = (eventId, forceUpdate = false) => async (dispatch, getState) => {
  dispatch(resetNotFound());
  if (!forceUpdate) {
    dispatch({ type: SET_LOADING });
  }
  const state = getState();
  if (parseInt(eventId, 10) === state.eventsv1.eventInfo.id && !forceUpdate) {
    dispatch({ type: UNSET_LOADING });
    return;
  }
  const resp = await getEvent(eventId);
  if (resp?.status && resp?.status !== 200) {
    dispatch(setNotFound());
    dispatch({ type: SET_ERROR });
    dispatch({ type: UNSET_LOADING });
  } else if (resp?.data) {
    await dispatch(settingEventData(resp.data));
    dispatch({ type: UNSET_LOADING });
  }
};

export const addInvitation = (data) => (dispatch) => {
  const invitationsToAdd = data.filter((invitation) => !invitation.error);
  return dispatch({
    type: ADD_EVENT_INVITATIONS,
    data: invitationsToAdd,
  });
};

export const eventInvite = (data, onSuccess) => {
  const { event_id, selected_role, email_list, personal_message } = data;
  const token = Cookie.get('token');
  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${event_id}/invitations`,
      success: addInvitation,
      body: {
        email_list,
        user_event_role_type_id: parseInt(selected_role, 10),
        hub_event_id: event_id,
        personal_message,
      },
      onSuccess,
      token,
    },
  };
};

export const updateHubEvent = (eventForm, eventId, onSuccess) => {
  const token = Cookie.get('token');
  return {
    type: API_PUT,
    payload: {
      body: {
        hub_event: {
          name: eventForm.name,
          description: eventForm.description,
          start_date: eventForm.startDateTime.toISOString(),
          end_date: eventForm.endDateTime.toISOString(),
          is_online: !!eventForm.is_online,
          location_map: eventForm.is_online ? null : eventForm.location_map,
          event_type: eventForm.type,
        },
      },

      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}`,
      success: updateHubEventSuccess,
      onSuccess,
      token,
    },
  };
};

export const removeHubEvent = (eventId, onSuccess) => {
  const token = Cookie.get('token');
  return {
    type: API_DELETE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}`,
      success: null,
      id: eventId,
      onSuccess,
      token,
    },
  };
};

export const postHubEventWinners = (data, eventId, onSuccess) => {
  const token = Cookie.get('token');
  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/events/${eventId}/winners`,
      success: addNewAward,
      body: data,
      onSuccess,
      token,
    },
  };
};

/**
 * EVENT POSTS ACTIONS
 */

export const getEventPosts = (eventId) => async (dispatch, getState) => {
  dispatch({ type: SET_POSTS_ERROR, data: false });
  dispatch({ type: SET_EVENT_POSTS_LOADING, data: true });
  const state = getState();
  if (parseInt(eventId, 10) === state.eventsv1.eventPosts.eventId) {
    dispatch({ type: SET_EVENT_POSTS_LOADING, data: false });
    return;
  }
  const resp = await getPosts(eventId);
  if (resp?.status && resp?.status !== 200) {
    dispatch({ type: SET_EVENT_POSTS_LOADING, data: false });
    dispatch({ type: SET_POSTS_ERROR, data: true });
  } else if (resp?.data) {
    dispatch({
      type: SET_EVENT_POSTS,
      data: {
        eventId,
        posts: resp.data,
      },
    });
  }
};

export const onSuccessUpsertPost = (post) => (dispatch) => {
  if (!post) return;
  dispatch({ type: SET_EVENT_POST, data: post });
};

export function createHubEventPost(hubEventId, hubEventPostId = null) {
  return (dispatch, getState) => {
    const { user, form } = getState();

    // validate
    const hubEventPostForm =
      form && form.HubEventPostForm && form.HubEventPostForm.values
        ? form.HubEventPostForm.values
        : null;

    if (!hubEventPostForm) {
      return;
    }

    if (hubEventPostId) {
      if (!hubEventPostForm[`text-${hubEventPostId}`]) {
        return;
      }
    } else if (!hubEventPostForm.text) {
      return;
    }

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events/${hubEventId}/posts`;

    const payload = {
      event_post: {
        text: hubEventPostId
          ? hubEventPostForm[`text-${hubEventPostId}`]
          : hubEventPostForm.text,
        is_sticky: !!hubEventPostForm.is_sticky,
        event_id: hubEventId,
        parent_post_id: hubEventPostId,
      },
    };

    request(url, jwt, 'POST', payload)
      .then((resp) => {
        if (hubEventPostId) {
          dispatch(change('HubEventPostForm', `text-${hubEventPostId}`, null));
          dispatch(untouch('HubEventPostForm', `text-${hubEventPostId}`));
        } else {
          dispatch(change('HubEventPostForm', 'text', null));
          dispatch(untouch('HubEventPostForm', 'text'));
          dispatch(change('HubEventPostForm', 'is_sticky', null));
          dispatch(untouch('HubEventPostForm', 'is_sticky'));
        }
        dispatch(onSuccessUpsertPost(resp.data));
      })
      .catch((er) => console.log(er));
  };
}

export function updateHubEventPost(eventId, postId, isSticky) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events/${eventId}/posts/${postId}`;

    const payload = {
      event_post: {
        is_sticky: isSticky,
      },
    };

    request(url, jwt, 'PUT', payload)
      .then((resp) => dispatch(onSuccessUpsertPost(resp.data)))
      .catch((er) => console.log(er));
  };
}

export function deleteHubEventPost(eventId, postId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events/${eventId}/posts/${postId}`;

    request(url, jwt, 'DELETE')
      .then(() =>
        dispatch({
          type: REMOVE_EVENT_POST,
          data: { postId, userId: user.get('id') },
        })
      )
      .catch((er) => console.log(er));
  };
}

export function createHubEventPostBoost(hubEventPostId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-post-boosts`;

    const payload = {
      event_post_boost: {
        event_post_id: hubEventPostId,
      },
    };

    request(url, jwt, 'POST', payload)
      .then((resp) =>
        dispatch({
          type: SET_EVENT_POST_BOOST,
          data: resp.data,
        })
      )
      .catch((er) => console.log(er));
  };
}

export function deleteHubEventPostBoost(hubEventPostId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-post-boosts/${hubEventPostId}`;

    request(url, jwt, 'DELETE')
      .then(() =>
        dispatch({
          type: REMOVE_EVENT_POST_BOOST,
          data: {
            postId: hubEventPostId,
            userId: user.get('id'),
          },
        })
      )
      .catch((er) => console.log(er));
  };
}

export const getEventInvitations = (eventId) => async (dispatch, getState) => {
  dispatch({ type: SET_EVENT_INVITATIONS_ERROR, data: false });
  dispatch({ type: SET_EVENT_INVITATIONS_LOADING, data: true });
  const state = getState();
  if (parseInt(eventId, 10) === state.eventsv1.eventInvitations.eventId) {
    dispatch({ type: SET_EVENT_INVITATIONS_LOADING, data: false });
    return;
  }
  const resp = await getInvitations(eventId);
  if (resp?.status && resp?.status !== 200) {
    dispatch({ type: SET_EVENT_INVITATIONS_LOADING, data: false });
    dispatch({ type: SET_EVENT_INVITATIONS_ERROR, data: true });
  } else if (resp?.data) {
    dispatch({
      type: SET_EVENT_INVITATIONS,
      data: {
        eventId,
        invitations: resp.data,
      },
    });
  }
};

// export const getEventParticipants =

export function changeUserEventRole(eventId, userEventRoleId) {
  return (dispatch, getState) => {
    const { user, form } = getState();
    const jwt = user.get('jwt');

    const userEventRoleTypeId = parseInt(
      form.ManageEventParticipantsForm.values[
        `permission_id-${userEventRoleId}`
      ],
      10
    );

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events/${eventId}/participants/${userEventRoleId}`;
    const payload = {
      user_event_role: {
        user_event_role_type_id: userEventRoleTypeId,
      },
    };

    return request(url, jwt, 'PUT', payload).then((response) => {
      dispatch({ type: UPDATE_USER_EVENT_ROLE, data: response.data });
      // reset form field
      dispatch({
        type: '@@redux-form/CHANGE',
        meta: {
          field: `permission_id-${userEventRoleId}`,
          form: 'ManageEventParticipantsForm',
        },
        payload: null,
      });
    });
  };
}
