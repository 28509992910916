import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import loader from 'images/loader.gif';
import socialFacebook from 'images/social_facebook.png';
import socialTwitter from 'images/social_twitter.png';
import socialLinkedin from 'images/social_linkedin.png';
import { withTranslation } from 'react-i18next';

import EditPenButton from 'components/Common/Buttons/EditPenButton';
import {
  editSocialLinks,
  saveSocialLinks,
  RESET_EDITING_SOCIAL_LINKS,
} from '../../../store/profile/profileActions';

class SocialLinks extends React.Component {

  // handleSubmit() {
  //   /**
  //    * dis rly bad right now, dispatches save mode before actually saving. can break badly
  //    */
  //   const socialLinkExists = 'id' in this.props.socialLinks;
  //   socialLinkExists
  //     ? this.props.dispatch(putSocialLinks())
  //     : this.props.dispatch(submitSocialLinks());
  //   this.props.dispatch(toggleSaveSocial());
  // }

  renderSaveButton() {
    const { isSavingSocialLinks, t } = this.props;
    if (isSavingSocialLinks) {
      return <img alt="loading icon" src={loader} />;
    }
    return <button className="userProfile__save-btn">{t('save')}</button>;
  }

  renderEmptyState() {
    const { edit, isUsersProfile, t } = this.props;
    if (!isUsersProfile) return null;
    return (
      <div className="bioInfo__wrap">
        <div className="socialMedia__socialmedia">
          <div className="userProfile__edit">
            <p className="bioInfo__title">{t('SocialLinks')}</p>

            <EditPenButton
              content={t('Edit')}
              className="edit-pen__social"
              onClickFunction={() => edit()}
            />
          </div>

          <p className="project__empty">{t('ProfilePhrase2')}</p>
          <button
            className="plus-sign__btn edit-pen__social btn-link"
            onClick={() => edit()}
          >
            <span className="plus-sign" />
            <span className="edit-pen__social__lnk">{t('AddSocialLinks')}</span>
          </button>
        </div>
      </div>
    );
  }

  renderEditState() {
    const { save, close, t } = this.props;
    return (
      <div className="bioInfo__wrap">
        <div className="socialMedia">
          <div className="userProfile__edit">
            <p className="bioInfo__title">{t('Connect')}</p>
            <button onClick={() => close()} className="btn-x">
              x
            </button>
          </div>
          <div className="socialMedia__social-links">
            <form
              className="socialMedia__social-links__form"
              onSubmit={(e) => save(e)}
            >
              {/* linkedin */}
              <div className="socialMedia__social-links__cont">
                <div className="socialMedia__social-links__icon">
                  <img src={socialLinkedin} alt="linkedin" />
                </div>
                <Field
                  component="input"
                  type="text"
                  name="linkedin"
                  className="socialMedia__social-links__input"
                  placeholder={t('linkedinUsername')}
                />
              </div>
              {/* twitter */}
              <div className="socialMedia__social-links__cont">
                <div className="socialMedia__social-links__icon">
                  <img src={socialTwitter} alt="twitter" />
                </div>
                <Field
                  component="input"
                  type="text"
                  name="twitter"
                  className="socialMedia__social-links__input"
                  placeholder={t('twitterHandle')}
                />
              </div>
              {/* facebook */}
              <div className="socialMedia__social-links__cont">
                <div className="socialMedia__social-links__icon">
                  <img src={socialFacebook} alt="facebook" />
                </div>
                <Field
                  component="input"
                  type="text"
                  name="facebook"
                  className="socialMedia__social-links__input"
                  placeholder={t('facebookUsername')}
                />
              </div>
              <div className="userProfile__save">{this.renderSaveButton()}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderViewState() {
    const { links, edit, isUsersProfile, openSocialLink, t } = this.props;
    const { facebook, linkedin, twitter } = links;
    return (
      <div className="bioInfo__wrap">
        <div className="socialMedia">
          <div className="userProfile__edit userProfile__edit--small">
            <p className="bioInfo__title bioInfo__title--small">
              {t('Connect')}
            </p>
            {isUsersProfile && (
              <EditPenButton
                content={t('Edit')}
                className="edit-pen__social"
                onClickFunction={() => edit()}
              />
            )}
          </div>
          <div className="socialMedia__social-links">
            {linkedin && (
              <div>
                <button
                  onClick={() =>
                    openSocialLink(
                      `https://www.linkedin.com/in/${linkedin}`,
                      'LinkedIn'
                    )
                  }
                  className="socialMedia__social-links__cont  btn-link"
                >
                  <div className="socialMedia__social-links__icon">
                    <img src={socialLinkedin} alt="linkedin" />
                  </div>
                  <span className="socialMedia__social-links__user">
                    {linkedin}
                  </span>
                </button>
              </div>
            )}
            {twitter && (
              <div>
                <button
                  onClick={() =>
                    openSocialLink(
                      `https://www.twitter.com/${twitter}`,
                      'Twitter'
                    )
                  }
                  className="socialMedia__social-links__cont  btn-link"
                >
                  <div className="socialMedia__social-links__icon">
                    <img src={socialTwitter} alt="twitter" />
                  </div>
                  <span className="socialMedia__social-links__user">
                    {twitter}
                  </span>
                </button>
              </div>
            )}
            {facebook && (
              <div>
                <button
                  className="socialMedia__social-links__cont  btn-link"
                  onClick={() =>
                    openSocialLink(
                      `https://www.facebook.com/${facebook}`,
                      'Facebook'
                    )
                  }
                >
                  <div className="socialMedia__social-links__icon">
                    <img src={socialFacebook} alt="facebook" />
                  </div>
                  <span className="socialMedia__social-links__user">
                    {facebook}
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      socialLinksLoaded,
      hasSocialLinks,
      isEditingSocialLinks,
    } = this.props;
    if (socialLinksLoaded) {
      if (isEditingSocialLinks) {
        return this.renderEditState();
      } if (hasSocialLinks) {
        return this.renderViewState();
      }
      return this.renderEmptyState();
    }
    return       <div className="socialMedia__socialmedia">
      <p className="bioInfo__title">Social Links</p>
      loading...
    </div>
  }
}

const mapDispatchToProps = (dispatch) => ({
  edit() {
    dispatch(editSocialLinks());
  },
  close() {
    dispatch({ type: RESET_EDITING_SOCIAL_LINKS });
  },
  save(event) {
    event.preventDefault();
    dispatch(saveSocialLinks());
  },
  openSocialLink(linkAddress, socialType) {
    const confirmation = window.confirm(`Open in ${socialType}?`);
    if (confirmation === true) {
      window.open(linkAddress, '_blank');
    }
  },
});

const mapStateToProps = (state, props) => {
  // is browsing someone else's profile
  if (!props.isUsersProfile) {
    const { browseProfile } = state.browse;

    const links = browseProfile.get('socialLinks').toJS();
    const hasSocialLinks = links.twitter || links.facebook || links.linkedin;

    return {
      links,
      hasSocialLinks,
      socialLinksLoaded: true,
    };
  }

  const { socialLinks, pageMetaData } = state.profile;

  const isEditingSocialLinks = pageMetaData.get('isEditingSocialLinks');
  const socialLinksLoaded = pageMetaData.get('socialLinksLoaded');
  const isSavingSocialLinks = pageMetaData.get('isSavingSocialLinks');

  const links = socialLinks.toJS();
  const initialValues = links;
  // check if all links are empty to load inital state
  const hasSocialLinks = links.twitter || links.facebook || links.linkedin;
  return {
    links,
    initialValues,
    isEditingSocialLinks,
    socialLinksLoaded,
    hasSocialLinks,
    isSavingSocialLinks,
  };
};

export default withTranslation(['translation.Profile'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: 'SocialLinksForm', enableReinitialize: true })(
      SocialLinks
    )
  )
);
