/* eslint-disable no-console */
import { postImage } from 'functions';
import { BEGIN_REQUEST, FINISH_REQUEST } from 'store/metadata/metadataActions';
import { imageFormatNotSupportedPopup } from 'functions/popup/imageFormatNotSupportedPopup';
import { API_PUT_POST_IMAGE } from './middlewareConstants';

const apiPutPostImageMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type !== API_PUT_POST_IMAGE) {
    return next(action);
  }

  const {
    payload: { url, success, body, onSuccess, token, onError, identifier },
  } = action;
  dispatch({ type: BEGIN_REQUEST });
  postImage(url, token, 'PUT', body)
    .then((res) => {
      if (res.status === 422 && identifier === 'hubSponsor') {
        return dispatch(onError());
      }
      if (res.status === 422) {
        return imageFormatNotSupportedPopup(dispatch)
      }
      if (success !== null) {
        dispatch(success(res.data));
      }
      if (onSuccess !== null) {
        onSuccess(res.data);
      }
      return false;
    })
    .then(() => dispatch({ type: FINISH_REQUEST }))
    .catch((err) => {
      console.error(err);
      dispatch({ type: FINISH_REQUEST });
    });

  return false;
};

export default apiPutPostImageMiddleware;
