import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import Modal from 'react-modal';
import emptyLogo from 'images/empty_logo.png';
import loader from 'images/loader.gif';
import { withTranslation } from 'react-i18next';
import { errorPopup } from 'functions/popup/errorPopup';
import { updateHubDetailsStepTwo } from 'store/hubs/hubActions';
import { modalCss } from 'utils/modalCss';
import './createHub.scss';

const FileInput = ({ t, input, type }) => {
  // eslint-disable-next-line no-param-reassign
  delete input.value;
  return (
    <div>
      <label htmlFor={input.name} className="btn-link">
        {t('SelectLogo')}
      </label>
      <input
        className="img-upload"
        id={input.name}
        {...input}
        type={type}
        accept=".png, .jpg, .jpeg, .gif"
      />
    </div>
  );
};
class CreateHubStepTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempLogoState: props.tempLogo,
    };
  }

  renderForm() {
    const { onSubmit, match, isRequesting, t } = this.props;
    return (
      <Modal isOpen contentLabel="Create Hub Modal" style={modalCss}>
        <form
          encType="multipart/form-data"
          className="createHub"
          onSubmit={(e) => onSubmit(e, match.params.h_id)}
        >
          <h2 className="createHub__title">{t('CreateHubStepTwoTitle')}</h2>
          <div className="createHub__question">
            <span className="createHub__label">{t('Logo')}</span>
            <div className="logo__wrap">
              {this.state.tempLogoState ? (
                <img alt="logo" src={this.state.tempLogoState} />
              ) : (
                <img alt="logo" src={emptyLogo} />
              )}
              <Field
                component={FileInput}
                name="logo"
                type="file"
                t={t}
                onChange={(e) => {
                  const reader = new FileReader();
                  const file = e.target.files[0];

                  if (file.size && file.size > 5242880) {
                    e.preventDefault();
                    return this.props.maxSizeErrorPopup();
                  }

                  const validImageTypes = [
                    'image/jpg',
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                  ];

                  if (file && !validImageTypes.includes(file.type)) {
                    e.preventDefault();
                    return this.props.triggerImageErrorPopup();
                  }
                  if (file) {
                    reader.onloadend = () => {
                      this.setState({ tempLogoState: reader.result });
                    };
                    reader.readAsDataURL(file);
                  }
                  return true;
                }}
              />
            </div>
          </div>
          <div className="createHub__question">
            <span className="createHub__label">{t('Description')}</span>
            <div>
              <Field
                component="textarea"
                className="createHub__field createHub__description"
                name="description"
                maxLength="250"
                placeholder={t('DescriptionPlaceholder')}
              />
            </div>
          </div>
          <div className="btn-wrap">
            <span className="createHub__pageIndex">2/2</span>
            <Link
              to={`/hubs/${match.params.h_id}/activities`}
              className="createHub__skip"
            >
              {t('SkipForNow')}
            </Link>
            {isRequesting ? (
              <button type="button" className="btn--saving disabled" disabled>
                <img src={loader} alt="loading" /> {t('Working')}
              </button>
            ) : (
              <button
                className="btn-main"
                type="submit"
                disabled={this.props.isFormEmpty}
              >
                {t('Done')}
              </button>
            )}
          </div>
        </form>
      </Modal>
    );
  }

  render() {
    return this.renderForm();
  }
}

const mapDispatchToProps = (dispatch, { t }) => ({
  onSubmit(e, hubId) {
    e.preventDefault();
    return dispatch(updateHubDetailsStepTwo(hubId));
  },
  triggerImageErrorPopup: () => dispatch(errorPopup(t('SupportedFormats'))),
  maxSizeErrorPopup: () => dispatch(errorPopup(t('MaxPhotoSize'))),
});

function mapStateToProps(state) {
  const { form, metadata } = state;
  const tempLogo =
    form.CreateHubForm &&
    form.CreateHubForm.values &&
    form.CreateHubForm.values.logo
      ? form.CreateHubForm.values.logo[0]
      : null;
  let isFormEmpty = true;
  if (form.CreateHubForm?.values) isFormEmpty = false;
  return {
    tempLogo,
    isRequesting: metadata.isRequesting,
    isFormEmpty,
  };
}

export default withTranslation(['translation.Hub'])(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      reduxForm({ form: 'CreateHubForm', enableReinitialize: true })(
        CreateHubStepTwo
      )
    )
  )
);
