import { List, fromJS } from 'immutable';
import { combineReducers } from 'redux';
import {
  SET_USER_HUBS,
  SET_USER_HUB,
  SET_HUB_MEMBERS,
  SET_HUB_EVENTS,
  SET_HUB_EVENT,
  SET_HUB_EVENT_INVITATIONS,
  SET_USER_EVENT_ROLES,
  UPDATE_HUB_EVENT,
  SET_HUB_EVENT_PROJECTS,
  SET_HUB_EVENT_PROJECT,
  DELETE_HUB_EVENT,
  SET_USER_HUB_CARDS,
  SET_USER_HUB_CARD,
  SET_USER_HUB_PROJECTS,
  REMOVE_USER_HUB_CARD,
  SET_HUB_MEMBER,
  HUB_VIEW_SELECTED_TRACK,
  HUB_VIEW_SELECTED_GROUP,
  RESET_HUB_VIEW_SELECTED_GROUP,
  RESET_HUB_GROUP_LIST,
  SET_HUB_GROUP_LIST,
  SET_SEE_ALL_BUILDERS,
  RESET_SEE_ALL_BUILDERS,
} from './hubActions';
import { DELETE_PENDING_HUB_EVENT_INVITATION } from '../invitations/invitationActions';
import { UPDATE_USER_EVENT_ROLE } from '../events/eventActions';
import { SET_AVATAR } from '../avatar-v1/avatarConstants';
import { EVENT_INVITE_SUCCESS } from '../events-v1/eventConstants';

// hubs that the user is part of
export function userHubs(state = List(), action) {
  switch (action.type) {
    case SET_USER_HUBS:
      return List().concat(fromJS(action.data));
    case SET_USER_HUB:
      return state.set(
        state.findIndex((h) => h.get('id') === action.data.id),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

export function hubMembers(state = List(), action) {
  switch (action.type) {
    case SET_HUB_MEMBERS:
      return List(fromJS(action.data));
    case SET_HUB_MEMBER:
      return state
        .filterNot((members) => members.get('id') === action.data.id)
        .push(fromJS(action.data));
    default:
      return state;
  }
}

export function hubEvents(state = List(), action) {
  switch (action.type) {
    case SET_HUB_EVENTS:
      return List().concat(fromJS(action.data));
    case SET_HUB_EVENT:
      return state.push(fromJS(action.data));
    case UPDATE_HUB_EVENT:
      return state.set(
        state.findIndex((h) => h.get('id') === action.data.id),
        fromJS(action.data)
      );
    case DELETE_HUB_EVENT:
      return state.filterNot((e) => e.get('id') === action.hubEventId);
    default:
      return state;
  }
}

export function hubEventInvitations(state = List(), action) {
  switch (action.type) {
    case SET_HUB_EVENT_INVITATIONS:
      return List().concat(fromJS(action.data));
    case DELETE_PENDING_HUB_EVENT_INVITATION:
      return state.filterNot((i) => i.get('id') === action.invitationId);
    case DELETE_HUB_EVENT:
      return state.filterNot(
        (hei) => hei.get('hub_event_id') === action.hubEventId
      );
    case EVENT_INVITE_SUCCESS:
      return state.concat(fromJS(action.data));
    default:
      return state;
  }
}

export function userEventRoles(state = List(), action) {
  switch (action.type) {
    case SET_USER_EVENT_ROLES:
      return List().concat(fromJS(action.data));
    case UPDATE_USER_EVENT_ROLE:
      return state.set(
        state.findIndex((uer) => uer.get('id') === action.data.id),
        fromJS(action.data)
      );
    case DELETE_HUB_EVENT:
      return state.filterNot(
        (uer) => uer.get('hub_event_id') === action.hubEventId
      );
    // temporary fix - this is so when the user updates their avatar, they see it updated platform wide
    case SET_AVATAR:
      return state.map((role) => {
        if (role.get('user_id') === action.userId) {
          return role.set('avatar_url', action.data);
        }
        return role;
      });
    default:
      return state;
  }
}

export function hubEventProjects(state = List(), action) {
  switch (action.type) {
    case SET_HUB_EVENT_PROJECTS:
      return List().concat(fromJS(action.data));
    case SET_HUB_EVENT_PROJECT:
      return state
        .filterNot(
          (hep) =>
            hep.get('hub_event_id') === action.data.hub_event_id &&
            hep.get('user_id') === action.data.user_id
        )
        .push(fromJS(action.data));
    default:
      return state;
  }
}

export function userHubCards(state = List(), action) {
  switch (action.type) {
    case SET_USER_HUB_CARDS:
      return List().concat(fromJS(action.data).sortBy(Math.random));
    case SET_USER_HUB_CARD:
      return state
        .filterNot((uhc) => uhc.get('id') === action.data.id)
        .push(fromJS(action.data));
    case REMOVE_USER_HUB_CARD:
      return state.filterNot(
        (uhc) => uhc.get('id') === action.user_hub_card_id
      );
    default:
      return state;
  }
}

export function userHubProjects(state = List(), action) {
  switch (action.type) {
    case SET_USER_HUB_PROJECTS:
      return List(fromJS(action.data));
    default:
      return state;
  }
}

export function hubViewTrack(state = {}, action) {
  switch (action.type) {
    case HUB_VIEW_SELECTED_TRACK:
      return action.data;
    default:
      return state;
  }
}

export function hubViewGroup(state = {}, action) {
  switch (action.type) {
    case HUB_VIEW_SELECTED_GROUP:
      return action.data;
    case RESET_HUB_VIEW_SELECTED_GROUP:
      return {};
    default:
      return state;
  }
}

export function hubGroupList(state = [], action) {
  switch (action.type) {
    case SET_HUB_GROUP_LIST:
      return action.data;
    case RESET_HUB_GROUP_LIST:
      return [];
    default:
      return state;
  }
}

export function seeAllBuilders(state = false, action) {
  switch (action.type) {
    case SET_SEE_ALL_BUILDERS:
      return true;
    case RESET_SEE_ALL_BUILDERS:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  hubEvents,
  hubMembers,
  userHubs,
  hubEventInvitations,
  userEventRoles,
  hubEventProjects,
  userHubCards,
  userHubProjects,
  hubViewTrack,
  hubViewGroup,
  hubGroupList,
  seeAllBuilders,
});
