import { Map, fromJS } from 'immutable';
import {
  SHOW_SIDE_NAV,
  HIDE_SIDE_NAV,
  OPEN_PROJECT_SELECT,
  CLOSE_PROJECT_SELECT,
  SET_FOCUSED_PROJECT,
  RESET_FOCUSED_PROJECT,
  OPEN_PROJECT_DROPDOWN,
  CLOSE_PROJECT_DROPDOWN,
  SET_FOCUSED_HUB,
  RESET_FOCUSED_HUB,
  OPEN_PROJECT_TEAMMATE_INVITE,
} from './sideNavActions';

// list of notifications
const initSideNav = Map(
  fromJS({
    menuOpen: false,
    focusedProject: null,
    focusedHub: null,
    projectSelectOpen: false,
    projectDropDownOpen: false,
    projectInviteTeammate: null,
  })
);
function sideNav(state = initSideNav, action) {
  switch (action.type) {
    case SHOW_SIDE_NAV:
      return state.set('menuOpen', true);
    case HIDE_SIDE_NAV:
      return state.set('menuOpen', false);
    case OPEN_PROJECT_SELECT:
      return state.set('projectSelectOpen', true);
    case CLOSE_PROJECT_SELECT:
      return state.set('projectSelectOpen', false);
    case SET_FOCUSED_PROJECT:
      return state
        .set('focusedProject', action.projectId)
        .set('projectSelectOpen', false)
        .set('projectDropDownOpen', false);
    case RESET_FOCUSED_PROJECT:
      return state
        .set('focusedProject', null)
        .set('projectDropDownOpen', false);
    case OPEN_PROJECT_DROPDOWN:
      return state.set('projectDropDownOpen', true);
    case CLOSE_PROJECT_DROPDOWN:
      return state.set('projectDropDownOpen', false);
    case SET_FOCUSED_HUB:
      return state.set('focusedHub', action.hubId);
    case RESET_FOCUSED_HUB:
      return state.set('focusedHub', null);
    case OPEN_PROJECT_TEAMMATE_INVITE:
      return state.set('projectInviteTeammate', action.projectId);
    default:
      return state;
  }
}

export default sideNav;
