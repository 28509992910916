// EVENTS

import Cookie from 'js-cookie';
import {
  SET_EVENT_POST,
  SET_EVENT_POST_BOOST,
  REMOVE_EVENT_POST_BOOST,
  SET_EVENT_ROUND,
  SET_EVENT_JUDGES,
  SET_EVENT_ROUNDS,
} from 'store/events-v1/eventConstants';
import { WebSocketConnect, WebSocketConnectClass } from '../../socket';
import { updateHubEventSuccess } from './eventActions';

import { request } from '../../functions';

import {
  fetchAllHubEventScoringRoundProjectScores,
  fetchAllHubEventScoringRoundProjects,
} from '../events/eventActions';

export const SET_EVENT_CHANNEL = 'channels/SET_EVENT_CHANNEL';

export const getEventJudgeDetails = () => {
  return (dispatch, getState) => {
    const token = Cookie.get('token');
    const { eventsv1 } = getState();

    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT}/api/events/${eventsv1.eventInfo.id}`;
    return request(url, token).then((response) => {
      dispatch({
        type: SET_EVENT_JUDGES,
        data: response.data.judges,
      });
    });
  };
};

export const getEventScoresDetails = () => {
  return (dispatch, getState) => {
    const token = Cookie.get('token');
    const { eventsv1 } = getState();

    const url = `${(window.env || process.env).REACT_APP_API_ENDPOINT}/api/events/${eventsv1.eventInfo.id}`;
    return request(url, token).then((response) => {
      dispatch({
        type: SET_EVENT_ROUNDS,
        data: response.data.rounds,
      });
    });
  };
};

export function JoinEventChannel(eventId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const channelName = WebSocketConnectClass.makeChannelName(
      WebSocketConnectClass.REBEL_EVENT_ROOM_LOBBY,
      eventId
    );
    const channel = WebSocketConnect.channel(user.get('jwt'), channelName);

    channel
      .join()
      .receive('ok', () => {
        dispatch({ type: SET_EVENT_CHANNEL, eventId, channel });
      })
      .receive('error', (error) => console.log('error', error));

    // update hub event details
    channel.on('hub_event_updated', (payload) => {
      updateHubEventSuccess(payload);
    });

    // OLD FUNCTIONS
    // when a new post is created, updated, or deleted
    channel.on('event_post_upsert', (payload) => {
      dispatch({ type: SET_EVENT_POST, data: payload.data });
    });

    // when a post is boosted
    channel.on('hub_event_post_boost_created', (payload) => {
      dispatch({ type: SET_EVENT_POST_BOOST, data: payload.data });
    });

    // when a post is boosted
    channel.on('hub_event_post_boost_removed', (payload) => {
      dispatch({ type: REMOVE_EVENT_POST_BOOST, data: payload.data });
    });

    // when a judge's bio is updated
    channel.on('judge_bio_upsert', () => {
      dispatch(getEventJudgeDetails());
    });

    // when a user role is modified
    channel.on('user_event_role_updated', (payload) => {
      dispatch({
        type: 'SET_JUDGE_BIO_PUBLISHED_STATE',
        data: payload.data,
      });
    });

    // changes to hub event scoring round
    channel.on('hub_event_scoring_round_upsert', (payload) => {
      dispatch({
        type: 'SET_HUB_EVENT_CHANNEL_SCORING_ROUND',
        data: payload.data,
      });

      dispatch(getEventScoresDetails());

      // old actions so that scoring page still works
      dispatch({ type: SET_EVENT_ROUND, data: payload.data });
      dispatch(fetchAllHubEventScoringRoundProjectScores());
      dispatch(fetchAllHubEventScoringRoundProjects());
    });

    // scores updated
    channel.on('hub_event_scoring_round_project_scores_updated', () => {
      dispatch(getEventScoresDetails());

      // old actions so that scoring page still works
      dispatch(fetchAllHubEventScoringRoundProjectScores());
    });
  };
}

export const leaveEventChannel = (eventId) => (dispatch, getState) => {
  const { eventsv1 } = getState();
  const channelName = WebSocketConnectClass.makeChannelName(
    WebSocketConnectClass.REBEL_EVENT_ROOM_LOBBY,
    eventId
  );
  if (eventsv1.channel?.topic === channelName) {
    WebSocketConnect.leaveChannel(channelName);
  }
};
