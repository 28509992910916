import { createTheme, Theme } from '@mui/material/styles';
import { Typography } from '@mui/material/styles/createTypography';

const defaultTheme: Theme = createTheme();
// TODO finalise htmlFontSize and fontSize for typography
const typography = {
  fontFamily: "'Lato', sans-serif",
  /**
   * these typography values can be applied by 'light', 'regular', 'medium' and 'bold'
   * Usage:
   * =====
   *
     <Box sx={{ fontWeight: 'light' }}>
      theme.typography.fontWeightLight
     </Box>

     <Box sx={{ fontWeight: 'regular' }}>
      theme.typography.fontWeightRegular
     </Box>

     <Box sx={{ fontWeight: 'medium' }}>
      theme.typography.fontWeightMedium
     </Box>

     <Box sx={{ fontWeight: 500 }}>
       custom values
     </Box>

     <Box sx={{ fontWeight: 'bold' }}>
        theme.typography.fontWeightMedium
     </Box>
   *
   */
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 300,
    fontSize: defaultTheme.typography.pxToRem(32),
    letterSpacing: 0,
    lineHeight: 48 / 32,
    textAlign: 'left'
  },
  h2: {
    fontWeight: 600,
    fontSize: defaultTheme.typography.pxToRem(22),
    letterSpacing: 0,
    lineHeight: 32 / 22,
    textAlign: 'left'
  },
  h3: {
    fontWeight: 400,
    fontSize: defaultTheme.typography.pxToRem(18),
    letterSpacing: 0,
    lineHeight: 28 / 18,
    textAlign: 'left'
  },
  h4: {
    fontWeight: 300,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontStyle: 'italic',
    lineHeight: 24 / 16,
    letterSpacing: 0,
    textAlign: 'left'
  },
  h5: {
    fontWeight: 700,
    fontSize: defaultTheme.typography.pxToRem(14),
    lineHeight: 20 / 14,
    letterSpacing: 0,
    textAlign: 'left'
  },
  h6: {
    fontWeight: 300,
    fontSize: defaultTheme.typography.pxToRem(14),
    lineHeight: 20 / 14,
    letterSpacing: 1.12,
    textAlign: 'left'
  },
  button: {
    textTransform: 'initial',
    fontWeight: 700,
    letterSpacing: 0,
  },
  subtitle1: {
    fontSize: defaultTheme.typography.pxToRem(12),
    lineHeight: 20 / 12,
    letterSpacing: 0,
    fontWeight: 700,
    textAlign: 'left'
  },
  subtitle2: {
    fontSize: defaultTheme.typography.pxToRem(10),
    lineHeight: 20 / 10,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left'
  },
  body1: {
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 20 / 14,
    letterSpacing: 0
  },
  body2: {
    fontSize: defaultTheme.typography.pxToRem(12),
    lineHeight: 20 / 14 ,
    letterSpacing: 0,
  },
  caption: {
    display: 'inline-block',
    fontSize: defaultTheme.typography.pxToRem(12),
    lineHeight: 18 / 12,
    letterSpacing: 0,
    fontWeight: 700,
  }
} as Typography;

export default typography;
