import { combineReducers } from 'redux';
import {
  SET_ADDING_EMAIL,
  RESET_ADDING_EMAIL,
  SET_VIEW_ACCOUNT,
  SET_VIEW_HUB,
  SET_VIEW_PROJECT,
  SET_SELECTED_HUB,
  SET_SELECTED_PROJECT,
} from './settingConstants';

export function isAddingEmail(state = false, action) {
  switch (action.type) {
    case SET_ADDING_EMAIL:
      return true;
    case RESET_ADDING_EMAIL:
      return false;
    default:
      return state;
  }
}

export function displayWindow(state = 'account', action) {
  switch (action.type) {
    case SET_VIEW_ACCOUNT:
      return 'account';
    case SET_VIEW_PROJECT:
      return 'project';
    case SET_VIEW_HUB:
      return 'hub';
    default:
      return state;
  }
}

export function selectedHub(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_HUB: {
      return action.id;
    }
    default:
      return state;
  }
}
export function selectedProject(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_PROJECT: {
      return action.id;
    }
    default:
      return state;
  }
}

export default combineReducers({
  isAddingEmail,
  displayWindow,
  selectedHub,
  selectedProject,
});
