/* eslint-disable no-console */
import { postImage } from 'functions';
import { API_QUILL_IMAGE } from './middlewareConstants';

const apiQuillImageMiddleware = () => (next) => (action) => {
  if (action.type !== API_QUILL_IMAGE) {
    return next(action);
  }

  const {
    payload: { url, body, onSuccess, token },
  } = action;

  postImage(url, token, 'POST', body)
    .then((resp) =>
      resp.json().then((res) => {
        if (onSuccess !== null) {
          onSuccess(res.data);
        }
      })
    )
    .catch((err) => {
      console.error(err);
    });

  return false;
};

export default apiQuillImageMiddleware;
