import React from 'react';
import { useSelector } from 'react-redux';
import logoTopNav from 'images/logo__topNav.png';
import appLogoStyles from './appLogo.module.scss';

const AppLogo = ({ className }) => {
  const { user_preference } = useSelector((state) => state.user.toJS());
  const languagePath = user_preference?.language === 'ESP' ? '/es' : '';

  return (
    <a
      href={`https://rebelbase.co${languagePath}`}
      className={appLogoStyles.logoLink}
    >
      <img
        className={className}
        alt="rebelbase logo"
        src={logoTopNav}
      />
    </a>
  );
};

export default AppLogo;
