import React, { useEffect, useState } from 'react';
import LoadingComponent from 'components/Loading/loadingComponent';
import { SET_EMAIL_ADDRESS } from 'store/login/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { processInvitationToken } from 'store/invitations/invitationActions';
import { RouteProps } from 'react-router-dom';

interface Props {
  location: RouteProps['location'];
  invitationType: string;
}

const Invitation: React.FC<Props> = ({ location, invitationType }) => {
  const [isProcessingInvite, setProcessingInvite] = useState<boolean>(false);

  const { metadata, user } = useSelector((state) => state) as any;
  const isUserLoggedIn: boolean =
    user.get('jwt') && metadata.loading.get('userLoaded');

  const isLoaded: boolean =
    isUserLoggedIn ||
    (metadata.loading.get('userLoaded') &&
      metadata.loading.get('appDataLoaded'));

  const token: string | undefined = location?.search
    ?.replace('?token=', '')
    .split('&email_address')[0];

  const params: string[] | undefined = location?.search.split('&');

  const getEmailAddress = (): string | null => {
    if (!params) return null;
    return params.length > 1 ? params[1].replace('email_address=', '') : null;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_EMAIL_ADDRESS, data: getEmailAddress() });
  }, []);

  if (isLoaded && !isProcessingInvite) {
    setProcessingInvite(true);
    dispatch(processInvitationToken(token, invitationType, true));
  }

  return <LoadingComponent />;
};

export default Invitation;
