import { isImmutable } from 'immutable';

export function convertDeltaToText(delta) {
  return delta.ops
    .map((operation) => operation.insert.replace('\n', ''))
    .join('\n');
}

export function isEmptyHtmlContent(html) {
  if (
    !html.insert &&
    !html.split('<p><br></p>').filter((ele) => ele !== '').length
  )
    return true;
  return false;
}

export function isQuillDeltaEmpty(delta) {
  if (delta === null || (delta?.ops === null) || (delta?.ops && delta?.ops[0]) === '') {
    return true;
  }
  if (isImmutable(delta)) {
    return delta?.toJS()?.ops[0]?.insert === '\n' && delta?.toJS()?.ops?.length < 2;
  }
  return delta?.ops[0]?.insert === '\n' && delta?.ops?.length < 2;
}

export function areDeltasDifferent(deltaMap1, deltaMap2) {
  const same = JSON.stringify(deltaMap1) === JSON.stringify(deltaMap2);
  if (same) {
    return false;
  }
  return true;
}
