import React from 'react';
import { connect } from 'react-redux';
import SEO from 'components/SEO';
import { Link } from 'react-router-dom';
import './noMatch.scss';
import { withTranslation } from 'react-i18next';

const NoMatch = ({ userId, t }) => (
  <SEO title="404">
    <div>
      <div className="fourOhFour">
        <div className="fourOhFour__breadCrumb">
          <h2>{t('NoMatchTitle')}</h2>
          <p>
            {t('LittleLostPhrase')} <br />
            {userId ? (
              <span>
                {t('Go')}{' '}
                <button type="button" onClick={() => window.history.back()}>
                  {t('back')}
                </button>{' '}
                {t('or')}
                {t('homeToYour')}{' '}
                <Link to={`/profile/${userId}`}>{t('profile')}</Link>.
              </span>
            ) : (
              <span>
                {t('Go')}{' '}
                <button type="button" onClick={() => window.history.back()}>
                  {t('back')}
                </button>{' '}
                {t('or')} <Link to="/">{t('home')}</Link>.
              </span>
            )}
          </p>
        </div>
        <div className="fourOhFour__joke">
          <p>
            &quot;{t('Explore')}&quot; {t('theySay')}
          </p>
          <p>
            &quot;{t('ItWillBeFun')}&quot; {t('theySay')}
          </p>
        </div>
      </div>
    </div>
  </SEO>
);

function mapStateToProps(state) {
  const { user } = state;
  const userId = user.get('id');
  return {
    userId,
  };
}

export default withTranslation(['translation.utils'])(
  connect(mapStateToProps)(NoMatch)
);
