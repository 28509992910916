/* eslint-disable no-console */
import { request } from 'functions';
import { API_GET } from './middlewareConstants';
import { SET_PAGE_NOT_FOUND } from '../metadata/metadataActions';

const apiGetMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type !== API_GET) {
    return next(action);
  }

  const {
    payload: { url, success, onSuccess, token, identifier, onError },
  } = action;

  request(url, token, 'GET', null)
    .then((res) => {
      if (res.status === 404) {
        return dispatch({ type: SET_PAGE_NOT_FOUND });
      }
      if (identifier === 'buildersData') {
        if (res.status === 500 || res.status === 403) {
          dispatch(onError());
        } else if (success !== null) {
          dispatch(success(res));
        }
      } else if (res.status !== 500 || res.status !== 403) {
        if (success !== null) {
          dispatch(success(res.data));
        }
        if (onSuccess !== null) {
          // If result?.data is not available then return actual result
          onSuccess(res.data || res);
        }
      }
      return '';
    })
    .catch((err) => {
      switch (err.status) {
        case 403:
          if (identifier === 'validateLogin') return dispatch(onError());
          break;
        case 404:
          dispatch({ type: SET_PAGE_NOT_FOUND });
          break;
        default:
          // something went wrong
          console.log(err);
      }
      return false
    });

  return false;
};

export default apiGetMiddleware;
