/* eslint-disable import/no-cycle */
import { request } from 'functions';
import { SET_PROJECTS_LOADED } from 'store/metadata/metadataActions';
import { initializeRebelProjectChannel } from 'store/projectProfile-v1/projectProfileChannelActions';

// ACTION TYPES
export const SET_PROJECTS = 'SET_PROJECTS';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';

// ACTION CREATORS
export function setProjects(data) {
  return {
    type: SET_PROJECTS,
    data,
  };
}

export function fetchProjects(
  url = `${
    (window.env || process.env).REACT_APP_API_ENDPOINT
  }/api/v1/rebel/projects`
) {
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch({ type: FETCH_PROJECTS });
    request(url, user.get('jwt')).then((response) => {
      dispatch(setProjects(response.data));
      response.data.forEach((project) =>
        dispatch(initializeRebelProjectChannel(project.id))
      );
      dispatch({ type: SET_PROJECTS_LOADED });
    });
  };
}

export function fetchProjectsOnUpdate(
  url = `${
    (window.env || process.env).REACT_APP_API_ENDPOINT
  }/api/v1/rebel/projects`
) {
  return (dispatch, getState) => {
    const { user, projectProfilev1 } = getState();
    dispatch({ type: FETCH_PROJECTS });
    request(url, user.get('jwt')).then((response) => {
      dispatch(setProjects(response?.data || []));
      (response?.data || []).forEach((project) => {
        if (
          !Object.keys(projectProfilev1.projectChannels.toJS()).includes(
            project.id.toString()
          )
        )
          dispatch(initializeRebelProjectChannel(project.id));
      });
      dispatch({ type: SET_PROJECTS_LOADED });
    });
  };
}
