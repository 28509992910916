import { combineReducers } from 'redux';
import { Map, List, fromJS } from 'immutable';
import {
  SET_INVITATIONS,
  SET_INVITATION,
  SET_TEAMMATES,
  SET_ONLINE_TEAMMATES,
  REMOVE_OFFLINE_TEAMMATES,
  REMOVE_INVITATION,
  SET_TEAMMATE,
  SET_USER_PROJECT_PREFERENCE,
  SET_USER_PROJECT_PREFERENCES,
} from './projectDataActions';
import { SET_AVATAR } from '../avatar-v1/avatarConstants';

const initTeammates = List();
function teammates(state = initTeammates, action) {
  switch (action.type) {
    case SET_TEAMMATES:
      return initTeammates.merge(fromJS(action.data));
    case SET_TEAMMATE:
      return state.set(
        state.findIndex((tm) => tm.get('id') === action.data.id),
        fromJS(action.data)
      );
    // temporary fix - this is so when the user updates their avatar, they see it updated platform wide
    case SET_AVATAR:
      return state.map((role) => {
        if (role.get('user_id') === action.userId) {
          return role.set('avatar_url', action.data);
        }
        return role;
      });
    default:
      return state;
  }
}

const initInvitations = List();
function invitations(state = initInvitations, action) {
  switch (action.type) {
    case SET_INVITATIONS:
      return state.merge(fromJS(action.data));
    case SET_INVITATION:
      return state.push(fromJS(action.data));
    case REMOVE_INVITATION:
      return state.filterNot((i) => i.get('id') === action.id);
    default:
      return state;
  }
}

const teammatesOnlineInit = Map();
function teammatesOnline(state = teammatesOnlineInit, action) {
  switch (action.type) {
    case SET_ONLINE_TEAMMATES:
      return state.merge(fromJS(action.teammates));
    case REMOVE_OFFLINE_TEAMMATES: {
      // use below line after immutable 4 is released
      // return state.removeAll(Object.keys(action.teammates));
      const keys = fromJS(Object.keys(action.teammates));
      return state.filterNot((tm, key) => keys.includes(key));
    }
    default:
      return state;
  }
}

const initUserProjectPreferences = List();
function userProjectPreferences(state = initUserProjectPreferences, action) {
  switch (action.type) {
    case SET_USER_PROJECT_PREFERENCES:
      return state.concat(fromJS(action.data));
    case SET_USER_PROJECT_PREFERENCE:
      return state
        .filterNot((upp) => upp.get('id') === action.data.id)
        .push(fromJS(action.data));
    default:
      return state;
  }
}

export default combineReducers({
  invitations,
  teammates,
  teammatesOnline,
  userProjectPreferences,
});
