import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import signupTranslationEN from './locales/en/signup.json';
import loginTranslationEN from './locales/en/login.json';
import selectTrackTranslationEN from './locales/en/selectTrack.json';
import TellUsTranslationEN from './locales/en/TellUs.json';
import AvatarUploadTranslationEN from './locales/en/AvatarUpload.json';
import ProfileTranslationEN from './locales/en/Profile.json';
import SettingsTranslationEN from './locales/en/settings.json';
import CreateProjectTranslationEN from './locales/en/CreateProject.json';
import InviteTeammateTranslationEN from './locales/en/InviteTeammate.json';
import ProjectProfileTranslationEN from './locales/en/ProjectProfile.json';
import BuildersTranslationEN from './locales/en/Builders.json';
import footerTranslationEN from './locales/en/footer.json';
import HowItWorksTranslationEN from './locales/en/HowItWorks.json';
import navigationTranslationEN from './locales/en/navigation.json';
import HubViewBuildersTranslationEN from './locales/en/HubViewBuilders.json';
import eventsTranslationEN from './locales/en/events.json';
import groupsTranslationEN from './locales/en/groups.json';
import formsTranslationEN from './locales/en/forms.json';
import HubTranslationEN from './locales/en/Hub.json';
import notificationTranslationEN from './locales/en/notification.json';
import HelpTranslationEN from './locales/en/Help.json';
import challengeTranslationEN from './locales/en/challenge.json';
import AcknowledgementTranslationEN from './locales/en/Acknowledgements.json';
import ResetPasswordTranslationEN from './locales/en/ResetPassword.json';
import ForgotPasswordTranslationEN from './locales/en/ForgotPassword.json';
import utilsTranslationEN from './locales/en/utils.json';
import HomepageTranslationEN from './locales/en/Homepage.json';
import AboutTranslationEN from './locales/en/About.json';
import ContactSalesTranslationEN from './locales/en/ContactSales.json';
import ForInnovatorsTranslationEN from './locales/en/ForInnovators.json';
import AlertsEN from './locales/en/Alerts.json';
import UserPreferencesEN from './locales/en/Preferences.json';
import DashboardEN from './locales/en/Dashboard.json';
import BrainEN from './locales/en/Brain.json';
import DateRangePickerEN from './locales/en/DateRangePicker.json';
import HubLibraryEN from './locales/en/HubLibrary.json';
import HubMemberSubscriptionEN from './locales/en/HubMemberSubscription.json';
import MyProjectBuildersEN from './locales/en/MyProjectBuilders.json';

import signupTranslationES from './locales/es/signup.json';
import loginTranslationES from './locales/es/login.json';
import selectTrackTranslationES from './locales/es/selectTrack.json';
import TellUsTranslationES from './locales/es/TellUs.json';
import AvatarUploadTranslationES from './locales/es/AvatarUpload.json';
import ProfileTranslationES from './locales/es/Profile.json';
import SettingsTranslationES from './locales/es/settings.json';
import CreateProjectTranslationES from './locales/es/CreateProject.json';
import InviteTeammateTranslationES from './locales/es/InviteTeammate.json';
import ProjectProfileTranslationES from './locales/es/ProjectProfile.json';
import BuildersTranslationES from './locales/es/Builders.json';
import footerTranslationES from './locales/es/footer.json';
import HowItWorksTranslationES from './locales/es/HowItWorks.json';
import navigationTranslationES from './locales/es/navigation.json';
import HubViewBuildersTranslationES from './locales/es/HubViewBuilders.json';
import eventsTranslationES from './locales/es/events.json';
import groupsTranslationES from './locales/es/groups.json';
import formsTranslationES from './locales/es/forms.json';
import HubTranslationES from './locales/es/Hub.json';
import notificationTranslationES from './locales/es/notification.json';
import HelpTranslationES from './locales/es/Help.json';
import challengeTranslationES from './locales/es/challenge.json';
import AcknowledgementTranslationES from './locales/es/Acknowledgements.json';
import ResetPasswordTranslationES from './locales/es/ResetPassword.json';
import ForgotPasswordTranslationES from './locales/es/ForgotPassword.json';
import utilsTranslationES from './locales/es/utils.json';
import HomepageTranslationES from './locales/es/Homepage.json';
import AboutTranslationES from './locales/es/About.json';
import ContactSalesTranslationES from './locales/es/ContactSales.json';
import ForInnovatorsTranslationES from './locales/es/ForInnovators.json';
import AlertsES from './locales/es/Alerts.json';
import TranslationsTR from './locales/tr/homepage.json';
import UserPreferencesES from './locales/es/Preferences.json';
import DashboardES from './locales/es/Dashboard.json';
import BrainES from './locales/es/Brain.json';
import DateRangePickerES from './locales/es/DateRangePicker.json';
import HubLibraryES from './locales/es/HubLibrary.json';
import HubMemberSubscriptionES from './locales/es/HubMemberSubscription.json';
import MyProjectBuildersES from './locales/es/MyProjectBuilders.json';

// the translations
const resources = {
  en: {
    'translation.Homepage': HomepageTranslationEN,
    'translation.About': AboutTranslationEN,
    'translation.ForInnovators': ForInnovatorsTranslationEN,
    'translation.ContactSales': ContactSalesTranslationEN,
    'translation.signup': signupTranslationEN,
    'translation.login': loginTranslationEN,
    'translation.selectTrack': selectTrackTranslationEN,
    'translation.TellUs': TellUsTranslationEN,
    'translation.AvatarUpload': AvatarUploadTranslationEN,
    'translation.Profile': ProfileTranslationEN,
    'translation.settings': SettingsTranslationEN,
    'translation.CreateProject': CreateProjectTranslationEN,
    'translation.InviteTeammate': InviteTeammateTranslationEN,
    'translation.ProjectProfile': ProjectProfileTranslationEN,
    'translation.Builders': BuildersTranslationEN,
    'translation.footer': footerTranslationEN,
    'translation.HowItWorks': HowItWorksTranslationEN,
    'translation.navigation': navigationTranslationEN,
    'translation.HubViewBuilders': HubViewBuildersTranslationEN,
    'translation.events': eventsTranslationEN,
    'translation.groups': groupsTranslationEN,
    'translation.forms': formsTranslationEN,
    'translation.Hub': HubTranslationEN,
    'translation.notification': notificationTranslationEN,
    'translation.Help': HelpTranslationEN,
    'translation.challenge': challengeTranslationEN,
    'translation.Acknowledgement': AcknowledgementTranslationEN,
    'translation.ResetPassword': ResetPasswordTranslationEN,
    'translation.ForgotPassword': ForgotPasswordTranslationEN,
    'translation.utils': utilsTranslationEN,
    'translation.Alerts': AlertsEN,
    'translation.UserPreferences': UserPreferencesEN,
    'translation.Dashboard': DashboardEN,
    'translation.Brain': BrainEN,
    'translation.DateRangePicker': DateRangePickerEN,
    'translation.HubLibrary': HubLibraryEN,
    'translation.HubMemberSubscription': HubMemberSubscriptionEN,
    'translation.MyProjectBuilders': MyProjectBuildersEN
  },
  es: {
    'translation.Homepage': HomepageTranslationES,
    'translation.About': AboutTranslationES,
    'translation.ForInnovators': ForInnovatorsTranslationES,
    'translation.ContactSales': ContactSalesTranslationES,
    'translation.signup': signupTranslationES,
    'translation.login': loginTranslationES,
    'translation.selectTrack': selectTrackTranslationES,
    'translation.TellUs': TellUsTranslationES,
    'translation.AvatarUpload': AvatarUploadTranslationES,
    'translation.Profile': ProfileTranslationES,
    'translation.settings': SettingsTranslationES,
    'translation.CreateProject': CreateProjectTranslationES,
    'translation.InviteTeammate': InviteTeammateTranslationES,
    'translation.ProjectProfile': ProjectProfileTranslationES,
    'translation.Builders': BuildersTranslationES,
    'translation.footer': footerTranslationES,
    'translation.HowItWorks': HowItWorksTranslationES,
    'translation.navigation': navigationTranslationES,
    'translation.HubViewBuilders': HubViewBuildersTranslationES,
    'translation.events': eventsTranslationES,
    'translation.groups': groupsTranslationES,
    'translation.forms': formsTranslationES,
    'translation.Hub': HubTranslationES,
    'translation.notification': notificationTranslationES,
    'translation.Help': HelpTranslationES,
    'translation.challenge': challengeTranslationES,
    'translation.Acknowledgement': AcknowledgementTranslationES,
    'translation.ResetPassword': ResetPasswordTranslationES,
    'translation.ForgotPassword': ForgotPasswordTranslationES,
    'translation.utils': utilsTranslationES,
    'translation.Alerts': AlertsES,
    'translation.UserPreferences': UserPreferencesES,
    'translation.Dashboard': DashboardES,
    'translation.Brain': BrainES,
    'translation.DateRangePicker': DateRangePickerES,
    'translation.HubLibrary': HubLibraryES,
    'translation.HubMemberSubscription': HubMemberSubscriptionES,
    'translation.MyProjectBuilders': MyProjectBuildersES
  },
  tr: {
    translation: TranslationsTR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
