import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import emptyProfile from 'images/empty_profile.png';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DraftsIcon from '@mui/icons-material/Drafts';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import { setHandleRedirect, showSideNav } from 'store/sideNav/sideNavActions';
import { withTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import LanguageIcon from '@mui/icons-material/Language';
import Badge from '@mui/material/Badge';
import { doLogout } from 'store/logout/logoutActions';
import { TopbarHeight } from 'theme/Theme-variable';
import { setUserPreference } from 'store/user/userActions';
import { getLanguageLabel } from 'functions';
import useUserInvites from 'hooks/useUserInvites';
import NotificationDropdown from 'Pages/Notifications';
import InvitesDropdown from 'Pages/Invitations';
import i18n from '../../../../i18n';
import './header.scss';
import PublicHeader from './PublicHeader';

const Header = ({ sx, customClass, t }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifyEl, setNotifyEl] = React.useState(null);
  const [invitesEl, setInvitesEl] = React.useState(null);
  const [toggleLang, setToggleLang] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('ENG');
  const dispatch = useDispatch();
  const { user, profile, sideNav } = useSelector((state) => state);
  const firstName = user?.get('first_name') || '';
  const avatarURL = profile.avatar || emptyProfile;
  const isLoggedIn = user?.get('jwt') ?? false;
  const isSideNavOpen = sideNav?.get('menuOpen') ?? false;
  const currentLanguage =
    getLanguageLabel(user?.get('user_preference')?.get('language')) ??
    'English';
  const inviteInfo = useUserInvites();
  const userPreferredLanguage = useSelector(
    (state) =>
      state.user &&
      state.user.get('user_preference') &&
      state.user.get('user_preference').get('language')
  );

  const handleRedirect = (pId, url, hId) => {
    dispatch(setHandleRedirect(pId, url, hId));
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(doLogout());
    setAnchorEl(null);
  };

  const closeNavBar = () => {
    if (isLoggedIn) {
      dispatch(showSideNav());
    }
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    dispatch(setUserPreference(language));
  };

  useEffect(() => {
    if (userPreferredLanguage === 'ESP') {
      i18n.changeLanguage('es');
    } else {
      i18n.changeLanguage('en');
    }
    setSelectedLanguage(userPreferredLanguage);
  }, [userPreferredLanguage]);

  return (
    <AppBar
      color="primary"
      sx={{
        ...sx,
        top: { xs: isLoggedIn ? 'auto' : 0, md: 0 },
        bottom: { xs: isLoggedIn ? 0 : 'auto', md: 'auto' },
        position: { xs: isLoggedIn ? 'fixed' : 'sticky', md: 'sticky' },
        flex: '0 0 auto',
      }}
      className={customClass}
    >
      <Toolbar
        sx={{
          justifyContent: { sm: 'space-between', xs: 'space-between' },
        }}
      >
        {isLoggedIn && (
          <>
            {!isSideNavOpen && (
                <Box onClick={closeNavBar}
                     aria-label="menu"
                     aria-controls="navbar-menu"
                     aria-haspopup="true"
                     sx={{
                       display: 'flex',
                       flexDirection: 'row',
                       alignItems: 'center',
                       cursor: 'pointer',
                       px: 1
                     }}>
                  <MenuIcon color='secondary' />
                  <Typography variant="h6" color='secondary' sx={{
                    display: {
                      xs: 'none',
                      sm: 'flex',
                    },
                  }}>
                    {t('Menu')}
                  </Typography>
                </Box>
            )}
            <Box onClick={() => handleRedirect(null, '/browse')}
                 aria-label="search"
                 color="secondary"
                 aria-controls="search-menu"
                 aria-haspopup="true"
                 sx={{
                   display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   cursor: 'pointer',
                   px: 1
                 }}>
              <SearchIcon color='secondary'/>
              <Typography variant="h6" color='secondary' sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}>
                {t('Search')}
              </Typography>
            </Box>
            <Box
              flexGrow={1}
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
            />
            <Box onClick={() => handleRedirect(null, `/profile/${user.get('id')}`)}
                 aria-label="menu"
                 aria-controls="profile-menu"
                 aria-haspopup="true"
                 color='secondary'
                 sx={{
                   display: 'flex',
                   flexDirection: 'row',
                   alignItems: 'center',
                   cursor: 'pointer',
                   px: 1
                 }}>
              <Avatar
                  src={avatarURL}
                  alt={avatarURL}
                  sx={{
                    width: '30px',
                    height: '30px',
                  }}
              />
              <Typography
                  variant="h6"
                  color='secondary'
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'flex',
                    },
                    ml: 1,
                  }}
              >
                {firstName}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '1px',
                backgroundColor: 'rgba(0,0,0,0.1)',
                height: '25px',
                ml: 1,
                mr: 1,
                display: { sm: 'none', xs: 'none', lg: 'block' },
              }}
            />
            <Tooltip title={t('Invitations')}>
              <IconButton
                onClick={($event) => setInvitesEl($event.currentTarget)}
                color="secondary"
                size="large"
                aria-label="Invitations"
                sx={{
                  marginLeft: { xs: 0, md: 1 },
                  marginRight: { xs: 0, md: 1 },
                  minWidth: { xs: 64, md: 64, lg: 'auto' },
                }}
              >
                <Badge badgeContent={inviteInfo?.inviteCount} color="secondary">
                  <DraftsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              id="invites-menu"
              anchorEl={invitesEl}
              keepMounted
              open={Boolean(invitesEl)}
              onClose={() => setInvitesEl(null)}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              sx={{
                zIndex: 900,
                '& .MuiMenu-paper': {
                  width: '450px',
                  maxHeight: '550px',
                  height: '550px',
                  overflowY: 'auto',
                  right: 20,
                  left: 'auto !important',
                  top: {
                    xs: 'auto !important',
                    sm: `auto !important`,
                    md: `${TopbarHeight}px !important`,
                    lg: `${TopbarHeight}px !important`,
                  },
                  bottom: {
                    xs: '56px !important',
                    sm: '56px !important',
                    md: 'auto !important',
                    lg: 'auto !important',
                  },
                  backgroundColor: (theme) => theme.palette.grey["100"],
                },
                '& .MuiList-padding': {
                  p: '10px 0',
                },
              }}
            >
              <InvitesDropdown
                isDropdown
                invites={inviteInfo?.invites}
                isOpened={Boolean(invitesEl)}
                onClose={() => setInvitesEl(null)}
              />
            </Menu>
            <Tooltip title={t('Notifications')}>
              <IconButton
                onClick={($event) => setNotifyEl($event.currentTarget)}
                color="secondary"
                size="large"
                aria-label="Invites"
                sx={{
                  marginLeft: { xs: 0, md: 1 },
                  marginRight: { xs: 0, md: 1 },
                  minWidth: { xs: 64, md: 64, lg: 'auto' },
                }}
              >
                <Badge color="secondary" badgeContent={inviteInfo?.notifyCount}>
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              id="notify-menu"
              anchorEl={notifyEl}
              keepMounted
              open={Boolean(notifyEl)}
              onClose={() => setNotifyEl(null)}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              sx={{
                zIndex: 900,
                '& .MuiMenu-paper': {
                  boxShadow: 1,
                  width: '326px',
                  overflowY: 'auto',
                  right: 20,
                  left: 'auto !important',
                  top: {
                    xs: 'auto !important',
                    sm: `auto !important`,
                    md: `${TopbarHeight}px !important`,
                    lg: `${TopbarHeight}px !important`,
                  },
                  bottom: {
                    xs: '56px !important',
                    sm: '56px !important',
                    md: 'auto !important',
                    lg: 'auto !important',
                  },
                }
              }}
            >
              <NotificationDropdown
                isMenuOpened={Boolean(notifyEl)}
                isDropdown
                close={() => setNotifyEl(null)}
              />
            </Menu>
            <Tooltip title={t('More')}>
              <IconButton
                onClick={($event) => setAnchorEl($event.currentTarget)}
                color="secondary"
                size="large"
                aria-label="More"
                sx={{
                  marginLeft: { xs: 0, md: 1 },
                  marginRight: { xs: 0, md: 1 },
                  display: { sm: 'none', xs: 'none', md: 'inline-flex' },
                }}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              sx={{
                '& .MuiMenu-paper': {
                  width: '280px',
                  right: 0,
                  top: '56px !important',
                  borderRadius: '20px',
                },
                '& .MuiList-padding': {
                  p: '0px',
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleRedirect(null, '/settings');
                }}
                sx={{
                  pt: 3,
                  pb: 3,
                  padding: '10px',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Button
                    color="secondary"
                    sx={{
                      boxShadow: 'none',
                      minWidth: '50px',
                      width: '45px',
                      height: '40px',
                      borderRadius: '10px',
                    }}
                  >
                    <SettingsIcon width="18" height="18" />
                  </Button>
                  <Box
                    sx={{
                      ml: 2,
                    }}
                  >
                    <Typography variant="h5">
                      {t('settings')}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                href="https://rebelbase.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                component="a"
                sx={{
                  pt: 3,
                  pb: 3,
                  padding: '10px',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Button
                    color="secondary"
                    sx={{
                      boxShadow: 'none',
                      minWidth: '50px',
                      width: '45px',
                      height: '40px',
                      borderRadius: '10px',
                    }}
                  >
                    <HelpIcon width="18" height="18" />
                  </Button>
                  <Box
                    sx={{
                      ml: 2,
                    }}
                  >
                    <Typography variant="h5">
                      {t('help')}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => setToggleLang(!toggleLang)}
                sx={{
                  pt: 3,
                  pb: 3,
                  padding: '10px',
                }}
              >
                <Box display="flex" alignItems="center" width={1}>
                  <Button
                    color="secondary"
                    sx={{
                      boxShadow: 'none',
                      minWidth: '50px',
                      width: '45px',
                      height: '40px',
                      borderRadius: '10px',
                    }}
                  >
                    <LanguageIcon width="18" height="18" />
                  </Button>
                  <Box
                    sx={{
                      ml: 2,
                    }}
                  >
                    <Typography variant="h5">
                      {t('Language')}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  {!toggleLang ? (
                    <IconButton
                      color="secondary"
                      size="large"
                      aria-label="More"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="secondary"
                      size="large"
                      aria-label="Less"
                    >
                      <ExpandLessIcon />
                    </IconButton>
                  )}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  selectLanguage('ENG');
                  setAnchorEl(null);
                }}
                disabled={selectedLanguage === 'ENG'}
                sx={{
                  pt: 3,
                  pb: 3,
                  padding: '10px',
                  display: toggleLang ? 'flex' : 'none',
                  justifyContent: 'flex-start',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      ml: 2,
                      pl: selectedLanguage !== 'ENG' ? 6 : 0,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {selectedLanguage === 'ENG' && (
                      <IconButton
                        color="secondary"
                        size="large"
                        aria-label="More"
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                    <Typography variant="h5">
                      English
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  selectLanguage('ESP');
                  setAnchorEl(null);
                }}
                disabled={selectedLanguage === 'ESP'}
                sx={{
                  pt: 3,
                  pb: 3,
                  padding: '10px',
                  display: toggleLang ? 'flex' : 'none',
                  justifyContent: 'flex-start',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      ml: 2,
                      pl: selectedLanguage !== 'ESP' ? 6 : 0,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {selectedLanguage === 'ESP' && (
                      <IconButton
                        color="secondary"
                        size="large"
                        aria-label="More"
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                    <Typography variant="h5">
                      Español
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={logout}
                sx={{
                  pt: 3,
                  pb: 3,
                  padding: '10px',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Button
                    color="secondary"
                    sx={{
                      boxShadow: 'none',
                      minWidth: '50px',
                      width: '45px',
                      height: '40px',
                      borderRadius: '10px',
                    }}
                  >
                    <LogoutIcon width="18" height="18" />
                  </Button>
                  <Box
                    sx={{
                      ml: 2,
                    }}
                  >
                    <Typography variant="h5">
                      {t('logOut')}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            </Menu>
          </>
        )}
        {!isLoggedIn && (
          <>
            <PublicHeader
              currentLanguage={currentLanguage}
              closeNavBar={closeNavBar}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withTranslation(['translation.navigation'])(React.memo(Header));
