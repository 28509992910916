import { fetchSectionChallengeTopicBlueprints } from 'store/appData/appDataActions';
import Cookie from 'js-cookie';
import moment from 'moment';
import { triggerSuccessPopup } from 'functions/popup/triggerSuccessPopup';
import { request } from '../../functions';
import { SET_USER_LOADED } from '../metadata/metadataActions';
import i18n from '../../i18n';

// ACTION TYPES
export const SET_USER = 'SET_USER';
export const SET_JWT = 'SET_JWT';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_EMAIL = 'SET_EMAIL';

// ACTION CREATORS
export function setUser(data) {
  return {
    type: SET_USER,
    data,
  };
}

export const SET_USER_CONSENT = 'user/SET_USER_CONSENT';
export function fetchUserConsent(jwt) {
  return (dispatch) => {
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/user-consents`;
    return request(url, jwt).then((response) => {
      dispatch({ type: SET_USER_CONSENT, data: response.data });
      dispatch({ type: SET_USER_LOADED });
    });
  };
}

export function fetchUser() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/settings/users`;
    return request(url, jwt)
      .then((response) => {
        dispatch(fetchUserConsent(jwt));
        dispatch(setUser(response.data[0]));
      })
      .catch(() => {
        return Promise.reject();
      });
  };
}

export function submitConsent() {
  return (dispatch, getState) => {
    const { user, form } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/user-consents`;

    const ConsentFormValues =
      form && form.ConsentForm && form.ConsentForm.values
        ? form.ConsentForm.values
        : null;

    // validate
    if (!ConsentFormValues) {
      return null;
    }

    const payload = {
      user_consent: {
        allow_data_processing: true,
        allow_data_transmission: true,
        allow_cookies: true,
        allow_promo_emails: ConsentFormValues.allowPromoEmails,
      },
    };
    return request(url, jwt, 'POST', payload).then((response) => {
      dispatch({ type: SET_USER_CONSENT, data: response.data });
    });
  };
}

export const SET_USER_PREFERENCE = 'user/SET_USER_PREFERENCE';
export function getUserPreference() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/accounts/preferences/${user.get('id')}`;
    if (jwt) {
      return request(url, jwt).then((response) => {
        dispatch({
          type: SET_USER_PREFERENCE,
          data: response.data || { language: Cookie.get('userPreference') },
        });

        if (response.data === 'ESP' || Cookie.get('userPreference') === 'ESP') {
          i18n.changeLanguage('es');
        } else {
          i18n.changeLanguage('en');
        }
      });
    }
    dispatch({
      type: SET_USER_PREFERENCE,
      data: { language: Cookie.get('userPreference') },
    });
    if (Cookie.get('userPreference') === 'ESP') i18n.changeLanguage('es');
    else i18n.changeLanguage('en');
    return '';
  };
}

export function setSchedulerPreferences(alertsEmailInterval, hubActivityEmailInterval) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/accounts/preferences/${user.get('id')}`;
    const payload = {
      preference: {
        alerts_email_interval: alertsEmailInterval,
        hub_activity_email_interval: hubActivityEmailInterval,
      },
    };
    if (jwt) {
      return request(url, jwt, 'PUT', payload).then((response) => {
        dispatch({
          type: SET_USER_PREFERENCE,
          data: response.data,
        });
        triggerSuccessPopup('EmailSettingsSaved', dispatch);
      });
    }
    return '';
  };
}

export function setUserPreference(language) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/accounts/preferences/${user.get('id')}`;

    const payload = {
      preference: {
        language,
      },
    };

    Cookie.set('userPreference', language, { expires: 30 });

    if (language === 'ESP') {
      moment.locale('es');
      Cookie.set('dateFormat', 'D[,] MMMM YYYY', { expires: 30 });
      i18n.changeLanguage('es');
    } else if (language === 'ENG') {
      moment.locale('en_US');
      Cookie.set('dateFormat', 'MMMM D[,] YYYY', { expires: 30 });
      i18n.changeLanguage('en');
    }

    if (jwt) {
      return request(url, jwt, 'PUT', payload).then((response) => {
        dispatch({ type: 'metadata/RESET_APP_DATA_LOADED' });
        dispatch({
          type: SET_USER_PREFERENCE,
          data: response.data || { language: Cookie.get('userPreference') },
        });
        dispatch(fetchSectionChallengeTopicBlueprints());
      });
    }
    dispatch({
      type: SET_USER_PREFERENCE,
      data: { language: Cookie.get('userPreference') },
    });
    return '';
  };
}
