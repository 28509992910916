import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

const useUserInvites = () => {
  const { notifications, alertsReducer } = useSelector((state) => state);
  const [inviteInfo, setInviteInfo] = useState();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    // Copy default values from state
    const inviteData = {
      invites: List(),
      inviteCount: 0,
    };

    // project invitation notifications
    inviteData.invites = inviteData.invites
      .concat(
        notifications.projectInvitationNotifications.map((pin) => {
          const notification = notifications.notifications.find(
            (n) => n && n.get('id') === pin?.get('notification_id')
          );
          return notification
            ?.merge(pin)
            .set('type', 'Project')
            .set('inserted_at', pin?.get('updated_at'));
        })
      )
      .toSet();

    // hub invitation notification
    inviteData.invites = inviteData.invites
      .concat(
        notifications.hubInvitationNotifications.map((hin) => {
          const notification = notifications.notifications.find(
            (n) => n && n.get('id') === hin?.get('notification_id')
          );
          return notification
            ?.merge(hin)
            .set('type', 'Hub')
            .set('inserted_at', hin?.get('updated_at'));
        })
      )
      .toSet();

    // hub event invitation
    inviteData.invites = inviteData.invites
      .concat(
        notifications.hubEventInvitationNotifications.map((hein) => {
          const notification = notifications.notifications.find(
            (n) => n && n.get('id') === hein?.get('notification_id')
          );
          return notification?.merge(hein).set('type', 'HubEvent');
        })
      )
      .toSet();

    inviteData.inviteCount =
      (inviteData.invites || List([]))
        .filter(
          (invite) =>
            invite &&
            !invite.get('is_accepted') &&
            !invite.get('is_declined') &&
            !invite.get('is_ignored')
        )
        ?.count() || 0;
    setInviteInfo(inviteData);
  }, [notifications]);

  useEffect(() => {
    setUnreadNotificationsCount(alertsReducer.totalUnreadAlertsCount);
  }, [alertsReducer]);
  return {
    ...inviteInfo,
    notifyCount: unreadNotificationsCount,
  };
};

export default useUserInvites;
