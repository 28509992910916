import { combineReducers } from 'redux';
import { List, fromJS } from 'immutable';

import {
  SET_ALL_NOTIFICATIONS_READ,
  SET_HUB_EVENT_INVITATION_NOTIFICATION,
  SET_HUB_EVENT_INVITATION_NOTIFICATIONS,
  SET_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_PROJECT_INVITATION_NOTIFICATION,
  SET_PROJECT_INVITATION_NOTIFICATIONS,
  UPDATE_PROJECT_INVITATION_NOTIFICATION,
  UPDATE_HUB_EVENT_INVITATION_NOTIFICATION,
  SET_HUB_INVITATION_NOTIFICATION,
  SET_HUB_INVITATION_NOTIFICATIONS,
  UPDATE_HUB_INVITATION_NOTIFICATION,
} from './notificationActions';

// list of notifications
const initNotifications = List();
function notifications(state = initNotifications, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return state.concat(fromJS(action.data));
    case SET_NOTIFICATION:
      return state.push(fromJS(action.data));
    case SET_ALL_NOTIFICATIONS_READ:
      return state.map((n) => n.set('has_read', true));
    default:
      return state;
  }
}

// list of projectInvitationNotifications
const initProjectInvitationNotifications = List();
function projectInvitationNotifications(
  state = initProjectInvitationNotifications,
  action
) {
  switch (action.type) {
    case SET_PROJECT_INVITATION_NOTIFICATIONS:
       return state.concat(fromJS(action.data));
    case SET_PROJECT_INVITATION_NOTIFICATION:
       return state.push(fromJS(action.data));
    case UPDATE_PROJECT_INVITATION_NOTIFICATION:
       return state.set(
        state.findIndex((n) => n.get('id') === action.data.id),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

// hub event invitation notification
function hubEventInvitationNotifications(state = List(), action) {
  switch (action.type) {
    case SET_HUB_EVENT_INVITATION_NOTIFICATION:
      return state.push(fromJS(action.data));
    case SET_HUB_EVENT_INVITATION_NOTIFICATIONS:
      return List().concat(fromJS(action.data));
    case UPDATE_HUB_EVENT_INVITATION_NOTIFICATION:
      return state.set(
        state.findIndex((i) => i.get('id') === action.data.id),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

// Hub invitation notification
function hubInvitationNotifications(state = List(), action) {
  switch (action.type) {
    case SET_HUB_INVITATION_NOTIFICATION:
      return state.push(fromJS(action.data));
    case SET_HUB_INVITATION_NOTIFICATIONS:
      return List().concat(fromJS(action.data));
    case UPDATE_HUB_INVITATION_NOTIFICATION:
      return state.set(
        state.findIndex((i) => i.get('id') === action.data.id),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

export default combineReducers({
  hubEventInvitationNotifications,
  hubInvitationNotifications,
  notifications,
  projectInvitationNotifications,
});
