import { combineReducers } from 'redux';
import { Map, List, fromJS } from 'immutable';
import {
  SET_EMAIL_ADDRESS,
  RESET_EMAIL_ADDRESS,
} from 'store/login/loginActions';
import {
  SET_APP_DATA_LOADED,
  SET_REDIRECT,
  RESET_REDIRECT,
  OPEN_WORKSHOP,
  CLOSE_WORKSHOP,
  SET_PROFILE_DATA_LOADED,
  SET_PROJECTS_LOADED,
  SET_USER_LOADED,
  SET_CHALLENGE_DATA_LOADED,
  SET_PROJECT_DATA_LOADED,
  OPEN_ANSWER,
  CLOSE_ANSWER,
  SET_PROJECTS_LOADING,
  SET_CHALLENGE_DATA_LOADING,
  SET_EDITING_PROJECT_ABOUT,
  SET_VIEWING_PROJECT_ABOUT,
  SET_EDITING_PROJECT_DESCRIPTION,
  SET_VIEWING_PROJECT_DESCRIPTION,
  RESET_PROJECT_PROFILE_METADATA,
  OPEN_UPLOAD_LOGO,
  CLOSE_UPLOAD_LOGO,
  REQUEST_PROCESSED,
  PROCESSING_REQUEST,
  SET_PAGE_NOT_FOUND,
  RESET_PAGE_NOT_FOUND,
  SET_PROJECT_ANSWERS_LOADED,
  SET_CHALLENGE_WORKSHOPS_LOADED,
  SET_FOCUS_MODE,
  RESET_FOCUS_MODE,
  SET_HUBS_LOADED,
  RESET_HUBS_LOADED,
  BEGIN_REQUEST,
  FINISH_REQUEST,
  SET_USER_INVITATIONS_LOADED,
  RESET_USER_INVITATIONS_LOADED,
  RESET_APP_DATA_LOADED,
  RESET_PROJECT_DATA_LOADED,
  VERIFYING_EMAIL,
  SET_SIGNUP,
  RESET_SIGNUP,
  OPEN_UPLOAD_BACKDROP,
  CLOSE_UPLOAD_BACKDROP,
  SET_DATA_LIST,
  RESET_DATA_LIST,
  SET_SCREEN_RESIZE,
  RESET_USER_LOADED,
  SET_DESCRIPTION_CHANGED,
  SET_DESCRIPTION_SAVED,
  SET_ABOUT_CHANGED,
  SET_ABOUT_SAVED,
  SET_TOKEN_ERROR,
  RESET_TOKEN_ERROR,
  SET_BUILDERS_DATA_LOADED,
  PERMISSION_DENIED_FOR_BUILDERS_DATA,
  RESET_PERMISSION_DENIED_FOR_BUILDERS_DATA,
  SET_PROJECT_ANSWER_CHANGED,
  SET_PROJECT_ANSWER_SAVED,
  SET_EDITING_HUB_ACTIVITY,
  SET_VIEWING_HUB_ACTIVITY,
  TELL_US_MODAL_STATE,
  SET_SCORING_DATA_LOADED,
} from './metadataActions';

// for redirects
function redirectTo(state = null, action) {
  switch (action.type) {
    case SET_REDIRECT:
      return action.url;
    case RESET_REDIRECT:
      return null;
    default:
      return state;
  }
}

// Loading
const initLoading = Map(
  fromJS({
    appDataLoaded: false,
    profileDataLoaded: false,
    projectsLoaded: false,
    userLoaded: false,
    challengeDataLoaded: false,
    challengeWorkshopsLoaded: false,
    projectAnswersLoaded: false,
    projectDataLoaded: false,
    hubsLoaded: false,
    userInvitationsLoaded: false,
    tokenError: false,
    buildersDataLoaded: false,
    scoringDataLoaded: false,
  })
);
function loading(state = initLoading, action) {
  switch (action.type) {
    case SET_APP_DATA_LOADED:
      return state.set('appDataLoaded', true);
    case RESET_APP_DATA_LOADED:
      return state.set('appDataLoaded', false);
    case SET_PROFILE_DATA_LOADED:
      return state.set('profileDataLoaded', true);
    case SET_PROJECTS_LOADED:
      return state.set('projectsLoaded', true);
    case SET_USER_LOADED:
      return state.set('userLoaded', true);
    case RESET_USER_LOADED:
      return state.set('userLoaded', false);
    case SET_CHALLENGE_DATA_LOADED:
      return state.set('challengeDataLoaded', true);
    case SET_CHALLENGE_DATA_LOADING:
      return state.set('challengeDataLoaded', false);
    case SET_PROJECT_DATA_LOADED:
      return state.set('projectDataLoaded', true);
    case SET_PROJECTS_LOADING:
      return state.set('projectsLoaded', false);
    case SET_PROJECT_ANSWERS_LOADED:
      return state.set('projectAnswersLoaded', true);
    case SET_CHALLENGE_WORKSHOPS_LOADED:
      return state.set('challengeWorkshopsLoaded', true);
    case SET_HUBS_LOADED:
      return state.set('hubsLoaded', true);
    case RESET_HUBS_LOADED:
      return state.set('hubsLoaded', false);
    case SET_USER_INVITATIONS_LOADED:
      return state.set('userInvitationsLoaded', true);
    case RESET_USER_INVITATIONS_LOADED:
      return state.set('userInvitationsLoaded', false);
    case RESET_PROJECT_DATA_LOADED:
      return state.set('projectDataLoaded', false);
    case SET_TOKEN_ERROR:
      return state.set('tokenError', true);
    case RESET_TOKEN_ERROR:
      return state.set('tokenError', false);
    case SET_BUILDERS_DATA_LOADED:
      return state.set('buildersDataLoaded', true);
    case SET_SCORING_DATA_LOADED:
      return state.set('scoringDataLoaded', true);
    default:
      return state;
  }
}

function permissionDenied(state = false, action) {
  switch (action.type) {
    case PERMISSION_DENIED_FOR_BUILDERS_DATA:
      return true;
    case RESET_PERMISSION_DENIED_FOR_BUILDERS_DATA:
      return false;
    default:
      return state;
  }
}

const initOPCW = Map();
function openedProjectChallengeWorkshops(state = initOPCW, action) {
  switch (action.type) {
    case OPEN_WORKSHOP: {
      const newList = state.get(action.projectId)
        ? state.get(action.projectId).push(action.workshopBlueprintId)
        : List([action.workshopBlueprintId]);
      return state.set(action.projectId, newList);
    }
    case CLOSE_WORKSHOP: {
      const list = state.get(action.projectId);
      return state.set(
        action.projectId,
        list.filterNot((wbid) => wbid === action.workshopBlueprintId)
      );
    }
    default:
      return state;
  }
}

// list of currently open project topics
const initOPCA = List();
function openedProjectChallengeAnswers(state = initOPCA, action) {
  switch (action.type) {
    case OPEN_ANSWER:
      return state.includes(action.topicId)
        ? state
        : state.push(action.topicId);
    case CLOSE_ANSWER:
      return state.includes(action.topicId)
        ? state.remove(state.findIndex((a) => a === action.topicId))
        : state;
    default:
      return state;
  }
}

// metadata for project profile
const initProjectProfile = Map(
  fromJS({
    isEditingAbout: false,
    isEditingDescription: false,
    isUploadingLogo: false,
    isDescriptionChanged: false,
    isAboutChanged: false,
    isProjectAnswerChanged: false,
  })
);
function projectProfile(state = initProjectProfile, action) {
  switch (action.type) {
    case SET_EDITING_PROJECT_ABOUT:
      return state.set('isEditingAbout', true);
    case SET_VIEWING_PROJECT_ABOUT:
      return state.set('isEditingAbout', false);
    case SET_EDITING_PROJECT_DESCRIPTION:
      return state.set('isEditingDescription', true);
    case SET_VIEWING_PROJECT_DESCRIPTION:
      return state.set('isEditingDescription', false);
    case SET_DESCRIPTION_CHANGED:
      return state.set('isDescriptionChanged', true);
    case SET_DESCRIPTION_SAVED:
      return state.set('isDescriptionChanged', false);
    case SET_ABOUT_CHANGED:
      return state.set('isAboutChanged', true);
    case SET_ABOUT_SAVED:
      return state.set('isAboutChanged', false);
    case SET_PROJECT_ANSWER_CHANGED:
      return state.set('isProjectAnswerChanged', true);
    case SET_PROJECT_ANSWER_SAVED:
      return state.set('isProjectAnswerChanged', false);
    case RESET_PROJECT_PROFILE_METADATA:
      return initProjectProfile;
    case OPEN_UPLOAD_LOGO:
      return state.set('isUploadingLogo', true);
    case CLOSE_UPLOAD_LOGO:
      return state.set('isUploadingLogo', false);
    case OPEN_UPLOAD_BACKDROP:
      return state.set('isUploadingBackdrop', true);
    case CLOSE_UPLOAD_BACKDROP:
      return state.set('isUploadingBackdrop', false);
    default:
      return state;
  }
}

// metadata for hub activity
const initHubActivity = Map(
  fromJS({
    isEditingHubActivity: false,
  })
);

function hubActivity(state = initHubActivity, action) {
  switch (action.type) {
    case SET_EDITING_HUB_ACTIVITY:
      return state.set('isEditingHubActivity', true);
    case SET_VIEWING_HUB_ACTIVITY:
      return state.set('isEditingHubActivity', false);
    default:
      return state;
  }
}

function isFocusModeActive(state = false, action) {
  switch (action.type) {
    case RESET_FOCUS_MODE:
      return false;
    case SET_FOCUS_MODE:
      return true;
    default:
      return state;
  }
}

function isProcessingRequest(state = false, action) {
  switch (action.type) {
    case REQUEST_PROCESSED:
      return false;
    case PROCESSING_REQUEST:
      return true;
    default:
      return state;
  }
}

function pageNotFound(state = false, action) {
  switch (action.type) {
    case SET_PAGE_NOT_FOUND:
      return true;
    case RESET_PAGE_NOT_FOUND:
      return false;
    default:
      return state;
  }
}

function isRequesting(state = false, action) {
  switch (action.type) {
    case BEGIN_REQUEST:
      return true;
    case FINISH_REQUEST:
      return false;
    default:
      return state;
  }
}

function isVerifyingEmail(state = false, action) {
  if (action.type === VERIFYING_EMAIL) {
    return true;
  }
  return state;
}

function isSignup(state = false, action) {
  switch (action.type) {
    case SET_SIGNUP:
      return true;
    case RESET_SIGNUP:
      return false;
    default:
      return state;
  }
}

function isTellUsModalOpen(state = false, action) {
  switch (action.type) {
    case TELL_US_MODAL_STATE:
      return action.state;
    default:
      return state;
  }
}

// store a list (of whatever data) to be used somewhere
export function dataList(state = List(), action) {
  switch (action.type) {
    case SET_DATA_LIST:
      return List(fromJS(action.data));
    case RESET_DATA_LIST:
      return List();
    default:
      return state;
  }
}

// UI
const width =
  (typeof window.orientation !== 'undefined'
    ? document.documentElement.clientWidth
    : window.innerWidth) || document.body.clientWidth;
export function UI(state = fromJS({ screenWidth: width }), action) {
  switch (action.type) {
    case SET_SCREEN_RESIZE:
      return state.set('screenWidth', action.screenSize);
    default:
      return state;
  }
}

function emailAddressFromParams(state = '', action) {
  switch (action.type) {
    case SET_EMAIL_ADDRESS:
      return action.data;
    case RESET_EMAIL_ADDRESS:
      return '';
    default:
      return state;
  }
}

export default combineReducers({
  isFocusModeActive,
  isProcessingRequest,
  isRequesting,
  isSignup,
  isTellUsModalOpen,
  isVerifyingEmail,
  loading,
  openedProjectChallengeAnswers,
  openedProjectChallengeWorkshops,
  pageNotFound,
  projectProfile,
  redirectTo,
  dataList,
  UI,
  permissionDenied,
  emailAddressFromParams,
  hubActivity,
});
