import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, SenderUser, Hub, Event } from 'utils/types/alert';
import { Trans, useTranslation, WithTranslation } from 'react-i18next';
import { Typography } from "@mui/material";
import {formatDate, getShortDateFormat} from "functions";

export interface Props {
  alert: Alert;
  t: WithTranslation['t'];
}

export const CustomLink: React.FC<{
  to: string;
  children: React.ReactChild;
}> = ({ to, children }) => (
  <Link to={to} style={{ fontWeight: 600 }}>
    {children}
  </Link>
);

const UserLink: React.FC<{ user: SenderUser }> = ({ user }) => (
  <CustomLink to={`/profile/${user.userId}`}>
    <>
      {user.firstName} {user.lastName}
    </>
  </CustomLink>
);

export const SenderUsers: React.FC<{
  users: SenderUser[];
}> = ({ users = [] }) => {
  const { t } = useTranslation(['translation.Alerts']);
  return (
    <>
      {!!users.length && <UserLink user={users[0]} />}
      {users.slice(1, 4).map((user, index) => {
        if (index === users.length - 2 || index === 2) {
          if (users.length <= 4)
            return (
              <>
                <span> {t('and')} </span>
                <UserLink user={user} />
              </>
            );
          return (
            <span>{` ${t('and')} ${users.length - 3} ${t('others')}`}</span>
          );
        }
        return (
          <>
            <span>, </span>
            <UserLink user={user} />
          </>
        );
      })}
    </>
  );
};

export const HubName: React.FC<{ hub: Hub; t: WithTranslation['t'] }> = ({
  hub,
  t,
}) => (
  <Trans
    i18nKey="hubName"
    values={{
      name: hub?.name,
    }}
    t={t}
  >
    in the
    <CustomLink
      to={`/hubs/${hub?.id}/activities`}
    >{`${hub?.name}`}</CustomLink>{' '}
    hub
  </Trans>
);

export const EventName: React.FC<{ event: Event; t: WithTranslation['t'] }> = ({
  event,
  t,
}) => (
  <Trans
    i18nKey="eventName"
    values={{
      name: event?.name,
    }}
    t={t}
  >
    the <CustomLink to={`/events/${event?.id}`}>{`${event?.name}`}</CustomLink>{' '}
    event
  </Trans>
);

export const TimeIndicator: React.FC<{
    timeStamp: Date
}> = ({ timeStamp}) => {
  return (
      <>
        {timeStamp && (
            <Typography variant="subtitle1" sx={{
              color: (theme) => theme?.palette?.grey["600"],
            }}>
              {formatDate(timeStamp)}
            </Typography>
        )}
      </>
  )
}

export const ShortTimeIndicator: React.FC<{
    timeStamp: Date
}> = ({ timeStamp}) => {
    return (
        <>
            {timeStamp && (
                <Typography fontStyle="italic" component="span" variant="subtitle1" sx={{
                    color: (theme) => theme?.palette?.grey["600"]
                }}>
                    {getShortDateFormat(timeStamp)}
                </Typography>
            )}
        </>
    )
}