import React, {useState} from "react";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavLink from "../NavLink/NavLink";

export interface TeamMember {
  firstName: string;
  lastName: string;
  userId: number;
}

export interface TeamMemberMenuProps {

  projectId: number;

  /**
   * Lis of members part of projects and etc
   */
  members: Array<TeamMember>;
  /**
   * this data member define user has enough rights to add project members or not
   */
  hasAddMemberRights: boolean;

  addTeamMember: (event: React.MouseEvent<HTMLDivElement>, projectId: number) => void

}

const TeamMembersMenu = (props: TeamMemberMenuProps) => {

  const [open, setOpen] = useState(false);

  const { t } = useTranslation(['translation.navigation']);

  const { members, hasAddMemberRights,  addTeamMember, projectId} = props;

    return (
      <>
        <MenuItem onClick={() => setOpen(!open)} sx={{ pl: 3 }}>
          <ListItemIcon
              sx={{ pr: 1 }}>
            {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5">
              {t('Team')}
            </Typography>
          </ListItemText>
          {
            hasAddMemberRights && (
                <ListItemIcon onClick={(event) => addTeamMember(event, projectId)} sx={{
                  minWidth: 'auto !important',
                  p: (theme) => theme.spacing(0, 0, 0, 1)
                }}>
                  <Tooltip title={t('addTeammate')}>
                    <AddIcon/>
                  </Tooltip>
                </ListItemIcon>
            )
          }
        </MenuItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuList
              sx={{
                  '& .MuiListItemIcon-root': {
                      minWidth: 'auto !important',
                      // TODO: palette grey colors are not properly configured. Need to adjust theme dont worry about this hard code color
                      color: '#747474'
                  }
              }}
              component="div"
              disablePadding>
              {
                members?.map(member => (
                    <MenuItem
                        component={NavLink}
                        onClick={(event: any) => {
                            event.stopPropagation();
                        }}
                        to={`/profile/${member?.userId}`}
                        sx={{ pl: 5 }}
                        key={member?.userId}>
                      <ListItemIcon
                        sx={{
                            pr: 1
                        }}>
                          <FiberManualRecordIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText sx={{color: (theme) => theme?.palette?.primary?.light}}>
                        <Typography variant="h5">
                          {member?.firstName}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                ))
              }
          </MenuList>
        </Collapse>
      </>
  )
};

export default React.memo(TeamMembersMenu);