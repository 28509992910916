import React, { useMemo } from 'react';
import { EventUpdateTypes } from 'utils/types/alert';
import { CustomLink, EventName, SenderUsers, Props } from '../Components';

export const EventUpdated: React.FC<Props> = ({ alert, t }) => {
  const body = useMemo<string>(() => {
    const first =
      !!alert.eventUpdates?.length &&
      t(EventUpdateTypes[alert.eventUpdates[0].typeId]);
    const second = alert.eventUpdates.slice(1).map((update, index) => {
      const updateString = t(EventUpdateTypes[update.typeId]);
      if (index === alert.eventUpdates.length - 2)
        return ` and ${updateString}`;
      return `, ${updateString}`;
    });
    return `${first}${second.join('')}`;
  }, [alert]);
  alert.eventUpdates.map((update) => update.typeId);
  return (
    <>
      {t('The')} {body} {t('for')}{' '}
      <CustomLink to={`/events/${alert.event?.id}`}>
        {alert.event?.name}
      </CustomLink>{' '}
      {alert.eventUpdates?.length > 1
        ? t('haveBeenUpdated')
        : t('hasBeenUpdated')}
    </>
  );
};

export const EventPostCreated: React.FC<Props> = ({ alert, t }) => {
  const body = useMemo<string>(() => {
    if (!alert.eventPosts[0]?.parentPostId)
      return t('postedIn', { count: alert.senderUsers?.length });
    if (alert.eventPosts[0].isUserParentPostOwner)
      return `${t('repliedTo', { count: alert.senderUsers?.length })} ${t(
        'yourPostOn'
      )}`;
    return t('repliedToAPostYouCommentedOn', {
      count: alert.senderUsers?.length,
    });
  }, [alert]);
  return (
    <>
      <SenderUsers users={alert.senderUsers} /> {body}{' '}
      <EventName event={alert.event} t={t} />
    </>
  );
};

export const EventPostKudos: React.FC<Props> = ({ alert, t }) => (
  <>
    <SenderUsers users={alert.senderUsers} />{' '}
    {alert.eventPosts && alert.eventPosts[0]?.parentPostId
      ? t('gaveBoostOnReply', { count: alert.senderUsers?.length })
      : t('gaveBoostOnPost', { count: alert.senderUsers?.length })}{' '}
    <EventName event={alert.event} t={t} />
  </>
);

export const EventDeleted: React.FC<Props> = ({ alert, t }) => (
  <>
    {alert.event?.name} {t('hasBeenDeleted')}
  </>
);
