import { combineReducers } from 'redux';
import { List, fromJS, Map } from 'immutable';
import { SET_USER_COMPLETED_SECTION, SET_USER_COMPLETED_TOPICS, SET_USER_COMPLETED_TOPIC, SET_USER_COMPLETED_BUILDERS, SET_USER_COMPLETED_BUILDER, SET_REBEL_MAP_CLASSNAMES, SET_REBEL_MAP_LAST_SIZE, SET_BUILDERS_OVERVIEW_DATA } from './builderDataActions';

function userCompletedBuilders(state = List(), action) {
  switch (action.type) {
    case SET_USER_COMPLETED_BUILDERS:
      return List(fromJS(action.data));
    case SET_USER_COMPLETED_BUILDER:
      return state.filterNot(ucb => ucb.get('id') === action.data.id).push(fromJS(action.data));
    default:
      return state;
  }
}

const initBuildersOverviewData = Map()

function buildersOverviewData(state = initBuildersOverviewData, action) {
  switch (action.type) {
    case SET_BUILDERS_OVERVIEW_DATA:
      return state.merge(fromJS(action.data));
    default:
      return state;
  }
}

function userCompletedTopics(state = List(), action) {
  switch (action.type) {
    case SET_USER_COMPLETED_TOPICS:
      return List(fromJS(action.data));
    case SET_USER_COMPLETED_TOPIC:
      return state.filterNot(uct => uct.get('id') === action.data.id).push(fromJS(action.data));
    default:
      return state;
  }
}

function userCompletedSections(state = List(), action) {
  switch (action.type) {
    case SET_USER_COMPLETED_SECTION:
      return state.filterNot(ucs => ucs.get('id') === action.data.id).push(fromJS(action.data));
    default:
      return state;
  }
}

const initRebelMapPosition = Map({ classNames: ['rebelMap--medium', 'rebelMap--right'], lastSize: 'rebelMap--medium' });
function rebelMapPosition(state = initRebelMapPosition, action) {
  switch (action.type) {
    case SET_REBEL_MAP_CLASSNAMES:
      return state.set('classNames', List(fromJS(action.classNames)));
    case SET_REBEL_MAP_LAST_SIZE:
      return state.set('lastSize', action.lastSize)
    default:
      return state;
  }
}

export default combineReducers({
  buildersOverviewData,
  rebelMapPosition,
  userCompletedBuilders,
  userCompletedSections,
  userCompletedTopics,
});
