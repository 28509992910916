import axios from 'axios';

export const READ_ALL_ALERTS = 'READ_ALL_ALERTS';
export const MARK_AS_READ_ALERTS = 'MARK_AS_READ_ALERTS';
export const READING_ALERTS = 'READING_ALERTS';
export const SET_ALERTS_USER_ID = 'SET_ALERTS_USER_ID';
export const UPDATE_NEW_ALERTS = 'UPDATE_NEW_ALERTS';

export const fetchAlerts =
  (userId, page = 1, limit = 10) =>
  (dispatch) => {
    dispatch({ type: READING_ALERTS });
    axios
      .get(
        `${
          (window.env || process.env).REACT_APP_NOTIFICATION_ENDPOINT
        }/get/alerts?userid=${userId}&pageNo=${page}&pageSize=${limit}`
      )
      .then((response) => {
        const alerts = response?.data?.alerts || [];
        const totalAlertsCount = response?.data?.totalAlertsCount || 0;
        const totalUnreadAlertsCount =
          response?.data?.totalUnreadAlertsCount ?? 0;
        dispatch({
          type: READ_ALL_ALERTS,
          payload: {
            alerts,
            totalAlertsCount,
            totalUnreadAlertsCount,
          },
        });
      })
      .catch((err) => err);
  };

export const fetchNewAlerts = 
(userId, page = 1, limit = 10) =>
(dispatch) => {
  dispatch({ type: READING_ALERTS });
  axios
    .get(
      `${
        (window.env || process.env).REACT_APP_NOTIFICATION_ENDPOINT
      }/get/alerts?userid=${userId}&pageNo=${page}&pageSize=${limit}`
    )
    .then((response) => {
      const alerts = response?.data?.alerts || [];
      const totalAlertsCount = response?.data?.totalAlertsCount || 0;
      const totalUnreadAlertsCount =
        response?.data?.totalUnreadAlertsCount ?? 0;
      dispatch({
        type: UPDATE_NEW_ALERTS,
        payload: {
          alerts,
          totalAlertsCount,
          totalUnreadAlertsCount,
        },
      });
    })
    .catch((err) => err);
};

export const fetchInitialUserAlerts = (userId) => (dispatch, getState) => {
  const { alertsReducer } = getState();
  if (userId !== alertsReducer.userId) {
    dispatch({ type: SET_ALERTS_USER_ID, payload: userId });
    dispatch(fetchAlerts(userId));
  }
};

export const markAsReadAlerts = (userId) => (dispatch) => {
  axios
    .put(
      `${
        (window.env || process.env).REACT_APP_NOTIFICATION_ENDPOINT
      }/alerts/see-alerts`,
      { userId }
    )
    .then(() => {
      dispatch({
        type: MARK_AS_READ_ALERTS,
      });
    });
};
