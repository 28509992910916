import Cookie from 'js-cookie';
import { API_GET } from 'store/middleware/middlewareConstants';
import { request } from '../../functions';
import {
  doRedirect,
  SET_BUILDERS_DATA_LOADED,
  PERMISSION_DENIED_FOR_BUILDERS_DATA,
  RESET_PERMISSION_DENIED_FOR_BUILDERS_DATA,
} from '../metadata/metadataActions';

/** ********************** */
/* USER COMPLETED TOPICS */
/** ********************** */
export const SET_USER_COMPLETED_TOPICS =
  'builderData/SET_USER_COMPLETED_TOPICS';
export const SET_USER_COMPLETED_TOPIC = 'builderData/SET_USER_COMPLETED_TOPIC';

export const SET_BUILDERS_OVERVIEW_DATA = 'SET_BUILDERS_OVERVIEW_DATA';

export const settingBuildersOverviewData = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_BUILDERS_OVERVIEW_DATA, data });
    dispatch({ type: SET_BUILDERS_DATA_LOADED });
    dispatch({ type: RESET_PERMISSION_DENIED_FOR_BUILDERS_DATA });
  };
};

export const permissionDenied = () => {
  return (dispatch) => {
    dispatch({ type: PERMISSION_DENIED_FOR_BUILDERS_DATA });
  };
};

export const fetchBuildersOverview = (projectId) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v3/rebel/builders/project-builders/${projectId}`,
    success: settingBuildersOverviewData,
    onSuccess: null,
    token: Cookie.get('token'),
    identifier: 'buildersData',
    onError: permissionDenied,
  },
});

export function fetchUserCompletedTopics() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/builders/user-completed-topics`;
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_USER_COMPLETED_TOPICS, data: response.data })
    );
  };
}

export function setUserCompletedTopicIntro(topicId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/users/${user?.get('id')}/foundry/topics/${topicId}`;
    const payload = {
      is_complete: true
    };
    return request(url, jwt, 'PUT', payload);
  };
}

/** ************************ */
/* USER COMPLETED SECTIONS */
/** ************************ */

export const SET_USER_COMPLETED_SECTION =
  'builderData/SET_USER_COMPLETED_SECTION';

export function setUserCompletedSection(sectionBlueprintId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/users/${user?.get('id')}/foundry/sections/${sectionBlueprintId}`;
    return request(url, jwt, 'PUT');
  };
}

/** ************************ */
/* USER COMPLETED BUILDERS */
/** ************************ */

export const SET_USER_COMPLETED_BUILDERS =
  'builderData/SET_USER_COMPLETED_BUILDERS';
export const SET_USER_COMPLETED_BUILDER =
  'builderData/SET_USER_COMPLETED_BUILDER';

export function fetchUserCompletedBuilders() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/builders/user-completed-builders`;
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_USER_COMPLETED_BUILDERS, data: response.data })
    );
  };
}

export function setUserCompletedBuilderIntro(
  builderId,
  redirectTo = null
) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/users/${user?.get('id')}/foundry/builders/${builderId}`;
    const payload = {
      is_complete: true
    };

    request(url, jwt, 'PUT', payload).then((response) =>
        dispatch({ type: SET_USER_COMPLETED_BUILDER, data: response.data })
    );
    if (redirectTo) {
      dispatch(doRedirect(redirectTo));
    }
  };
}

/** ********** */
/* REBEL MAP */
/** ********** */

export const SET_REBEL_MAP_CLASSNAMES = 'builderData/SET_REBEL_MAP_CLASSNAMES';
export const SET_REBEL_MAP_LAST_SIZE = 'builderData/SET_REBEL_MAP_LAST_SIZE';
