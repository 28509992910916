import React from 'react';
import { connect } from 'react-redux';
import './loading.scss';

class Loading extends React.Component {
  static renderLoading() {
    return (
      <div className="loading__wrapper">
        <div className="loading">
          <h2>
            <em>L</em>
            <em className="planet left">O</em>
            <em>A</em>
            <em>D</em>
            <em>I</em>
            <em>N</em>
            <em>G</em>
          </h2>
        </div>
      </div>
    );
  }

  render() {
    const { isLoaded, isTokenError } = this.props;

    if (!isLoaded && !isTokenError) {
      return this.constructor.renderLoading();
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { metadata, user } = state;

  // loading condition
  const isUserLoggedIn = user.get('jwt');
  const isTokenError = metadata.loading.get('tokenError');
  const isLoaded = isUserLoggedIn
    ? metadata.loading.get('appDataLoaded') &&
      metadata.loading.get('profileDataLoaded') &&
      metadata.loading.get('challengeDataLoaded') &&
      metadata.loading.get('projectsLoaded') &&
      metadata.loading.get('projectDataLoaded') &&
      metadata.loading.get('hubsLoaded') &&
      metadata.loading.get('userLoaded')
    : true;

  return {
    isLoaded,
    isTokenError,
  };
}

export default connect(mapStateToProps)(Loading);
