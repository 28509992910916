export const modalCss = {
  overlay: {
    WebkitTransform: 'translate3d(0, 0, 0)',
    transform: 'translate3d(0, 0, 0)',
    // transform: 'translateZ(1000px)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 985,
    opacity: '.99',
    backgroundColor: 'rgba(37, 48, 49, 0.9)'
  },
  content: {
    WebkitTransform: 'translate3d(0, 0, 0)',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: '1px solid #ccc',
    background: '#fff',
    overflowY: 'auto',
    overflowX: 'none',
    maxHeight: '90vh',
    maxWidth: '95vw',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    marginRight: '-50%',
    // eslint-disable-next-line no-dupe-keys
    transform: 'translate(-50%, -50%)',
    zIndex: 981,
  },
};
