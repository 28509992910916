export const SET_GROUPS = 'SET_GROUPS';
export const SET_SINGLE_GROUP = 'SET_SINGLE_GROUP';

export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_GROUP_NAME = 'SET_GROUP_NAME';
export const SET_ACTIVATION = 'SET_ACTIVATION';
export const REMOVE_DELETE = 'REMOVE_DELETE';
export const SET_CHANGED_PROJECT = 'SET_CHANGED_PROJECT';

export const SET_USER_PROJECT = 'SET_USER_PROJECT';
export const PARSE_PROJECTS = 'PARSE_PROJECTS';

export const ADD_BUILDER = 'ADD_BUILDER';
export const ADD_FETCHED_BUILDER = 'ADD_FETCHED_BUILDER';
export const REMOVE_BUILDER = 'REMOVE_BUILDER';
export const SET_INITIAL_BUILDERS = 'SET_INITIAL_BUILDERS';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const ADD_TEMPLATE_WITHOUT_SORT = 'ADD_TEMPLATE_WITHOUT_SORT';
export const REMOVE_TEMPLATE_FLAG = 'REMOVE_TEMPLATE_FLAG';
export const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES';
export const SAME_TEMPLATE_TRUE = 'SAME_TEMPLATE_TRUE';
export const SAME_TEMPLATE_FALSE = 'SAME_TEMPLATE_FALSE';

export const CLEAR_BUILDER = 'CLEAR_BUILDER';
export const DATE_CHANGED_TRUE = 'DATE_CHANGED_TRUE';
export const TIME_CHANGED_FALSE = 'TIME_CHANGED_FALSE';
export const DATE_CHANGED_FALSE = 'DATE_CHANGED_FALSE';
export const TIME_CHANGED_TRUE = 'TIME_CHANGED_TRUE';

export const BUILDER_CHANGED_TRUE = 'BUILDER_CHANGED_TRUE';
export const BUILDER_CHANGED_FALSE = 'BUILDER_CHANGED_FALSE';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_FETCHED = 'CLEAR_FETCHED';
export const SHOW_SWITCH_PROJECT = 'SHOW_SWITCH_PROJECT';
export const HIDE_SWITCH_PROJECT = 'HIDE_SWITCH_PROJECT';

export const SET_LOADER = 'SET_LOADER';

export const SET_ADMIN_VIEW_TAB_INDEX = 'SET_ADMIN_VIEW_TAB_INDEX';
