import React from "react";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";

const MainMenu = (props) => {
    const {
        variant = 'h3',
        label,
        onItemClick,
        startIcon,
        endIcon
    } = props
  return (
      <>
          <MenuList sx={{
              '& .MuiMenuItem-root': {
                  minHeight: '40px !important',
                  alignItems: 'center'
              },
              '& .MuiListItemIcon-root': {
                  minWidth: 'auto',
                  color: (theme) => theme?.palette?.common?.white,
              }
          }}>
              <MenuItem
                  disableRipple
                  disableTouchRipple
                  sx={{
                      p: (theme) => theme?.spacing(0, 2, 0, 1),
                  }}>
                  {
                      startIcon && (
                          <ListItemIcon
                              sx={{ pr: 1  }}>
                              {startIcon}
                          </ListItemIcon>
                      )
                  }
                  <ListItemText>
                      <Typography variant={variant}>
                          {label}
                      </Typography>
                  </ListItemText>
                  {
                      endIcon && (
                          <ListItemIcon
                              onClick={onItemClick}
                              sx={{
                                  minWidth: 'auto !important',
                                  pl: 1
                              }}>
                              {endIcon}
                          </ListItemIcon>
                      )
                  }
              </MenuItem>
          </MenuList>
      </>
  )
};

export default React.memo(MainMenu);