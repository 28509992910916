import fetch from 'isomorphic-fetch';
import { setAvatar } from 'store/avatar-v1/avatarActions';
import {
  SAVED_AVATAR,
  CLOSE_AVATAR_UPLOAD,
  SAVE_AVATAR,
  OPEN_AVATAR_UPLOAD,
} from 'store/avatar-v1/avatarConstants';
import { imageFormatNotSupportedPopup } from 'functions/popup/imageFormatNotSupportedPopup';
import { triggerSuccessPopup } from 'functions/popup/triggerSuccessPopup';
import { triggerTellUsWarning } from 'functions/popup/triggerWarningPopup';
import { profileCompletedPopup } from 'functions/popup/profileCompletedPopup';
import { SET_PROFILE_DATA_LOADED } from '../metadata/metadataActions';
import { request, postImage } from '../../functions';

export const EDIT_EXPERIENCE = 'EDIT_EXPERIENCE';
export const SAVE_EXPERIENCE = 'SAVE_EXPERIENCE';
export const ADD_EXPERIENCE = 'ADD_EXPERIENCE';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const RECEIVE_EXPERIENCE = 'RECEIVE_EXPERIENCE';
export const TOGGLE_PRESENT = 'TOGGLE_PRESENT';

// REWORKED
export const FETCHING_PROJECTS = 'FETCHING_PROJECTS';

export const FETCHING_PROFILE = 'FETCHING_PROFILE';
export const SET_PROFILE_ID = 'SET_PROFILE_ID';
// export const SET_AVATAR = 'SET_AVATAR';
// export const SAVE_AVATAR = 'SAVE_AVATAR';
// export const SAVED_AVATAR = 'SAVED_AVATAR';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_PURPOSE = 'SET_PURPOSE';

export const SET_SUMMARY = 'SET_SUMMARY';
export const EDIT_SUMMARY = 'EDIT_SUMMARY';
export const SAVE_SUMMARY = 'SAVE_SUMMARY';

// export const OPEN_AVATAR_UPLOAD = 'OPEN_AVATAR_UPLOAD';
// export const CLOSE_AVATAR_UPLOAD = 'CLOSE_AVATAR_UPLOAD';

export const SET_META_DATA = 'SET_META_DATA';

export const SET_SOCIAL_LINKS = 'SET_SOCIAL_LINKS';
export const EDIT_SOCIAL_LINKS = 'EDIT_SOCIAL_LINKS';
export const SAVE_SOCIAL_LINKS = 'SAVE_SOCIAL_LINKS';

export const PROFILE_COMPLETED = 'PROFILE_COMPLETED';

// reworked v2
export const SET_PROFILE_VISIBILITY = 'profile/SET_PROFILE_VISIBILITY';

// *** EXPERIENCE *** //
export function toggleEditExperience(editId) {
  return {
    type: EDIT_EXPERIENCE,
    editId,
  };
}

export function toggleAddExperience() {
  return {
    type: ADD_EXPERIENCE,
  };
}

export function toggleSaveExperience() {
  return {
    type: SAVE_EXPERIENCE,
  };
}

export function receiveExperience(data) {
  return {
    type: RECEIVE_EXPERIENCE,
    data: {
      ...data,
    },
  };
}

export function fetchExperience() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const expUrl = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/experiences`;

    request(expUrl, jwt, 'GET').then((json) =>
      dispatch(receiveExperience(json))
    );
  };
}

export function deleteExperience(formId) {
  return (dispatch, getState) => {
    const { user } = getState();

    return fetch(
      `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v1/rebel/user_profile/experiences/${formId}`,
      {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': window.csrfToken,
          Authorization: `Bearer ${user.get('jwt')}`,
        },
      }
    ).then(
      () => {
        dispatch(fetchExperience());
      },
      (error) => console.log('Error:', error)
    );
  };
}

export function submitExperience() {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { ExperienceFormNew } = getState().form;
    const { user } = getState();
    return fetch(
      `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v1/rebel/user_profile/experiences`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': window.csrfToken,
          Authorization: `Bearer ${user.get('jwt')}`,
        },
        body: JSON.stringify({ experience: ExperienceFormNew.values }),
      }
    ).then(
      () => {
        triggerSuccessPopup('ExperienceAdded', dispatch);
        dispatch(fetchExperience());
      },
      (error) => console.log('Error:', error)
    );
  };
}

export function editExperience(formId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const editForm = getState().form[`ExperienceFormEdit-${formId}`];
    const { user } = getState();

    return fetch(
      `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v1/rebel/user_profile/experiences/${formId}`,
      {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': window.csrfToken,
          Authorization: `Bearer ${user.get('jwt')}`,
        },
        body: JSON.stringify({ experience: editForm.values }),
      }
    ).then(
      () => {
        triggerSuccessPopup('ExperienceSaved', dispatch);
        dispatch(fetchExperience());
      },
      (error) => console.log('Error:', error)
    );
  };
}

export function toggleCheckbox(expIndex) {
  return (dispatch, getState) => {
    const { experience } = getState();
    return {
      type: TOGGLE_PRESENT,
      present: !experience.data[expIndex].present,
      index: expIndex,
    };
  };
}

/*
 *
 *
 * REWORKED ACTIONS
 *
 *
 */

// Judge Bios
export const SET_REBEL_JUDGE_BIO = 'rebel/SET_REBEL_JUDGE_BIO';

export function fetchJudgeBio() {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/user-profile/judge-bios/${user.get('id')}`;
    return request(url, jwt).then((response) => {
      dispatch({ type: SET_REBEL_JUDGE_BIO, data: response.data });
    });
  };
}

export function postJudgeBio(confirmSavePopup = false) {
  return (dispatch, getState) => {
    const { user, form } = getState();

    const judgeBioForm =
      form && form.JudgeBioForm && form.JudgeBioForm.values
        ? form.JudgeBioForm.values
        : null;

    if (judgeBioForm) {
      const payload = {
        judge_bio: {
          title: judgeBioForm.title ? judgeBioForm.title : null,
          bio: judgeBioForm.bio ? judgeBioForm.bio : null,
        },
      };

      const jwt = user.get('jwt');
      const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/user-profile/judge-bios`;

      return request(url, jwt, 'POST', payload).then((response) => {
        dispatch({ type: SET_REBEL_JUDGE_BIO, data: response.data });
        dispatch({ type: 'UPDATE_JUDGE_BIO_SUCCESS', data: response.data });
        if (confirmSavePopup) {
          triggerSuccessPopup('JudgeBioSaved', dispatch);
        }
      });
    }
    return '';
  };
}

// summary
function setSummary(data) {
  return {
    type: SET_SUMMARY,
    data,
  };
}

export function editSummary() {
  return {
    type: EDIT_SUMMARY,
  };
}

export const RESET_EDITING_SUMMARY = 'profile/RESET_EDITING_SUMMARY';
export function saveSummary() {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch({ type: SAVE_SUMMARY });
    const { form, user, profile } = getState();
    const jwt = user.get('jwt');
    const profileURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/rebel_profiles/${profile.profileId}`;
    const payload = {
      rebel_profile: {
        summary: form.SummaryForm.values.summary,
      },
    };
    request(profileURL, jwt, 'PUT', payload)
      .then((response) => {
        dispatch(setSummary(response.data.summary));
        triggerSuccessPopup('AboutSaved', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

export function setLocation(data) {
  if (!data)
    return {
      type: SET_LOCATION,
      data: {
        city: null,
        state: null,
      },
    };
  return {
    type: SET_LOCATION,
    data,
  };
}

export function setPurpose(data) {
  if (!data)
    return {
      type: SET_PURPOSE,
      data: {
        label: null,
      },
    };
  return {
    type: SET_PURPOSE,
    data,
  };
}

// function setAvatar(data, userId = null) {
//   return {
//     type: SET_AVATAR,
//     data,
//     userId,
//   };
// }

function setProfileId(data) {
  return {
    type: SET_PROFILE_ID,
    data,
  };
}

function setMetaData(data) {
  return {
    type: SET_META_DATA,
    data,
  };
}

function setSocialLinks(data) {
  return {
    type: SET_SOCIAL_LINKS,
    data,
  };
}

export const RESET_EDITING_SOCIAL_LINKS = 'profile/RESET_EDITING_SOCIAL_LINKS';
export function editSocialLinks() {
  return {
    type: EDIT_SOCIAL_LINKS,
  };
}

export function saveSocialLinks() {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch({ type: SAVE_SOCIAL_LINKS });
    const { form, user } = getState();
    const jwt = user.get('jwt');
    const socialLinksURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/social_links`;

    let { linkedin } = form.SocialLinksForm.values;
    if (linkedin) {
      if (linkedin[linkedin.length - 1] === '/')
        linkedin = linkedin.substring(0, linkedin.length - 1);
      linkedin = linkedin.substring(linkedin.lastIndexOf('/') + 1);
    }

    let { twitter } = form.SocialLinksForm.values;
    if (twitter) {
      if (twitter[twitter.length - 1] === '/')
        twitter = twitter.substring(0, twitter.length - 1);
      twitter = twitter.substring(twitter.lastIndexOf('/') + 1);
    }

    let { facebook } = form.SocialLinksForm.values;
    if (facebook) {
      if (facebook[facebook.length - 1] === '/')
        facebook = facebook.substring(0, facebook.length - 1);
      facebook = facebook.substring(facebook.lastIndexOf('/') + 1);
    }

    const payload = {
      data: {
        linkedin,
        twitter,
        facebook,
      },
    };

    return request(socialLinksURL, jwt, 'POST', payload)
      .then((response) => {
        dispatch(setSocialLinks(response.data));
        triggerSuccessPopup('SocialLinksSaved', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

function fetchSocialLinks(url, jwt) {
  return (dispatch) =>
    request(url, jwt)
      .then((response) => dispatch(setSocialLinks(response.data)))
      .catch((err) => console.log(err));
}

export function openAvatarUpload() {
  return {
    type: OPEN_AVATAR_UPLOAD,
  };
}

export function closeAvatarUpload() {
  return {
    type: CLOSE_AVATAR_UPLOAD,
  };
}

export function saveAvatar() {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user } = getState();
    const { NewAvatarImg } = getState().form;
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/user-profile/rebel-profiles`;

    //validate;
    if (
      !NewAvatarImg ||
      !NewAvatarImg.values ||
      !NewAvatarImg.values.picture[0]
    ) {
      // return dispatch(popup('Please select an image.', 'error', null, 'tc', 5));
      return false;
    }

    dispatch({ type: SAVE_AVATAR });
    // multipart forms need this data structure
    const formData = new FormData();
    formData.append('_utf8', true);
    formData.append('rebel_profile[avatar]', NewAvatarImg.values.picture[0]);

    return postImage(url, jwt, 'POST', formData).then((res) => {
      if (res.status === 500) {
        dispatch({
          type: SAVED_AVATAR,
        });
        return imageFormatNotSupportedPopup(dispatch);
      }
      res.json().then((respone) => {
        dispatch(setAvatar(respone.data.avatar_url, user.get('id')));
        dispatch({
          type: SET_PROFILE_DATA_LOADED,
        });
        dispatch({
          type: CLOSE_AVATAR_UPLOAD,
        });
        triggerSuccessPopup('ProfileImageUploaded', dispatch);
        dispatch({
          type: SAVED_AVATAR,
        });
      });
      return '';
    });
  };
}

export function profileCompleted() {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch({ type: PROFILE_COMPLETED });
    profileCompletedPopup(dispatch);

    const { profile, user } = getState();
    const jwt = user.get('jwt');
    const profileURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/rebel_profiles/${profile.profileId}`;
    const payload = {
      rebel_profile: {
        metadata: {
          isProfileComplete: true,
        },
      },
    };

    request(profileURL, jwt, 'PUT', payload);
  };
}

// Visibility
function setProfileVisibility(value) {
  return {
    type: SET_PROFILE_VISIBILITY,
    value,
  };
}

function fetchProfile(
  url = `${
    (window.env || process.env).REACT_APP_API_ENDPOINT
  }/api/v1/rebel/user_profile/rebel_profiles`,
  jwt
) {
  return (dispatch, getState) => {
    const { user, metadata } = getState();
    dispatch({ type: FETCHING_PROFILE });
    dispatch(fetchJudgeBio());
    return request(url, jwt)
      .then((response) => {
        // check if tell us page is complete
        if (!metadata.isSignup && !response.data.location_map) {
          triggerTellUsWarning('TellUs', dispatch);
        } else {
          dispatch(setLocation(response.data.location_map));
          dispatch(setPurpose(response.data.purpose));
        }
        dispatch(setProfileId(response.data.id));
        dispatch(setSummary(response.data.summary));
        dispatch(setAvatar(response.data.avatar_url, user.get('id')));
        dispatch(setMetaData(response.data.metadata));
        dispatch(setProfileVisibility(response.data.is_public));
      })
      .catch((err) => console.log(err));
  };
}

export function fetchProfileData() {
  return (dispatch, getState) => {
    const jwt = getState().user.get('jwt');
    const profileURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/rebel_profiles`;
    // const experienceURL = window.env.REACT_APP_API_ENDPOINT+'/api/v1/rebel/user_profile/experiences';
    const socialLinksURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/social_links/`;
    Promise.all([
      dispatch(fetchProfile(profileURL, jwt)),
      dispatch(fetchSocialLinks(socialLinksURL, jwt)),
    ]).then(() => dispatch({ type: SET_PROFILE_DATA_LOADED }));
  };
}

export function toggleProfileVisibility() {
  return (dispatch, getState) => {
    const { form, user, profile } = getState();
    const jwt = user.get('jwt');
    const profileURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/user_profile/rebel_profiles/${profile.profileId}`;
    const payload = {
      rebel_profile: {
        is_public: form.ProfileVisibilityForm.values.is_public,
      },
    };
    request(profileURL, jwt, 'PUT', payload)
      .then((response) => {
        dispatch(setProfileVisibility(response.data.is_public));
      })
      .catch((err) => console.log(err));
  };
}
