export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const REBEL_BLUE = 'REBEL_BLUE';
export const GREENISH_BLACK = 'GREENISH_BLACK';
export const SMALT_BLUE = 'SMALT_BLUE';
export const MEADOW_TEAL = 'MEADOW_TEAL';
export const MUTED_ORANGE = 'MUTED_ORANGE';
export const RED_THEME = 'RED_THEME';

export const setTheme = (payload) => ({
  type: THEME_COLOR,
  payload,
});
export const setDarkMode = (payload) => ({
  type: DARK_THEME,
  payload,
});
export const setNavbarBg = (payload) => ({
  type: NAVBAR_BG,
  payload,
});

export const setSidebarBg = (payload) => ({
  type: SIDEBAR_BG,
  payload,
});

export const setDir = (payload) => ({
  type: DIRECTION,
  payload,
});
