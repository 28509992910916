import React from "react";
import { IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useTranslation } from 'react-i18next';
import { RebelbaseLogoIcon } from "assets";

const BrandHeader = (props) => {
    const { isSmallScreen = false , collapse } = props;
    const { t  } = useTranslation(['translation.navigation']);
    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    color: (theme) => theme?.palette?.common?.white,
                    height: 64,
                    p: (theme) => theme.spacing(0, 2, 0, 1 )
                }}
            >
                <RebelbaseLogoIcon sx={{ width: 'auto', height: 24 }} />
                <Tooltip title={t('hideMenu')}>
                    <IconButton data-test="collapse" sx={{ ml: 'auto', height: 24, width: 24, color: 'inherit' }} onClick={collapse}>
                        {isSmallScreen ? (
                            <ChevronRightIcon data-test="right-icon" />
                        ) : (
                            <ChevronLeftIcon data-test="left-icon" />
                        )}
                    </IconButton>
                </Tooltip>
            </Stack>
        </>
    )
};
export default React.memo(BrandHeader);