import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Menu, Typography } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from "@mui/material/MenuItem";
import { setUserPreference } from 'store/user/userActions';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const LanguagePicker = ({withLabel = true}) => {

    const [selectedLanguage, setSelectedLanguage] = useState('ENG');

    const {t} = useTranslation(['translation.navigation']);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const dispatch = useDispatch();

    const selectLanguage = (language) => {
        setSelectedLanguage(language);
        dispatch(setUserPreference(language));
        setAnchorEl(null);
    };

    const userPreferredLanguage = useSelector(
        (state) =>
            state.user &&
            state.user.get('user_preference') &&
            state.user.get('user_preference').get('language')
    );

    useEffect(() => {
        if (userPreferredLanguage === 'ESP') i18n.changeLanguage('es');
        else i18n.changeLanguage('en');
        setSelectedLanguage(userPreferredLanguage);
    }, [userPreferredLanguage]);

    return (
        <>
            <Box display="flex" alignItems="center">
                <IconButton id="language-picker-menu"
                            aria-controls={open ? 'language-picker-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                    <LanguageIcon color="primary" sx={{
                        height: 20,
                        width: 20
                    }}/>
                </IconButton>
                {
                    withLabel && (
                        <Typography sx={{ cursor: 'pointer' }} onClick={handleClick} varaint="body1" color="secondary">
                            {t('Language')}
                        </Typography>
                    )
                }
            </Box>
            <Menu
                id="language-picker-menu"
                aria-labelledby="language-picker-position-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    selected={selectedLanguage === 'ENG'}
                    disabled={selectedLanguage === 'ENG'}
                    onClick={() => selectLanguage('ENG')}>
                    English
                </MenuItem>
                <MenuItem
                    selected={selectedLanguage === 'ESP'}
                    disabled={selectedLanguage === 'ESP'}
                    onClick={() => selectLanguage('ESP')}>
                    Spanish
                </MenuItem>
            </Menu>
        </>
    );
};

export default React.memo(LanguagePicker);