/* eslint-disable import/no-cycle */
import ReactGA from 'react-ga4';
import Cookie from 'js-cookie';
import { push } from 'react-router-redux';
import { isMobileDevice } from '../functions';
import { setStoreLogin } from './login/loginActions';
import { initializeRebelUserChannel } from './channels/channelActions';
import { fetchProjects } from './project/projectActions';
import { fetchProfileData } from './profile/profileActions'; // profile data
import {
  fetchUser,
  getUserPreference,
  setUserPreference,
} from './user/userActions';
import {
  fetchAppData,
  fetchPublicAppData,
  fetchSectionChallengeTopicBlueprints,
} from './appData/appDataActions';
import { fetchChallengeData } from './projectProfile-v1/projectProfileActions';
import { fetchProjectData } from './projectData/projectDataActions';
import {
  SET_USER_LOADED,
  doRedirect,
  SET_HUBS_LOADED,
  setTokenError,
  resetTokenError,
  SET_APP_DATA_LOADED,
} from './metadata/metadataActions';
import { fetchUserInvitations } from './invitations/invitationActions';
import { SHOW_SIDE_NAV } from './sideNav/sideNavActions';
import { fetchHubData } from './hubs/hubActions';
import { getAuthUser } from './auth/authActions';

export const RESET_TO_PUBLIC_DATA = 'RESET_TO_PUBLIC_DATA';

export function resetAppState() {
  return (dispatch) => {
    dispatch({ type: RESET_TO_PUBLIC_DATA });
    dispatch({ type: SET_APP_DATA_LOADED });
    dispatch({ type: SET_HUBS_LOADED });
    dispatch({ type: SET_USER_LOADED });
  };
}

// data load
export function loadAllData() {
  return (dispatch) => {
    dispatch(initializeRebelUserChannel());
    dispatch(fetchProjects());
    dispatch(fetchProfileData());
    /**
     * section, challenge, and topic blueprints were being fetched twice on load:
     * 1. fetch app data
     * 2. after setting user preference
     *
     * now, we check if user preference is available, set that (PUT request), and then fetch the blueprints from inside the function
     * if user preference is not available, we just fetch the blueprints
     */
    dispatch(fetchAppData());
    if (Cookie.get('userPreference')) {
      dispatch(setUserPreference(Cookie.get('userPreference')));
    } else {
      dispatch(fetchSectionChallengeTopicBlueprints());
    }
    dispatch(getUserPreference());
    dispatch(fetchChallengeData());
    dispatch(fetchProjectData());
    dispatch(fetchUserInvitations());
    dispatch(fetchHubData());
    if (!isMobileDevice()) {
      dispatch({ type: SHOW_SIDE_NAV });
    }
  };
}

// when the user hits the app, this is the initial load point
export function initializeApp(token = null, redirectUrl) {
  return (dispatch, getState) => {
    // see if there is an existing session
    const jwt = Cookie.get('token') || token;
    if (jwt) {
      dispatch(setStoreLogin(jwt));
      dispatch(fetchUser())
        .then(() => {
          const { user } = getState();
          return dispatch(getAuthUser(user.get('id')));
        })
        .then(() => {
          dispatch(resetTokenError());
          const { user } = getState();

          // google analytics
          ReactGA.ga('set', 'userId', user.get('tracking_id'));
          ReactGA.ga('set', 'dimension1', user.get('tracking_id'));
          ReactGA.ga('send', 'event', 'authentication', 'user-id available');

          // redirect to profile if going to homepage and already logged in
          const pathName = window.location.pathname;
          if (redirectUrl) {
            dispatch(push(redirectUrl));
          } else if (pathName === '/' || pathName.includes('/auth/')) {
              dispatch(push(`/dashboard`));
          }
          dispatch(loadAllData());
        })
        .catch(() => {
          Cookie.remove('token');
          dispatch(setTokenError());
          dispatch(doRedirect(`/auth/login`));
        });
    } else {
      dispatch(fetchPublicAppData());
      dispatch({ type: SET_HUBS_LOADED });
      dispatch({ type: SET_USER_LOADED });
    }
  };
}
