import { combineReducers } from 'redux';
import {
  RESET_DATA_SUBMITTED_SUCCESSFULLY,
  SET_DATA_SUBMITTED_SUCCESSFULLY,
  SET_OWNED_BY_ME_FILTER,
  SET_HUB_ACTIVITY_FILTER,
  SET_ACTIVITES_DATA,
  SET_LOADING_ACTIVITIES_DATA,
  SET_ERROR_FETCHING_ACTIVITIES,
  UNSET_ERROR_FETCHING_ACTIVITIES,
  APPEND_ACTIVITIES_DATA,
  SET_HUB_ACTIVITY_LIKE,
  DELETE_HUB_ACTIVITY_LIKE,
  DELETE_HUB_ACTIVITY,
  DELETE_HUB_ACTIVITY_REPLY,
  UPDATE_HUB_ACTIVITY,
  SET_HUB_ACTIVITY_REPLY_LIKE,
  DELETE_HUB_ACTIVITY_REPLY_LIKE,
  APPEND_ACTIVITY_REPLY,
  SET_MORE_OWNED_BY_ME_POSTS,
  SET_SINGLE_HUB_ACTIVITY,
  SET_ERROR_FETCHING_SINGLE_ACTIVITY,
  UNSET_ERROR_FETCHING_SINGLE_ACTIVITY,
  TOGGLE_ANNOUNCEMENT_STICKY,
  SET_LOADING_SINGLE_ACTIVITY,
} from './hubActivityConstants';

const dataSubmittedSuccessfully = (state = false, action) => {
  switch (action.type) {
    case SET_DATA_SUBMITTED_SUCCESSFULLY:
      return true;
    case RESET_DATA_SUBMITTED_SUCCESSFULLY:
      return false;
    default:
      return state;
  }
};

const activitiesState = {
  activities: [],
  counts: {},
  loading: true,
  error: false,
  hubId: null,
  moreOwnedByMePosts: true, //used to determine if there are more owned by me posts in case the filter is selected
};

const activities = (state = activitiesState, action) => {
  switch (action.type) {
    case SET_ACTIVITES_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case APPEND_ACTIVITIES_DATA:
      return {
        ...state,
        counts: action.payload.counts,
        activities: [...state.activities, ...action.payload.activities],
        loading: false,
        moreOwnedByMePosts:
          action.payload.moreOwnedByMePosts !== undefined
            ? action.payload.moreOwnedByMePosts
            : state.moreOwnedByMePosts,
      };
    case SET_ERROR_FETCHING_ACTIVITIES:
      return {
        ...state,
        ...activitiesState,
        loading: false,
        error: true,
      };
    case UNSET_ERROR_FETCHING_ACTIVITIES:
      return {
        ...state,
        error: false,
      };
    case SET_LOADING_ACTIVITIES_DATA:
      return {
        ...state,
        loading: action.payload,
      };
    case DELETE_HUB_ACTIVITY: {
      let activityType = null;
      const updatedActivities = state.activities.filter((activity) => {
        if (activity.id === action.hubActivityId) {
          activityType = activity.activity_type;
          return false;
        }
        return true;
      });
      const updatedCounts = state.counts;
      if (activityType) {
        updatedCounts.total -= 1;
        updatedCounts[activityType] -= 1;
      }
      return {
        ...state,
        activities: updatedActivities,
        counts: updatedCounts,
      };
    }
    case SET_HUB_ACTIVITY_LIKE: {
      const { hub_activity_id, id, user_id } = action.payload;
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === hub_activity_id) {
          if (activity.likes.map((like) => like.id).includes(id))
            return activity;
          return {
            ...activity,
            likes: [
              ...activity.likes,
              {
                id,
                user_id,
              },
            ],
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case DELETE_HUB_ACTIVITY_LIKE: {
      let { id, likeId } = action.data;
      if (action.data.hub_activity_id) {
        id = action.data.hub_activity_id;
        likeId = action.data.id;
      }
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === id) {
          const updatedLikes = activity.likes.filter(
            (like) => like.id !== likeId
          );
          return {
            ...activity,
            likes: updatedLikes,
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case DELETE_HUB_ACTIVITY_REPLY: {
      const { id, replyId } = action.data;
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === id) {
          const updatedReplies = activity.replies.filter(
            (reply) => reply.id !== replyId
          );
          return {
            ...activity,
            replies: updatedReplies,
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case UPDATE_HUB_ACTIVITY: {
      const { question, idea, is_sticky, details } = action.data;
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === action.data.hub_activity_id) {
          return {
            ...activity,
            details,
            is_sticky,
            question,
            idea,
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case SET_HUB_ACTIVITY_REPLY_LIKE: {
      const { hub_activity_id, hub_activity_reply_id, user_id, id } =
        action.data;
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === hub_activity_id) {
          const updatedReplies = activity.replies.map((reply) => {
            if (reply.id === hub_activity_reply_id) {
              if (reply.likes.map((like) => like.user_id).includes(user_id))
                return reply;
              return {
                ...reply,
                likes: [
                  ...reply.likes,
                  {
                    id,
                    user_id,
                  },
                ],
              };
            }
            return reply;
          });
          return {
            ...activity,
            replies: updatedReplies,
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case DELETE_HUB_ACTIVITY_REPLY_LIKE: {
      const { id, replyId, replyLikeId } = action.data;
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === id) {
          const updatedReplies = activity.replies.map((reply) => {
            if (reply.id === replyId) {
              return {
                ...reply,
                likes: reply.likes.filter((like) => like.id !== replyLikeId),
              };
            }
            return reply;
          });
          return {
            ...activity,
            replies: updatedReplies,
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case APPEND_ACTIVITY_REPLY: {
      const newReply = {
        ...action.data,
        created_at: action.data.inserted_at,
        likes: [],
        parent_reply_id: action.data.hub_activity_parent_reply_id,
        user: {
          ...action.data.user,
          user_id: action.data.user_id,
        },
      };
      const updatedActivities = state.activities.map((activity) => {
        if (activity.id === newReply.hub_activity_id) {
          if (activity.replies.map((reply) => reply.id).includes(newReply.id))
            return activity;
          return {
            ...activity,
            replies: [newReply, ...activity.replies],
          };
        }
        return activity;
      });
      return {
        ...state,
        activities: updatedActivities,
      };
    }
    case SET_MORE_OWNED_BY_ME_POSTS:
      return {
        ...state,
        moreOwnedByMePosts: action.data,
      };
    default:
      return state;
  }
};

export function activityFilter(state = null, action) {
  if (action.type === SET_HUB_ACTIVITY_FILTER) {
    return action.filter;
  }
  return state;
}

const isOwnedByMeFilterSelected = (state = false, action) => {
  switch (action.type) {
    case SET_OWNED_BY_ME_FILTER:
      return action.data;
    default:
      return state;
  }
};

const activity = (
  state = {
    activity: null,
    loading: true,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case SET_SINGLE_HUB_ACTIVITY:
      return {
        ...state,
        activity: action.data,
        loading: false,
      };
    case SET_ERROR_FETCHING_SINGLE_ACTIVITY: {
      return {
        ...state,
        activity: null,
        loading: false,
        error: true,
      };
    }
    case UNSET_ERROR_FETCHING_SINGLE_ACTIVITY: {
      return {
        ...state,
        error: false,
      };
    }
    case SET_HUB_ACTIVITY_LIKE: {
      const { hub_activity_id, id, user_id } = action.payload;
      if (
        hub_activity_id !== state.activity?.id ||
        state.activity.likes.map((like) => like.id).includes(id)
      )
        return state;
      const updatedActivity = {
        ...state.activity,
        likes: [
          ...state.activity.likes,
          {
            id,
            user_id,
          },
        ],
      };
      return {
        ...state,
        activity: updatedActivity,
      };
    }
    case DELETE_HUB_ACTIVITY_LIKE: {
      let { id, likeId } = action.data;
      if (action.data.hub_activity_id) {
        id = action.data.hub_activity_id;
        likeId = action.data.id;
      }
      if (id !== state.activity?.id) return state;
      const updatedActivity = {
        ...state.activity,
        likes: state.activity.likes.filter((like) => like.id !== likeId),
      };
      return {
        ...state,
        activity: updatedActivity,
      };
    }
    case UPDATE_HUB_ACTIVITY: {
      if (action.data.hub_activity_id !== state.activity?.id) return state;
      const { question, idea, is_sticky, details } = action.data;
      return {
        ...state,
        activity: {
          ...state.activity,
          details,
          is_sticky,
          question,
          idea,
        },
      };
    }
    case DELETE_HUB_ACTIVITY: {
      if (action.hubActivityId !== state.activity?.id) return state;
      return {
        ...state,
        activity: null,
        loading: true,
        error: false,
      };
    }
    case SET_HUB_ACTIVITY_REPLY_LIKE: {
      const { hub_activity_id, hub_activity_reply_id, user_id, id } =
        action.data;
      if (hub_activity_id !== state.activity?.id) return state;
      const updatedReplies = state.activity.replies.map((reply) => {
        if (reply.id === hub_activity_reply_id) {
          if (reply.likes.map((like) => like.user_id).includes(user_id))
            return reply;
          return {
            ...reply,
            likes: [
              ...reply.likes,
              {
                id,
                user_id,
              },
            ],
          };
        }
        return reply;
      });
      return {
        ...state,
        activity: {
          ...state.activity,
          replies: updatedReplies,
        },
      };
    }
    case DELETE_HUB_ACTIVITY_REPLY_LIKE: {
      const { id, replyId, replyLikeId } = action.data;
      if (id !== state.activity?.id) return state;
      const updatedReplies = state.activity.replies.map((reply) => {
        if (reply.id === replyId) {
          return {
            ...reply,
            likes: reply.likes.filter((like) => like.id !== replyLikeId),
          };
        }
        return reply;
      });
      return {
        ...state,
        activity: {
          ...state.activity,
          replies: updatedReplies,
        },
      };
    }
    case APPEND_ACTIVITY_REPLY: {
      if (
        action.data.hub_activity_id !== state.activity?.id ||
        state.activity.replies.map((reply) => reply.id).includes(action.data.id)
      )
        return state;
      const newReply = {
        ...action.data,
        created_at: action.data.inserted_at,
        likes: [],
        parent_reply_id: action.data.hub_activity_parent_reply_id,
        user: {
          ...action.data.user,
          user_id: action.data.user_id,
        },
      };
      return {
        ...state,
        activity: {
          ...state.activity,
          replies: [newReply, ...state.activity.replies],
        },
      };
    }
    case DELETE_HUB_ACTIVITY_REPLY: {
      const { id, replyId } = action.data;
      if (id !== state.activity?.id) return state;
      return {
        ...state,
        activity: {
          ...state.activity,
          replies: state.activity.replies.filter(
            (reply) => reply.id !== replyId
          ),
        },
      };
    }
    case TOGGLE_ANNOUNCEMENT_STICKY: {
      if (
        state.activity?.id !== action.payload.activityId ||
        state.activity?.activity_type !== 'announcement'
      )
        return state;
      return {
        ...state,
        activity: {
          ...state.activity,
          is_sticky: action.payload.is_sticky,
        },
      };
    }
    case SET_LOADING_SINGLE_ACTIVITY:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

export default combineReducers({
  activity,
  activities,
  activityFilter,
  dataSubmittedSuccessfully,
  isOwnedByMeFilterSelected,
});
