import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as ActivitySvg } from './icons/Activity.svg';
import { ReactComponent as AddProjectSvg } from './icons/AddProject.svg';
import { ReactComponent as MyAllEventSvg } from './icons/AllMyEvents.svg';
import { ReactComponent as BrainSvg } from './icons/Brain.svg';
import { ReactComponent as BuilderSvg } from './icons/Builders.svg';
import { ReactComponent as DashboardSvg } from './icons/Dashboard.svg';
import { ReactComponent as EventSvg } from './icons/Events.svg';
import { ReactComponent as GroupSvg } from './icons/Groups.svg';
import { ReactComponent as MemberSvg } from './icons/Members.svg';
import { ReactComponent as ProjectPageSvg } from './icons/ProjectsPage.svg';
import { ReactComponent as KudosSvg } from './icons/Kudos.svg';
import { ReactComponent as MiscSvg } from './icons/Misc.svg';
import { ReactComponent as RebelbaseLogoSvg } from './icons/Rebelbase_TextLogo.svg';

// HubLibrary
import { ReactComponent as HubLibrarySvg } from './icons/HubLibrary.svg';
// Hub Builders
import { ReactComponent as HubBuilderAnswerSvg } from './icons/HubBuilders/Answer.svg';
import { ReactComponent as HubBuilderInspirationByteSvg } from './icons/HubBuilders/InspirationByte.svg';
import { ReactComponent as HubBuilderIntroSvg } from './icons/HubBuilders/Intro.svg';
import { ReactComponent as HubBuilderSectionSvg } from './icons/HubBuilders/Section-1.svg';
import { ReactComponent as HubBuilderTopicSvg } from './icons/HubBuilders/Topic.svg';
import { ReactComponent as BuilderTopicSvg1 } from './icons/HubBuilders/BuilderTopic.svg';
import { ReactComponent as HubBuilderWorkshopSvg } from './icons/HubBuilders/Workshop.svg';

// Project Builders
import { ReactComponent as BuilderPublishedSvg } from './icons/ProjectBuilders/BuilderPublished.svg';
import { ReactComponent as BuilderUnPublishedSvg } from './icons/ProjectBuilders/BuilderUnPublished.svg';

import { ReactComponent as ProjectProfileEditSvg } from './icons/ProjectProfile/ProjectProfileEdit.svg';
import { ReactComponent as ProjectProfilePlaningSvg } from './icons/ProjectProfile/ProjectPlaning.svg';
import { ReactComponent as ProjectProfileIndustrySvg } from './icons/ProjectProfile/ProjectLocation.svg';

const ActivityIcon = (props) => <SvgIcon component={ActivitySvg} inheritViewBox {...props} />;
const AddProjectIcon = (props) => <SvgIcon component={AddProjectSvg} inheritViewBox {...props} />;
const MyAllEventIcon = (props) => <SvgIcon component={MyAllEventSvg} inheritViewBox {...props} />;
const BrainIcon = (props) => <SvgIcon component={BrainSvg} inheritViewBox {...props} />;
const BuilderIcon = (props) => <SvgIcon component={BuilderSvg} inheritViewBox {...props} />;
const DashboardIcon = (props) => <SvgIcon component={DashboardSvg} inheritViewBox {...props} />;
const EventIcon = (props) => <SvgIcon component={EventSvg} inheritViewBox {...props} />;
const GroupIcon = (props) => <SvgIcon component={GroupSvg} inheritViewBox {...props} />;
const MemberIcon = (props) => <SvgIcon component={MemberSvg} inheritViewBox {...props} />;
const ProjectPageIcon = (props) => <SvgIcon component={ProjectPageSvg} inheritViewBox {...props} />;
const KudosIcon = (props) => <SvgIcon component={KudosSvg} inheritViewBox {...props} />;
const MiscIcon = (props) => <SvgIcon component={MiscSvg} inheritViewBox {...props} />;
const RebelbaseLogoIcon = (props) => <SvgIcon component={RebelbaseLogoSvg} inheritViewBox {...props} />;

// HubLibrary
const HubLibraryIcon = (props) => <SvgIcon component={HubLibrarySvg} inheritViewBox {...props} />;

// Hub Builders
// TODO some icons are colored need to ask Nick to provide default gray color if possible
const HubBuilderAnswerIcon = (props) => <SvgIcon component={HubBuilderAnswerSvg} inheritViewBox {...props} />;
const HubBuilderInspirationByteIcon = (props) => <SvgIcon component={HubBuilderInspirationByteSvg} inheritViewBox {...props} />;
const HubBuilderIntroIcon = (props) => <SvgIcon component={HubBuilderIntroSvg} inheritViewBox {...props} />;
const HubBuilderSectionIcon = (props) => <SvgIcon component={HubBuilderSectionSvg} inheritViewBox {...props} />;
const HubBuilderTopicIcon = (props) => <SvgIcon component={HubBuilderTopicSvg} inheritViewBox {...props} />;
const HubBuilderWorkshopIcon = (props) => <SvgIcon component={HubBuilderWorkshopSvg} inheritViewBox {...props} />;
const HubBuilderTopicIcon1 = (props) => <SvgIcon component={BuilderTopicSvg1} inheritViewBox {...props} />;

// Project Builders
const BuilderPublishedIcon = (props) => <SvgIcon component={BuilderPublishedSvg} inheritViewBox {...props} />;
const BuilderUnPublishedIcon = (props) => <SvgIcon component={BuilderUnPublishedSvg} inheritViewBox {...props} />;

// Profile Profile Icons
const ProjectProfileEditIcon = (props) => <SvgIcon component={ProjectProfileEditSvg} inheritViewBox {...props} />;
const ProjectProfilePlaningIcon = (props) => <SvgIcon component={ProjectProfilePlaningSvg} inheritViewBox {...props} />;
const ProjectProfileIndustryIcon = (props) => <SvgIcon component={ProjectProfileIndustrySvg} inheritViewBox {...props} />;

export {
    ActivityIcon,
    AddProjectIcon,
    MyAllEventIcon,
    BrainIcon,
    BuilderIcon,
    DashboardIcon,
    EventIcon,
    GroupIcon,
    MemberIcon,
    ProjectPageIcon,
    KudosIcon,
    MiscIcon,
    RebelbaseLogoIcon,
    HubLibraryIcon,
    HubBuilderAnswerIcon,
    HubBuilderInspirationByteIcon,
    HubBuilderIntroIcon,
    HubBuilderSectionIcon,
    HubBuilderTopicIcon,
    HubBuilderTopicIcon1,
    HubBuilderWorkshopIcon,
    BuilderPublishedIcon,
    BuilderUnPublishedIcon,
    ProjectProfileEditIcon,
    ProjectProfilePlaningIcon,
    ProjectProfileIndustryIcon
};
