import { triggerEmailVerifiedPopup } from 'functions/popup/triggerEmailVerifiedPopup';
import { triggerVerifyEmailPopup } from 'functions/popup/triggerVerifyEmailPopup';
import { triggerEmailVerificationSentPopup } from 'functions/popup/triggerEmailVerificationSentPopup';
import { triggerInvalidTokenPopup } from 'functions/popup/triggerInvalidTokenPopup';
import { request } from '../../functions';
import { removePopups } from '../popup/popupActions';
import { doRedirect } from '../metadata/metadataActions';
import { fetchUserEmailAddresses } from '../userEmailAddresses/userEmailAddressActions';

export function processEmailVerification(token) {
  return (dispatch, getState) => {
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/accounts/email-verification/${0}`;
    const userId = getState().user.get('id');

    const payload = {
      email_verification: {
        token,
      },
    };
    return request(url, null, 'PUT', payload)
      .then(() => {
        dispatch(doRedirect(userId ? `/profile/${userId}` : '/'));
        dispatch(removePopups());
        triggerEmailVerifiedPopup(dispatch);
        if (userId) dispatch(fetchUserEmailAddresses());
      })
      .catch(() => {
        dispatch(doRedirect(userId ? `/profile/${userId}` : '/'));
        triggerInvalidTokenPopup(dispatch);
      });
  };
}

export function requestEmailVerification(emailAddress) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/email-verification`;

    const payload = {
      email_verification: {
        email_address: emailAddress,
      },
    };
    request(url, jwt, 'POST', payload).then(() => {
      return triggerEmailVerificationSentPopup(emailAddress, dispatch);
    });
  };
}

export function isEmailVerified() {
  return (dispatch, getState) => {
    const { userEmailAddresses, metadata } = getState();

    // if email verification is in process, don't check for unverified email addresses this session
    if (metadata.isVerifyingEmail) {
      return;
    }

    // for each email address, check if it is verified or not
    // if the email is no verified, shoot a popup
    userEmailAddresses.forEach((uea) => {
      if (!uea.get('is_verified')) {
        triggerVerifyEmailPopup(
          uea.get('email_address'),
          uea.get('id'),
          dispatch
        );
      }
    });
  };
}
