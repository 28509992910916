// LOADING
export const SET_APP_DATA_LOADED = 'metadata/SET_APP_DATA_LOADED';
export const RESET_APP_DATA_LOADED = 'metadata/RESET_APP_DATA_LOADED';
export const SET_PROFILE_DATA_LOADED = 'metadata/SET_PROFILE_DATA_LOADED';
export const SET_CHALLENGE_DATA_LOADING = 'metadata/SET_CHALLENGE_DATA_LOADING';
export const SET_CHALLENGE_DATA_LOADED = 'metadata/SET_CHALLENGE_DATA_LOADED';
export const RESET_USER_LOADED = 'metadata/RESET_USER_LOADED';
export const SET_USER_LOADED = 'metadata/SET_USER_LOADED';
export const SET_PROJECTS_LOADED = 'metadata/SET_PROJECTS_LOADED';
export const SET_PROJECTS_LOADING = 'metadata/SET_PROJECTS_LOADING';
export const SET_PROJECT_DATA_LOADED = 'metadata/SET_PROJECT_DATA_LOADED';
export const SET_PROJECT_DATA_LOADING = 'metadata/SET_PROJECT_DATA_LOADING';
export const SET_CHALLENGE_WORKSHOPS_LOADED =
  'metadata/SET_CHALLENGE_WORKSHOPS_LOADED';
export const SET_PROJECT_ANSWERS_LOADED = 'metadata/SET_PROJECT_ANSWERS_LOADED';
export const SET_HUBS_LOADED = 'metadata/SET_HUBS_LOADED';
export const RESET_HUBS_LOADED = 'metadata/RESET_HUBS_LOADED';
export const SET_USER_INVITATIONS_LOADED =
  'metadata/SET_USER_INVITATIONS_LOADED';
export const RESET_USER_INVITATIONS_LOADED =
  'metadata/RESET_USER_INVITATIONS_LOADED';
export const RESET_PROJECT_DATA_LOADED = 'metadata/RESET_PROJECT_DATA_LOADED';
export const SET_BUILDERS_DATA_LOADED = 'metadata/SET_BUILDERS_DATA_LOADED';
export const PERMISSION_DENIED_FOR_BUILDERS_DATA =
  'metadata/PERMISSION_DENIED_FOR_BUILDERS_DATA';
export const RESET_PERMISSION_DENIED_FOR_BUILDERS_DATA =
  'metadata/RESET_PERMISSION_DENIED_FOR_BUILDERS_DATA';

// REDIRECT
export const SET_REDIRECT = 'metadata/SET_REDIRECT';
export const RESET_REDIRECT = 'metadata/RESET_REDIRECT';
export const SET_EDITING_HUB_ACTIVITY = 'metadata/SET_EDITING_HUB_ACTIVITY';
export const SET_VIEWING_HUB_ACTIVITY = 'metadata/SET_VIEWING_HUB_ACTIVITY';

// page not found
export const SET_PAGE_NOT_FOUND = 'metadata/SET_PAGE_NOT_FOUND';
export const RESET_PAGE_NOT_FOUND = 'metadata/RESET_PAGE_NOT_FOUND';

// toggle workshop
export const OPEN_WORKSHOP = 'metadata/OPEN_WORKSHOP';
export const CLOSE_WORKSHOP = 'metadata/CLOSE_WORKSHOP';

// focusMode
export const SET_FOCUS_MODE = 'metadata/SET_FOCUS_MODE';
export const RESET_FOCUS_MODE = 'metadata/RESET_FOCUS_MODE';

// toggle answers
export const OPEN_ANSWER = 'metadata/OPEN_ANSWER';
export const CLOSE_ANSWER = 'metadata/CLOSE_ANSWER';

// PROJECT PROFILE
export const SET_EDITING_PROJECT_ABOUT = 'metadata/SET_EDITING_PROJECT_ABOUT';
export const SET_VIEWING_PROJECT_ABOUT = 'metadata/SET_VIEWING_PROJECT_ABOUT';
export const SET_ABOUT_CHANGED = 'metadata/SET_ABOUT_CHANGED';
export const SET_ABOUT_SAVED = 'metadata/SET_ABOUT_SAVED';
export const SET_PROJECT_ANSWER_CHANGED = 'metadat/SET_PROJECT_ANSWER_CHANGED';
export const SET_PROJECT_ANSWER_SAVED = 'metadata/SET_PROJECT_ANSWER_SAVED';

export const SET_EDITING_PROJECT_DESCRIPTION =
  'metadata/SET_EDITING_PROJECT_DESCRIPTION';
export const SET_VIEWING_PROJECT_DESCRIPTION =
  'metadata/SET_VIEWING_PROJECT_DESCRIPTION';
export const SET_DESCRIPTION_CHANGED = 'metadata/SET_DESCRIPTION_CHANGED';
export const SET_DESCRIPTION_SAVED = 'metadata/SET_DESCRIPTION_SAVED';

export const OPEN_UPLOAD_LOGO = 'metadata/OPEN_UPLOAD_LOGO';
export const CLOSE_UPLOAD_LOGO = 'metadata/CLOSE_UPLOAD_LOGO';

export const OPEN_UPLOAD_BACKDROP = 'metadata/OPEN_UPLOAD_BACKDROP';
export const CLOSE_UPLOAD_BACKDROP = 'metadata/CLOSE_UPLOAD_BACKDROP';

export const RESET_PROJECT_PROFILE_METADATA =
  'metadata/RESET_PROJECT_PROFILE_METADATA';

// requests
export const PROCESSING_REQUEST = 'metadata/PROCESSING_REQUEST';
export const REQUEST_PROCESSED = 'metadata/REQUEST_PROCESSED';

// requests
export const BEGIN_REQUEST = 'metadata/BEGIN_REQUEST';
export const FINISH_REQUEST = 'metadata/FINISH_REQUEST';

// email verification
export const VERIFYING_EMAIL = 'metadata/VERIFYING_EMAIL';

// sign up
export const SET_SIGNUP = 'metadata/SET_SIGNUP';
export const RESET_SIGNUP = 'metadata/RESET_SIGNUP';

// data list
export const SET_DATA_LIST = 'metadata/SET_DATA_LIST';
export const RESET_DATA_LIST = 'metadata/RESET_DATA_LIST';

// UI
export const SET_SCREEN_RESIZE = 'metadata/SET_SCREEN_RESIZE';

// token error
export const SET_TOKEN_ERROR = 'metadata/SET_TOKEN_ERROR';
export const RESET_TOKEN_ERROR = 'metadata/RESET_TOKEN_ERROR';

export const TELL_US_MODAL_STATE = 'metadata/TELL_US_MODAL_STATE';

export const SET_SCORING_DATA_LOADED = 'metadata/SET_SCORING_DATA_LOADED';

export function doRedirect(url) {
  return {
    type: SET_REDIRECT,
    url,
  };
}
export function setTokenError() {
  return {
    type: SET_TOKEN_ERROR,
  };
}

export function resetTokenError() {
  return {
    type: RESET_TOKEN_ERROR,
  };
}

export function openWorkshop(projectId, workshopBlueprintId) {
  return {
    type: OPEN_WORKSHOP,
    projectId,
    workshopBlueprintId,
  };
}

export function closeWorkshop(projectId, workshopBlueprintId) {
  return {
    type: CLOSE_WORKSHOP,
    projectId,
    workshopBlueprintId,
  };
}

export function openAnswer(topicId) {
  return {
    type: OPEN_ANSWER,
    topicId,
  };
}

export function closeAnswer(topicId) {
  return {
    type: CLOSE_ANSWER,
    topicId,
  };
}

export const setTellUsModalState = (state) => (dispatch) => {
  dispatch({ type: TELL_US_MODAL_STATE, state });
};

export const resetNotFound = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE_NOT_FOUND });
  };
};

export const setNotFound = () => {
  return (dispatch) => {
    dispatch({ type: SET_PAGE_NOT_FOUND });
  };
};
