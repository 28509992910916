import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Box,
  MenuItem,
  Typography,
  Avatar,
  Link as MenuLink,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';
import emptyProfile from 'images/empty_profile.png';
import { doesStartWithVowel, formatLocalDatetime } from 'functions';
import {
  acceptHubEventInvitation,
  acceptHubInvitation,
  acceptProjectInvitation,
  declineHubEventInvitation,
  declineHubInvitation,
  updateProjectInvitationNotification,
} from 'store/notifications/notificationActions';
import { useHistory } from 'react-router';
import { List } from 'immutable';
import useUserInvites from 'hooks/useUserInvites';

const Invitations = (props) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isSpaceRequired = mdUp && !(props?.isDropdown || false);
  const history = useHistory();
  const { t, onClose } = props;
  const { metadata, appData, invitations } = useSelector((state) => state);
  const dispatch = useDispatch();
  const inviteInfo = useUserInvites();
  // const invites = useSelector(state => state.invites.inviteReducer);
  const isLoaded =
    metadata.loading.get('appDataLoaded') &&
    metadata.loading.get('userLoaded') &&
    metadata.loading.get('userInvitationsLoaded');

  const awaitingInvites = useMemo(
    () =>
      (inviteInfo?.invites || List())
        .filter(
          (invite) =>
            invite &&
            !invite.get('is_accepted') &&
            !invite.get('is_declined') &&
            !invite.get('is_ignored')
        )
        .sort((left, right) => {
          const first = formatLocalDatetime(left.get('inserted_at')).toDate();
          const second = formatLocalDatetime(right.get('inserted_at')).toDate();
          return second.getTime() - first.getTime();
        })
        .toJS(),
    [inviteInfo]
  );

  const respondedInvites = useMemo(
    () =>
      (inviteInfo?.invites || List())
        .filter(
          (invite) =>
            invite &&
            (invite.get('is_accepted') ||
              invite.get('is_declined') ||
              invite.get('is_ignored'))
        )
        .sort((left, right) => {
          const first = formatLocalDatetime(left.get('inserted_at')).toDate();
          const second = formatLocalDatetime(right.get('inserted_at')).toDate();
          return second.getTime() - first.getTime();
        })
        .toJS(),
    [inviteInfo]
  );

  const closeMenu = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const accept = (type, inviteId, notificationId) => {
    if (type === 'Project') {
      dispatch(acceptProjectInvitation(inviteId));
    } else if (type === 'Hub') {
      dispatch(acceptHubInvitation(notificationId, inviteId));
    } else if (type === 'HubEvent') {
      dispatch(acceptHubEventInvitation(notificationId, inviteId));
    }
    closeMenu();
  };

  const decline = (type, notificationId) => {
    if (type === 'Project') {
      dispatch(
        updateProjectInvitationNotification(notificationId, true, false)
      );
    } else if (type === 'Hub') {
      dispatch(declineHubInvitation(notificationId));
    } else if (type === 'HubEvent') {
      dispatch(declineHubEventInvitation(notificationId));
    }
    closeMenu();
  };

  const InviteAvatar = ({ sender }) => {
    return (
      <Badge variant="dot" color="primary">
        <Avatar
          src={sender.avatar_url || emptyProfile}
          alt={`${sender.first_name} ${sender.last_name}`}
          sx={{
            width: '45px',
            height: '45px',
          }}
        />
      </Badge>
    );
  };

  const InviteFooter = ({ invite, invitationId }) => {
    return (
      <>
        {!invite.is_accepted && !invite.is_declined && !invite.is_ignored && (
          <Stack
            direction="row"
            width="100%"
            padding="8px 0"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Button
              size="small"
              sx={{ color: (theme) => theme.palette.secondary.main }}
              onClick={() => decline(invite.type, invite.id)}
            >
              {t('decline')}
            </Button>
            <Button
              size="small"
              variant="contained"
              className="btn-main"
              onClick={() => accept(invite.type, invitationId, invite.id)}
              startIcon={<CheckIcon />}
            >
              {t('accept')}
            </Button>
          </Stack>
        )}
      </>
    );
  };

  const ProjectInvite = ({ invite }) => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <InviteAvatar sender={invite.sender} />
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography color="textSecondary" variant="body2">
              <Box component="span" sx={{ pr: 1 }}>
                {invite.type}
              </Box>
              {moment(invite.inserted_at).format('MMM D, YYYY h:mm A')}
            </Typography>
            <Typography
              variant="body2"
              wrap
              sx={{
                width: '320px',
                fontWeight: 'normal',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              <Box
                component="span"
                sx={{ fontWeight: 700 }}
              >{`${invite.sender.first_name} ${invite.sender.last_name}`}</Box>
              &nbsp;
              {t('InvitedYouToTheirTeam')}&nbsp;
              <Box
                component="span"
                sx={{ fontWeight: 700, display: 'inline-block' }}
              >
                {invite.project.name}
              </Box>
            </Typography>
          </Box>
        </Box>
        <InviteFooter
          invite={invite}
          invitationId={invite.project_invitation_id}
        />
      </>
    );
  };

  const HubInvite = ({ invite }) => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <InviteAvatar sender={invite.sender} />
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography color="textSecondary" variant="body2">
              <Box component="span" sx={{ pr: 1 }}>
                {invite.type}
              </Box>
              {moment(invite.inserted_at).format('MMM D, YYYY h:mm A')}
            </Typography>
            <Typography
              variant="body2"
              wrap
              sx={{
                width: '320px',
                fontWeight: 'normal',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              <Box
                component="span"
                sx={{ fontWeight: 700 }}
              >{`${invite.sender.first_name} ${invite.sender.last_name}`}</Box>
              &nbsp;
              {t('InvitedYouToHub')}&nbsp;
              <Box
                component="span"
                sx={{ fontWeight: 700, display: 'inline-block' }}
              >
                {invite.hub.name}
              </Box>
            </Typography>
          </Box>
        </Box>
        <InviteFooter invite={invite} invitationId={invite.hub_invitation_id} />
      </>
    );
  };

  const HubEventInvite = ({ invite }) => {
    const invitation = invitations.hubEventInvitations.find(
      (i) => i.get('id') === invite.hub_event_invitation_id
    );
    const role =
      invitation &&
      appData.userEventRoleTypes
        .find(
          (uert) => uert.get('id') === invitation.get('user_event_role_type_id')
        )
        .toJS();
    if (!role) return null;
    return (
      <>
        <Box display="flex" alignItems="center">
          <InviteAvatar sender={invite.sender} />
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography color="textSecondary" variant="body2">
              <Box component="span" sx={{ pr: 1 }}>
                {invite.type}
              </Box>
              {moment(invite.inserted_at).format('MMM D, YYYY h:mm A')}
            </Typography>
            <Typography
              variant="body2"
              wrap
              sx={{
                width: '320px',
                fontWeight: 'normal',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              <Box
                component="span"
                sx={{ fontWeight: 700 }}
              >{`${invite.sender.first_name} ${invite.sender.last_name}`}</Box>
              &nbsp;
              {t('InvitedYouToAttendAnEvent')}&nbsp;
              <Box
                component="span"
                sx={{ fontWeight: 700, display: 'inline-block' }}
              >
                {invite.hub_event.name}
              </Box>
              &nbsp;
              {t('as')} {doesStartWithVowel(role.label) ? 'an' : 'a'}{' '}
              {role.label === 'Resource' ? 'Support' : role.label}
            </Typography>
            {invite.is_declined ||
              (invite.is_ignored && (
                <Box component="span" sx={{ pl: 1 }}>
                  - {t('YouAreNotGoing')}
                </Box>
              ))}
          </Box>
        </Box>
        <InviteFooter
          invite={invite}
          invitationId={invite.hub_event_invitation_id}
        />
        {invite.is_accepted && (
          <Box>
            {t('YouAreAttendingAs')}{' '}
            {doesStartWithVowel(role.label) ? t('an') : t('a')}{' '}
            {role.label === 'Resource' ? 'Support' : role.label}.{' '}
            <Link to={`/events/${invite.hub_event?.id}`}>
              {' '}
              {t('SeeEvent')}.
            </Link>
          </Box>
        )}
      </>
    );
  };

  const UserInvite = ({ invite }) => {
    return (
      <MenuItem
        disableRipple
        key={`${invite.id}`}
        sx={{
          pt: 2,
          pb: 2,
          borderRadius: '15px',
          margin: 2,
          flexDirection: 'column',
          alignItems: 'flex-start',
          background: (theme) => theme.palette.common.white,
        }}
      >
        {invite.type === 'Project' && <ProjectInvite invite={invite} />}
        {invite.type === 'Hub' && <HubInvite invite={invite} />}
        {invite.type === 'HubEvent' && <HubEventInvite invite={invite} />}
      </MenuItem>
    );
  };

  const EmptyPlaceHolder = () => {
    return (
      <MenuItem
        disableRipple
        sx={{
          pt: 2,
          pb: 2,
          borderRadius: '15px',
          margin: 2,
          flexDirection: 'column',
          alignItems: 'flex-start',
          background: (theme) => theme.palette.common.white,
        }}
      >
        {t('NoInvitesRightNow')}
      </MenuItem>
    );
  };

  const showAllUseInvites = (event) => {
    event?.preventDefault();
    history.push('/invitations');
    props?.onClose();
  };

  if (!isLoaded) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: isSpaceRequired ? '0 10%' : '0px',
      }}
    >
      <Box
        sx={{
          p: '10px 15px',
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h4"
            fontWeight="700"
            sx={{
              margin: '0 !important',
              textTransform: 'uppercase',
            }}
          >
            {t('Invites')}
          </Typography>
          {props?.isDropdown && (
            <Box
              sx={{
                ml: 'auto',
              }}
            >
              <MenuLink to="#" onClick={showAllUseInvites}>
                {t('SeeAll')}
              </MenuLink>
            </Box>
          )}
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          margin: 2,
          mb: 0,
          mt: 0,
        }}
      >
        Awaiting
      </Typography>
      {awaitingInvites?.length === 0 && <EmptyPlaceHolder />}
      {awaitingInvites.map((invite) => (
        <UserInvite invite={invite} key={`${invite.id}`} />
      ))}
      <Typography
        variant="body2"
        sx={{
          margin: 2,
          mb: 0,
          mt: 0,
        }}
      >
        Responded
      </Typography>
      {respondedInvites?.length === 0 && <EmptyPlaceHolder />}
      {respondedInvites.map((invite) => (
        <UserInvite invite={invite} key={`${invite.id}`} />
      ))}
    </Box>
  );
};

export default withTranslation(['translation.notification'])(
  React.memo(Invitations)
);
