import React from 'react';
import SEO from 'components/SEO';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AppLogo from 'components/Common/AppLogo';
import '../ForgotPassword/forgotPassword.scss';
import { createNewPassword } from '../../../store/passwordRecovery/passwordRecoveryActions';

// form field
const renderField = ({
  input,
  placeholder,
  type,
  className,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={className}
      />
      {touched &&
        ((error && <span className="">{error}</span>) ||
          (warning && <span className="">{warning}</span>))}
    </div>
  );
};

// sync validation
const validate = (values, { t }) => {
  const errors = {};
  if (!values.password) {
    errors.password = t('Required');
  } else if (values.password.length < 6) {
    errors.password = t('ValidatingPassword');
  }
  if (!values.confirm_password) {
    errors.confirm_password = t('Required');
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = t('PasswordDoesNotMatch');
  }
  return errors;
};

const ResetPassword = ({ submitNewPassword, token, t }) => (
  <SEO>
    <section className="backg__bg">
      <div className="forgot-pass">
        <div className="forgot-pass__cont">
          <div className="app-logo__container">
            <AppLogo className="app-logo" />
          </div>
          <h3>{t('ResetYourPassword')}</h3>
          <form onSubmit={(event) => submitNewPassword(event, token)}>
            <Field
              type="password"
              component={renderField}
              name="password"
              placeholder={t('Password')}
              className="forgot-pass__input"
            />
            <Field
              type="password"
              component={renderField}
              name="confirm_password"
              placeholder={t('ConfirmPassword')}
              className="forgot-pass__input"
            />
            <div className="btn-wrap">
              <button type="submit" className="forgot-pass__btn">
                {t('ResetButton')}
              </button>
            </div>
          </form>
          <div className="forgot-pass__links">
            <p>
              <Link to="/auth/login">{t('Login')}</Link> {t('or')}{' '}
              <Link to="/auth/sign-up">{t('Signup')}</Link> {t('instead')}
            </p>
          </div>
        </div>
      </div>
    </section>
  </SEO>
);

const mapDispatchToProps = (dispatch) => ({
  submitNewPassword(event, token) {
    event.preventDefault();
    dispatch(createNewPassword(token));
  },
});

const mapStateToProps = (state, props) => {
  const token = props.location.search?.replace('?token=', '');
  return {
    token,
  };
};

export default withTranslation(['translation.ResetPassword'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'ResetPasswordForm',
      validate,
      enableReinitialize: true,
    })(ResetPassword)
  )
);
