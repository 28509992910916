/* eslint-disable no-console */
/* eslint-disable camelcase */
import { reset } from 'redux-form';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { fetchChallengeBlueprints } from 'store/appData/appDataActions';
import { fetchUserEmailAddresses } from 'store/userEmailAddresses/userEmailAddressActions';
import { signupOrLoginFirstPopup } from 'functions/popup/signupOrLoginFirstPopup';
import { triggerInvalidTokenPopup } from 'functions/popup/triggerInvalidTokenPopup';
import { loginWithCorrectEmailPopup } from 'functions/popup/loginWithCorrectEmailPopup';
import { triggerErrorPopup } from 'functions/popup/triggerErrorPopup';
import { invalidEmailPopup } from 'functions/popup/invalidEmailPopup';
import { triggerSuccessPopup } from 'functions/popup/triggerSuccessPopup';
import { invitationSentPopup } from 'functions/popup/invitationSentPopup';
import { alreadyInvitedPopup } from 'functions/popup/alreadyInvitedPopup';
import { invitingYourselfPopup } from 'functions/popup/invitingYourselfPopup';
import { fetchProjects } from 'store/project/projectActions';
import { fetchChallengeData } from 'store/projectProfile-v1/projectProfileActions';
import { updateProjectInvitationNotificationOnAccept } from 'store/notifications/notificationActions';
import { projectInvitationAcceptedPopup } from 'functions/popup/projectInvitationAcceptedPopup';
import { REMOVE_EVENT_INVITATION } from 'store/events-v1/eventConstants';
import { fetchNewAlerts } from 'store/alerts/alertsAction';

import {
  fetchHubData,
  SET_HUB_EVENT_INVITATIONS,
} from '../hubs/hubActions';
import {
  doRedirect,
  BEGIN_REQUEST,
  FINISH_REQUEST,
  RESET_USER_INVITATIONS_LOADED,
  SET_USER_INVITATIONS_LOADED,
  RESET_SIGNUP,
} from '../metadata/metadataActions';
import { fetchProjectData } from '../projectData/projectDataActions';
import { removePopups } from '../popup/popupActions';
import { request, emailTextToList } from '../../functions';
import { getAuthUser } from '../auth/authActions';

export const RESET_INVITATION_TOKEN = 'invitations/RESET_INVITATION_TOKEN';

export const SET_CREATE_HUB_INVITATIONS =
  'invitations/SET_CREATE_HUB_INVITATIONS';
export const SET_CREATE_HUB_INVITATION =
  'invitations/SET_CREATE_HUB_INVITATIONS';
export const SET_CREATE_HUB_INVITATION_TOKEN =
  'invitations/SET_CREATE_HUB_INVITATION_TOKEN';
export const SET_HUB_EVENT_INVITATION_TOKEN =
  'invitations/SET_HUB_EVENT_INVITATION_TOKEN';
export const SET_HUB_INVITATION_TOKEN = 'invitations/SET_HUB_INVITATION_TOKEN';

export const invitationTypes = {
  createHub: 'createHub',
  hubEventInvitation: 'hubEventInvitation',
  hubInvitation: 'hubInvitation',
  projectInvitation: 'projectInvitation',
};

/* ******************* */
/* PROJECT INVITATIONS */
/* ******************* */

export const SET_ACTIVE_PROJECT_INVITATIONS =
  'invitations/SET_ACTIVE_PROJECT_INVITATIONS';

export const RESET_ACTIVE_PROJECT_INVITATION_ON_DECLINE =
  'invitations/RESET_ACTIVE_PROJECT_INVITATION_ON_DECLINE';

export const SET_PROJECT_INVITATION_TOKEN =
  'invitations/SET_PROJECT_INVITATION_TOKEN';

export function fetchActiveProjectInvitations() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/invitations/project-invitations`;
    return request(url, jwt).then((response) => {
      dispatch({ type: SET_ACTIVE_PROJECT_INVITATIONS, data: response.data });
      // dispatch(pendingInvitationPopup());
    });
  };
}

export const processProjectInvitationOnSuccess = (
  response,
  redirect,
  token
) => {
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch(removePopups());
    dispatch(fetchUserEmailAddresses());
    projectInvitationAcceptedPopup(dispatch);
    dispatch({ type: RESET_INVITATION_TOKEN });
    dispatch(fetchProjects());
    dispatch(getAuthUser(user.get('id')));
    dispatch(fetchChallengeData());
    dispatch(fetchProjectData());
    const { appData } = getState();
    const { userProjectRoleTypes } = appData;
    const userRole = userProjectRoleTypes
      .toJS()
      .filter((role) => role.id === response.data.role_type_id);
    if (redirect) {
      if (userRole?.length && userRole[0].level > 30)
        dispatch(doRedirect(`/project/${response.data.project_id}/builders`));
      else dispatch(doRedirect(`/project/${response.data.project_id}`));
    }
    dispatch(updateProjectInvitationNotificationOnAccept(token));
    return '';
  };
};

/* ********************** */
/* CREATE HUB INVITATIONs */
/* ********************** */

export const createHubInvitationOnSuccess = (response) => (dispatch) => {
  dispatch({
    type: SET_CREATE_HUB_INVITATION,
    data: response.data,
  });
  dispatch(doRedirect('/create-hub/start'));
};

/* *************** */
/* HUB INVITATIONs */
/* *************** */

export const SET_HUB_INVITATIONS = 'invitations/SET_HUB_INVITATIONS';
export function fetchHubInvitations() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/invitations/hub-invitations`;
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_HUB_INVITATIONS, data: response.data })
    );
  };
}

export const hubInvitationOnSuccess = (response, redirect, token) => {
  return /*withTranslation(['translation.notification']) (*/ (dispatch, getState) => {
    const { user } = getState();
    dispatch(removePopups());
    dispatch(fetchUserEmailAddresses());
    if (redirect) dispatch(doRedirect(`/hubs/${response.data.hub_id}/welcome`));
    triggerSuccessPopup('InvitationAccepted', dispatch);
    dispatch({ type: SET_HUB_INVITATIONS, token });
    dispatch({ type: RESET_INVITATION_TOKEN });
    dispatch(fetchHubData());
    dispatch(fetchHubInvitations());
    dispatch(getAuthUser(user.get('id')));
    dispatch(fetchChallengeBlueprints());
    return '';
  };
};

/* ***************** */
/* EVENT INVITATIONs */
/* ***************** */
export function inviteHubEventParticipants(eventIdString, redirect = false) {
  return (dispatch, getState) => {
    dispatch({ type: BEGIN_REQUEST });
    const { form, user, userEmailAddresses, hubs } = getState();
    const eventId = parseInt(eventIdString, 10);

    dispatch(removePopups());

    // participants that are going to be invited (list of emails)
    const participantsText =
      form &&
      form.InviteHubEventParticipants.values &&
      form.InviteHubEventParticipants.values.email_addresses
        ? form.InviteHubEventParticipants.values.email_addresses
        : null;
    const participantsEmailList = emailTextToList(participantsText);
    const participantsInvalidItems = _.filter(
      participantsEmailList,
      (e) => e.valid !== true
    );
    const participantsValidEmailList = _.filter(
      participantsEmailList,
      (e) => e.valid
    );
    const participantsUniqueEmailList = _.uniqWith(
      participantsValidEmailList,
      _.isEqual
    );

    // error for invalid emails
    const invalidItemsList = participantsInvalidItems;
    if (invalidItemsList.length > 0) {
      const invalidItems = _.reduce(
        invalidItemsList,
        (text, e, key) => {
          if (key === invalidItemsList.length - 1) {
            return `${text} ${e.email}`;
          }
          return `${text} ${e.email},`;
        },
        ''
      );
      invalidEmailPopup(invalidItems, dispatch);

      dispatch({ type: FINISH_REQUEST });
      return;
    }

    // make sure there is at least 1 email
    if (participantsUniqueEmailList.length === 0) {
      if (redirect) {
        triggerErrorPopup('EnterEmailToInvite', dispatch);
      } else {
        triggerErrorPopup('EnterSomeEmails', dispatch);
      }
      return;
    }

    // check that the user is not inviting themselves
    const userEmailAddressList = userEmailAddresses
      .map((e) => {
        return { email: e.get('email_address') };
      })
      .toJS();
    const userEmailAddressIntersection = _.intersectionBy(
      userEmailAddressList,
      participantsUniqueEmailList,
      (e) => e.email
    );
    if (userEmailAddressIntersection.length > 0) {
      const message = `${_.reduce(
        userEmailAddressIntersection,
        (text, e, key) => {
          if (key === userEmailAddressIntersection.length - 1) {
            return `${text} ${e.email}`;
          }
          return `${text} ${e.email},`;
        },
        ''
      )}.`;
      invitingYourselfPopup(message, dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    // check that the email isn't already part of the event
    const alreadyInEventEmailList = hubs.hubEventInvitations
      .filter((hei) => hei.get('hub_event_id') === eventId)
      .map((hei) => ({ email: hei.get('email_address') }))
      .concat(
        hubs.userEventRoles
          .filter((uer) => uer.get('hub_event_id') === eventId)
          .map((uer) => ({ email: uer.get('email_address') }))
      )
      .push(fromJS({ email: user.get('email_address') }))
      .toJS();
    const intersectionParticipantEmails = _.intersectionBy(
      alreadyInEventEmailList,
      participantsUniqueEmailList,
      (e) => e.email
    );
    if (intersectionParticipantEmails.length > 0) {
      const message = `${_.reduce(
        intersectionParticipantEmails,
        (text, e, key) => {
          if (key === intersectionParticipantEmails.length - 1) {
            return `${text} ${e.email}`;
          }
          return `${text} ${e.email},`;
        },
        ''
      )}.`;
      alreadyInvitedPopup(message, dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    // make sure a role type is selected
    if (
      !form &&
      !form.InviteHubEventParticipants.values &&
      (!form.InviteHubEventParticipants.values.user_event_role_type_id ||
        form.InviteHubEventParticipants.values.user_event_role_type_id ===
          'defualt')
    ) {
      triggerErrorPopup('SelectRoleType', dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    // make requests
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-invitations`;

    const payload = {
      hub_event_invitations: {
        email_addresses:
          participantsUniqueEmailList.length > 0
            ? participantsUniqueEmailList
            : null,
        user_event_role_type_id: parseInt(
          form.InviteHubEventParticipants.values.user_event_role_type_id,
          10
        ),
        hub_event_id: eventId,
      },
    };

    request(url, jwt, 'POST', payload).then((response) => {
      dispatch({ type: SET_HUB_EVENT_INVITATIONS, data: response.data });
      invitationSentPopup('InvitationsSent', dispatch);

      dispatch(reset('InviteHubEventParticipants'));
      dispatch({ type: FINISH_REQUEST });
      if (redirect) {
        dispatch(doRedirect(`/events/${eventId}`));
      }
    });
  };
}

export const DELETE_PENDING_HUB_EVENT_INVITATION =
  'invitations/DELETE_PENDING_HUB_EVENT_INVITATION';
export function deleteHubEventInvitation(eventId, invitationId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events/${eventId}/invitations/${invitationId}`;

    const { user } = getState();

    const jwt = user.get('jwt');

    request(url, jwt, 'DELETE')
      .then(() => {
        dispatch({ type: DELETE_PENDING_HUB_EVENT_INVITATION, invitationId });
        dispatch({ type: REMOVE_EVENT_INVITATION, data: invitationId });
        triggerSuccessPopup('InvitationDeleted', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

export function resendHubEventInvitation(eventId, invitationId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events/${eventId}/invitations`;

    const payload = {
      resend_invitation: {
        event_invitation_id: invitationId,
      },
    };

    request(url, jwt, 'POST', payload)
      .then(() => {
        invitationSentPopup('InvitationResent', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

export const SET_USER_HUB_EVENT_INVITATIONS =
  'invitations/SET_USER_HUB_EVENT_INVITATIONS';
export function fetchHubEventInvitations() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/invitations/hub-event-invitations`;
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_USER_HUB_EVENT_INVITATIONS, data: response.data })
    );
  };
}

export const hubEventInvitationOnSuccess =
  (response) => (dispatch, getState) => {
    dispatch(removePopups());
    dispatch(fetchUserEmailAddresses());
    triggerSuccessPopup('InvitationAccepted', dispatch);
    dispatch({ type: RESET_INVITATION_TOKEN });
    dispatch(fetchHubData());
    dispatch(fetchHubEventInvitations());

    // if the user is a participant, take them to the select project modal
    const { appData } = getState();
    const role = appData.userEventRoleTypes.find(
      (uert) => uert.get('id') === response.data.user_event_role_type_id
    );
    switch (role.get('name')) {
      case 'Competitor':
        dispatch(
          doRedirect(`/events/${response.data.hub_event_id}/select-project`)
        );
        break;
      case 'Judge':
        dispatch(
          doRedirect(`/events/${response.data.hub_event_id}/welcome-judge`)
        );
        break;
      case 'Resource':
        dispatch(
          doRedirect(`/events/${response.data.hub_event_id}/welcome-resource`)
        );
        break;
      default:
        dispatch(doRedirect(`/events/${response.data.hub_event_id}`));
    }
  };

/* ********************** */
/* PROCESS INVITATOINS    */
/* ********************** */

const processInvitationData = {
  [invitationTypes.createHub]: {
    setTokenAction: SET_CREATE_HUB_INVITATION_TOKEN,
    apiEndpoint: 'api/v2/rebel/invitations/create-hub-invitations',
    onSuccess: createHubInvitationOnSuccess,
  },
  [invitationTypes.hubEventInvitation]: {
    setTokenAction: SET_HUB_EVENT_INVITATION_TOKEN,
    apiEndpoint: 'api/v2/rebel/events/hub-event-invitations',
    payloadKey: 'hub_event_invitation',
    onSuccess: hubEventInvitationOnSuccess,
  },
  [invitationTypes.hubInvitation]: {
    setTokenAction: SET_HUB_INVITATION_TOKEN,
    apiEndpoint: 'api/v2/rebel/affiliations/hub-invitations',
    payloadKey: 'hub_invitation',
    onSuccess: hubInvitationOnSuccess,
  },
  [invitationTypes.projectInvitation]: {
    setTokenAction: SET_PROJECT_INVITATION_TOKEN,
    apiEndpoint: 'api/v2/rebel/project/invitations',
    payloadKey: 'process_project_invitation',
    onSuccess: processProjectInvitationOnSuccess,
  },
};

const generatePayload = (invitationType, token) =>
  Object.defineProperty({}, processInvitationData[invitationType].payloadKey, {
    value: { token },
    enumerable: true,
  });

const invitationProcessOnSuccess = (
  response,
  invitationType,
  redirect,
  redirectTo,
  token,
  dispatch
) => {
  if (response.status === 403 || response.status === 422) {
    loginWithCorrectEmailPopup(dispatch);
    if (redirect) dispatch(doRedirect(redirectTo));
    return dispatch({ type: RESET_INVITATION_TOKEN });
  }
  if (invitationType === 'createHub') dispatch(doRedirect(redirectTo));
  return dispatch(
    processInvitationData[invitationType].onSuccess(response, redirect, token)
  );
};

const invitationProcessOnError = (err, redirect, redirectTo, dispatch) => {
  switch (err.status) {
    case 422:
      triggerErrorPopup('InvitationExpired', dispatch);
      break;
    case 401:
    case 403:
      loginWithCorrectEmailPopup(dispatch);
      break;
    default:
      triggerInvalidTokenPopup(dispatch);
      console.log(err.status);
  }
  if (redirect) {
    dispatch(doRedirect(redirectTo));
  }
};

export const processInvitationToken =
  (token, invitationType, redirect = true) =>
  async (dispatch, getState) => {
    if (!token) return;

    if (!Object.keys(invitationTypes).includes(invitationType)) return;

    const { user } = getState();
    if (invitationType === 'createHub')
      dispatch({
        type: processInvitationData[invitationType].setTokenAction,
        token,
      });

    const jwt = user.get('jwt');
    const redirectTo = jwt ? `/profile/${user.get('id')}` : '/';
    if (!jwt) {
      if (invitationType !== 'createHub')
        dispatch({
          type: processInvitationData[invitationType].setTokenAction,
          token,
        });
      signupOrLoginFirstPopup(dispatch);
      dispatch(doRedirect('/auth/login'));
      return;
    }

    let url = `${(window.env || process.env).REACT_APP_API_ENDPOINT}/${
      processInvitationData[invitationType].apiEndpoint
    }`;
    if (invitationType === 'createHub') url = url.concat(`/${token}`);

    try {
      dispatch({ type: BEGIN_REQUEST });
      let response;
      if (invitationType === 'createHub') response = await request(url, jwt);
      else {
        const payload = generatePayload(invitationType, token);
        response = await request(url, jwt, 'POST', payload);
      }
      invitationProcessOnSuccess(
        response,
        invitationType,
        redirect,
        redirectTo,
        token,
        dispatch
      );
      dispatch(fetchNewAlerts(user.get('id')));
    } catch (err) {
      invitationProcessOnError(err, redirect, redirectTo, dispatch);
    } finally {
      dispatch({ type: FINISH_REQUEST });
    }
  };

// Check token exist to process
export function isTokenAvailableToProcessInvitation(invitations) {
  const {
    hubEventInvitationToken,
    projectInvitationToken,
    createHubInvitationToken,
    hubInvitationToken,
  } = invitations;

  if (
    hubEventInvitationToken ||
    projectInvitationToken ||
    createHubInvitationToken ||
    hubInvitationToken
  )
    return true;
  return false;
}

// If there are any invitations, process them (accept) and
// redirect to the respective accepted invitation entity.
// If no tokens are present, go to way-find.
export function processInvitationTokens() {
  return (dispatch, getState) => {
    const { invitations } = getState();

    const {
      hubEventInvitationToken,
      projectInvitationToken,
      createHubInvitationToken,
      hubInvitationToken,
    } = invitations;

    if (hubEventInvitationToken) {
      return dispatch(
        processInvitationToken(
          hubEventInvitationToken,
          invitationTypes.hubEventInvitation,
          false
        )
      );
    }

    if (projectInvitationToken) {
      return dispatch(
        processInvitationToken(
          projectInvitationToken,
          invitationTypes.projectInvitation,
          true
        )
      );
    }

    if (createHubInvitationToken) {
      return dispatch(
        processInvitationToken(
          createHubInvitationToken,
          invitationTypes.createHub,
          true
        )
      );
    }

    if (hubInvitationToken) {
      return dispatch(
        processInvitationToken(
          hubInvitationToken,
          invitationTypes.hubInvitation,
          true
        )
      );
    }

    return null;
  };
}

const clearIfRedirectFromProtectedRoute = ({ from }) => {
  if (from === 'protectedRoute') {
    sessionStorage.removeItem('scrollDetails');
  }
};

const checkForRedirect = () => {
  if (sessionStorage.getItem('scrollDetails')) {
    const sessionData = JSON.parse(sessionStorage.getItem('scrollDetails'));
    const { previousPath } = sessionData;
    clearIfRedirectFromProtectedRoute(sessionData);
    return previousPath;
  }
  return false;
};

export function fetchUserInvitations() {
  return (dispatch, getState) => {
    const { metadata, invitations } = getState();
    dispatch({ type: RESET_USER_INVITATIONS_LOADED });
    Promise.all([
      dispatch(fetchHubEventInvitations()),
      dispatch(fetchHubInvitations()),
      dispatch(fetchActiveProjectInvitations()),
    ]).then(() => {
      dispatch({ type: SET_USER_INVITATIONS_LOADED });
      if (!metadata.isSignup) {
        if (isTokenAvailableToProcessInvitation(invitations)) {
          dispatch(processInvitationTokens());
        }

        const redirectPath = checkForRedirect();

        if (redirectPath) {
          return dispatch(doRedirect(redirectPath));
        }
      }
      return '';
    });
  };
}

export const processInvitationsAfterSignUp = () => {
  return async (dispatch, getState) => {
    const { invitations } = getState();
    const redirect = checkForRedirect();
    if (isTokenAvailableToProcessInvitation(invitations))
      await dispatch(processInvitationTokens());
    else if (redirect) dispatch(doRedirect(redirect));
    return dispatch({ type: RESET_SIGNUP });
  };
};
