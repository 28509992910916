import { triggerErrorPopup } from 'functions/popup/triggerErrorPopup';
import { triggerSuccessPopup } from 'functions/popup/triggerSuccessPopup';
import { triggerInvalidTokenPopup } from 'functions/popup/triggerInvalidTokenPopup';
import { request } from '../../functions';
import { removePopups } from '../popup/popupActions';
import { doRedirect } from '../metadata/metadataActions';

export function sendResetInstructionsToEmail(email) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch
  ) => {
    dispatch(removePopups());

    const forgotPasswordFormValues = email;
    const forgotPwURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/forgot-password`;
    const payload = {
      forgot_password: {
        email_address: forgotPasswordFormValues,
      },
    };
    request(forgotPwURL, null, 'POST', payload)
      .then((response) => {
        // if email doesn't exist
        if (response.data.recovery_email_sent === false) {
          triggerErrorPopup('NoAccount', dispatch);
        } else {
          triggerSuccessPopup('CheckYourEmail', dispatch);
        }
      })
      .catch((err) => console.log(err));
  };
}

export function createNewPassword(token) {
  return (dispatch, getState) => {
    dispatch(removePopups());

    const resetPasswordFormValues = getState().form.ResetPasswordForm;

    // validate
    if (
      !(
        resetPasswordFormValues.values &&
        resetPasswordFormValues.values.password &&
        resetPasswordFormValues.values.confirm_password
      )
    ) {
      // form incomplete error
      triggerErrorPopup('FillInAllFields', dispatch);
      return;
    }

    if (
      resetPasswordFormValues.values.password !==
        resetPasswordFormValues.values.confirm_password ||
      resetPasswordFormValues.values.password.length < 6
    ) {
      triggerErrorPopup('EnterFieldsProperly', dispatch);
      return;
    }

    const resetPwURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/reset-password`;
    const payload = {
      token,
      user: {
        password: resetPasswordFormValues.values.password,
      },
    };
    request(resetPwURL, null, 'POST', payload)
      .then(() => {
        triggerSuccessPopup('PasswordReset', dispatch);
        dispatch(doRedirect('/'));
        dispatch(doRedirect('/auth/login'));
      })
      .catch((err) => {
        console.log(err);
        triggerInvalidTokenPopup(dispatch);
      });
  };
}
