import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import SEO from 'components/SEO';
import { modalCss } from 'utils/modalCss';
import { processInvitationsAfterSignUp } from 'store/invitations/invitationActions';
import './avatarUpload.scss';
import AvatarUploadForm from 'Forms/AvatarUploadForm/AvatarUploadForm';
import loadingIcon from 'images/loader.gif';
import { withTranslation } from 'react-i18next';

const AvatarUpload = ({ isOpen, closeModal, t }) => {
  const dispatch = useDispatch();

  const isSavingAvatar = useSelector((state) =>
    state.profile.pageMetaData.get('isSavingAvatar')
  );

  const avatar = useSelector((state) => state.profile.avatar);

  const isSignup = useSelector((state) => state.metadata.isSignup);

  const skip = () => {
    if (isSignup) dispatch(processInvitationsAfterSignUp());
    closeModal();
  };

  const renderSaveButton = (image) => {
    if (isSavingAvatar) {
      return <img alt="loading icon" src={loadingIcon} />;
    }
    return (
      <button
        disabled={typeof image === 'undefined' || image?.size > 10485760}
        className="save-btn"
        type="submit"
      >
        {t('save')}
      </button>
    );
  };

  return (
    <SEO>
      <Modal
        isOpen={isOpen}
        style={modalCss}
        contentLabel="Avatar Upload Modal"
        onRequestClose={() => skip()}
        shouldCloseOnOverlayClick
      >
        <button type="button" onClick={() => skip()} className="btn-x">
          x
        </button>
        <AvatarUploadForm
          renderSaveButton={renderSaveButton}
          skip={skip}
          avatar={avatar}
          isSignup={isSignup}
        />
      </Modal>
    </SEO>
  );
};

export default withTranslation(['translation.AvatarUpload'])(AvatarUpload);
