import React from "react";
import {
    Box,
    FormControl, IconButton,
    InputLabel,
    Link as MatLink,
    OutlinedInput,
    Typography, useMediaQuery
} from '@mui/material';
import { Theme } from "@mui/material/styles";
import { RebelbaseLogoIcon } from "assets";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import LanguagePicker from "components/Common/LanguagePicker/LanguagePicker";
import { useHistory } from "react-router-dom";
import { ForgotPasswordFormData } from './ForgotPasswordForm';

const ForgotPassword = ({
        onSubmit,
        formHandleSubmit,
        register,
        errors,
        language
    }: ForgotPasswordFormData) => {

    const history = useHistory();

    const { t } = useTranslation(['translation.ForgotPassword']);

    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const baseUrl = language === 'ESP' ? '/es' : '';

    const authLink = (event: React.SyntheticEvent) => {
        event?.preventDefault();
        history.push('/auth/login');
    };

    const signupLink = (event: React.SyntheticEvent) => {
        event?.preventDefault();
        history.push('/auth/sign-up');
    };

    return (
        <form
            onSubmit={formHandleSubmit(onSubmit)}
            noValidate
        >
            <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{
                    maxHeight: 581,
                    width: { xs: 328, md: 572 },
                    background: (theme) => theme?.palette?.common?.white,
                    borderRadius: 3,
                    padding: (theme: Theme) => theme.spacing(5, (isMobileView ? 2 : 4), 12, (isMobileView ? 2 : 4)),
                    position: 'relative'
                }} display="flex" alignItems="center" flexDirection="column">
                    {
                        isMobileView && (
                            <>
                                <Box sx={{
                                    position: 'absolute',
                                    top: 8,
                                    left: 16
                                }}>
                                    <IconButton onClick={authLink}>
                                        <ArrowBackIosNewIcon sx={{
                                            height: 20,
                                            width: 20,
                                            color: (theme: Theme) => theme?.palette?.primary?.main,
                                            '&:hover': {
                                                color: (theme: Theme) => theme?.palette?.primary?.main
                                            }
                                        }}/>
                                    </IconButton>
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 16
                                }}>
                                    <LanguagePicker withLabel={false} />
                                </Box>
                            </>
                        )
                    }
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        pt: { xs: 8, md: 2 }
                    }}>
                        {
                            !isMobileView && (
                                <Box flex={0}>
                                    <MatLink sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }} onClick={authLink} color="primary">
                                        <IconButton>
                                            <ArrowBackIosNewIcon sx={{
                                                height: 20,
                                                width: 20,
                                                color: (theme: Theme) => theme?.palette?.primary?.main,
                                                '&:hover': {
                                                    color: (theme: Theme) => theme?.palette?.primary?.main
                                                }
                                            }}/>
                                        </IconButton>
                                    </MatLink>
                                </Box>
                            )
                        }
                        <MatLink sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }} flex="1 1 100%" href={`https://rebelbase.co${baseUrl}`} color="primary">
                            <RebelbaseLogoIcon sx={{
                                height: 30,
                                width: 206,
                                color: (theme: Theme) => theme?.palette?.primary?.main,
                                '&:hover': {
                                    color: (theme: Theme) => theme?.palette?.primary?.main
                                }
                            }} />
                        </MatLink>
                        {
                            !isMobileView && (
                                <Box flex={0}>
                                    <LanguagePicker withLabel={false} />
                                </Box>
                            )
                        }
                    </Box>
                    <Typography sx={{
                        pt: 3,
                        pb: 4,
                        typography: { xs: 'h3', md: 'h1'}
                    }} color="secondary">
                        {t('ForgotPassword')}
                    </Typography>

                    <Box
                        sx={{
                            pt: 15,
                            width: { xs: 'auto', md: '100%' }
                        }}
                    >
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel error={Boolean(errors?.email)} htmlFor="email">{t('Email')}</InputLabel>
                            <OutlinedInput
                                id="email"
                                fullWidth
                                {...register("email", {
                                    required: "Required"
                                })}
                                label={t('Email')}
                                error={Boolean(errors?.email)}
                            />
                            {
                                errors?.email && (
                                    <FormHelperText error={Boolean(errors?.email)} id="outlined-email-helper-text">
                                        {errors?.email?.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    </Box>

                    <Box sx={{
                        pt: 6,
                        pb: 4,
                        width: '100%'
                    }} display="flex" alignItems="center" justifyContent="center">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            {t('ResetPassword')}
                        </Button>
                    </Box>

                    <Box sx={{
                        width: '100%'
                    }} display="flex" alignItems="center" justifyContent="center">
                        <MatLink underline="none" color="primary" onClick={authLink} sx={{
                            pl: 1,
                            '& :hover': {
                                color: (theme: Theme) => theme?.palette?.primary?.main
                            }
                        }}>
                            <Typography variant="body1" color="primary">
                                {t('Login')}
                            </Typography>
                        </MatLink>
                        <Typography align='left' variant='body1' color="secondary">
                            {' '}{t('or')}{' '}
                        </Typography>
                        <MatLink underline="none" color="primary" onClick={signupLink} sx={{
                            '& :hover': {
                                color: (theme: Theme) => theme?.palette?.primary?.main
                            }
                        }}>
                            <Typography variant="body1" color="primary">
                                {t('Signup')}
                            </Typography>
                        </MatLink>
                        <Typography align='left' variant='body1' color="secondary">
                            {' '}{t('instead')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

export default React.memo(ForgotPassword);