import React from "react";
import { NavLink } from "react-router-dom";
import { ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

const DesignGuide = () => {

    return (
        <>
            <MenuList sx={{
                '& .MuiMenuItem-root': {
                    minHeight: '40px !important',
                    alignItems: 'center',
                    color: (theme) => theme?.palette?.primary?.light,
                    '&:hover': {
                        backgroundColor: (theme: Theme) =>
                            theme.palette.secondary.light,
                    }
                },
                '& .MuiListItemText-root': {
                    '.MuiTypography-root': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }
            }}>

                <MenuItem
                    component={NavLink}
                    to="/design-guides/buttons"
                    exact
                    sx={{ px: 3 }}>
                    <ListItemText>
                        <Typography variant="h5">
                            Buttons
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    component={NavLink}
                    to="/design-guides/typography"
                    exact
                    sx={{ px: 3 }}>
                    <ListItemText>
                        <Typography variant="h5">
                            Typography
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    component={NavLink}
                    to="/design-guides/custom-form"
                    exact
                    sx={{ px: 3 }}>
                    <ListItemText>
                        <Typography variant="h5">
                            Custom Form
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    component={NavLink}
                    to="/design-guides/custom-spacing"
                    exact
                    sx={{ px: 3 }}>
                    <ListItemText>
                        <Typography variant="h5">
                            Spacing
                        </Typography>
                    </ListItemText>
                </MenuItem>

            </MenuList>
        </>
    );
};

export default React.memo(DesignGuide);