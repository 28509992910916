import React from 'react';
import { CustomLink, Props } from '../Components';

export const HubMemberAddedToGroup: React.FC<Props> = ({ alert, t }) => (
  <>
    <span>{t('youWereAddedToThe')} </span>
    <CustomLink to={`/hubs/${alert.hub?.id}/groups/${alert.hubGroup?.id}`}>
      {alert.hubGroup?.name}
    </CustomLink>
    <span> {t('group')} </span>
  </>
);
