export const userEventRoleTypes = [
  {
    description: null,
    id: 1,
    is_assignable: true,
    label: 'Host',
    level: 100,
    name: 'Host',
  },
  {
    description: null,
    id: 3,
    is_assignable: true,
    label: 'General',
    level: 40,
    name: 'General',
  },
  {
    description: null,
    id: 4,
    is_assignable: true,
    label: 'Judge',
    level: 40,
    name: 'Judge',
  },
  {
    description: null,
    id: 2,
    is_assignable: true,
    label: 'Competitor',
    level: 50,
    name: 'Competitor',
  },
  {
    description: null,
    id: 5,
    is_assignable: true,
    label: 'Sponsor',
    level: 40,
    name: 'Sponsor',
  },
  {
    description: null,
    id: 6,
    is_assignable: true,
    label: 'Resource',
    level: 40,
    name: 'Resource',
  },
];
