import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import emptyProfile from 'images/empty_profile.png';
import loader from 'images/loader.gif';
import { withTranslation } from 'react-i18next';

class ProfileHeader extends React.Component {
  renderSaveButton() {
    const { isSavingAvatar, t } = this.props;
    if (isSavingAvatar) {
      return <img alt="loading icon" src={loader} />;
    }
    return (
      <button className="userProfile__save-btn" type="submit">
        {t('save')}
      </button>
    );
  }

  renderAvatar() {
    const { avatarUrl, open, isUsersProfile, t } = this.props;

    if (!isUsersProfile) {
      return (
        <div className="bioInfo__pic__wrap-public">
          <img src={avatarUrl} alt="avatar" className="bioInfo__pic" />
        </div>
      );
    }

    return (
      <button
        type="button"
        tabIndex="0"
        style={{ border: 'none', padding: '0' }}
        onClick={() => open()}
      >
        <div className="bioInfo__pic__wrap">
          <img src={avatarUrl} alt="avatar" className="bioInfo__pic" />
          <button
            type="button"
            tabIndex="0"
            className="bioInfo__pic__edit"
            onClick={() => open()}
          >
            <span>{t('updatePic')}</span>
          </button>
        </div>
      </button>
    );
  }

  renderProfileData() {
    const { name, locale, purposeLabel } = this.props;
    return (
      <div className="bioInfo__wrap">
        <div className="bioInfo__name">
          <h2>{name}</h2>
        </div>
        <div className="bioInfo__function">
          <h4 className="bioInfo__purpose">{purposeLabel}</h4>
          <span className="bioInfo__city">{locale}</span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section>
        <div style={{ textAlign: 'center' }}>
          {/* this is for the avatar itself */}
          {this.renderAvatar()}
          {/* this is for the profile data (name, location, etc) */}
          {this.renderProfileData()}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state, props) {
  if (!props.isUsersProfile) {
    const { browseProfile } = state.browse;

    const name = `${browseProfile.getIn([
      'user',
      'first_name',
    ])} ${browseProfile.getIn(['user', 'last_name'])}`;
    const avatarUrl = browseProfile.get('avatar') || emptyProfile;
    const locale = browseProfile.get('location_map')
      ? browseProfile.getIn(['location_map', 'descrption'])
      : null;
    const purposeLabel = browseProfile.getIn(['purpose', 'label']);

    return {
      name,
      avatarUrl,
      locale,
      purposeLabel,
    };
  }

  const { avatar, location, purpose, pageMetaData } = state.profile;
  const { user } = state;
  const name = `${user.get('first_name')} ${user.get('last_name')}`;
  const locale = location ? location.get('description') : null;
  const avatarUrl = avatar || emptyProfile;
  const purposeLabel = purpose && purpose.get('label');
  const isUploadingAvatar = pageMetaData.get('isUploadingAvatar');
  const isSavingAvatar = pageMetaData.get('isSavingAvatar');
  const imageURL =
    state.form.NewAvatarImg &&
    state.form.NewAvatarImg.values &&
    state.form.NewAvatarImg.values.picture
      ? state.form.NewAvatarImg.values.picture[0]
      : '';

  return {
    name,
    locale,
    avatarUrl,
    purposeLabel,
    isUploadingAvatar,
    isSavingAvatar,
    imageURL,
  };
}

export default withTranslation(['translation.Profile'])(
  connect(mapStateToProps)(reduxForm({ form: 'NewAvatarImg' })(ProfileHeader))
);
