import React from 'react';
import { LastLocationProvider } from "react-router-last-location";

const EmptyLayout = ({children}) => {
  return (
    <>
        <LastLocationProvider>
            <div className='empty-layout'>
                {children}
            </div>
        </LastLocationProvider>
    </>
  )
};

export default EmptyLayout;
