// returns sorted array by first pushing current user then comparing first name then last name

export const sortEventParticipants = (attendees, userId) => {
  const attendeesList = [];

  attendees.map((attendee) => {
    if (attendee.user_id === userId || attendee.id === userId) {
      attendeesList.push(attendee);
    }
    return false;
  });

  attendees
    .filter(
      (attendee) => !(attendee.user_id === userId || attendee.id === userId)
    )
    .sort((a, b) => {
      const firstName1 = a.first_name.toLowerCase();
      const firstName2 = b.first_name.toLowerCase();

      if (firstName1 !== firstName2) {
        return firstName1 < firstName2 ? -1 : 1;
      }

      const lastName1 = a.last_name.toLowerCase();
      const lastName2 = b.last_name.toLowerCase();

      if (lastName1 !== lastName2) {
        return lastName1 < lastName2 ? -1 : 1;
      }
      return false;
    })
    .map((ele) => attendeesList.push(ele));
  return attendeesList;
};

// return sorted array by first pushing user's project then comparing by project's name

export const sortProjects = (projects, userId) => {
  const projectsList = [];

  projects.map((project) => {
    if (project.user_ids.includes(userId)) {
      projectsList.push(project);
    }
    return false;
  });

  projects
    .filter((project) => !project.user_ids.includes(userId))
    .sort((a, b) => {
      const project1 = a.name.toLowerCase();
      const project2 = b.name.toLowerCase();

      if (project1 !== project2) {
        return project1 < project2 ? -1 : 1;
      }
      return false;
    })
    .map((ele) => projectsList.push(ele));
  return projectsList;
};

// returns sorted array by comparing 2 strings

export const sortByName = (participants) => {
  return participants.sort((a, b) => {
    const firstName1 = a.name.toLowerCase();
    const firstName2 = b.name.toLowerCase();

    if (firstName1 !== firstName2) {
      return firstName1 < firstName2 ? -1 : 1;
    }
    return false;
  });
};

// returns sorted array by first comparing with role type id then first name then last

export const sortByRoleIdfirstNamelastName = (member) => {
  return member.sort((a, b) => {
    if (a.role_type_id !== b.role_type_id) {
      return a.role_type_id < b.role_type_id ? -1 : 1;
    }

    const firstName1 = a.first_name.toLowerCase();
    const firstName2 = b.first_name.toLowerCase();

    if (firstName1 !== firstName2) {
      return firstName1 < firstName2 ? -1 : 1;
    }

    const lastName1 = a.last_name.toLowerCase();
    const lastName2 = b.last_name.toLowerCase();

    if (lastName1 !== lastName2) {
      return lastName1 < lastName2 ? -1 : 1;
    }

    return '';
  });
};

const isDate = (date) => {
  return !Number.isNaN(Date.parse(date));
};

export const sortObjArrByDate = (objArr, keyName, descending = false) => {
  if (!objArr.length) return objArr;
  if (!isDate(objArr[0][keyName])) return objArr;
  const multiplier = descending ? -1 : 1;
  return objArr.sort(
    (a, b) => (new Date(a[keyName]) - new Date(b[keyName])) * multiplier
  );
};
