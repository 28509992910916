import i18n from '../../i18n';
import { errorPopup } from './errorPopup';

export const triggerErrorPopup = (subject, dispatch) => {
  dispatch(
    errorPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification'][
          subject
        ]
      }`
    )
  );
};
