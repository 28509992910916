import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, useTheme } from "@mui/material";

const FollowUs = () => {

    const theme = useTheme();

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            pt: { xs: 7, md: 10 }
        }}>
            <Typography variant="h2" fontWeight="bold" textTransform="uppercase" sx={{
                color: theme?.palette?.common?.white,
                display: "flex",
                alignItems: "center"
            }}>
                Follow Us
            </Typography>
            <Stack spacing={6} direction="row" sx={{
                mt: 3
            }}>
                <Box
                    sx={{
                        background: 'transparent',
                        color: `${theme?.palette?.common?.white} !important`
                    }}
                    component="a"
                    href="https://www.linkedin.com/company/rebelbase/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <LinkedInIcon />
                </Box>
                <Box
                    sx={{
                        background: 'transparent',
                        color: `${theme?.palette?.common?.white} !important`
                    }}
                    component="a"
                    href="https://twitter.com/RebelBaseco"
                    target="_blank"
                    rel="noopener noreferrer">
                    <TwitterIcon />
                </Box>
                <Box
                    sx={{
                        background: 'transparent',
                        color: `${theme?.palette?.common?.white} !important`
                    }}
                    component="a"
                    href="https://www.youtube.com/channel/UCpnsRrLWQExnFkOOPsTWemQ"
                    target="_blank"
                    rel="noopener noreferrer">
                    <YouTubeIcon />
                </Box>
            </Stack>
        </Box>
    )
};

export default FollowUs;