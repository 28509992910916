import ReactGA from 'react-ga4';
import { changeProject } from 'store/hubGroups/actions';
import { RESET_PROJECT_CREATED_FROM_HUB_GROUP } from 'store/events-v1/eventConstants';
import { requiredFieldPopup } from 'functions/popup/requiredFieldPopup';
import { doRedirect } from 'store/metadata/metadataActions';
import { request } from '../../functions';
import { fetchProjects } from '../project/projectActions';
import { fetchChallengeData } from '../projectProfile-v1/projectProfileActions';
import { SET_FOCUSED_PROJECT } from '../sideNav/sideNavActions';
import { fetchProjectData } from '../projectData/projectDataActions';
import { addNewUserProject, getAuthUser } from '../auth/authActions';

export const PROJECT_CREATED = 'createProject/PROJECT_CREATED';
export const RESET_PROJECT_CREATE_STATE =
  'createProject/RESET_PROJECT_CREATE_STATE';
export const SET_PROCESSING_PROJECT_CREATE =
  'createProject/SET_PROCESSING_PROJECT_CREATE';
export const RESET_PROCESSING_PROJECT_CREATE =
  'createProject/RESET_PROCESSING_PROJECT_CREATE';

export function createNewProject(triggerProjectNamePopup) {
  return (dispatch, getState) => {
    const { user, form, eventsv1 } = getState();
    const jwt = user.get('jwt');
    const projectForm = form.CreateProjectForm.values
      ? form.CreateProjectForm.values
      : null;

    // validate
    if (
      !projectForm ||
      !projectForm.industry_id ||
      projectForm.industry_id === 'Select industry' ||
      !projectForm.location_map ||
      !projectForm.name
    ) {
      requiredFieldPopup(dispatch);
      dispatch({ type: RESET_PROCESSING_PROJECT_CREATE });
      return;
    }
    let data = {
      industry_id: form.CreateProjectForm.values.industry_id,
      location_map: form.CreateProjectForm.values.location_map,
      name: form.CreateProjectForm.values.name,
    };
    const { isProjectCreatedFromHubGroup } = eventsv1;
    if (isProjectCreatedFromHubGroup)
      data = {
        ...data,
        hub_group_id: isProjectCreatedFromHubGroup.groupId,
      };
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/projects`;
    const payload = { project: { ...data } };
    request(url, jwt, 'POST', payload)
      .then((response) => {
        ReactGA.event({
          category: 'Projects',
          action: 'Create a Project',
        });
        if (isProjectCreatedFromHubGroup) {
          const changeData = {
            group_id: isProjectCreatedFromHubGroup.groupId,
            project_id: response.data.id,
          };
          dispatch(changeProject(changeData));
          dispatch({ type: RESET_PROJECT_CREATED_FROM_HUB_GROUP });
        }
        dispatch(getAuthUser(user.get('id')));
        dispatch(fetchProjects());
        dispatch(fetchChallengeData());
        dispatch(fetchProjectData());
        dispatch({
          type: SET_FOCUSED_PROJECT,
          projectId: response.data.id,
        });
        dispatch({
          type: PROJECT_CREATED,
          projectId: response.data.id,
        });
        if (isProjectCreatedFromHubGroup) {
          dispatch(
            doRedirect(
              `/hubs/${isProjectCreatedFromHubGroup.hubId}/groups/${isProjectCreatedFromHubGroup.groupId}`
            )
          );
        }
        dispatch(addNewUserProject(response.data));
      })
      .catch((err) => {
        triggerProjectNamePopup();
        dispatch({ type: RESET_PROCESSING_PROJECT_CREATE });
        console.error(err);
      });
  };
}

export function resetCreateProjectState() {
  return {
    type: RESET_PROJECT_CREATE_STATE,
  };
}
