import { invalidEmailPopup } from 'functions/popup/invalidEmailPopup';
import { emailAlreadyAssociatedPopup } from 'functions/popup/emailAlreadyAssociatedPopup';
import { alternativeEmailAddedPopup } from 'functions/popup/alternativeEmailAddedPopup';
import { request, validateEmail } from '../../functions';
import { RESET_ADDING_EMAIL } from '../settings-v1/settingConstants';
import { isEmailVerified } from '../emailVerification/emailVerificationActions';

export const SET_USER_EMAIL_ADDRESSES =
  'userEmailAddresses/SET_USER_EMAIL_ADDRESSES';

export const setUserEmailAddressAtSignUp = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_EMAIL_ADDRESSES, data });
  };
};

export const setUserEmailAddress = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_EMAIL_ADDRESSES, data });
    dispatch(isEmailVerified());
  };
};

export function fetchUserEmailAddresses() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/user-email-addresses`;

    return request(url, jwt).then((response) => {
      return dispatch(setUserEmailAddress(response.data));
    });
  };
}

export const DELETE_USER_EMAIL_ADDRESS =
  'userEmailAddresses/DELETE_USER_EMAIL_ADDRESSES';

export function deleteUserEmailAddress(emailAddressId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/user-email-addresses/${emailAddressId}`;

    return request(url, jwt, 'DELETE').then(() => {
      dispatch({ type: DELETE_USER_EMAIL_ADDRESS, emailAddressId });
    });
  };
}

export const UPDATE_PRIMARY_USER_EMAIL_ADDRESS =
  'userEmailAddresses/UPDATE_USER_EMAIL_ADDRESS';

export function setUserEmailAddressAsPrimary(emailAddressId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/user-email-addresses/${emailAddressId}`;

    return request(url, jwt, 'PUT').then(() => {
      dispatch({ type: UPDATE_PRIMARY_USER_EMAIL_ADDRESS, emailAddressId });
    });
  };
}

export const ADD_USER_EMAIL_ADDRESS =
  'userEmailAddresses/ADD_USER_EMAIL_ADDRESS';

export function addUserEmailAddress(email_address) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user } = getState();

    // validate
    if (!email_address || !validateEmail(email_address)) {
      // no email has been entered or invalid email entered
      invalidEmailPopup(dispatch);
      return;
    }

    const payload = {
      user_email_address: {
        email_address,
      },
    };

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/accounts/user-email-addresses`;

    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: ADD_USER_EMAIL_ADDRESS, data: response.data });
        dispatch({ type: RESET_ADDING_EMAIL });
        alternativeEmailAddedPopup(dispatch);
      })
      .catch((er) => {
        if (er.status === 422) {
          emailAlreadyAssociatedPopup(dispatch);
        }
        console.error(er);
      });
  };
}
