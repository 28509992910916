import {Set, fromJS, Map} from 'immutable';
import { combineReducers } from 'redux';
import {
  SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECTS,
  SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECT_SCORES,
  SET_HUB_EVENT_SCORING_ROUND_PROJECT_SCORE, SET_USER_UPDATE_SCORES, UNSET_USER_UPDATE_SCORES,
} from './eventActions';

function hubEventScoringRoundProjects(state = Set(), action) {
  switch (action.type) {
    case SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECTS:
      return Set().concat(fromJS(action.data));
    default:
      return state;
  }
}

const intialScores = Map(
    fromJS({
      isScoresUpdating: false,
      scores: false
    })
);

function hubEventScoringRoundProjectScores(state = intialScores, action) {
  switch (action.type) {
    case SET_ALL_HUB_EVENT_SCORING_ROUND_PROJECT_SCORES:
      return state?.set('scores', Set().concat(fromJS(action.data)));
    case SET_HUB_EVENT_SCORING_ROUND_PROJECT_SCORE:
      return state?.set('scores', state.get('scores')
          .filterNot((score) => score.get('id') === action.data.id)
          .add(fromJS(action.data)));
    case SET_USER_UPDATE_SCORES:
      return state?.set('isScoresUpdating', true);
    case UNSET_USER_UPDATE_SCORES:
      return state?.set('isScoresUpdating', false);
    default:
      return state;
  }
}

export default combineReducers({
  hubEventScoringRoundProjectScores,
  hubEventScoringRoundProjects,
});
