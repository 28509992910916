import i18n from '../../i18n';
import { errorPopup } from './errorPopup';

export const enterEventLocationPopup = (dispatch) => {
  dispatch(
    errorPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .EnterEventLocation
      }`
    )
  );
};
