// NOTE: The contents of this file will only be executed if
// you uncomment its entry in "web/static/js/app.js".

// To use Phoenix channels, the first step is to import Socket
// and connect at the socket path in "lib/my_app/endpoint.ex":
import { Socket } from 'phoenix';

export class WebSocketConnectClass {
  static REBEL_EVENT_ROOM_LOBBY = 'rebel_event_room:lobby';

  static REBEL_USER_LOBBY = 'rebel:user:lobby';

  static PROJECT_PROFILE_ROOM = 'project_profile_room';

  static REBEL_PROJECT_ROOM_LOBBY = 'rebel_project_room:lobby';

  static REBEL_HUB_ROOM_LOBBY = 'rebel_hub_room:lobby';

  constructor(endpoint) {
    this.endpoint = endpoint;
    this.channelsChache = {};
  }

  connect(jwt) {
    this.currentJWT = jwt;
    this.socket = new Socket(this.endpoint, {
      params: {
        token: jwt,
      },
    });

    this.socket.connect();
    return this.socket;
  }

  close() {
    this.socket.disconnect();
    this.channelsChache = {};
  }

  channel(jwt, channelName) {
    if (!this.socket) {
      this.connect(jwt);
    } else if (this.currentJWT !== jwt) {
      this.close();
      this.connect(jwt);
    }

    let connect = this.channelsChache[channelName];
    if (!connect) {
      connect = this.socket.channel(channelName);
    }

    return connect;
  }

  leaveChannel(channelName) {
    const connect = this.channelsChache[channelName];
    if (connect) {
      connect.leave();
      delete this.channelsChache[channelName];
    }
  }

  static makeChannelName(name, id) {
    let channelName = name;
    if (id) {
      channelName += `:${id}`;
    }

    return channelName;
  }
}

export const WebSocketConnect = new WebSocketConnectClass(
  (window.env || process.env).REACT_APP_WS_ENDPOINT
);

// When you connect, you'll often need to authenticate the client.
// For example, imagine you have an authentication plug, `MyAuth`,
// which authenticates the session and assigns a `:current_user`.
// If the current user exists you can assign the user's token in
// the connection for use in the layout.
//
// In your "web/router.ex":
//
//     pipeline :browser do
//       ...
//       plug MyAuth
//       plug :put_user_token
//     end
//
//     defp put_user_token(conn, _) do
//       if current_user = conn.assigns[:current_user] do
//         token = Phoenix.Token.sign(conn, "user socket", current_user.id)
//         assign(conn, :user_token, token)
//       else
//         conn
//       end
//     end
//
// Now you need to pass this token to JavaScript. You can do so
// inside a script tag in "web/templates/layout/app.html.eex":
//
//     <script>window.userToken = "<%= assigns[:user_token] %>";</script>
//
// You will need to verify the user token in the "connect/2" function
// in "web/channels/user_socket.ex":
//
//     def connect(%{"token" => token}, socket) do
//       # max_age: 1209600 is equivalent to two weeks in seconds
//       case Phoenix.Token.verify(socket, "user socket", token, max_age: 1209600) do
//         {:ok, user_id} ->
//           {:ok, assign(socket, :user, user_id)}
//         {:error, reason} ->
//           :error
//       end
//     end
//
// Finally, pass the token on connect as below. Or remove it
// from connect if you don't care about authentication.

// socket.connect();

// Now that you are connected, you can join channels with a topic:
// const channel = socket.channel('topic:subtopic', {});
// channel.join()
// .receive('ok', (resp) => { console.log('Joined successfully', resp); });
// .receive('error', (resp) => { console.log('Unable to join', resp); });
