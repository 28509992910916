import { combineReducers } from 'redux';
import { Map, fromJS } from 'immutable';

import { PROJECT_CREATED, RESET_PROJECT_CREATE_STATE, SET_PROCESSING_PROJECT_CREATE, RESET_PROCESSING_PROJECT_CREATE } from './createProjectActions';

const initMetadata = Map(fromJS({
  projectCreated: false,
  newProjectId: null,
  processingProjectCreate: false,
}));
export function metadata(state = initMetadata, action) {
  switch (action.type) {
    case PROJECT_CREATED: {
      return state.set('projectCreated', true)
        .set('newProjectId', action.projectId)
        .set('processingProjectCreate', false);
    }
    case RESET_PROJECT_CREATE_STATE: {
      return state.set('projectCreated', false)
        .set('newProjectId', null);
    }
    case SET_PROCESSING_PROJECT_CREATE:
      return state.set('processingProjectCreate', true);
    case RESET_PROCESSING_PROJECT_CREATE:
      return state.set('processingProjectCreate', false);
    default:
      return state;
  }
}

export default combineReducers({
  metadata,
});
