/* eslint-disable no-console */
import { request } from '../../functions';

// /* ***********
// BROWSER A USER
// *********** */

export const SET_BROWSE_PROFILE_DATA = 'browse/SET_BROWSE_PROFILE_DATA';
export const RESET_BROWSE_PROFILE_DATA = 'browse/RESET_BROWSE_PROFILE_DATA';
export const SET_BROWSE_PROFILE_LOADED = 'browse/SET_BROWSE_PROFILE_LOADED';

export function fetchUserProfile(userId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');

    if (jwt) {
      const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/user-profile/rebel-profiles/${userId}`;

      return request(url, jwt)
        .then((response) => {
          dispatch({ type: SET_BROWSE_PROFILE_DATA, data: response.data });
          dispatch({ type: SET_BROWSE_PROFILE_LOADED });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SET_BROWSE_PROFILE_LOADED });
        });
    }

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/user-profile/rebel-profiles/${userId}`;

    return request(url)
      .then((response) => {
        dispatch({ type: SET_BROWSE_PROFILE_DATA, data: response.data });
        dispatch({ type: SET_BROWSE_PROFILE_LOADED });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SET_BROWSE_PROFILE_LOADED });
      });
  };
}

/* *************
BROWSER AN EVENT
************* */
export const SET_BROWSE_EVENT_DATA = 'browse/SET_BROWSE_EVENT_DATA';
export const SET_BROWSE_EVENT_NOT_FOUND = 'browse/SET_BROWSE_EVENT_NOT_FOUND';
export function fetchHubEvent(eventId) {
  return (dispatch) => {
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/events/hub-events/${eventId}`;

    return request(url)
      .then((response) => {
        dispatch({ type: SET_BROWSE_EVENT_DATA, data: response.data });
      })
      .catch((err) => {
        if (err.status === 404) {
          dispatch({ type: SET_BROWSE_EVENT_NOT_FOUND });
        } else {
          console.log(err);
        }
      });
  };
}
