import { List, fromJS } from 'immutable';
import { SET_PROJECTS } from './projectActions';

// list of projects the user is part of
const initProjects = List();
export default function project(state = initProjects, action) {
  switch (action.type) {
    case SET_PROJECTS:
      return List(fromJS(action.data)); // this is bad, creates a new list
    default:
      return state;
  }
}
