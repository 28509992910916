import {
  SET_HUB_ACTIVITY_LIKE,
  DELETE_HUB_ACTIVITY,
  DELETE_HUB_ACTIVITY_LIKE,
  // SET_HUB_ACTIVITY_REPLY_LIKE,
  // DELETE_HUB_ACTIVITY_REPLY,
  // DELETE_HUB_ACTIVITY_REPLY_LIKE,
  APPEND_ACTIVITY_REPLY,
} from 'store/hubActivity-v1/hubActivityConstants';
import { WebSocketConnect, WebSocketConnectClass } from '../../socket';
import {
  SET_PROJECT_INVITATION_NOTIFICATIONS,
  SET_PROJECT_INVITATION_NOTIFICATION,
  SET_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_ALL_NOTIFICATIONS_READ,
  SET_HUB_EVENT_INVITATION_NOTIFICATION,
  SET_HUB_EVENT_INVITATION_NOTIFICATIONS,
  SET_HUB_INVITATION_NOTIFICATION,
  SET_HUB_INVITATION_NOTIFICATIONS,
  UPDATE_HUB_EVENT_INVITATION_NOTIFICATION,
  UPDATE_HUB_INVITATION_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_PROJECT_INVITATION_NOTIFICATION,
} from '../notifications/notificationActions';
import {
  fetchActiveProjectInvitations,
  fetchHubInvitations,
  fetchHubEventInvitations,
} from '../invitations/invitationActions';
import {
  // SET_HUB_ACTIVITY_ANNOUNCEMENT,
  // SET_HUB_ACTIVITY,
  // SET_HUB_ACTIVITY_POST,
  // SET_HUB_ACTIVITY_OFFER,
  // SET_HUB_ACTIVITY_IDEA,
  // SET_HUB_ACTIVITY_QUESTION,
  REMOVE_USER_HUB_CARD,
  SET_USER_HUB_CARD,
  SET_HUB_MEMBER,
  // SET_HUB_ACTIVITY_REPLY,
  fetchHubMembers,
  fetchUserHubCards,
} from '../hubs/hubActions';

// REBEL USER CHANNEL
export const SET_REBEL_USER_CHANNEL = 'channels/SET_REBEL_USER_CHANNEL';

export function initializeRebelUserChannel() {
  return (dispatch, getState) => {
    const { user } = getState();
    const channelName = WebSocketConnectClass.makeChannelName(
      WebSocketConnectClass.REBEL_USER_LOBBY,
      user.get('id')
    );
    const channel = WebSocketConnect.channel(user.get('jwt'), channelName);

    // init channel and save channel to store
    channel
      .join()
      .receive('ok', () =>
        dispatch({ type: SET_REBEL_USER_CHANNEL, data: channel })
      )
      .receive('error', (error) => console.log('error', error));

    // fetch
    channel.push('fetch:notifications', {}).receive('ok', (payload) => {
      dispatch({ type: SET_NOTIFICATIONS, data: payload.data });
    });

    channel
      .push('fetch:project_invitation_notifications', {})
      .receive('ok', (payload) => {
        dispatch({
          type: SET_PROJECT_INVITATION_NOTIFICATIONS,
          data: payload.data,
        });
      });

    channel
      .push('fetch:hub_event_invitation_notifications', {})
      .receive('ok', (payload) => {
        dispatch({
          type: SET_HUB_EVENT_INVITATION_NOTIFICATIONS,
          data: payload.data,
        });
      });

    channel
      .push('fetch:hub_invitation_notifications', {})
      .receive('ok', (payload) => {
        dispatch({
          type: SET_HUB_INVITATION_NOTIFICATIONS,
          data: payload.data,
        });
      });

    // on create
    channel.on('create:project_invitation_notification', (payload) => {
      dispatch(fetchActiveProjectInvitations());
      dispatch({ type: SET_NOTIFICATION, data: payload.notification.data });
      dispatch({
        type: SET_PROJECT_INVITATION_NOTIFICATION,
        data: payload.project_invitation_notification.data,
      });
    });

    channel.on('create:hub_event_invitation_notification', (payload) => {
      dispatch(fetchHubEventInvitations());
      dispatch({ type: SET_NOTIFICATION, data: payload.notification.data });
      dispatch({
        type: SET_HUB_EVENT_INVITATION_NOTIFICATION,
        data: payload.hub_event_invitation_notification.data,
      });
    });

    channel.on('create:hub_invitation_notification', (payload) => {
      dispatch(fetchHubInvitations());
      dispatch({ type: SET_NOTIFICATION, data: payload.notification.data });
      dispatch({
        type: SET_HUB_INVITATION_NOTIFICATION,
        data: payload.hub_invitation_notification.data,
      });
    });

    //on update
    channel.on('update:project_invitation_notification', (payload) => {
      dispatch(fetchActiveProjectInvitations());
      dispatch({ type: UPDATE_NOTIFICATION, data: payload.notification.data });
      dispatch({
        type: UPDATE_PROJECT_INVITATION_NOTIFICATION,
        data: payload.project_invitation_notification.data,
      });
    });

    channel.on('update:hub_event_invitation_notification', (payload) => {
      dispatch(fetchHubEventInvitations());
      dispatch({ type: UPDATE_NOTIFICATION, data: payload.notification.data });
      dispatch({
        type: UPDATE_HUB_EVENT_INVITATION_NOTIFICATION,
        data: payload.hub_event_invitation_notification.data,
      });
    });

    channel.on('update:hub_invitation_notification', (payload) => {
      dispatch(fetchHubInvitations());
      dispatch({ type: UPDATE_NOTIFICATION, data: payload.notification.data });
      dispatch({
        type: UPDATE_HUB_INVITATION_NOTIFICATION,
        data: payload.hub_invitation_notification.data,
      });
    });
  };
}

export function markAllNotificationsRead() {
  return (dispatch, getState) => {
    const { channels } = getState();

    if (channels.rebelChannel) {
      // read all notifications
      channels.rebelChannel.push('set_read:notifications', {});
      dispatch({ type: SET_ALL_NOTIFICATIONS_READ });
    }
  };
}

// HUBS
export const SET_HUB_CHANNEL = 'channels/SET_HUB_CHANNEL';

export function joinHubChannel(hubId) {
  return (dispatch, getState) => {
    const { user, channels } = getState();

    // check if already joined channel

    const hubChannel = channels.hubChannels.get(hubId);

    if (hubChannel) {
      return;
    }

    const channelName = WebSocketConnectClass.makeChannelName(
      WebSocketConnectClass.REBEL_HUB_ROOM_LOBBY,
      hubId
    );
    const channel = WebSocketConnect.channel(user.get('jwt'), channelName);

    channel
      .join()
      .receive('ok', () => dispatch({ type: SET_HUB_CHANNEL, hubId, channel }))
      .receive('error', (error) => console.log('error', error));

    // user just joined hub - improve this
    channel.on('new_hub_member', () => {
      dispatch(fetchHubMembers());
      dispatch(fetchUserHubCards());
    });

    // hub role added or updated
    channel.on('user_hub_role_upserted', (payload) => {
      dispatch({ type: SET_HUB_MEMBER, data: payload.data });
    });

    // user hub card created or updated
    channel.on('user_hub_card_upserted', (payload) => {
      dispatch({ type: SET_USER_HUB_CARD, data: payload.data });
    });

    // user hub card deactivated
    channel.on('user_hub_card_deactivated', (payload) => {
      dispatch({
        type: REMOVE_USER_HUB_CARD,
        user_hub_card_id: payload.user_hub_card_id,
      });
    });

    // hub activity deleted
    channel.on('hub_activity_deleted', (payload) => {
      dispatch({
        type: DELETE_HUB_ACTIVITY,
        hubActivityId: payload.hub_activity_id,
      });
    });

    // // hub activity announcement created or updated
    // channel.on('hub_activity_announcement_upserted', (payload) => {
    //   dispatch({ type: SET_HUB_ACTIVITY, data: payload.hub_activity.data });
    //   dispatch({
    //     type: SET_HUB_ACTIVITY_ANNOUNCEMENT,
    //     data: payload.hub_activity_announcement.data,
    //   });
    // });

    // // hub activity post created or updated
    // channel.on('hub_activity_post_upserted', (payload) => {
    //   dispatch({ type: SET_HUB_ACTIVITY, data: payload.hub_activity.data });
    //   dispatch({
    //     type: SET_HUB_ACTIVITY_POST,
    //     data: payload.hub_activity_post.data,
    //   });
    // });

    // // hub activity offer created or updated
    // channel.on('hub_activity_offer_upserted', (payload) => {
    //   dispatch({ type: SET_HUB_ACTIVITY, data: payload.hub_activity.data });
    //   dispatch({
    //     type: SET_HUB_ACTIVITY_OFFER,
    //     data: payload.hub_activity_offer.data,
    //   });
    // });

    // // hub activity question created or updated
    // channel.on('hub_activity_question_upserted', (payload) => {
    //   dispatch({ type: SET_HUB_ACTIVITY, data: payload.hub_activity.data });
    //   dispatch({
    //     type: SET_HUB_ACTIVITY_QUESTION,
    //     data: payload.hub_activity_question.data,
    //   });
    // });

    // // hub activity idea created or updated
    // channel.on('hub_activity_idea_upserted', (payload) => {
    //   dispatch({ type: SET_HUB_ACTIVITY, data: payload.hub_activity.data });
    //   dispatch({
    //     type: SET_HUB_ACTIVITY_IDEA,
    //     data: payload.hub_activity_idea.data,
    //   });
    // });

    // hub activity like
    channel.on('hub_activity_liked', (payload) => {
      dispatch({ type: SET_HUB_ACTIVITY_LIKE, payload: payload.data });
    });

    // hub activity unlike
    channel.on('hub_activity_unliked', (payload) => {
      dispatch({ type: DELETE_HUB_ACTIVITY_LIKE, data: payload.data });
    });

    // hub activity reply created or updated
    channel.on('hub_activity_reply', (payload) => {
      // dispatch({ type: SET_HUB_ACTIVITY_REPLY, data: payload.data });
      dispatch({ type: APPEND_ACTIVITY_REPLY, data: payload.data });
    });

    // hub activity reply deleted
    // channel.on('hub_activity_reply_deleted', (payload) => {
    //   dispatch({
    //     type: DELETE_HUB_ACTIVITY_REPLY,
    //     data: payload,
    //   });
    // });

    // hub activity reply like
    // channel.on('hub_activity_liked', (payload) => {
    //   dispatch({ type: SET_HUB_ACTIVITY_REPLY_LIKE, data: payload.data });
    // });

    // hub activity reply unlike
    // channel.on('hub_activity_unliked', (payload) => {
    //   dispatch({ type: DELETE_HUB_ACTIVITY_REPLY_LIKE, data: payload.data });
    // });
  };
}

export function joinHubChannels() {
  return (dispatch, getState) => {
    const { hubs } = getState();

    hubs.userHubs
      // .filter(event => moment.utc(event.get('end_date')).local().isAfter(moment()))
      .forEach((hub) => dispatch(joinHubChannel(hub.get('id'))));
  };
}
