import { SET_PROFILE_DATA_LOADED } from 'store/metadata/metadataActions';
import { postImage } from 'functions';
import { profileImageUploadedPopup } from 'functions/popup/profileImageUploadedPopup';
import { imageFormatNotSupportedPopup } from 'functions/popup/imageFormatNotSupportedPopup';
import {
  SET_AVATAR,
  OPEN_AVATAR_UPLOAD,
  CLOSE_AVATAR_UPLOAD,
  SAVED_AVATAR,
  SAVE_AVATAR,
} from './avatarConstants';

export const setAvatar = (data, userId = null) => ({
  type: SET_AVATAR,
  data,
  userId,
});

export const openAvatarUpload = () => ({
  type: OPEN_AVATAR_UPLOAD,
});

export const closeAvatarUpload = () => ({
  type: CLOSE_AVATAR_UPLOAD,
});

export const savingAvatar = (data, cb = () => {}) => {
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch(setAvatar(data.avatar_url, user.get('id')));
    dispatch({
      type: SET_PROFILE_DATA_LOADED,
    });
    dispatch({
      type: CLOSE_AVATAR_UPLOAD,
    });
    cb();
    profileImageUploadedPopup(dispatch);
    dispatch({
      type: SAVED_AVATAR,
    });
  };
};

export const saveAvatar = (formData, cb = () => {}) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/user-profile/rebel-profiles`;

    dispatch({ type: SAVE_AVATAR });
    return postImage(url, jwt, 'POST', formData).then((res) => {
      if (res.status === 500) {
        dispatch({
          type: SAVED_AVATAR,
        });
        return imageFormatNotSupportedPopup(dispatch);
      }
      return res.json().then((respone) => {
        dispatch(savingAvatar(respone.data, cb));
      });
    });
  };
};

export function uploadAvatar(formData, cb = () => {}) {
  return (dispatch) => {
    dispatch(saveAvatar(formData, cb));
  };
}
