import React, { Component } from 'react';
import SEO from 'components/SEO';
import './researchData_page.scss';

class ResearchData extends Component {
  componentDidMount() {}

  render() {
    return (
      <SEO>
        <iframe
          title="Research Data"
          id="research-data-frame"
          src="https://docs.google.com/document/d/e/2PACX-1vQ49WRxGtZ48HlUbs9mGUCrdsxmRosHHrFMx8rLvGJVub_FIoLxw1Kr5Zi4P23kBl-i2Ud1n26IItsB/pub?embedded=true"
        />
      </SEO>
    );
  }
}

export default ResearchData;
