import React, {useMemo, useState} from 'react';
import LanguagePicker from 'components/Common/LanguagePicker/LanguagePicker';
import {withTranslation} from 'react-i18next';
import './publicHeader.scss';
import { Box, Divider, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { RebelbaseLogoIcon } from 'assets';
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";
import NavLink from "../sidebar/NavLink/NavLink";

// eslint-disable-next-line no-unused-vars
const PublicHeader = ({currentLanguage, closeNavBar, t}) => {

    const theme = useTheme();

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const externalLinks = useMemo(() => {
        const languagePath = currentLanguage === 'Español' ? 'es/' : '';
        return {
            homepage: `https://rebelbase.co/${languagePath}`,
            forInnovators: `https://rebelbase.co/${languagePath}for-innovators/`,
            contactSales: `https://rebelbase.co/${languagePath}contact-us/`,
            forEducators: `https://rebelbase.co/${languagePath}for-educators/`,
            about: `https://rebelbase.co/${languagePath}about/`,
            blog: `https://rebelbase.co/${languagePath}blog/`,
        };
    }, [currentLanguage]);

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem
                component={NavLink}
                to="/auth/sign-up"
                sx={{
                    width: '100%',
                    '&:focus': {
                        border: 'none !important'
                    }
                }}>
                Sign up
            </MenuItem>
            <MenuItem
                component={NavLink}
                to="/auth/login"
                sx={{
                    width: '100%',
                    '&:focus': {
                        border: 'none !important'
                    }
                }}>
                Log In
            </MenuItem>
            <MenuItem
                href={externalLinks?.forInnovators}
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                sx={{width: '100%'}}>
                Case Studies
            </MenuItem>
            <MenuItem
                to="/browse"
                component={NavLink}
                sx={{width: '100%'}}>
                {t('Browse')}
            </MenuItem>
            <MenuItem
                href={externalLinks?.about}
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                sx={{width: '100%'}}>
                {t('about')}
            </MenuItem>
            <MenuItem
                href={externalLinks?.blog}
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                sx={{width: '100%'}}>
                {t('blog')}
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: '100%'
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: 56,
                    alignItems: "center",
                    px: { xs: 0, md: 8 }
                }}>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <LanguagePicker withLabel/>
                    </Box>

                    <RebelbaseLogoIcon sx={{
                        flexGrow: 1,
                        color: theme?.palette?.info?.main,
                        width: 180,
                        height: 28
                    }}/>

                    <Button
                        href="https://rebelbase.co/contact-us/"
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            display: {xs: 'flex', md: 'none'},
                            minWidth: 100,
                            maxHeight: 44,
                            ml: 'auto',
                            color: `${theme?.palette?.common?.black} !important`,
                            borderRadius: 3,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        color="warning"
                        variant="contained"
                        size="small"
                    >
                        {t('contactSales')}
                    </Button>

                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="secondary"
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>

                    <Box sx={{
                        display: {xs: "none", md: "flex"},
                        flexDirection: 'row'
                    }}>
                        <Box
                            component={NavLink}
                            to="/auth/sign-up"
                            sx={{
                                minWidth: 120,
                                maxHeight: 56,
                                color: `${theme?.palette?.secondary?.main} !important`,
                                textAlign: 'center',
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}
                            color="secondary"
                            variant="text"
                            size="small"
                        >
                            Sign up
                        </Box>
                        <Box
                            component={NavLink}
                            to="/auth/login"
                            sx={{
                                minWidth: 120,
                                maxHeight: 56,
                                color: `${theme?.palette?.secondary?.main} !important`,
                                textAlign: 'center',
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}
                            color="secondary"
                            variant="text"
                            size="small"
                        >
                            Log In
                        </Box>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={{
                    display: {xs: "none", md: "flex"},
                    flexDirection: "row",
                    height: 80,
                    alignItems: "center",
                    px: { xs: 2, md: 8 }
                }}>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Box
                            href={externalLinks?.forInnovators}
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                minWidth: 120,
                                maxHeight: 56,
                                color: `${theme?.palette?.secondary?.main} !important`,
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}>
                            Case Studies
                        </Box>
                        <Box
                            to="/browse"
                            component={NavLink}
                            sx={{
                                minWidth: 120,
                                maxHeight: 56,
                                color: `${theme?.palette?.secondary?.main} !important`,
                                pl: 4,
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}>
                            {t('Browse')}
                        </Box>
                        <Box
                            href={externalLinks?.about}
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                minWidth: 120,
                                maxHeight: 56,
                                color: `${theme?.palette?.secondary?.main} !important`,
                                pl: 4,
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}>
                            {t('about')}
                        </Box>
                        <Box
                            href={externalLinks?.blog}
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                minWidth: 120,
                                maxHeight: 56,
                                color: `${theme?.palette?.secondary?.main} !important`,
                                pl: 4,
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}>
                            {t('blog')}
                        </Box>
                    </Box>

                    <Button
                        href={externalLinks?.contactSales}
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            minWidth: 196,
                            maxHeight: 44,
                            ml: 'auto',
                            color: `${theme?.palette?.common?.black} !important`,
                            borderRadius: 3,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        color="warning"
                        variant="contained"
                        size="small"
                    >
                        {t('contactSales')}
                    </Button>

                </Box>
                {renderMobileMenu}
            </Box>
        </>
    );
};

export default withTranslation(['translation.navigation'])(PublicHeader);
