// Quill

export const SET_PROJECTPROFILEABOUT_QUILL_DELTA =
  'SET_PROJECTPROFILEABOUT_QUILL_DELTA';
export const SAVE_PROJECTPROFILEABOUT_QUILL = 'SAVE_PROJECTPROFILEABOUT_QUILL';
export const SET_PROJECTPROFILEABOUT_QUILL_HTML =
  'SET_PROJECTPROFILEABOUT_QUILL_HTML';

// KUDOS

export const SET_PROJECT_ALL_TOPIC_KUDOS = 'SET_PROJECT_ALL_TOPIC_KUDOS';
export const SET_PROJECT_TOPIC_KUDOS = 'SET_PROJECT_TOPIC_KUDOS';
export const REMOVE_PROJECT_TOPIC_KUDOS = 'REMOVE_PROJECT_TOPIC_KUDOS';

// NOTES

export const SET_PROJECT_TOPIC_NOTES = 'SET_PROJECT_TOPIC_NOTES';
export const SET_PROJECT_TOPIC_NOTE = 'SET_PROJECT_TOPIC_NOTE';
export const DELETE_PROJECT_TOPIC_NOTE = 'DELETE_PROJECT_TOPIC_NOTE';
export const OPEN_PROJECT_TOPIC_NOTES = 'OPEN_PROJECT_TOPIC_NOTES';
export const CLOSE_PROJECT_TOPIC_NOTES = 'CLOSE_PROJECT_TOPIC_NOTES';
export const CLOSE_PROJECT_NOTES = 'CLOSE_PROJECT_NOTES';
export const SET_EDITING_NOTES = 'SET_EDITING_NOTES';
export const RESET_EDITING_NOTES = 'RESET_EDITING_NOTES';

// Challenges
export const SET_CHALLENGES = 'SET_CHALLENGES';
export const UPDATE_CHALLENGE = 'UPDATE_CHALLENGE';
export const SET_PROJECT_CHALLENGES = 'SET_PROJECT_CHALLENGES';

// Topics
export const SET_TOPICS = 'SET_TOPICS';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_PROJECT_TOPICS = 'SET_PROJECT_TOPICS';

// Answers
export const SET_ANSWERS = 'SET_ANSWERS';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_PROJECT_ANSWERS = 'SET_PROJECT_ANSWERS';

// channel topic updated
export const SET_CONFLICTING_ANSWERS = 'SET_CONFLICTING_ANSWERS';
export const RESOLVE_CONFLICT = 'RESOLVE_CONFLICT';

// teammates in topic
export const SET_TEAMMATE_ON_TOPIC = 'SET_TEAMMATE_ON_TOPIC';
export const REMOVE_TEAMMATE_ON_TOPIC = 'REMOVE_TEAMMATE_ON_TOPIC';

// BROWSER A PROJECT

export const CLEAR_BROWSE_PROJECT_DATA = 'CLEAR_BROWSE_PROJECT_DATA';
export const SET_BROWSE_PROJECT_CHANNEL_LOADED =
  'SET_BROWSE_PROJECT_CHANNEL_LOADED';

// Project Details

export const SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS';

// Project Team

export const SET_PROJECT_TEAM = 'SET_PROJECT_TEAM';

// FILTER
export const SET_FILTERED_PROJECTS = 'SET_FILTERED_PROJECTS';
export const SET_FILTER_QUERY = 'SET_FILTER_QUERY';
export const SET_FILTER = 'SET_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

// Get all the projects
export const SET_BROWSE_ALL_PROJECTS = 'SET_BROWSE_ALL_PROJECTS';

// Channel
export const SET_PROJECT_CHANNEL = 'SET_PROJECT_CHANNEL';
