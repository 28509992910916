import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import MultiEmail from 'FormInputs/MultiEmail';
import { sleep } from 'functions';
import { Stack, Typography, CircularProgress, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RebelFormLabel from 'Pages/Hubs/components/FormLabel/RebelFormLabel';
import { isArray } from 'lodash';
import { useDispatch } from 'react-redux';
import { errorPopup } from 'functions/popup/errorPopup';
import InviteErrorModal from './InviteErrorModal';

// TODO please migrate all components to mui. dont use formik components going forward

const MultiEmailInvite = ({
  title = undefined,
  placeholder,
  btnText,
  onSubmitRequest,
  onSuccessFn = () => {},
  inputFieldSize = 'small',
  isActive = true,
  reverse = false,
  InputProps = {},
  additionalField,
  children
}) => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorEmails, setErrorEmails] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation(['translation.Hub']);

  const inputRef = useRef(null);

  const resetMsgs = () => {
    setShowSuccessMsg(false);
    setShowErrorMsg(false);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Formik
        initialValues={{
          invitees: [],
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (isActive) {
            resetMsgs();
            let list = values.invitees;
            const onSuccess = async (data) => {
              inputRef.current?.resetMultiEmail();
              setSubmitting(false);
              resetForm();
              const errorEmailsArr = data.filter(
                (inviteObj) => !!inviteObj.error
              );
              if (errorEmailsArr.length > 0) {
                setErrorEmails(errorEmailsArr);
                setShowErrorMsg(true);
              } else setShowSuccessMsg(true);
              onSuccessFn();
              await sleep(10000);
              resetMsgs();
            };
            if (values.raw_email?.length > 0) {
              const value = values.raw_email.trim();
              if (!inputRef.current?.isValid(value)) {
                return;
              }
              list = [...list, value];
            }
            const resp = await onSubmitRequest(list);
            if (resp?.status && resp?.status !== 201) {
              if (typeof resp.data?.error === 'string')
                dispatch(errorPopup(resp.data.error));
              console.error(resp);
            } else if (resp?.data && isArray(resp.data)) onSuccess(resp.data);
          }
        }}
      >
        {(props) => (
          <Form>
            {title && (
              <RebelFormLabel htmlFor="invitees">{title}</RebelFormLabel>
            )}
            <Stack direction={reverse ? 'column' : 'column-reverse'} gap={2}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                gap={2}
                sx={{ alignItems: reverse ? 'flex-start' : 'flex-end' }}
              >
                <Box sx={{ width: { xs: '100%', sm: 'auto', md: '100%', xl: '100%' }, maxWidth: { xs: 'auto', sm: 'auto', md: '400px', xl: '600px' } }}>
                  <Field
                    disabled={!isActive}
                    inputRef={(ref) => {
                      inputRef.current = ref;
                    }}
                    name="invitees"
                    placeholder={placeholder}
                    component={MultiEmail}
                    inputFieldSize={inputFieldSize}
                    setError={(error) => props.setFieldError('invitees', error)}
                    showError={false}
                    showChips={false}
                    className={`multi_email ${
                      reverse ? 'multi_email--reverse' : ''
                    }`}
                    InputProps={InputProps}
                  />
                </Box>
                {additionalField}
                <Button
                  data-testid="hub-send-invite-btn"
                  variant="contained"
                  type="submit"
                  disabled={
                    !isActive ||
                    props.isSubmitting ||
                    (props.values.invitees.length === 0 &&
                      !props.values.raw_email)
                  }
                  sx={{
                    minWidth: 'max-content',
                    width: 'max-content',
                    height: 'max-content',
                    padding: '7.75px 16px',
                    margin: { xs: 'auto', sm: 0 },
                    minHeight: 32
                  }}
                >
                  {!props.isSubmitting ? (
                    btnText
                  ) : (
                    <CircularProgress size={24.5} />
                  )}
                </Button>
                {children}
              </Stack>
              <Stack direction="row" gap={1} sx={{ mb: 2, flexWrap: 'wrap' }}>
                {props.values.invitees.map((item) => (
                  <Chip
                    sx={{
                      borderColor: 'transparent',
                      borderRadius: 4,
                    }}
                    color="primary"
                    variant="outlined"
                    label={item}
                    onDelete={() => {
                      props.setFieldValue(
                        'invitees',
                        props.values.invitees.filter((i) => i !== item)
                      );
                      if (
                        inputRef.current &&
                        typeof inputRef.current.handleDelete === 'function'
                      )
                        inputRef.current.handleDelete(item);
                    }}
                  />
                ))}
              </Stack>
            </Stack>
            {props.errors.invitees && (
              <Typography
                color="error"
                sx={{ mt: 0.5, fontStyle: 'italic' }}
                data-testid="hub-invite-error"
              >
                {props.errors.invitees}
              </Typography>
            )}
            {showSuccessMsg && (
              <Typography
                color="success"
                sx={{ mt: 0.5, fontStyle: 'italic' }}
                data-testid="hub-invite-success-response"
              >
                {t('inviteSentMsg')}
              </Typography>
            )}
            {showErrorMsg && (
              <Box data-testid="hub-invite-error-response">
                <Typography color="error" sx={{ mt: 0.5, fontStyle: 'italic' }}>
                  {`${t('errorSendingInviteMsg')} `}
                  <button
                    type="button"
                    onClick={() => {
                      setShowErrorModal(true);
                    }}
                    style={{
                      textDecoration: 'underline',
                      color: 'inherit',
                      border: 'none',
                      font: 'inherit',
                      padding: 0,
                    }}
                  >
                    {t('ClickHere')}
                  </button>
                </Typography>
              </Box>
            )}
          </Form>
        )}
      </Formik>
      <InviteErrorModal
        isOpen={showErrorModal}
        close={() => {
          setShowErrorModal(false);
        }}
        t={t}
        errorEmails={errorEmails}
      />
    </Box>
  );
};

export default React.memo(MultiEmailInvite);
