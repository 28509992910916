export const SUCCESS_ALERT = 'SUCCESS_ALERT';
export const WARNING_ALERT = 'WARNING_ALERT';
export const INFO_ALERT = 'INFO_ALERT';
export const DANGER_ALERT = 'DANGER_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export const showSuccessAlert = (payload) => ({
    type: SUCCESS_ALERT,
    payload: {...payload, ...{ severity: 'success', open: true }},
});

export const dangerAlert = (payload) => ({
    type: DANGER_ALERT,
    payload: {...payload, ...{ severity: 'error', open: true }}
});

export const warningAlert = (payload) => ({
    type: WARNING_ALERT,
    payload: {...payload, ...{ severity: 'warning', open: true }}
});

export const infoAlert = (payload) => ({
    type: INFO_ALERT,
    payload: {...payload, ...{ severity: 'info', open: true}}
});

export const hideAlert = () => ({
    type: HIDE_ALERT
});
