import React from 'react';
import './loadingComponent.scss';
import { withTranslation } from 'react-i18next';

const loadingQuote = [
  'rebellionStarted',
  'RebelsBreakRules',
  'LookingForLostEntrepreneurs',
  'inner-innovator',
  'PopcornAnyone',
  'GotAnIdea',
];

const LoadingComponent = ({ showMessage = true, t }) => (
  <div>
    <div className="loaderWrapper">
      <div className="loader">
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__ball" />
      </div>
    </div>
    {showMessage &&
      <div className="loaderQuote">{t(loadingQuote[Math.floor(Math.random() * loadingQuote.length)])}</div>
    }
  </div>
);

export default withTranslation(['translation.utils']) (LoadingComponent);
