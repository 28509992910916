import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const GooglePlacesSearchBox = ({
  autocompletionRequest,
  placeholder,
  initialValue,
  required,
  className,
  disabled,
  input: { onChange },
}) => {
  return (
    <GooglePlacesAutocomplete
      inputClassName={className}
      required={required}
      debounce={300}
      placeholder={placeholder}
      onSelect={onChange}
      initialValue={initialValue}
      autocompletionRequest={autocompletionRequest}
      disabled={disabled}
    />
  );
};

export default GooglePlacesSearchBox;
