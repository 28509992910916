import Cookie from 'js-cookie';
import { push } from 'react-router-redux';
import { resetAppState } from 'store/actions';
import { request } from '../../functions';

export function deleteSession() {
  return (dispatch, getState) => {
    const { user } = getState();
    const userId = user.get('id');
    const jwt = user.get('jwt');
    const logoutURL = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/auth/logout/${userId}`;
    Cookie.remove('token');
    request(logoutURL, jwt, 'DELETE')
      .then(() => {
        // api call to logout
        dispatch(push('/'));
        dispatch(resetAppState());
      })
      .catch((err) => console.log(err));
  };
}

export function doLogout() {
  return (dispatch) => {
    // clear state
    dispatch(deleteSession());
  };
}
