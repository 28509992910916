import { Box } from '@mui/material';
import CarouselSkeleton from 'components/Dashboard/Carousel/CarouselSkeleton';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { TopbarHeight, baseTheme } from 'theme/Theme-variable';
import WelcomeRebel from 'components/Dashboard/WelcomeRebel';
import { useSelector } from 'react-redux';
import AvatarUpload from 'components/SignupProcess/AvatarUpload/AvatarUpload';
import DashboardNotifications from 'components/Dashboard/Notifications';
import { useTranslation } from 'react-i18next';
import WelcomeRebelOverlay from 'components/Dashboard/WelcomeRebelOverlay';
import { DashboardCard, NotificationCard } from './dashboardConfig';

const MyGroupBuilderSchedule = lazy(() =>
  import('components/Dashboard/MyGroupBuilderSchedule')
);
const MyGroupBuilders = lazy(() =>
  import('components/Dashboard/MyGroupBuilders')
);
const MyBuilders = lazy(() => import('components/Dashboard/MyBuilders'));
const ExploreBuilders = lazy(() =>
  import('components/Dashboard/ExploreBuilders')
);
const ExploreProjects = lazy(() =>
  import('components/Dashboard/ExploreProjects')
);

const Dashboard = () => {
  const projects = useSelector((state) => state.project).toJS();
  const isSignup = useSelector((state) => state.metadata.isSignup);
  const [isAvatarUploadOpen, setAvatarUploadOpen] = useState(false);
  const [showNotificationsCard, setShowNotificationCard] = useState(false);
  const [showWelcomeRebelCard, setShowWelcomeRebelCard] = useState(false);

  const pageContainer = useRef();

  const { t } = useTranslation(['translation.Dashboard']);

  const observer = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      setShowNotificationCard(
        entries[0].contentRect.width >=
          DashboardCard.width * 1.5 + NotificationCard.width + 64 //64px padding on both sides of the page
      );
    });
  });

  useEffect(() => {
    if (isSignup) {
      setAvatarUploadOpen(true);
      setShowWelcomeRebelCard(true);
    }
  }, [isSignup]);

  useEffect(() => {
    if (pageContainer.current) observer.observe(pageContainer.current);
  }, [pageContainer.current]);

  return (
    <>
      <Box ref={pageContainer}>
        {showWelcomeRebelCard ? (
          <Box
            sx={{
              p: { xs: 0, md: 4 },
              background: 'white',
              minHeight: `calc(100vh - ${TopbarHeight}px)`,
              display: 'flex',
              alignItems: { xs: 'stretch', md: 'center' },
            }}
          >
            <WelcomeRebelOverlay
              t={t}
              handleClose={() => {
                setShowWelcomeRebelCard(false);
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              p: { xs: 2, md: 4 },
              backgroundColor: baseTheme.palette.grey.light,
              minHeight: `calc(100vh - ${TopbarHeight}px)`,
            }}
          >
            {projects && projects.length === 0 && <WelcomeRebel t={t} />}
            <Box>
              {showNotificationsCard && <DashboardNotifications t={t} />}
              <Box>
                <Suspense fallback={<CarouselSkeleton />}>
                  <MyGroupBuilderSchedule t={t} />
                </Suspense>
                <Suspense fallback={<CarouselSkeleton />}>
                  <MyGroupBuilders
                    t={t}
                    reqKey="my_group_builders"
                    title={t('My Group Builders')}
                    tooltip={t('MyGroupBuildersTooltip')}
                  />
                </Suspense>
                <Suspense fallback={<CarouselSkeleton />}>
                  <MyBuilders t={t} />
                </Suspense>
                <Suspense fallback={<CarouselSkeleton />}>
                  <ExploreBuilders
                    t={t}
                    userHasProjects={projects.length > 0}
                  />
                </Suspense>
                <Suspense fallback={<CarouselSkeleton />}>
                  <ExploreProjects t={t} />
                </Suspense>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <AvatarUpload
        isOpen={isAvatarUploadOpen}
        closeModal={() => {
          setAvatarUploadOpen(false);
        }}
      />
    </>
  );
};

export default Dashboard;
