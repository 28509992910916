import moment from 'moment';
import { reset } from 'redux-form';
import Cookie from 'js-cookie';
import { API_GET } from 'store/middleware/middlewareConstants';
import { settingBuildersOverviewData } from 'store/builderData/builderDataActions';
import { imageFormatNotSupportedPopup } from 'functions/popup/imageFormatNotSupportedPopup';
import { mandatoryFieldPopup } from 'functions/popup/mandatoryFieldPopup';
import { invalidWebsiteUrlPopup } from 'functions/popup/invalidWebsiteUrlPopup';
import { hubSuccessfullyCreatedPopup } from 'functions/popup/hubSuccessfullyCreatedPopup';
import { hubDetailsUpdatedPopup } from 'functions/popup/hubDetailsUpdatedPopup';
import { requiredFieldPopup } from 'functions/popup/requiredFieldPopup';
import { enterEventLocationPopup } from 'functions/popup/enterEventLocationPopup';
import { invalidEventDatePopup } from 'functions/popup/invalidEventDatePopup';
import { cardUpdatedSuccessfullyPopup } from 'functions/popup/cardUpdatedSuccessfullyPopup';
import { selectAProjectPopup } from 'functions/popup/selectAProjectPopup';
import { projectSubmittedPopup } from 'functions/popup/projectSubmittedPopup';
import { RESET_INVITATION_TOKEN } from '../invitations/invitationActions';
import { SET_FOCUSED_HUB } from '../sideNav/sideNavActions';
import { joinHubChannels } from '../channels/channelActions';
import {
  doRedirect,
  SET_HUBS_LOADED,
  RESET_HUBS_LOADED,
  BEGIN_REQUEST,
  FINISH_REQUEST,
} from '../metadata/metadataActions';
import { removePopups } from '../popup/popupActions';
import { request, validateURL, postImage } from '../../functions';
import { getAuthUser } from '../auth/authActions';

// hubs the user is part of
export const SET_USER_HUBS = 'hubs/SET_USER_HUBS';
export const SET_USER_HUB = 'hubs/SET_USER_HUB';

export function fetchUserHubs() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/hubs`;
    return request(url, jwt).then((response) => {
      const manipulatedActiveStatusData = response.data.map((data) => {
        if (data.is_active) {
          return data;
        }
        return {
          ...data,
          is_active: false,
        };
      });
      dispatch({ type: SET_USER_HUBS, data: manipulatedActiveStatusData });
      dispatch(joinHubChannels());
    });
  };
}

// USER HUB ROLES
export const SET_HUB_MEMBERS = 'hubs/SET_HUB_MEMBERS';
export const SET_HUB_MEMBER = 'hubs/SET_HUB_MEMBER';

export function fetchHubMembers() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-roles`;
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_HUB_MEMBERS, data: response.data })
    );
  };
}

export function setUserHubAdminRole(userHubRoleId) {
  return (dispatch, getState) => {
    dispatch({ type: BEGIN_REQUEST });
    const { user, appData } = getState();

    const jwt = user.get('jwt');

    const userHubAdminRoleType = appData.userHubRoleTypes.find(
      (uhrt) => uhrt.get('name') === 'Manager'
    );

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-roles/${userHubRoleId}`;
    const payload = {
      user_hub_role: {
        user_hub_role_type_id: userHubAdminRoleType.get('id'),
      },
    };

    return request(url, jwt, 'PUT', payload).then(() => {
      dispatch({ type: FINISH_REQUEST });
    });
  };
}

// hub event invitations
export const SET_HUB_EVENT_INVITATIONS = 'hubs/SET_HUB_EVENT_INVITATIONS';

// event participants
export const SET_USER_EVENT_ROLES = 'hubs/SET_USER_EVENT_ROLES';

export function updateHubDetailsStepTwo(hubId, redirect = true) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch(removePopups());
    dispatch({ type: BEGIN_REQUEST });

    const { user, form } = getState();

    if (!form.CreateHubForm || !form.CreateHubForm.values) {
      if (redirect) {
        dispatch(doRedirect(`/create-hub/${hubId}/step-three`));
      } else {
        hubDetailsUpdatedPopup(dispatch);
      }
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    const jwt = user.get('jwt');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/hubs/${hubId}`;

    const formData = new FormData();
    formData.append('_utf8', true);

    if (form.CreateHubForm.values.logo && form.CreateHubForm.values.logo[0]) {
      const validImageTypes = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/gif',
      ];

      if (!validImageTypes.includes(form.CreateHubForm.values.logo[0].type)) {
        imageFormatNotSupportedPopup(dispatch);
        dispatch({ type: FINISH_REQUEST });
      }
    }

    formData.append(
      'hub[logo]',
      form.CreateHubForm.values.logo && form.CreateHubForm.values.logo[0]
        ? form.CreateHubForm.values.logo[0]
        : null
    );
    formData.append(
      'hub[description]',
      form.CreateHubForm &&
        form.CreateHubForm.values &&
        form.CreateHubForm.values.description
        ? form.CreateHubForm.values.description
        : ''
    );

    postImage(url, jwt, 'PUT', formData)
      .then((response) => {
        if (redirect) {
          dispatch(doRedirect(`/hubs/${hubId}/activities`));
        } else {
          hubDetailsUpdatedPopup(dispatch);
        }
        dispatch({ type: FINISH_REQUEST });
        return response;
      })
      .then((responseData) =>
        dispatch({ type: SET_USER_HUB, data: responseData.data })
      )
      .catch((err) => console.log(err));
  };
}

// HUB EVENTS
export const SET_HUB_EVENTS = 'hubs/SET_HUB_EVENTS';
export const SET_HUB_EVENT = 'hubs/SET_HUB_EVENT';
export const UPDATE_HUB_EVENT = 'hubs/UPDATE_HUB_EVENT';
export const DELETE_HUB_EVENT = 'hubs/DELETE_HUB_EVENT';

export function createHubEvent(hubId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user, form } = getState();

    form.CreateHubEventForm.values.start_time =
      form.CreateHubEventForm.values.start_time ||
      new Date(new Date().setHours(0, 0, 0, 0));

    form.CreateHubEventForm.values.end_time =
      form.CreateHubEventForm.values.end_time ||
      new Date(new Date().setHours(0, 0, 0, 0));

    dispatch({ type: BEGIN_REQUEST });

    // validate
    if (
      !form ||
      !form.CreateHubEventForm ||
      !form.CreateHubEventForm.values ||
      !form.CreateHubEventForm.values.name ||
      !form.CreateHubEventForm.values.start_date ||
      !form.CreateHubEventForm.values.start_time ||
      !form.CreateHubEventForm.values.end_date ||
      !form.CreateHubEventForm.values.end_time ||
      !form.CreateHubEventForm.values.event_type
    ) {
      requiredFieldPopup(dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    // validate location
    if (!form.CreateHubEventForm.values.is_online) {
      if (!form.CreateHubEventForm.values.location_map) {
        enterEventLocationPopup(dispatch);
        dispatch({ type: FINISH_REQUEST });
        return;
      }
    }

    const eventForm = form.CreateHubEventForm.values;

    // validate date and timings

    // moment([year, month, day, hour, minute, second, millisecond])
    const startDateTime = moment([
      moment(eventForm.start_date).get('year'),
      moment(eventForm.start_date).get('month'),
      moment(eventForm.start_date).get('date'),
      moment(eventForm.start_time).get('hour'),
      moment(eventForm.start_time).get('minute'),
      0,
      0,
    ]);

    const endDateTime = moment([
      moment(eventForm.end_date).get('year'),
      moment(eventForm.end_date).get('month'),
      moment(eventForm.end_date).get('date'),
      moment(eventForm.end_time).get('hour'),
      moment(eventForm.end_time).get('minute'),
      0,
      0,
    ]);

    // check that end time is after start time
    if (
      endDateTime.isBefore(startDateTime)
      // ||moment().isAfter(startDateTime)
    ) {
      invalidEventDatePopup(dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    // tokens and payload
    const jwt = user.get('jwt');
    const payload = {
      hub_event: {
        name: eventForm.name,
        description: eventForm.description,
        start_date: startDateTime.toISOString(),
        end_date: endDateTime.toISOString(),
        is_online: !!eventForm.is_online,
        location_map: eventForm.is_online ? null : eventForm.location_map,
        event_type: eventForm.event_type,
        hub_id: parseInt(hubId, 10),
        number_of_rounds: parseInt(eventForm.number_of_rounds, 10),
        has_rounds: eventForm.has_rounds === 'yes',
      },
    };

    // request
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/events`;

    request(url, jwt, 'POST', payload).then((response) => {
      dispatch(reset('CreateHubEventForm'));
      dispatch({ type: FINISH_REQUEST });
      window.onbeforeunload = null;
      dispatch(doRedirect(`/events/${response.id}`));
    });
  };
}

// hub event projects
export const SET_HUB_EVENT_PROJECTS = 'events/SET_HUB_EVENT_PROJECTS';
export const SET_HUB_EVENT_PROJECT = 'events/SET_HUB_EVENT_PROJECT';

export function selectHubEventProject(eventId, project) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    if (!project) {
      selectAProjectPopup(dispatch);
      return;
    }
    const { user } = getState();
    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/events/hub-event-projects`;
    const payload = {
      hub_event_project: {
        hub_event_id: eventId,
        project_id: project.id,
      },
    };
    request(url, jwt, 'POST', payload).then((response) => {
      dispatch({ type: SET_HUB_EVENT_PROJECT, data: response.data });
      dispatch({ type: 'UPDATE_EVENT_PROJECTS', data: response.data });
      projectSubmittedPopup(dispatch);
      dispatch(doRedirect(`/events/${eventId}`));
    });
  };
}

// USER HUB CARDS
export const SET_USER_HUB_CARDS = 'hubs/SET_USER_HUB_CARDS';
export const SET_USER_HUB_CARD = 'hubs/SET_USER_HUB_CARD';
export const REMOVE_USER_HUB_CARD = 'hubs/REMOVE_USER_HUB_CARD';

export function fetchUserHubCards() {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-cards`;

    request(url, jwt)
      .then((response) =>
        dispatch({ type: SET_USER_HUB_CARDS, data: response.data })
      )
      .catch((err) => console.log(err));
  };
}

// used for the welcome to hub modal
export function updateUserHubCards(hubId) {
  return (dispatch, getState) => {
    const { user, form, hubs } = getState();

    const memberCards = hubs.userHubCards.filter(
      (uhc) =>
        uhc.get('hub_id') === hubId && uhc.get('user_id') === user.get('id')
    );
    const memberSupportCard = memberCards
      ? memberCards.find((mc) => mc.get('card_type') === 'support')
      : null;
    const memberAdminCard = memberCards
      ? memberCards.find((mc) => mc.get('card_type') === 'admin')
      : null;

    if (memberAdminCard) {
      const roleTitle =
        form.AdminCardForm &&
        form.AdminCardForm.values &&
        form.AdminCardForm.values.role_title
          ? form.AdminCardForm.values.role_title
          : null;

      const jwt = user.get('jwt');
      const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/affiliations/user-hub-cards/${memberAdminCard.getIn([
        'user_hub_admin_card',
        'id',
      ])}`;
      const payload = {
        user_hub_admin_card: {
          role_title: roleTitle,
        },
      };

      request(url, jwt, 'PUT', payload)
        .then((response) =>
          dispatch({ type: SET_USER_HUB_CARD, data: response.data })
        )
        .catch((err) => console.log(err));
    }
    if (memberSupportCard) {
      const contactText =
        form.SupportCardForm &&
        form.SupportCardForm.values &&
        form.SupportCardForm.values.contact_text
          ? form.SupportCardForm.values.contact_text
          : null;
      const powers =
        form.SupportCardForm &&
        form.SupportCardForm.values &&
        form.SupportCardForm.values.powers
          ? form.SupportCardForm.values.powers
          : null;

      const jwt = user.get('jwt');
      const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/affiliations/user-hub-cards/${memberSupportCard.getIn([
        'user_hub_support_card',
        'id',
      ])}`;
      const payload = {
        user_hub_support_card: {
          contact_text: contactText,
          powers,
        },
      };

      request(url, jwt, 'PUT', payload)
        .then((response) =>
          dispatch({ type: SET_USER_HUB_CARD, data: response.data })
        )
        .catch((err) => console.log(err));
    }
    dispatch(doRedirect(`/hubs/${hubId}`));
  };
}

export function createUserHubCard(hubId, userId, cardType) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-cards`;
    const payload = {
      hub_id: hubId,
      user_id: userId,
      card_type: cardType,
    };

    request(url, jwt, 'POST', payload)
      .then((response) =>
        dispatch({ type: SET_USER_HUB_CARD, data: response.data })
      )
      .catch((err) => console.log(err));
  };
}

export function deactivateUserHubCard(cardId) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-cards/${cardId}`;
    const payload = {
      deactivate_card: 'deactivate card',
    };

    request(url, jwt, 'PUT', payload).catch((err) => console.log(err));
  };
}

export function updateHubAdminCard(cardId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user, form } = getState();

    const roleTitle =
      form.AdminCardForm &&
      form.AdminCardForm.values &&
      form.AdminCardForm.values.role_title
        ? form.AdminCardForm.values.role_title
        : null;

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-cards/${cardId}`;
    const payload = {
      user_hub_admin_card: {
        role_title: roleTitle,
      },
    };

    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: SET_USER_HUB_CARD, data: response.data });
        cardUpdatedSuccessfullyPopup('Admin', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

export function updateHubSupportCard(cardId) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    const { user, form } = getState();

    const contactText =
      form.SupportCardForm &&
      form.SupportCardForm.values &&
      form.SupportCardForm.values.contact_text
        ? form.SupportCardForm.values.contact_text
        : null;
    const powers =
      form.SupportCardForm &&
      form.SupportCardForm.values &&
      form.SupportCardForm.values.powers
        ? form.SupportCardForm.values.powers
        : null;

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-cards/${cardId}`;
    const payload = {
      user_hub_support_card: {
        contact_text: contactText,
        powers,
      },
    };

    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: SET_USER_HUB_CARD, data: response.data });
        cardUpdatedSuccessfullyPopup('Support', dispatch);
      })
      .catch((err) => console.log(err));
  };
}

// USER HUB PROJECTS
export const SET_USER_HUB_PROJECTS = 'hubs/SET_USER_HUB_PROJECTS';

export function fetchUserHubProjects() {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-projects`;

    request(url, jwt)
      .then((response) =>
        dispatch({ type: SET_USER_HUB_PROJECTS, data: response.data })
      )
      .catch((err) => console.log(err));
  };
}

export function updateUserHubProjects(hubId, projects) {
  return (dispatch, getState) => {
    const { user } = getState();

    const jwt = user.get('jwt');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/user-hub-projects`;
    const payload = {
      projects,
      hub_id: hubId,
    };

    request(url, jwt, 'POST', payload)
      .then((response) =>
        dispatch({ type: SET_USER_HUB_PROJECTS, data: response.data })
      )
      .catch((err) => console.log(err));
  };
}

// hub view select track
export const HUB_VIEW_SELECTED_TRACK = 'hubs/HUB_VIEW_SELECTED_TRACK';
export const setHubViewSelectedTrack = (hubId, challengeTrackBlueprintId) => {
  return {
    type: HUB_VIEW_SELECTED_TRACK,
    data: {
      hubId: parseInt(hubId, 10),
      challengeTrackBlueprintId,
    },
  };
};

// hub view select group
export const HUB_VIEW_SELECTED_GROUP = 'hubs/HUB_VIEW_SELECTED_GROUP';
export const RESET_HUB_VIEW_SELECTED_GROUP =
  'hubs/RESET_HUB_VIEW_SELECTED_GROUP';

export const setHubViewSelectedGroup = (hubId, groupId) => {
  return {
    type: HUB_VIEW_SELECTED_GROUP,
    data: {
      hubId: parseInt(hubId, 10),
      groupId: parseInt(groupId, 10),
    },
  };
};

export function hubViewSelectedTrack(hubId, challengeTrackBlueprintId) {
  return (dispatch) => {
    dispatch(setHubViewSelectedTrack(hubId, challengeTrackBlueprintId));
    dispatch({ type: RESET_HUB_VIEW_SELECTED_GROUP });
    dispatch(doRedirect(`/hubs/${hubId}/builders`));
  };
}

export const SET_HUB_GROUP_LIST = 'hubs/SET_HUB_GROUP_LIST';
export const RESET_HUB_GROUP_LIST = 'hubs/RESET_HUB_GROUP_LIST';

export const settingListOfGroups = (data) => {
  return {
    type: SET_HUB_GROUP_LIST,
    data: data.groups,
  };
};

export function fetchListOFGroups(hubId) {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-builders/${hubId}`,
      success: settingListOfGroups,
      onSuccess: null,
      token,
    },
  };
}

export const fetchHubGroupData = (hubId, hubGroupId) => {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-builders/${hubId}?hub_group_id=${hubGroupId}`,
      success: settingBuildersOverviewData,
      onSuccess: null,
      token,
    },
  };
};

export function hubViewSelectedGroup(hubId, groupId) {
  return (dispatch) => {
    dispatch(fetchHubGroupData(hubId, groupId));
    dispatch(setHubViewSelectedGroup(hubId, groupId));
    dispatch(doRedirect(`/hubs/${hubId}/builders`));
  };
}

export const SET_SEE_ALL_BUILDERS = 'SET_SEE_ALL_BUILDERS';
export const RESET_SEE_ALL_BUILDERS = 'RESET_SEE_ALL_BUILDERS';

// fetch all hub data
export function fetchHubData() {
  return (dispatch) => {
    dispatch({ type: RESET_HUBS_LOADED });
    Promise.all([
      dispatch(fetchUserHubs()),
      dispatch(fetchHubMembers()),
      dispatch(fetchUserHubCards()),
      dispatch(fetchUserHubProjects()),
    ]).then(() => {
      dispatch({ type: SET_HUBS_LOADED });
    });
  };
}

// hub creation process
export function createHub() {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch(removePopups());
    dispatch({ type: BEGIN_REQUEST });

    const { user, form, invitations } = getState();

    // validate
    if (
      !form.CreateHubForm ||
      !form.CreateHubForm.values ||
      !form.CreateHubForm.values.location_map ||
      !form.CreateHubForm.values.organization_type_id ||
      form.CreateHubForm.values.organization_type_id === ''
    ) {
      mandatoryFieldPopup(dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    if (
      form.CreateHubForm.values.website &&
      !validateURL(form.CreateHubForm.values.website)
    ) {
      invalidWebsiteUrlPopup(dispatch);
      dispatch({ type: FINISH_REQUEST });
      return;
    }

    const jwt = user.get('jwt');
    const payload = {
      token: invitations.createHubInvitationToken,
      hub: {
        ...form.CreateHubForm.values,
      },
    };

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/affiliations/hubs/`;

    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch(fetchHubData());
        dispatch(getAuthUser(user.get('id')));
        dispatch({ type: SET_USER_HUB, data: response.data });
        dispatch(doRedirect(`/hubs/${response.id}/activities`));
        hubSuccessfullyCreatedPopup(dispatch);
        dispatch({ type: SET_FOCUSED_HUB, hubId: response.data.id });
        dispatch({ type: RESET_INVITATION_TOKEN });
        dispatch(doRedirect(`/create-hub/${response.data.id}/step-two`));
        dispatch({ type: FINISH_REQUEST });
      })
      .catch((err) => console.log(err));
  };
}
