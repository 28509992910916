/* eslint-disable import/no-extraneous-dependencies */
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import apiGetMiddleware from './middleware/apiGetMiddleware';
import apiPutMiddleware from './middleware/apiPutMiddleware';
import apiPostImageMiddleware from './middleware/apiPostImageMiddleware';
import apiDeleteMiddleware from './middleware/apiDeleteMiddleware';
import apiQuillImageMiddleware from './middleware/apiQuillImageMiddleware';
import apiPostMiddleware from './middleware/apiPostMiddleware';
import apiPutPostImageMiddleware from './middleware/apiPutPostImageMiddleware';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const routerHistoryMiddleware = routerMiddleware(history);

export const configureStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        apiGetMiddleware,
        apiPutMiddleware,
        apiDeleteMiddleware,
        apiPostImageMiddleware,
        routerHistoryMiddleware,
        apiQuillImageMiddleware,
        apiPostMiddleware,
        apiPutPostImageMiddleware
        // redux logger middleware. comment this when you push the code for staging or production.
        // window.env.REACT_APP_TYPE === 'DEVELOPMENT' && logger
      )
    )
  );
