import { useState, useEffect } from 'react';
import {
  SET_HUB_EVENT_INVITATION_TOKEN,
  SET_CREATE_HUB_INVITATION_TOKEN,
  SET_PROJECT_INVITATION_TOKEN,
  SET_HUB_INVITATION_TOKEN,
} from 'store/invitations/invitationActions';
import { useDispatch } from 'react-redux';

const useSetInviteToken = (path, search) => {
  const dispatch = useDispatch();

  const params = search.split('&');

  const [email, setEmail] = useState(params[2]?.replace('email=', ''));

  const saveToken = (type, token) => {
    switch (type) {
      case 'hub_event_invitation':
        dispatch({ type: SET_HUB_EVENT_INVITATION_TOKEN, token });
        break;
      case 'hub_invitation':
        dispatch({ type: SET_HUB_INVITATION_TOKEN, token });
        break;
      case 'project_invitation':
        dispatch({ type: SET_PROJECT_INVITATION_TOKEN, token });
        break;
      case 'create_hub_invitation':
        dispatch({ type: SET_CREATE_HUB_INVITATION_TOKEN, token });
        break;
      default:
    }
  };

  useEffect(() => {
    if (path.includes('/auth/sign-up') && search.length > 0) {
      if (params.length > 1) {
        const type = params[0]?.replace('?type=', '');
        const token = params[1]?.replace('token=', '');
        setEmail(params[2]?.replace('email=', ''));

        saveToken(type, token);
      }
    }
  }, [path, search]);

  return email;
};

export default useSetInviteToken;
