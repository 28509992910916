import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import {Box, Typography} from "@mui/material";
import {RebelbaseLogoIcon} from "assets";
import FollowUs from "Pages/Browse/Footer/FollowUs";
import ContactUs from "Pages/Browse/Footer/ContactUs";
import ExploreMore from "Pages/Browse/Footer/ExploreMore";
import StayInTouch from "Pages/Browse/Footer/StayInTouch";
import {checkPublicPath} from 'functions';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // openVideoModal: window.location.pathname === '/users-video',
        };
    }

    render() {
        const {isPublicPath} = this.props;
        const {t} = this.props;

        if (isPublicPath) {
            return (
                <>
                    <Box component="footer" sx={{
                        px: {xs: 0, md: 15},
                        pt: {xs: 5, md: 10},
                        pb: {xs: 5, md: 4},
                        background: (theme) => theme?.palette?.info?.dark,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>

                        <Box sx={{
                            width: {xs: 'auto', lg: 1024},
                            margin: "0 auto"
                        }}>
                            <Box sx={{
                                textAlign: {xs: 'center', md: 'left'},
                                background: 'transparent',
                                color: (theme) => `${theme?.palette?.common?.white} !important`,
                                '&:focus': {
                                    border: 'none !important'
                                }
                            }}
                                 component="a"
                                 href="https://rebelbase.co">
                                <RebelbaseLogoIcon sx={{
                                    color: (theme) => theme?.palette?.common?.white,
                                    width: {xs: 269, md: 402},
                                    height: {xs: 48, md: 64}
                                }}/>
                            </Box>
                        </Box>

                        <Box sx={{
                            pt: {xs: 7, md: 8},
                            display: "flex",
                            flexDirection: {xs: "column", md: "row"},
                            flexWrap: "wrap",
                            margin: "0 auto"
                        }}>
                            <Box sx={{
                                width: {xs: 'auto', lg: "512px"},
                                order: {xs: 2, md: 1}
                            }}>
                                <Box sx={{
                                    flexBasis: {xs: 'auto', md: "50%"}
                                }}>
                                    <ExploreMore/>
                                </Box>
                                <Box sx={{
                                    flexBasis: {xs: 'auto', md: "50%"},
                                }}>
                                    <ContactUs/>
                                </Box>
                                <Box sx={{
                                    flexBasis: {xs: 'auto', md: "50%"},
                                }}>
                                    <FollowUs/>
                                </Box>
                                <Box sx={{
                                    flexBasis: {xs: 'auto', md: "50%"},
                                    order: {xs: 5, md: 5},
                                    display: "flex",
                                    pt: {xs: 10, md: 13},
                                    justifyContent: {xs: 'center', md: 'flex-start'}
                                }}>
                                    <Box
                                        sx={{
                                            background: 'transparent',
                                            color: (theme) => `${theme?.palette?.common?.white} !important`,
                                            '&:focus': {
                                                border: 'none !important'
                                            }
                                        }}
                                        component='a'
                                        href="https://rebelbase.co/privacy/">
                                        <Typography variant="h6" sx={{
                                            color: (theme) => theme?.palette?.common?.white
                                        }}>
                                            {t('footerPrivacyLink')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            pl: 3,
                                            background: 'transparent',
                                            color: (theme) => `${theme?.palette?.common?.white} !important`,
                                            '&:focus': {
                                                border: 'none !important'
                                            }
                                        }}
                                        component='a'
                                        href="https://rebelbase.co/terms-of-service/">
                                        <Typography variant="h6" sx={{
                                            color: (theme) => theme?.palette?.common?.white
                                        }}>
                                            {t('footerTermsLink')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                width: {xs: 'auto', lg: "512px"},
                                pl: {xs: 0, lg: 13},
                                order: {xs: 1, md: 2}
                            }}>
                                <Box sx={{
                                    flexBasis: {xs: 'auto', md: "50%"},
                                    order: {xs: 1, md: 2}
                                }}>
                                    <StayInTouch/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            );
        }
        return null;
    }
}

function mapStateToProps() {
    const isPublicPath = checkPublicPath(window.location.pathname);

    return {
        isPublicPath,
    };
}

export default withTranslation(['translation.footer'])(connect(mapStateToProps)(Footer));
