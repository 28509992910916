/* eslint-disable no-console */
import { postImage } from 'functions';
import { API_POST_IMAGE } from './middlewareConstants';

const apiPostImageMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type !== API_POST_IMAGE) {
    return next(action);
  }

  const {
    payload: { url, success, body, onSuccess, token, onError, identifier },
  } = action;

  postImage(url, token, 'POST', body)
    .then((resp) => {
      if (
        (resp.status === 500 || resp.status === 422) &&
        identifier === 'hubSponsor'
      ) {
        return dispatch(onError());
      } 
        resp.json().then((res) => {
          if (success !== null) {
            dispatch(success(res.data));
          }
          if (onSuccess !== null) {
            onSuccess(res.data);
          }
        });
      return '';
    })
    .catch((err) => {
      console.error(err);
    });

  return false;
};

export default apiPostImageMiddleware;
