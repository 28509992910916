import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RESET_REDIRECT } from '../../store/metadata/metadataActions';

const DoRedirect = ({ redirectTo, resetRedirect }) => {
  if (redirectTo) {
    const url = redirectTo;
    resetRedirect();
    return <Redirect push to={url} />;
  }
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  resetRedirect() {
    dispatch({ type: RESET_REDIRECT });
  },
});

function mapStateToProps(state) {
  const { redirectTo } = state.metadata;
  return {
    redirectTo,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoRedirect);
