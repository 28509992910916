import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { toggleProfileVisibility } from '../../../store/profile/profileActions';

class Visibility extends React.Component {
  renderView() {
    const { toggle, isPublic, t } = this.props;
    return (
      <section className="visibility">
        <div className="visibility__wrap  bioInfo__wrap">
          <div className="visibility__cont">
            <div className="visibility__selection">
              <form onChange={(e) => toggle(e)} className="visibility__form">
                <div className="visibility__toggle__container">
                  <Field
                    component="input"
                    type="checkbox"
                    id="public"
                    name="is_public"
                  />
                  <label htmlFor="public">
                    <div
                      className="visibility__toggle-btn"
                      style={isPublic ? { left: '1.45rem' } : {}}
                    />
                  </label>
                  <div className="visibility__toggle__label">
                    {isPublic ? t('on') : t('off')}
                  </div>
                </div>
              </form>
            </div>
            <div className="visibility__title__wrap">
              <div>
                <span className="visibility__title">
                  {t('PublicVisibility')}
                </span>
                <span className="visibility__subtitle">{t('whoCanSee')}</span>
              </div>
              <div className="visibility__learn-more">
                <div className="visibility__learn-more__mark">?</div>
                <div className="visibility__learn-more__cont">
                  <h3>{t('EditVisibility')}</h3>
                  <p>
                    {t('NotLoggedinView')}
                    <br />
                    <br />
                    <span className="bold">{t('on')}:</span> {t('ifOn')}
                    <br />
                    <br />
                    <span className="bold">{t('off')}:</span> {t('ifOff')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  render() {
    return this.renderView();
  }
}
const mapDispatchToProps = (dispatch) => ({
  toggle(e) {
    e.preventDefault();
    dispatch(toggleProfileVisibility());
  },
});

const mapStateToProps = (state) => {
  const initialValues = {
    is_public: state.profile.isPublic,
  };
  return {
    initialValues,
    isPublic: state.form?.ProfileVisibilityForm?.values?.is_public,
  };
};

export default withTranslation(['translation.Profile'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: 'ProfileVisibilityForm', enableReinitialize: true })(
      Visibility
    )
  )
);
