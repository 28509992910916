import React from 'react';
import { Avatar, AvatarGroup, Box } from '@mui/material';
import emptyHubLogo from 'images/empty_logo.png';

const UserAvatar = ({ sender, ...rest }) => {
  return (
    <Avatar
      {...rest}
      alt={`${sender?.firstName} ${sender?.lastName}`}
      src={sender?.avatarUrl}
    />
  );
};

export const AlertAvatar = ({ users }) => {
  return (
    <Box sx={{
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        {users?.length > 1 ? (
            <AvatarGroup sx={{
                '& .MuiAvatar-root': {
                    height: 32,
                    width: 32
                }
            }} spacing={28} max={2}>
                {users?.map((sender) => {
                    return (
                        <Avatar
                            alt={`${sender?.firstName} ${sender?.lastName}`}
                            src={sender?.avatarUrl}
                            key={sender?.userId}
                        />
                    );
                })}
            </AvatarGroup>
        ) : (
            <UserAvatar
                sender={users[0]}
                sx={{
                    height: 32,
                    width: 32
                }}
            />
        )}
    </Box>
  );
};

export const HubLogo = ({ hub }) => (
  <Box
    sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiAvatar-root': {
            height: 32,
            width: 32
        }
    }}
  >
    <Avatar alt={hub?.name} src={hub?.logo || emptyHubLogo} />
  </Box>
);
