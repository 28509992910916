import React, { useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'components/Modal';
import { withTranslation } from 'react-i18next';
import SEO from 'components/SEO';
import loader from 'images/loader.gif';
import NoPermission from 'components/NoPermission';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Typography,
  Box,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Tooltip,
} from '@mui/material';
import MultiEmailInvite from 'components/MultiEmailInvite';
import AddIcon from '@mui/icons-material/Add';
import { createProjectInvitation } from 'utils/api-calls';
import { sendInvitation } from '../../../../store/projectData/projectDataActions';
import './inviteTeammate.scss';

const InviteTeammate = ({
  close,
  userPermissionLevel,
  t,
  projectName,
  isOpen,
  projectId,
}) => {
  const userProjectRoleTypes = useSelector(
    (state) => state.appData.userProjectRoleTypes
  ).toJS();
  const [role, setRole] = useState(3);

  const TooltipContent = () => (
    <Box sx={{ mt: -2 }}>
      {userProjectRoleTypes.map((roleType) => (
        <>
          <Typography fontWeight="bold" sx={{ mt: 2 }}>
            {t(roleType.label)}
          </Typography>
          <Typography>{t(roleType.description)}</Typography>
        </>
      ))}
    </Box>
  );

  const handleSubmit = useCallback(
    async (list) => createProjectInvitation(projectId, list, role),
    [projectId, role]
  );

  if (!userPermissionLevel) {
    return (
      <SEO title={projectName}>
        <Modal isOpen={isOpen} handleClose={() => close()}>
          <img src={loader} alt="loading" />
        </Modal>
      </SEO>
    );
  }

  // if the user doesn't have enough permission
  if (userPermissionLevel < 50) {
    return (
      <SEO title={projectName}>
        <Modal isOpen={isOpen} handleClose={() => close()}>
          <NoPermission />
        </Modal>
      </SEO>
    );
  }

  return (
    <SEO title={projectName}>
      <Modal
        isOpen={isOpen}
        title={
          <Typography variant="h1" align="center">
            {projectName}
          </Typography>
        }
        handleClose={() => close()}
      >
        <Box
          sx={{
            mt: -2,
            width: '70vw',
            maxWidth: '800px',
          }}
        >
          <Typography variant="h2" fontWeight="bold" align="center">
            {t('Invite Your Teammates')}
          </Typography>
          <Typography color="primary" align="center">
            {t('Teammates can take challenges and strengthen your project.')}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography fontWeight="bold" sx={{ mb: 2 }}>
              {t('Invite People')}
            </Typography>
            <Box sx={{ width: '100%' }}>
              <MultiEmailInvite
                inputFieldSize="normal"
                placeholder={t('Add Email(s)')}
                btnText={t('Invite Teammates')}
                reverse
                onSubmitRequest={handleSubmit}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    minWidth: 0,
                    background: (theme) => theme?.palette?.common?.white
                  },
                }}
                additionalField={
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: '400px', maxWidth: '100%' }}
                  >
                    <FormControl fullWidth>
                      <Select
                        value={role}
                        defaultValue={3}
                        sx={{
                          minWidth: 0,
                        }}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      >
                        {userProjectRoleTypes.map((roleType) => (
                          <MenuItem
                            key={roleType.id}
                            value={roleType.id}
                            disabled={roleType.level > userPermissionLevel}
                          >
                            {t(roleType.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Tooltip
                      title={<TooltipContent />}
                      enterTouchDelay={0}
                      PopperProps={{
                        placement: 'right',
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          height: 16,
                          width: 16,
                          ml: 1,
                        }}
                      />
                    </Tooltip>
                  </Stack>
                }
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </SEO>
  );
};

const mapDispatchToProps = (dispatch) => ({
  send(e, projectId) {
    e.preventDefault();
    dispatch(sendInvitation(projectId));
  },
});

function mapStateToProps(state, props) {
  const { project, projectData, appData, user, form } = state;

  // get project info
  const projectName = project
    .find((p) => p?.get('id') === props.projectId)
    ?.get('name');

  // get users permission level in this project
  const userRole = projectData.teammates.find(
    (tm) =>
      tm.get('user_id') === user.get('id') &&
      tm.get('project_id') === props.projectId
  );
  const userPermissionLevel = userRole
    ? appData.userProjectRoleTypes
        .find((uprt) => uprt.get('id') === userRole.get('role_type_id'))
        .get('level')
    : null;

  // get all the invitations for this project
  const invitations = projectData.invitations
    .filter((i) => i.get('project_id') === props.projectId)
    .map((i) => {
      const roleType = appData.userProjectRoleTypes.find(
        (uprt) => uprt.get('id') === i.get('role_type_id')
      );
      return i.set('role_type', roleType);
    })
    .sort((a, b) => {
      const roleLevelA = a.getIn(['role_type', 'level']);
      const roleLevelB = b.getIn(['role_type', 'level']);

      if (roleLevelA < roleLevelB) return -1;
      if (roleLevelA > roleLevelB) return 1;
      if (roleLevelA === roleLevelB) {
        if (a.get('inserted_at') < b.get('inserted_at')) return -1;
        return 1;
      }
      return 0;
    })
    .reverse();

  // available role types the user can assign to an invitation
  const roleTypes = appData.userProjectRoleTypes
    .filter((uprt) => uprt.get('is_assignable'))
    .filter((uprt) => uprt.get('level') <= userPermissionLevel)
    .sortBy((uprt) => uprt.get('level'))
    .reverse();

  // set default form value to 'Team Member'
  const initialValues = {
    role_type_id: roleTypes.isEmpty()
      ? null
      : roleTypes.find((rt) => rt.get('level') === 50)?.get('id'),
  };

  // get the currently selected role type in order to display the description
  const selectedRoleTypeId =
    form.InviteTeammateForm && form.InviteTeammateForm.values
      ? parseInt(form.InviteTeammateForm.values.role_type_id, 10)
      : initialValues.role_type_id;

  const selectedRoleType = roleTypes.find(
    (rt) => rt.get('id') === selectedRoleTypeId
  );

  return {
    invitations: invitations ? invitations.toJS() : null,
    userProjectRoleTypes: roleTypes.toJS(),
    userId: user.get('id'),
    userPermissionLevel,
    initialValues,
    selectedRoleTypeDescription: selectedRoleType
      ? selectedRoleType.get('description')
      : null,
    projectName,
  };
}

export default withTranslation(['translation.InviteTeammate'])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: 'InviteTeammateForm', enableReinitialize: true })(
      InviteTeammate
    )
  )
);
