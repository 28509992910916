import { GET } from 'utils/api-calls';
import { request } from 'functions';
import { setUserEmailAddress } from 'store/userEmailAddresses/userEmailAddressActions';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const ADD_USER_PROJECT = 'ADD_USER_PROJECT';
export const UPDATE_MY_DEFAULT_PROJECT = 'UPDATE_MY_DEFAULT_PROJECT';
export const REMOVE_USER_PROJECT = 'REMOVE_USER_PROJECT';

const getAuthUserInfo = async (userId) =>
    GET(`/api/users/${userId}`);

export const setAuthUser = (payload) => ({
    type: SET_AUTH_USER,
    payload,
});

export const addNewUserProject = (payload) => ({
    type: ADD_USER_PROJECT,
    payload,
});

export const removeUserProject = (payload) => ({
    type: REMOVE_USER_PROJECT,
    payload,
});

export const getAuthUser = (userId) => async (dispatch) => {
    const resp = await getAuthUserInfo(userId);
    if (resp?.status && resp?.status !== 200) {
        // eslint-disable-next-line no-console
        console.error(resp);
    } else if (resp?.data) {
        dispatch(setAuthUser(resp.data));

        // this needs to be removed when refactoring invitations and settings
        dispatch(setUserEmailAddress(resp.data.email_addresses)); 
    }
};

export const setMyDefaultProject = (projectId) => async (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const payload = {
        user_default_project: {
            project_id: projectId,
        }
    };
    const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v3/rebel/venture/user-default-projects`;
    try {
        const response = await request(url, jwt, 'POST', payload);
        dispatch({
            type: UPDATE_MY_DEFAULT_PROJECT,
            payload: response.data.project_id,
        });
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};
