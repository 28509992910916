import React from 'react';
import { requestEmailVerification } from 'store/emailVerification/emailVerificationActions';
import i18n from '../../i18n';
import { warningPopup } from './warningPopup';

export const triggerVerifyEmailPopup = (emailAddress, id, dispatch) => {
  const popupBody = (
    <div>
      <button
        type="button"
        onClick={() => {
          dispatch(requestEmailVerification(emailAddress));
        }}
      >
        {
          i18n.getDataByLanguage(i18n.language)['translation.notification']
            .SendVerificationEmail
        }
      </button>
    </div>
  );

  dispatch(
    warningPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .VerifyEmail
      } ${emailAddress}`,
      0,
      `email-verification-${id}`,
      popupBody
    )
  );
};
