import * as Yup from 'yup';
import { appendErrors, FieldError } from 'react-hook-form';
import { Resolver } from './types';
import { validateFieldsNatively } from './validateFieldsNatively';
import { toNestError } from './toNestError';

/**
 * Why `path!` ? because it could be `undefined` in some case
 * https://github.com/jquense/yup#validationerrorerrors-string--arraystring-value-any-path-string
 */
const parseErrorSchema = (
    error: Yup.ValidationError,
    validateAllFieldCriteria: boolean,
) => {
    return (error.inner || []).reduce<Record<string, FieldError>>(
        (previous, validationError) => {
            if (!previous[validationError.path!]) {
                // eslint-disable-next-line no-param-reassign
                previous[validationError.path!] = { message: validationError.message, type: validationError.type! };
            }

            if (validateAllFieldCriteria) {
                const {types} = previous[validationError.path!];
                const messages = types && types[validationError.type!];

                // eslint-disable-next-line no-param-reassign
                previous[validationError.path!] = appendErrors(
                    validationError.path!,
                    validateAllFieldCriteria,
                    previous,
                    validationError.type!,
                    messages
                        ? ([] as string[]).concat(messages as string[], validationError.message)
                        : validationError.message,
                ) as FieldError;
            }

            return previous;
        },
        {},
    );
};

export const yupResolver: Resolver =
    (schema, schemaOptions = {}, resolverOptions = {}) =>
        async (values, context, options) => {
            try {
                if (schemaOptions.context && process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn(
                        "You should not used the yup options context. Please, use the 'useForm' context object instead",
                    );
                }

                const result = await schema[
                    resolverOptions.mode === 'sync' ? 'validateSync' : 'validate'
                    ](
                    values,
                    {abortEarly: false, ...schemaOptions, context},
                );

                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                options.shouldUseNativeValidation && validateFieldsNatively({}, options);

                return {
                    values: resolverOptions.rawValues ? values : result,
                    errors: {},
                };
            } catch (e: any) {
                if (!e.inner) {
                    throw e;
                }

                return {
                    values: {},
                    errors: toNestError(
                        parseErrorSchema(
                            e,
                            !options.shouldUseNativeValidation &&
                            options.criteriaMode === 'all',
                        ),
                        options,
                    ),
                };
            }
        };