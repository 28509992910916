import React from "react";
import { Box } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import CircleIcon from "@mui/icons-material/Circle";

const AlertIndicator = () => {
    return (
        <Box sx={{
            pl: 2
        }}>
            <SvgIcon sx={{
                height: 12,
                width: 12
            }} component={CircleIcon}  color="primary"/>
        </Box>
    );
}

export default AlertIndicator;