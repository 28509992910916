import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import * as Yup from 'yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from 'utils/yup';
import { sendResetInstructionsToEmail } from 'store/passwordRecovery/passwordRecoveryActions';
import SEO from 'components/SEO';
import ForgotPassword from "./ForgotPassword";
import {ForgotPasswordForm} from "./ForgotPasswordForm";

const ForgotPasswordContainer = () => {

    // TODO remove any type
    const { user_preference: userPreference } = useSelector((state: any) => state.user.toJS());

    const dispatch = useDispatch();

    const language = userPreference?.language === 'ESP' ? '/es' : '';

    const { t } = useTranslation(['translation.login']);

    const validationSchema: ObjectSchema<ObjectShape> = Yup.object().shape({
        email: Yup.string()
            .email(t('EnterValidEmail'))
            .required(t('EnterValidEmail'))
    });

    const formOptions: UseFormProps = {
        // onBlur, onChange, and etc...
        mode: 'all',
        resolver: yupResolver(validationSchema)
    };

    const { register, handleSubmit, formState } = useForm(formOptions);

    const { errors } = formState;

    const send = (form: ForgotPasswordForm) => {
        dispatch(sendResetInstructionsToEmail(form.email));
    }

    return (
        <SEO>
            <ForgotPassword
                onSubmit={send}
                errors={errors}
                register={register}
                language={language}
                formHandleSubmit={handleSubmit}/>
        </SEO>
    )
};

export default React.memo(ForgotPasswordContainer);