/* eslint-disable no-console */
import { request } from 'functions';
import { API_PUT } from './middlewareConstants';
import { BEGIN_REQUEST, FINISH_REQUEST } from '../metadata/metadataActions';

const apiPutMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type !== API_PUT) {
      return next(action);
    }
    const {
      payload: { url, success, body, onSuccess, token, onError },
    } = action;

    dispatch({ type: BEGIN_REQUEST });

    request(url, token, 'PUT', body)
      .then((res) => {
        if (res.status === 422 && onError) {
          onError({ error: 422 });
        } else {
          if (success !== null) {
            dispatch(success(res.data || res));
          }
          if (onSuccess !== null) {
            onSuccess(res.data || res);
          }
        }
      })
      .then(() => dispatch({ type: FINISH_REQUEST }))
      .catch((err) => {
        console.error(err);
        dispatch({ type: FINISH_REQUEST });
      });

    return false;
  };

export default apiPutMiddleware;
