import i18n from '../../i18n';
import { successPopup } from './successPopup';

export const profileImageUploadedPopup = (dispatch) => {
  dispatch(
    successPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .ProfileImageUploaded
      }`
    )
  );
};
