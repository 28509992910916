import i18n from '../../i18n';
import { warningPopup } from './warningPopup';

export const signupOrLoginFirstPopup = (dispatch) => {
  dispatch(
    warningPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .SignupOrLoginFirst
      }`
    )
  );
};
