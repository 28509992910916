/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea/lib';

const TextAreaAutoResize = ({
  field,
  placeholder,
  className,
  autoFocus = false,
  isDisabled = false,
}) => (
  <TextareaAutosize
    {...field}
    //   onBlur={() => input.onBlur()}
    value={field.value} // requires value to be an array
    placeholder={placeholder}
    className={className}
    autoFocus={autoFocus}
    disabled = {isDisabled}
  />
);

TextAreaAutoResize.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
};

export default TextAreaAutoResize;
