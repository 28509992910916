import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert as AlertType,
  HubActivityAlertTypeEnum,
  EventAlertTypeEnum,
  HubGroupAlertTypeEnum, SenderUser,
} from 'utils/types/alert';
import { AlertAvatar, HubLogo } from 'components/Notifications/AlertAvatar';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box, Collapse, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  HubActivityCreated,
  HubAnnouncementCreated,
  HubActivityReply,
  HubActivityKudos,
  HubActivityReplyKudos,
} from './HubActivityNotifications';
import { HubName, ShortTimeIndicator, TimeIndicator } from './Components';
import {
  EventDeleted,
  EventPostCreated,
  EventPostKudos,
  EventUpdated,
} from './EventNotifications';
import {
  BuilderPublishAlert,
  BuilderUpdateAlert,
  ProjectTopicKudosAlert,
  ProjectTopicNoteAlert
} from './ProjectNotifications';
import { HubMemberAddedToGroup } from './HubGroupNotifications';
import AlertIndicator from "./AlertIndicator";

interface Props {
  alert: AlertType;
  close: Function;
  t: WithTranslation['t'];
  user: SenderUser;
}

const notificationBody = (
  alert: AlertType,
  userId: number,
  t: WithTranslation['t']
): React.ReactChild => {
  switch (alert.alertTypeId) {
    /**
     * Hub Activity Notifications
     */
    case HubActivityAlertTypeEnum.AnnouncementCreated:
      return <HubAnnouncementCreated alert={alert} t={t} />;
    case HubActivityAlertTypeEnum.HubActivityCreated:
      return <HubActivityCreated alert={alert} t={t} />;
    case HubActivityAlertTypeEnum.HubActivityReply:
      return <HubActivityReply alert={alert} userId={userId} t={t} />;
    case HubActivityAlertTypeEnum.HubActivityKudos:
      return <HubActivityKudos alert={alert} t={t} />;
    case HubActivityAlertTypeEnum.HubActivityReplyKudos:
      return <HubActivityReplyKudos alert={alert} t={t} />;

    /**
     * Event Notifications
     */
    case EventAlertTypeEnum.EventUpdated:
      return <EventUpdated alert={alert} t={t} />;
    case EventAlertTypeEnum.EventPostCreated:
      return <EventPostCreated alert={alert} t={t} />;
    case EventAlertTypeEnum.EventPostKudos:
      return <EventPostKudos alert={alert} t={t} />;
    case EventAlertTypeEnum.EventDeleted:
      return <EventDeleted alert={alert} t={t} />;

    /**
     * Hub Group Notifications
     */
    case HubGroupAlertTypeEnum.HubMemberAddedToGroup:
      return <HubMemberAddedToGroup alert={alert} t={t} />;

    default:
      return <div />;
  }
};

const Alert: React.FC<Props> = ({ alert, close, t, user }) => {
  const notificationLinkStyles = {
    color: 'black',
    borderBottom: 0,
    width: '100%',
    flex: '1 0 100%',
    display: 'flex'
  };

  if (alert.alertTypeId >= 0 && alert.alertTypeId <= 5) {
    switch (alert.alertTypeId) {
      case 1:
        return <BuilderUpdateAlert user={user} alert={alert} t={t} />;
      case 2:
        return <BuilderPublishAlert user={user} alert={alert} t={t} />;
      case 3:
      case 5:
        return <ProjectTopicNoteAlert user={user} alert={alert} t={t} />;
      case 4:
        return <ProjectTopicKudosAlert user={user} alert={alert} close={close} t={t} />;
      default:
        return <></>;
    }
  }

  const setPostScrollDetails = () => {
    if (!alert.eventPosts.length) return;
    const { id, parentPostId } = alert.eventPosts[0];
    const data = {
      path: `/events/${alert?.event?.id}`,
      post: { id, parentPostId },
    };
    sessionStorage.setItem('postScrollDetails', JSON.stringify(data));
  };

  const alertType: 'hubActivity' | 'event' | 'hubGroup' | null = useMemo(() => {
    if (alert.alertTypeId >= 6 && alert.alertTypeId <= 10) return 'hubActivity';
    if (alert.alertTypeId >= 11 && alert.alertTypeId <= 14) return 'event';
    if (alert.alertTypeId === 15) return 'hubGroup';
    return null;
  }, [alert]);

  const link: string = useMemo(() => {
    if (alert.alertTypeId === EventAlertTypeEnum.EventDeleted)
      return window.location.pathname;
    switch (alertType) {
      case 'hubActivity':
        return `/hubs/${alert.hub?.id}/activities`;
      case 'event':
        return `/events/${alert.event?.id}`;
      case 'hubGroup':
        return `/hubs/${alert.hub?.id}/groups/${alert.hubGroup?.id}`;
      default:
        return window.location.pathname;
    }
  }, [alertType, alert]);

  const showHubLogo: boolean = useMemo(
    () =>
      alert.alertTypeId === HubActivityAlertTypeEnum.HubActivityCreated ||
      alert.alertTypeId === EventAlertTypeEnum.EventUpdated ||
      alert.alertTypeId === EventAlertTypeEnum.EventDeleted ||
      alert.alertTypeId === HubGroupAlertTypeEnum.HubMemberAddedToGroup,
    [alert]
  );

  const showHubName = useMemo<boolean>(
    () =>
      alertType === 'hubActivity' ||
      alert.alertTypeId === EventAlertTypeEnum.EventDeleted ||
      alert.alertTypeId === HubGroupAlertTypeEnum.HubMemberAddedToGroup,
    [alert, alertType]
  );

  const linkOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (
      [
        EventAlertTypeEnum.EventPostCreated,
        EventAlertTypeEnum.EventPostKudos,
      ].includes(alert.alertTypeId)
    )
      setPostScrollDetails();
    if (typeof close === 'function') close();
  };

  const userId: number = useSelector((state) => (state as any).authUser.id);

  return (
    <Link to={link} style={notificationLinkStyles} onClick={linkOnClick}>
      {showHubLogo ? (
          <HubLogo hub={alert.hub} />
      ) : (
          <AlertAvatar
              users={alert.senderUsers}
          />
      )}
      <Box sx={{
        flex: 1,
        pl: 1,
        display: 'flex',
        alignItems: 'center'
      }}>
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
          <Typography variant="body1" sx={{ flex: 1 }}>
            {notificationBody(alert, userId, t)}
            {showHubName && (
                <>
                  {' '}
                  <HubName hub={alert.hub} t={t} />
                </>
            )}
            {
              !alert?.isMouseOver && <>
                {' '}
                <ShortTimeIndicator timeStamp={alert?.createdAt} />
              </>
            }
            <Collapse in={alert?.isMouseOver}>
              <TimeIndicator timeStamp={alert?.createdAt} />
            </Collapse>
          </Typography>
        </Box>
        {
          !alert.isSeen && <AlertIndicator />
        }
      </Box>
    </Link>
  );
};

export default React.memo(withTranslation(['translation.Alerts'])(Alert));
