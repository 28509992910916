import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";

const StayInTouch = () => {

    const theme = useTheme();

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://rebelbaseco.activehosted.com/f/embed.php?id=3';
        script.async = true;

        document.body.appendChild(script);
    }, []);

    return (
        <Box sx={{
            width: { xs: '100%', md: 375 },
            display: 'flex',
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            pt: { xs: 7, md: 0 }
        }}>
            <Typography variant="h2" fontWeight="bold" textTransform="uppercase" sx={{
                color: theme?.palette?.common?.white,
                display: "flex"
            }}>
                Stay in the loop
            </Typography>
            <div className="_form_3" />
        </Box>
    )
}

export default StayInTouch;