import {
    set,
    get,
    FieldErrors,
    Field,
    ResolverOptions,
} from 'react-hook-form';
import { validateFieldsNatively } from './validateFieldsNatively';

export const toNestError = <TFieldValues>(
    errors: FieldErrors,
    options: ResolverOptions<TFieldValues>,
): FieldErrors<TFieldValues> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    options.shouldUseNativeValidation && validateFieldsNatively(errors, options);

    const fieldErrors = {} as FieldErrors<TFieldValues>;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const path in errors) {
        const field = get(options.fields, path) as Field['_f'] | undefined;
        set(
            fieldErrors,
            path,
            // @ts-ignore
            Object.assign(errors[path], { ref: field && field.ref }),
        );
    }

    return fieldErrors;
};