import { Map, fromJS } from 'immutable';

import {
  SET_USER,
  SET_USER_ID,
  SET_JWT,
  SET_EMAIL,
  SET_USER_CONSENT,
  SET_USER_PREFERENCE,
} from './userActions';

// REDUCER
const initUser = Map(
  fromJS({
    email_address: null,
    first_name: null,
    id: null,
    last_name: null,
    jwt: null,
    location_map: null,
    purpose_id: null,
    user_consent: null,
    user_preference: null,
  })
);
function user(state = initUser, action) {
  switch (action.type) {
    case SET_USER:
      return state.merge(action.data);
    case SET_USER_ID:
      return state.set('id', action.userId);
    case SET_JWT:
      return state.set('jwt', action.jwt);
    case SET_EMAIL:
      return state.set('email_address', action.emailAddress);
    case SET_USER_CONSENT:
      return state.set('user_consent', fromJS(action.data));
    case SET_USER_PREFERENCE:
      return state.set('user_preference', fromJS(action.data));
    default:
      return state;
  }
}

export default user;
