import { Theme } from '@mui/material/styles';

const DEFAULT_BORDER_RADIUS = 10;
const DEFAULT_INPUT_HEIGHT = 56;

const getThemedComponents = (theme: Theme): { components: Theme['components'] } => {
  return {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            boxSizing: 'border-box',
          },
          html: {
            height: '100%',
            width: '100%',
            fontFamily: "'Lato', sans-serif",
          },
          body: {
            height: '100vh',
            minHeight: '100vh',
            margin: 0,
            padding: 0,
            fontFamily: "'Lato', sans-serif",
          },
          '#root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '100vh',
          },
          '.MuiCardHeader-action': {
            alignSelf: 'center !important',
          },
          p: {
            fontFamily: "'Lato', sans-serif",
          },
          '&.MuiTypography-root': {
            padding: 0,
            border: 0,
            outline: 0,
            verticalAlign: 'baseline',
            fontStyle: 'normal',
          },
          '&.MuiTypography-root.capitalize': {
            textTransform: 'capitalize',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            paddingTop: '0 !important',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: theme?.palette?.common.white,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          },
        },
      },
      /**
       * Tooltip specification can be found in below link. Should be applied for all components
       * @reference
       *  - https://rebel-base.atlassian.net/wiki/spaces/REBELBASE/pages/23232513/Rebel+Dashboard+Reference+codes
       */
      MuiTooltip: {
        styleOverrides: {
          tooltip: theme.unstable_sx({
            p: 2,
            maxWidth: 256,
            background: theme?.palette?.grey['600'],
            borderRadius: 1
          })
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => {
            const state = {
              minHeight: DEFAULT_INPUT_HEIGHT,
              minWidth: 248,
              borderRadius: DEFAULT_BORDER_RADIUS,
            };
            if (ownerState.size === 'small') {
              state.minHeight = 44;
              state.minWidth = 102;
              state.borderRadius = 5;
            } else if (ownerState.size === 'medium') {
              state.minWidth = 174;
            }
            return theme.unstable_sx(state);
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  minHeight: DEFAULT_INPUT_HEIGHT,
                }
              }
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ ownerState }) => {
            const state = {
              minHeight: DEFAULT_INPUT_HEIGHT,
              minWidth: 320
            };
            if (ownerState.size === 'small') {
              state.minHeight = 32;
              state.minWidth = 128;
            }
            return theme.unstable_sx({
              ...state,
              '&.MuiOutlinedInput-root': {
                borderRadius: DEFAULT_BORDER_RADIUS,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme?.palette?.grey?.[600]
                }
              }
            })
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            borderRadius: '9px',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: theme.unstable_sx({
            pb: 0,
            pt: 0
          }),
        }
      },
      MuiCard: {
        styleOverrides: {
          root: theme.unstable_sx({
            padding: 0,
            margin: 0,
          }),
        },
      },
      MuiList: {
        styleOverrides: {
          root: theme.unstable_sx({
            p: theme.spacing(0)
          }),
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '40px',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '&.rebel-disabled': {
              '& .MuiTabs-indicator': {
                backgroundColor: theme?.palette?.action?.disabledBackground,
              },
              '& .Mui-selected': {
                color: theme?.palette?.action?.disabled,
              }
            }
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: theme.unstable_sx({
            color: theme?.palette?.grey['600'],
            minHeight: 80,
            minWidth: 96
          })
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: theme.unstable_sx({
            backgroundColor: '#ecf0f2',
            borderRadius: DEFAULT_BORDER_RADIUS,
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingBottom: '1rem',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            '& .MuiMenuItem-root': {
              '&.Mui-selected': {
                color: theme?.palette?.common?.white,
                backgroundColor: theme?.palette?.primary?.main
              },
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: theme.unstable_sx({
            cursor: 'pointer'
          }),
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: theme.unstable_sx({
            backgroundColor: theme?.palette?.common?.white
          }),
        }
      },
      MuiChip: {
        styleOverrides: {
          root: theme.unstable_sx({
            borderRadius: 1,
            typography: 'body1',
            borderColor: theme?.palette?.grey["600"],
            boxShadow: 1,
            color: theme?.palette?.secondary?.main
          })
        }
      }
    }
  }
};

export default getThemedComponents;
