import React from 'react';
import { connect } from 'react-redux';
import './NoPermission.scss';
import noPermission from 'images/no_permission_robot.png';
import { withTranslation } from 'react-i18next';

const NoPermission = ({ t }) => (
  <div className="noPermission">
    <img src={noPermission} alt="" />
    <div>
      <h2>{t('NoPermissionTitle')}</h2>
      <p>{t('NoPermissionPhrase')}</p>
    </div>
  </div>
);

function mapStateToProps(state) {
  const { user } = state;
  const userId = user.get('id');
  return {
    userId,
  };
}

export default withTranslation(['translation.utils'])(
  connect(mapStateToProps)(NoPermission)
);
