export const SET_DATA_SUBMITTED_SUCCESSFULLY =
  'SET_DATA_SUBMITTED_SUCCESSFULLY';
export const RESET_DATA_SUBMITTED_SUCCESSFULLY =
  'RESET_DATA_SUBMITTED_SUCCESSFULLY';
export const SET_OWNED_BY_ME_FILTER = 'SET_OWNED_BY_ME_FILTER';
export const SET_HUB_ACTIVITY_FILTER = 'SET_HUB_ACTIVITY_FILTER';
export const SET_ACTIVITES_DATA = 'SET_ACTIVITES_DATA';
export const APPEND_ACTIVITIES_DATA = 'APPEND_ACTIVITIES_DATA';
export const SET_ERROR_FETCHING_ACTIVITIES = 'SET_ERROR_FETCHING_ACTIVITIES';
export const UNSET_ERROR_FETCHING_ACTIVITIES =
  'UNSET_ERROR_FETCHING_ACTIVITIES';
export const SET_LOADING_ACTIVITIES_DATA = 'SET_LOADING_ACTIVITIES_DATA';
export const SET_HUB_ACTIVITY_LIKE = 'SET_HUB_ACTIVITY_LIKE';
export const DELETE_HUB_ACTIVITY_LIKE = 'DELETE_HUB_ACTIVITY_LIKE';
export const DELETE_HUB_ACTIVITY = 'DELETE_HUB_ACTIVITY';
export const DELETE_HUB_ACTIVITY_REPLY = 'DELETE_HUB_ACTIVITY_REPLY';
export const UPDATE_HUB_ACTIVITY = 'UPDATE_HUB_ACTIVITY';
export const SET_HUB_ACTIVITY_REPLY_LIKE = 'SET_HUB_ACTIVITY_REPLY_LIKE';
export const DELETE_HUB_ACTIVITY_REPLY_LIKE = 'DELETE_HUB_ACTIVITY_REPLY_LIKE';
export const APPEND_ACTIVITY_REPLY = 'APPEND_ACTIVITY_REPLY';
export const SET_MORE_OWNED_BY_ME_POSTS = 'SET_MORE_OWNED_BY_ME_POSTS';
export const SET_SINGLE_HUB_ACTIVITY = 'SET_SINGLE_HUB_ACTIVITY';
export const SET_ERROR_FETCHING_SINGLE_ACTIVITY =
  'SET_ERROR_FETCHING_SINGLE_ACTIVITY';
export const UNSET_ERROR_FETCHING_SINGLE_ACTIVITY =
  'UNSET_ERROR_FETCHING_SINGLE_ACTIVITY';
export const TOGGLE_ANNOUNCEMENT_STICKY = 'TOGGLE_ANNOUNCEMENT_STICKY';
export const SET_LOADING_SINGLE_ACTIVITY = 'SET_LOADING_SINGLE_ACTIVITY';
