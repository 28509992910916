import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@mui/material';
import Modal from 'components/Modal';

const InviteErrorModal = ({ isOpen, close, t, errorEmails = [] }) => (
  <Modal isOpen={isOpen} handleClose={close} title={t('errorSendingInvites')}>
    <Box sx={{ my: 2, width: '80vw', maxWidth: '700px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: '1.1rem' }}>{t('Email')}</TableCell>
            <TableCell sx={{ fontSize: '1.1rem' }} align="right">
              {t('errorMessage')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errorEmails.map((row) => (
            <TableRow
              key={row.email_address}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.email_address}
              </TableCell>
              <TableCell align="right">{t(row.error)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  </Modal>
);

export default InviteErrorModal;
