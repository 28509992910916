import Cookie from 'js-cookie';
import { request } from 'functions';
import { fetchBuildersOverview } from 'store/builderData/builderDataActions';
import {
  API_GET,
  API_POST,
  API_PUT,
  API_DELETE,
} from '../middleware/middlewareConstants';
import {
  SET_GROUPS,
  SET_SINGLE_GROUP,
  SET_TEMPLATE,
  SET_GROUP_NAME,
  SET_ACTIVATION,
  REMOVE_DELETE,
  SET_CHANGED_PROJECT,
  SHOW_SWITCH_PROJECT,
  HIDE_SWITCH_PROJECT,
  SET_USER_PROJECT,
  PARSE_PROJECTS,
  SET_INITIAL_BUILDERS,
  ADD_BUILDER,
  REMOVE_BUILDER,
  ADD_FETCHED_BUILDER,
  CLEAR_BUILDER,
  ADD_TEMPLATE,
  ADD_TEMPLATE_WITHOUT_SORT,
  REMOVE_TEMPLATE_FLAG,
  DATE_CHANGED_FALSE,
  DATE_CHANGED_TRUE,
  TIME_CHANGED_FALSE,
  TIME_CHANGED_TRUE,
  BUILDER_CHANGED_FALSE,
  BUILDER_CHANGED_TRUE,
  CLEAR_DATA,
  CLEAR_FETCHED,
  CLEAR_TEMPLATES,
  SAME_TEMPLATE_TRUE,
  SAME_TEMPLATE_FALSE,
  SET_LOADER,
} from './constants';

export const setGroupData = (data) => ({
  type: SET_GROUPS,
  data,
});

export const setTemplateData = (data) => ({
  type: SET_TEMPLATE,
  data,
});

export const setSingleGroup = (data) => ({
  type: SET_SINGLE_GROUP,
  data,
});

export const setGroupName = (data) => ({
  type: SET_GROUP_NAME,
  data,
});

export const setActivateGroup = (data) => ({
  type: SET_ACTIVATION,
  data,
});

export const removeDeletedGroup = (data) => ({
  type: REMOVE_DELETE,
  data,
});

export const setChangedProject = (data) => ({
  type: SET_CHANGED_PROJECT,
  data,
});

export const setUProject = (data) => ({
  type: SET_USER_PROJECT,
  data,
});

export const parseProjects = (data) => ({
  type: PARSE_PROJECTS,
  data,
});

export const setInitialBuilders = (data) => ({
  type: SET_INITIAL_BUILDERS,
  data,
});

export const addBuilder = (data) => ({
  type: ADD_BUILDER,
  data,
});

export const addTemplate = (data) => ({
  type: ADD_TEMPLATE,
  data,
});
export const addTemplateWithoutSort = (data) => ({
  type: ADD_TEMPLATE_WITHOUT_SORT,
  data,
});

export const removeTemplateFlag = () => ({
  type: REMOVE_TEMPLATE_FLAG,
});

export const dateChangedTrue = () => ({
  type: DATE_CHANGED_TRUE,
});

export const clearDataGroup = () => ({
  type: CLEAR_DATA,
});

export const setLoader = () => ({
  type: SET_LOADER,
});
export const sameTemplateTrue = () => ({
  type: SAME_TEMPLATE_TRUE,
});
export const sameTemplateFalse = () => ({
  type: SAME_TEMPLATE_FALSE,
});

export const clearTemplates = () => ({
  type: CLEAR_TEMPLATES,
});

export const clearFetchedGroup = () => ({
  type: CLEAR_FETCHED,
});

export const dateChangedFalse = () => ({
  type: DATE_CHANGED_FALSE,
});

export const timeChangedTrue = () => ({
  type: TIME_CHANGED_TRUE,
});

export const timeChangedFalse = () => ({
  type: TIME_CHANGED_FALSE,
});

export const builderChangedTrue = () => ({
  type: BUILDER_CHANGED_TRUE,
});

export const builderChangedFalse = () => ({
  type: BUILDER_CHANGED_FALSE,
});

export const addFetchedBuilder = (data) => ({
  type: ADD_FETCHED_BUILDER,
  data,
});

export const removeBuilder = (data) => ({
  type: REMOVE_BUILDER,
  data,
});

export const clearBuilder = () => ({
  type: CLEAR_BUILDER,
});

export const showSwitchProject = () => ({
  type: SHOW_SWITCH_PROJECT,
});
export const hideSwitchProject = () => ({
  type: HIDE_SWITCH_PROJECT,
});

export const getTemplates = (cb = () => {}) => {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-group-templates`,
      success: null,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export const getHubGroups = (hub_id, cb = () => {}) => {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-groups?hub_id=${hub_id}`,
      success: setGroupData,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export const getSingleGroup = (group_id, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-groups/${group_id}`,
      success: setSingleGroup,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export const getAllAvailableBuilders = (hubId, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
          (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/hubs/${hubId}/library/builders`,
      success: null,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
}

export const getBuilderForGroup = (hubId, groupId, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/hubs/${hubId}/groups/${groupId}/builders`,
      success: setSingleGroup,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export const updateGroupName = (name, group_id, cb, errorCb) => {
  const token = Cookie.get('token');
  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-groups/${group_id}`,
      body: {
        hub_group: {
          name,
        },
      },
      success: setGroupName,
      onSuccess: cb,
      token: token !== undefined ? token : null,
      onError: errorCb,
    },
  };
};

export const setUserVerifiedProject = (hub_group_project_id, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-group-projects/${hub_group_project_id}`,
      body: {
        hub_group_project: {
          user_verified_project_submission: true,
        },
      },
      success: null,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export function setUserVerifiedProjectFromBuilders(
  hub_group_project_id,
  projectId
) {
  return (dispatch) => {
    const token = Cookie.get('token');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v3/rebel/affiliations/hub-group-projects/${hub_group_project_id}`;

    const payload = {
      hub_group_project: {
        user_verified_project_submission: true,
      },
    };
    return request(url, token, 'PUT', payload).then(() =>
      dispatch(fetchBuildersOverview(projectId))
    );
  };
}

export const deleteAutoProject = (hub_group_project_id, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_DELETE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-group-projects/${hub_group_project_id}`,
      body: null,
      success: null,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export const deleteAutoProjectFromBuilders = (
  hub_group_project_id,
  projectId
) => {
  return (dispatch) => {
    const token = Cookie.get('token');

    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v3/rebel/affiliations/hub-group-projects/${hub_group_project_id}`;

    return request(url, token, 'DELETE', null).then(() =>
      dispatch(fetchBuildersOverview(projectId))
    );
  };
};

export const activateGroup = (
  action,
  group_id,
  cb = () => {},
  errorCb = () => {}
) => {
  const token = Cookie.get('token');
  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-groups/${group_id}`,
      body: {
        hub_group: {
          is_active: action,
        },
      },
      success: setActivateGroup,
      onSuccess: cb,
      token: token !== undefined ? token : null,
      onError: errorCb,
    },
  };
};

export const deleteGroup = (id, cb, errorCb) => {
  const token = Cookie.get('token');
  return {
    type: API_DELETE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-groups/${id}`,
      body: null,
      success: removeDeletedGroup,
      id,
      onSuccess: cb,
      token: token !== undefined ? token : null,
      onError: errorCb,
    },
  };
};

export const deleteGroupMember = (user_hub_group_role_id, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_DELETE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/user-hub-group-roles/${user_hub_group_role_id}`,
      body: null,
      success: null,
      onSuccess: cb,
      token: token !== undefined ? token : null,
    },
  };
};

export const saveBuilderSchedule = (data, onSuccess) => {
  const { hub_id, group_id, builders } = data;
  const token = Cookie.get('token');

  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/hubs/${hub_id}/groups/${group_id}/builders`,
      body: {
        group_id,
        builders,
      },
      onSuccess,
      token: token !== undefined ? token : null,
      success: null,
    },
  };
};

export const changeProject = (data, onSuccess = () => {}) => {
  const { group_id, project_id } = data;
  const token = Cookie.get('token');

  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-group-projects`,
      body: {
        group_project: {
          project_id,
          group_id,
        },
      },
      onSuccess,
      token: token !== undefined ? token : null,
      success: setChangedProject,
    },
  };
};

export const addGroupMembers = (data, onSuccess) => {
  const { group_id, user_ids, admin_ids } = data;
  const token = Cookie.get('token');

  if (admin_ids !== null) {
    return {
      type: API_POST,
      payload: {
        url: `${
          (window.env || process.env).REACT_APP_API_ENDPOINT
        }/api/v3/rebel/affiliations/user-hub-group-roles`,
        body: {
          group_id,
          admin_ids,
        },
        onSuccess,
        token: token !== undefined ? token : null,
        success: null,
      },
    };
  }
  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/user-hub-group-roles`,
      body: {
        group_id,
        user_ids,
      },
      onSuccess,
      token: token !== undefined ? token : null,
      success: null,
    },
  };
};

export const createGroup = (data, onSuccess = () => {}, onError) => {
  const { hub_id, name, user_ids } = data;
  const token = Cookie.get('token');

  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/affiliations/hub-groups`,
      body: {
        hub_group: {
          hub_id, // required
          name, // required
          user_ids, // required but can be empty list
        },
      },
      onSuccess,
      token: token !== undefined ? token : null,
      success: null,
      onError,
    },
  };
};

export const SaveCustomTemplate = (data, onSuccess = () => {}) => {
  const { name, builders } = data;
  const token = Cookie.get('token');

  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/builders/builder-schedule-custom-templates`,
      body: {
        builder_schedule_custom_template: {
          name,
          builders,
        },
      },
      onSuccess,
      token: token !== undefined ? token : null,
      success: null,
    },
  };
};

export const deleteCustomTemplate = (template_id, cb) => {
  const token = Cookie.get('token');
  return {
    type: API_DELETE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/builders/builder-schedule-custom-templates/${template_id}`,
      body: null,
      success: null,
      onSuccess: cb,
      id: template_id,
      token: token !== undefined ? token : null,
    },
  };
};

export const overwriteTemplate = (data, onSuccess = () => {}) => {
  const { id, builders } = data;
  const token = Cookie.get('token');

  return {
    type: API_PUT,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v3/rebel/builders/builder-schedule-custom-templates/${id}`,
      body: {
        builder_schedule_custom_template: {
          builders,
        },
      },
      onSuccess,
      token: token !== undefined ? token : null,
      success: null,
    },
  };
};
