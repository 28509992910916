import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {Box, Collapse, useTheme} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";

const ContactUs = () => {

    const theme = useTheme();

    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: "column",
            alignItems: {xs: "center", md: "flex-start"},
            pt: {xs: 7, md: 10}
        }}>
            <Typography variant="h2" fontWeight="bold" textTransform="uppercase" sx={{
                color: theme?.palette?.common?.white,
                display: "flex",
                alignItems: "center"
            }}>
                Contact Us
                <ExpandMoreIcon
                    sx={{display: {xs: 'block', md: 'none'}}}
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                />
            </Typography>

            <Collapse sx={{
                width: {xs: '100%', md: 'auto'}
            }} in={expanded} timeout="auto" unmountOnExit>
                <Stack columnGap={7} rowGap={5} sx={{
                    flexDirection: {xs: 'column', md: 'row'},
                    mt: 3,
                    color: theme?.palette?.common?.white
                }}>
                    <Box sx={{
                            textAlign: {xs: 'center', md: 'left'},
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                         }}
                         component="a"
                         href="https://rebelbase.co/contact-us/"
                         target="_blank"
                         rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: {xs: '100%', md: 125},
                            textAlign: {xs: 'center', md: 'left'}
                        }}>
                            Schedule Demo
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        component="a"
                        href="https://rebelbase.zendesk.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: {xs: '100%', md: 65},
                            textAlign: {xs: 'center', md: 'left'}
                        }}>
                            Support
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        component="a"
                        href="https://rebelbase.zendesk.com/hc/en-us"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: {xs: '100%', md: 45},
                            textAlign: {xs: 'center', md: 'left'}
                        }}>
                            FAQs
                        </Typography>
                    </Box>
                </Stack>
            </Collapse>
        </Box>
    )
}

export default ContactUs;