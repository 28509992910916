import { combineReducers } from 'redux';
import { Map, fromJS, Set } from 'immutable';
import {
  SET_BROWSE_PROFILE_DATA,
  RESET_BROWSE_PROFILE_DATA,
  SET_BROWSE_PROFILE_LOADED,
  SET_BROWSE_EVENT_DATA,
  SET_BROWSE_EVENT_NOT_FOUND,
} from './browseActions';

const initBrowseProfile = Map(
  fromJS({
    experience: {
      data: [],
    },
    avatar: null,
    user: {
      first_name: null,
      last_name: null,
    },
    purpose: {
      label: null,
    },
    socialLinks: {
      twitter: null,
      facebook: null,
      linkedin: null,
    },
    isPublic: false,
    location: null,
    hasLoaded: false,
    judgeBio: {
      bio: null,
      title: null,
    },
    projects: [],
  })
);

function browseProfile(state = initBrowseProfile, action) {
  switch (action.type) {
    case SET_BROWSE_PROFILE_DATA:
      return initBrowseProfile
        .set('user', fromJS(action.data.user))
        .set('experience', fromJS(action.data.experiences))
        .set('avatar', action.data.avatar_url)
        .set('isPublic', action.data.is_public)
        .set('summary', action.data.summary)
        .setIn(['purpose', 'label'], action.data.purpose)
        .set('location', fromJS(action.data.location))
        .set('socialLinks', fromJS(action.data.social_link))
        .set('projects', fromJS(action.data.projects.data))
        .set('judgeBio', fromJS(action.data.judge_bio));
    case SET_BROWSE_PROFILE_LOADED:
      return state.set('hasLoaded', true);
    case RESET_BROWSE_PROFILE_DATA:
      return initBrowseProfile;
    default:
      return state;
  }
}

const initBrowseEvent = Map(
  fromJS({
    hubEventProjects: Set(),
    hubEvent: Map(),
    userEventRoles: Set(),
    judgeBios: Set(),
    hubEventWinners: Set(),
    hubEventSponsors: Set(),
    hubEventScoringRounds: Set(),
    hubEventScoringRoundProjects: Set(),
    hubEventScoringRoundProjectScores: Set(),
    hubEventPosts: Set(),
    hubEventPostBoosts: Set(),
  })
);
function browseEvent(state = initBrowseEvent, action) {
  switch (action.type) {
    case SET_BROWSE_EVENT_DATA:
      return Map(
        fromJS({
          hubEventProjects: Set(fromJS(action.data.hub_event_projects.data)),
          hubEvent: Map(fromJS(action.data.hub_event.data)),
          userEventRoles: Set(fromJS(action.data.user_event_roles.data)),
          judgeBios: Set(fromJS(action.data.judge_bios.data)),
          hubEventWinners: Set(fromJS(action.data.hub_event_winners.data)),
          hubEventSponsors: Set(fromJS(action.data.hub_event_sponsors.data)),
          hubEventScoringRounds: Set(
            fromJS(action.data.hub_event_scoring_rounds.data)
          ),
          hubEventScoringRoundProjects: Set(
            fromJS(action.data.hub_event_scoring_round_projects.data)
          ),
          hubEventScoringRoundProjectScores: Set(
            fromJS(action.data.hub_event_scoring_round_project_scores.data)
          ),
          hubEventPosts: Set(fromJS(action.data.hub_event_posts.data)),
          hubEventPostBoosts: Set(
            fromJS(action.data.hub_event_post_boosts.data)
          ),
        })
      );
    case SET_BROWSE_EVENT_NOT_FOUND:
      return Map(
        fromJS({
          hubEvent: {
            notFound: true,
          },
        })
      );
    default:
      return state;
  }
}

export default combineReducers({
  browseProfile,
  browseEvent,
});
