import React from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import CustomImageInput from 'FormInputs/CustomImageInput';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { uploadAvatar } from 'store/avatar-v1/avatarActions';
import emptyAvatarImage from 'images/empty_profile.png';

const AvatarUploadForm = ({
  renderSaveButton,
  skip,
  avatar,
  isSignup,
  t,
}) => {
  const dispatch = useDispatch();

  const save = (values) => {
    dispatch(uploadAvatar(values, skip));
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        const formData = new FormData();
        formData.append('_utf8', true);
        formData.append('rebel_profile[avatar]', values.picture);
        save(formData);
      }}
    >
      {(props) => (
        <Form className="sign-up__avatar-upload" encType="multipart/form-data">
          <div className="flex-container">
            <div className="flex-child">
              <h4>{t('AvatarUploadHeadingPart1')}</h4>
              <h1>{t('Photo')}</h1>
              <label htmlFor="picture" className="choose-btn">
                {t('select')}
              </label>
              <Field
                name="picture"
                className="img-upload"
                imageType="avatar-upload"
                component={CustomImageInput}
                setFieldValue={props.setFieldValue}
                touched={props.touched.picture}
                onBlur={props.handleBlur}
              />
              {props.values.picture && props.values.picture.size > 10485760 && (
                <>
                  <br />
                  <br />
                  <br />
                  <span style={{ color: 'red' }}>{t('PhotoLimit')}</span>
                </>
              )}
            </div>
            <div className="image-container">
              {props.values.picture || avatar ? (
                <img
                  className="flex-child avatar-display"
                  src={
                    (props.values.picture &&
                      window.URL.createObjectURL(props.values.picture)) ||
                    avatar
                  }
                  alt="temp"
                  style={{ height: '200px', width: 'auto' }}
                />
              ) : (
                <img
                  className="flex-child avatar-display"
                  src={emptyAvatarImage}
                  style={{ height: '200px', width: 'auto' }}
                  alt="default avatar"
                />
              )}
            </div>
          </div>

          <div className="btn-wrap">
            {isSignup && (
              <button type="button" className="btn-skip" onClick={() => skip()}>
                {t('SkipForNow')} &gt;
              </button>
            )}
            <div>{renderSaveButton(props.values.picture)}</div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AvatarUploadForm.propTypes = {
  renderSaveButton: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  avatar: PropTypes.string,
  isSignup: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func,
  touched: PropTypes.instanceOf(Object),
  handleBlur: PropTypes.func,
  values: PropTypes.instanceOf(Object),
  t: PropTypes.func.isRequired,
};

AvatarUploadForm.defaultProps = {
  avatar: '',
  setFieldValue: () => {},
  touched: {},
  handleBlur: () => {},
  values: {},
};

export default withTranslation(['translation.forms'])(AvatarUploadForm);
