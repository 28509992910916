import React, { Component } from 'react';
import './styles/multi_email.scss';
import { validateEmail } from 'functions';
import { withTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

class MultiEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      value: '',
      error: null,
    };

    const { inputRef } = this.props;
    if (typeof inputRef === 'function') inputRef(this);
    this.nameInput = React.createRef();
  }

  handleKeyDown = (evt) => {
    const { form, field } = this.props;

    if (
      ['Enter', 'Tab', ','].includes(evt.key) ||
      evt.charCode === 32 ||
      evt.keyCode === 32 ||
      evt.keyCode === 186
    ) {
      evt.preventDefault();

      const value = this.state.value.trim();

      if (value && this.isValid(value)) {
        this.setState(
          {
            items: [...this.state.items, value],
            value: '',
          },
          () => {
            this.nameInput.current.focus();
            form.setFieldValue(field.name, this.state.items);
            form.setFieldValue('raw_email', null);
          }
        );
      }
    }
  };

  cleanEmail = (email) => {
    return email.replace(/[\r\n\t]+/gm, '').trim();
  };

  handlePaste = (evt) => {
    const { form, field, setError, t } = this.props;
    evt.preventDefault();
    setError(null);
    this.setState({
      error: null,
    });

    const paste = evt.clipboardData.getData('text');

    const emails = paste.split(/[;, \r\n\t]/);

    let emailsSent = 0;
    const validatedLists = emails.filter((email) => {
      if (email.length > 0) {
        emailsSent += 1;
        return validateEmail(this.cleanEmail(email));
      }
      return false;
    });

    if (validatedLists.length < emailsSent) {
      this.setState({
        error: t('errorMessage'),
      });
      setError(t('errorMessage'));
    }

    if (validatedLists.length > 0) {
      const toBeAdded = validatedLists.filter((email) => !this.isInList(email));

      this.setState(
        {
          items: [...this.state.items, ...toBeAdded],
          value: '',
        },
        () => {
          this.nameInput.current.focus();
          form.setFieldValue(field.name, this.state.items);
        }
      );
    } else {
      this.setState({
        error: t('errorMessage'),
      });
      setError(t('errorMessage'));
    }
  };

  handleChange = (evt) => {
    const { form, setError } = this.props;
    setError(null);
    this.setState(
      {
        value: evt.target.value,
        error: null,
      },
      () => {
        form.setFieldValue('raw_email', this.state.value);
      }
    );
  };

  handleDelete = (item) => {
    const { form, field } = this.props;

    this.setState(
      {
        items: this.state.items.filter((i) => i !== item),
      },
      () => {
        this.nameInput.current.focus();
        form.setFieldValue(field.name, this.state.items);
      }
    );
  };

  isValid(email) {
    let error = null;
    const { t } = this.props;

    if (this.isInList(email)) {
      error = `${email}${t('emailAlreadyAddedError')}`;
    }

    if (!validateEmail(email)) {
      error = `${email}${t('emailNotValidError')}`;
    }

    if (error) {
      this.props.setError(error);
      this.setState({ error });
      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  resetMultiEmail() {
    this.setState({ items: [], value: '' });
  }

  render() {
    const { value, items } = this.state;
    const {
      placeholder,
      className,
      inputFieldSize = undefined,
      disabled = false,
      InputProps = {},
      showChips = true,
    } = this.props;

    return (
      <div className={className}>
        {showChips && (
          <div>
            {items.map((item) => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => this.handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}
        <TextField
          fullWidth
          ref={this.nameInput}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onPaste={this.handlePaste}
          size={inputFieldSize || 'small'}
          InputProps={InputProps}
        />
        {this.state.error && this.props.showError && (
          <p className="error">{this.state.error}</p>
        )}
      </div>
    );
  }
}

MultiEmail.defaultProps = {
  showError: true,
  setError: () => {},
};

export default withTranslation(['translation.forms'])(MultiEmail);
