import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SEO from 'components/SEO';
import './profile.scss';
import { withTranslation } from 'react-i18next';

import {
  RESET_BROWSE_PROFILE_DATA,
  fetchUserProfile,
} from 'store/browse/browseActions';
import AvatarUpload from 'components/SignupProcess/AvatarUpload/AvatarUpload';
import LoadingComponent from 'components/Loading/loadingComponent';
import NoMatch from 'Pages/NoMatch';

// export default Profile;
import ProfileProgressBar from './components/ProfileProgressBar';
import Visibility from './components/Visibility';
import ProfileHeader from './components/ProfileHeader';

// should these be in containers folder since they will mutate global state?
import UserProjects from './components/UserProjects';
import SocialLinks from './components/SocialLinks';
import ProfileSummary from './components/ProfileSummary';
import ProfileExperience from './components/ProfileExperience';
import JudgeBio from './components/JudgeBio';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataRequested: false, isAvatarUploadModalOpen: false };
  }

  componentDidMount() {
    if (
      !this.props.isLoading &&
      !this.props.isUsersProfile &&
      this.state.dataRequested === false
    ) {
      this.props.fetchProfile(this.props.match.params.u_id);
      this.setState({ dataRequested: true });
    }
    if (this.props.isSignup) {
      this.setState({ isAvatarUploadModalOpen: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.params.u_id !== nextProps.match.params.u_id) {
      nextProps.reset();
      this.setState({ dataRequested: false });
    }
    if (nextState.dataRequested === false) {
      if (!nextProps.isLoading && !nextProps.isUsersProfile) {
        nextProps.fetchProfile(nextProps.match.params.u_id);
        this.setState({ dataRequested: true });
      }
    }
    return true;
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleAvatarUploadModal = (state = false) => {
    this.setState({
      isAvatarUploadModalOpen: state,
    });
  };

  renderNoPermission() {
    const { firstName, t } = this.props;
    return (
      <div className="no_permission">
        <div className="no_permission__wrap">
          <div className="no_permission__cont">
            <h1>
              {t('View')} {firstName}&#39;{t('fullProfile')}
            </h1>
            <h2>{t('ProfilePhrase1')}</h2>
            <Link className="link-btn" to="/auth/sign-up">
              {t('signUpFree')}{' '}
            </Link>
            <br />
            <Link className="no_permission__cont__logIn" to="/auth/login">
              {t('or')} {t('Login')}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isUsersProfile, isProfilePublic, isRequestingData, doesUserExist } =
      this.props;

    if (isRequestingData) {
      return <LoadingComponent />;
    }

    if (!doesUserExist) {
      return <NoMatch />;
    }

    return (
      <SEO title={isUsersProfile ? this.props.fullName : this.props.name}>
        <div className="userProfile ">
          {isUsersProfile && <ProfileProgressBar />}
          <div className="userProfile__wrap">
            <div className="bioInfo">
              <ProfileHeader
                isUsersProfile={isUsersProfile}
                open={() => this.handleAvatarUploadModal(true)}
              />
              {isUsersProfile && <Visibility />}
              <SocialLinks isUsersProfile={isUsersProfile} />
              <UserProjects isUsersProfile={isUsersProfile} />
            </div>
            <div className="contentInfo">
              {!isProfilePublic ? (
                <div>{this.renderNoPermission()}</div>
              ) : (
                <div>
                  <ProfileSummary isUsersProfile={isUsersProfile} />
                  <JudgeBio isUsersProfile={isUsersProfile} />
                  <ProfileExperience isUsersProfile={isUsersProfile} />
                </div>
              )}
            </div>
          </div>
        </div>
        <AvatarUpload
          isOpen={this.state.isAvatarUploadModalOpen}
          closeModal={this.handleAvatarUploadModal}
        />
      </SEO>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProfile(userId) {
    dispatch(fetchUserProfile(userId));
  },
  reset() {
    dispatch({ type: RESET_BROWSE_PROFILE_DATA });
  },
});

const mapStateToProps = (state, props) => {
  const { user, metadata, browse } = state;

  // check if the current user is viewing his/her own profile
  const isUsersProfile =
    user.get('id') === parseInt(props.match.params.u_id, 10);

  const firstName = user?.toJS().first_name;
  const fullName = firstName ? `${firstName} ${user.toJS().last_name}` : null;

  const name = browse?.browseProfile?.getIn(['user', 'first_name'])
    ? `${browse.browseProfile.getIn([
        'user',
        'first_name',
      ])} ${browse.browseProfile.getIn(['user', 'last_name'])}`
    : null;

  // this is to render 404 in case a user does not exist.
  // we assume that the user does not exist while the request is being made
  const doesUserExist =
    isUsersProfile || (name && browse.browseProfile.get('hasLoaded'));

  const isLoading =
    !metadata.loading.get('userLoaded') &&
    !metadata.loading.get('profileDataLoaded');
  const isRequestingData = isUsersProfile
    ? isLoading
    : !browse.browseProfile.get('hasLoaded');

  // check whether the profile being viewed is public or not
  const isProfilePublic =
    isUsersProfile || browse.browseProfile.get('isPublic') || user.get('jwt');

  return {
    name,
    isUsersProfile,
    isProfilePublic,
    firstName,
    fullName,
    isLoading,
    doesUserExist,
    isRequestingData,
    userInvitationsLoaded: metadata.loading.get('userInvitationsLoaded'),
    invitations: state.invitations,
    isSignup: metadata.isSignup,
  };
};

export default withTranslation(['translation.Profile'])(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
