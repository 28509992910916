import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ExperienceEntry from './ExperienceEntry';
import ExperienceEntryForm from './ExperienceEntryForm';

import {
  toggleEditExperience,
  toggleAddExperience,
  fetchExperience,
} from '../../../store/profile/profileActions';

class ProfileExperience extends React.Component {
  addNewExperience() {
    if (this.props.isAddingExperience) {
      return <ExperienceEntryForm form="ExperienceFormNew" />;
    }
    return null;
  }

  renderEmptyExperience() {
    const { isUsersProfile, t } = this.props;
    if (isUsersProfile) {
      return (
        <div className="experience__empty">
          <span className="experience__empty__prompt">
            {' '}
            {t('ProfilePhrase5')}{' '}
          </span>
        </div>
      );
    }

    return (
      <div>
        <div className="experience__empty__prompt">
          <span>NoExperience</span>
        </div>
      </div>
    );
  }

  renderExperiences() {
    if (this.props.experience.length === 0) {
      return this.renderEmptyExperience();
    }

    const experienceEntries = this.props.experience.sort((a, b) => {
      if (
        (a.present && b.present) ||
        (new Date(b.end_date).getMonth() === new Date(a.end_date).getMonth() &&
          new Date(b.end_date).getYear() === new Date(a.end_date).getYear())
      ) {
        return new Date(b.start_date) - new Date(a.start_date);
      }
      if (b.present) {
        return 1;
      }
      if (a.present) {
        return -1;
      }
      return new Date(b.end_date) - new Date(a.end_date);
    });

    return experienceEntries.map((experience) => {
      if (this.props.isEditingExperience === experience.id) {
        return (
          <ExperienceEntryForm
            entry={experience}
            formId={experience.id}
            formType="edit"
            form={`ExperienceFormEdit-${experience.id}`}
          />
        );
      }
      return (
        <ExperienceEntry
          key={experience.id}
          experience={experience}
          editExperience={this.props.editExperience}
          isUsersProfile={this.props.isUsersProfile}
        />
      );
    });
  }

  render() {
    const { isUsersProfile, t } = this.props;
    return (
      <div className="experience">
        <div className="userProfile__edit">
          <p className="contentInfo__title">{t('Experience')}</p>
          {isUsersProfile && (
            <button
              className="plus-sign__btn edit-pen__experience"
              onClick={() => this.props.toggleAddExperience()}
            >
              <span className="plus-sign" />
            </button>
          )}
        </div>
        {isUsersProfile && this.addNewExperience()}
        {this.renderExperiences()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (!props.isUsersProfile) {
    return {
      experience: state.browse.browseProfile
        .getIn(['experience', 'data'])
        .toJS(),
    };
  }

  const {
    experience,
    isEditingExperience,
    isAddingExperience,
    forms,
  } = state.profile;

  return {
    forms,
    experience: experience.data,
    isEditingExperience,
    isAddingExperience,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchExperience: () => dispatch(fetchExperience()),
  editExperience: (expIndex) => dispatch(toggleEditExperience(expIndex)),
  toggleAddExperience: () => dispatch(toggleAddExperience()),
});

export default withTranslation(['translation.Profile']) (connect(mapStateToProps, mapDispatchToProps)(ProfileExperience));
