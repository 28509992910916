import React, { forwardRef } from 'react';
import { Skeleton, Box, Stack } from '@mui/material';
import { DashboardCard } from 'Pages/Dashboard/dashboardConfig';

const NUMBER_OF_TILES = 10;

export const CarouselSkeletonWithoutHeading = () => (
  <Stack direction="row" gap="1.5rem" sx={{ mt: 3, width: 'max-content' }}>
    {Array(NUMBER_OF_TILES)
      .fill('')
      .map(() => (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={DashboardCard.height}
          width={DashboardCard.width}
        />
      ))}
  </Stack>
);

const CarouselSkeleton = forwardRef((props, ref) => {
  return (
    <Box ref={ref} sx={{ mb: 4, overflow: 'hidden', mr: { xs: -2, sm: 0 } }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={18}
        width={200}
      />
      <CarouselSkeletonWithoutHeading />
    </Box>
  );
});

export default CarouselSkeleton;
