import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  SET_VIEWING_PROJECT_DESCRIPTION,
  SET_VIEWING_PROJECT_ABOUT,
  SET_DESCRIPTION_SAVED,
  SET_PROJECT_ANSWER_SAVED,
  SET_VIEWING_HUB_ACTIVITY,
  SET_REDIRECT,
} from 'store/metadata/metadataActions';

import { CLOSE_PROJECT_NOTES } from 'store/projectProfile-v1/projectProfileConstants';
import i18n from '../../i18n';

export const SHOW_SIDE_NAV = 'SHOW_SIDE_NAV';
export const HIDE_SIDE_NAV = 'HIDE_SIDE_NAV';

export function showSideNav() {
  return {
    type: SHOW_SIDE_NAV,
  };
}

export function hideSideNav() {
  return {
    type: HIDE_SIDE_NAV,
  };
}

// hub
export const SET_FOCUSED_HUB = 'sideNav/SET_FOCUSED_HUB';
export const RESET_FOCUSED_HUB = 'sideNav/RESET_FOCUSED_HUB';

export const OPEN_PROJECT_SELECT = 'OPEN_PROJECT_SELECT';
export const CLOSE_PROJECT_SELECT = 'CLOSE_PROJECT_SELECT';
export const SET_FOCUSED_PROJECT = 'SET_FOCUSED_PROJECT';
export const RESET_FOCUSED_PROJECT = 'RESET_FOCUSED_PROJECT';

export function setHandleRedirect(projectId, url, hubId) {
  return (dispatch, getState) => {
    const { metadata } = getState();

    const hasUnsavedData =
      metadata.projectProfile.get('isDescriptionChanged') ||
      metadata.projectProfile.get('isAboutChanged') ||
      metadata.projectProfile.get('isProjectAnswerChanged') ||
      metadata.hubActivity.get('isEditingHubActivity');

    if (hasUnsavedData) {
      confirmAlert({
        // title: 'Leave Page',
        message: `${
          i18n.getDataByLanguage(i18n.language)['translation.Builders']
            .ConfirmLeavingPage
        }`,
        buttons: [
          {
            label: `${
              i18n.getDataByLanguage(i18n.language)['translation.Builders']
                .NoGoBack
            }`,
            onClick: () => {},
          },
          {
            label: `${
              i18n.getDataByLanguage(i18n.language)['translation.Builders']
                .YesClose
            }`,
            onClick: () => {
              if (projectId) {
                dispatch({
                  type: SET_FOCUSED_PROJECT,
                  projectId,
                });
              }
              if (hubId) {
                dispatch({ type: SET_FOCUSED_HUB, hubId });
              }
              dispatch({
                type: SET_VIEWING_PROJECT_DESCRIPTION,
              });
              dispatch({
                type: SET_VIEWING_PROJECT_ABOUT,
              });
              dispatch({
                type: SET_PROJECT_ANSWER_SAVED,
              });
              dispatch({ type: SET_VIEWING_HUB_ACTIVITY });
              dispatch({
                type: SET_REDIRECT,
                url,
              });
              dispatch({ type: CLOSE_PROJECT_NOTES });
              dispatch({ type: SET_DESCRIPTION_SAVED });
            },
          },
        ],
      });
    } else {
      if (projectId) {
        dispatch({
          type: SET_FOCUSED_PROJECT,
          projectId,
        });
      }
      if (hubId) {
        dispatch({ type: SET_FOCUSED_HUB, hubId });
      }
      dispatch({
        type: SET_VIEWING_PROJECT_DESCRIPTION,
      });
      dispatch({
        type: SET_VIEWING_PROJECT_ABOUT,
      });
      dispatch({
        type: SET_PROJECT_ANSWER_SAVED,
      });

      dispatch({ type: SET_VIEWING_HUB_ACTIVITY });
      dispatch({
        type: SET_REDIRECT,
        url,
      });
      dispatch({ type: CLOSE_PROJECT_NOTES });
    }
  };
}

export const OPEN_PROJECT_DROPDOWN = 'OPEN_PROJECT_DROPDOWN';
export const CLOSE_PROJECT_DROPDOWN = 'CLOSE_PROJECT_DROPDOWN';
export const OPEN_PROJECT_TEAMMATE_INVITE = 'OPEN_PROJECT_TEAMMATE_INVITE';

export const openProjectTeammateInvite = (projectId) => {
  return (dispatch) => {
    dispatch({ type: OPEN_PROJECT_TEAMMATE_INVITE, projectId });
  };
};
