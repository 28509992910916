import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';

import emptyAbout from 'images/empty_about.png';
import emptyAboutView from 'images/empty_about_view.png';
import loader from 'images/loader.gif';
import {
  editSummary,
  saveSummary,
  RESET_EDITING_SUMMARY,
} from '../../../store/profile/profileActions';

class ProfileSummary extends React.Component {
  static renderLoadingState() {
    return (
      <div className="about">
        <p className="contentInfo__title">About</p>
        loading...
      </div>
    );
  }

  renderSaveButton() {
    const { isSavingSummary, t } = this.props;
    if (isSavingSummary) {
      return <img alt="loading icon" src={loader} />;
    }
    return (
      <button className="userProfile__save-btn" type="submit">
        {t('save')}
      </button>
    );
  }

  renderEmptyState() {
    const { edit, isUsersProfile, t } = this.props;

    if (isUsersProfile) {
      return (
        <div className="contentInfo__wrap">
          <div className="about">
            <div className="userProfile__edit">
              <p className="contentInfo__title">{t('About')}</p>
              <button
                className="edit-pen__btn edit-pen__about"
                onClick={() => edit()}
              >
                <span className="edit-pen" />
              </button>
            </div>
            <div className="about__empty">
              <div>
                <img
                  src={emptyAbout}
                  alt="empty landscape prompt"
                  className="about__empty"
                />
              </div>
              <div className="about__empty__prompt">
                <span>{t('ProfilePhrase3')}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="contentInfo__wrap">
        <div className="about">
          <div className="userProfile__edit">
            <p className="contentInfo__title">{t('About')}</p>
          </div>
          <div className="about__empty">
            <div>
              <img
                src={emptyAboutView}
                alt="empty character waving hi"
                className="about__empty"
              />
            </div>
            <div className="about__empty__prompt">
              <span>{t('NoAbout')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderViewState() {
    const { summary, edit, isUsersProfile, t } = this.props;
    return (
      <div className="contentInfo__wrap">
        <div className="about">
          <div className="userProfile__edit">
            <p className="contentInfo__title">{t('About')}</p>
            {isUsersProfile && (
              <button
                className="edit-pen__btn edit-pen__about"
                onClick={() => edit()}
              >
                <span className="edit-pen" />
              </button>
            )}
          </div>
          <div>
            <p>{summary}</p>
          </div>
        </div>
      </div>
    );
  }

  renderEditState() {
    const { save, close, t } = this.props;
    return (
      <div className="contentInfo__wrap">
        <div className="about">
          <div className="userProfile__edit">
            <p className="contentInfo__title">{t('About')}</p>
            <button onClick={() => close()} className="btn-x">
              x
            </button>
          </div>
          <form onSubmit={(e) => save(e)}>
            <Field
              component="textarea"
              type="text"
              name="summary"
              placeholder={t('ProfilePhrase4')}
            />
            <div className="userProfile__save">{this.renderSaveButton()}</div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { isEditingSummary, summaryLoaded, summary } = this.props;
    if (summaryLoaded) {
      if (isEditingSummary) {
        return this.renderEditState();
      }
      if (summary) {
        // incase there is no summary
        return this.renderViewState();
      }
      return this.renderEmptyState();
    }
    return this.constructor.renderLoadingState();
  }
}

const mapDispatchToProps = (dispatch) => ({
  edit() {
    dispatch(editSummary());
  },
  save(event) {
    event.preventDefault();
    dispatch(saveSummary());
  },
  close() {
    dispatch({ type: RESET_EDITING_SUMMARY });
  },
});

const mapStateToProps = (state, props) => {
  if (props.isUsersProfile) {
    const { summary, pageMetaData } = state.profile;
    const isEditingSummary = pageMetaData.get('isEditingSummary');
    const summaryLoaded = pageMetaData.get('summaryLoaded');
    const initialValues = { summary };
    const isSavingSummary = pageMetaData.get('isSavingSummary');
    return {
      summary,
      isEditingSummary,
      summaryLoaded,
      initialValues,
      isSavingSummary,
    };
  }

  const { browse } = state;
  const summary = browse.browseProfile.get('summary');

  return {
    summary,
    summaryLoaded: true,
  };
};

export default withTranslation(['translation.Profile']) (connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'SummaryForm', enableReinitialize: true })(ProfileSummary)));
