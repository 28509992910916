import { combineReducers } from 'redux';
import {
  SET_LOADING,
  UNSET_LOADING,
  SET_ERROR,
  UNSET_ERROR,
  SET_EVENT_SPONSORS,
  SET_EVENT_USERS,
  SET_EVENT_JUDGES,
  SET_EVENT_HUB_INFO,
  SET_EVENT_INFO,
  SET_EVENT_AWARDS,
  SET_EVENT_PROJECTS,
  ADD_EVENT_SPONSOR,
  ADD_NEW_AWARD,
  SET_JUDGE_BIO_PUBLISHED_STATE,
  DELETE_EVENT_SPONSOR,
  SET_EVENT_ROUNDS,
  UPDATE_ROUND,
  SET_EVENT_QUILL_DELTA,
  SET_EVENT_QUILL_HTML,
  UPDATE_HUB_SUCCESS,
  UPDATE_JUDGE_BIO_SUCCESS,
  UPDATE_EVENT_SPONSOR,
  UPDATE_EVENT_PROJECTS,
  UPDATE_EVENT_USERS,
  SET_PROJECT_CREATED_FROM_HUB_GROUP,
  RESET_PROJECT_CREATED_FROM_HUB_GROUP,
  SET_EVENT_POST,
  SET_EVENT_POSTS,
  SET_POSTS_ERROR,
  SET_EVENT_POSTS_LOADING,
  SET_EVENT_INVITATIONS,
  SET_EVENT_INVITATIONS_LOADING,
  SET_EVENT_INVITATIONS_ERROR,
  REMOVE_EVENT_INVITATION,
  ADD_EVENT_INVITATIONS,
  UPDATE_USER_EVENT_ROLE,
  REMOVE_EVENT_POST,
  SET_EVENT_POST_BOOST,
  REMOVE_EVENT_POST_BOOST,
} from './eventConstants';
import { SET_EVENT_CHANNEL } from './eventChannelActions';

function loading(state = true, action) {
  switch (action.type) {
    case SET_LOADING:
      return true;
    case UNSET_LOADING:
      return false;
    default:
      return state;
  }
}

function error(state = false, action) {
  switch (action.type) {
    case SET_ERROR:
      return true;
    case UNSET_ERROR:
      return false;
    default:
      return state;
  }
}

function sponsors(state = [], action) {
  try {
    switch (action.type) {
      case SET_EVENT_SPONSORS:
        return action.data;

      case ADD_EVENT_SPONSOR:
        return [...state, action.data];

      case UPDATE_EVENT_SPONSOR: {
        const updateObjectInArray = (array, data) => {
          return array.map((item) => {
            if (item.id === data.id) {
              return { ...data };
            }
            return { ...item };
          });
        };

        return [...updateObjectInArray(state, action.data)];
      }

      case DELETE_EVENT_SPONSOR: {
        const updatedData = state.filter(
          (sponsor) => sponsor.id !== action.data
        );
        return updatedData;
      }

      default:
        return state;
    }
  } catch (err) {
    console.error('err', err);
  }
  return false;
}

function users(state = { loading: true, participants: [] }, action) {
  switch (action.type) {
    case SET_EVENT_USERS:
      return { ...state, loading: false, participants: [...action.data] };

    case 'SET_RESOURCE_LABEL':
    case UPDATE_EVENT_USERS: {
      const updateObjectInArray = (array, data) => {
        return array.map((item) => {
          if (item.id !== data.user_id) {
            return item;
          }
          return {
            ...item,
            resource_label: data.resource_label,
          };
        });
      };
      return {
        ...state,
        loading: false,
        participants: updateObjectInArray(state.participants, action.data),
      };
    }

    case 'SET_AVATAR': {
      const updateObjectInArray = (array, actionData) => {
        return array.map((item) => {
          if (item.id !== actionData.userId) {
            return item;
          }
          return {
            ...item,
            avatar_url: actionData.data,
          };
        });
      };
      return {
        ...state,
        loading: false,
        participants: updateObjectInArray(state.participants, action),
      };
    }

    case UPDATE_USER_EVENT_ROLE: {
      const updatedParticipants = state.participants.map((p) => {
        if (p.id === action.data?.user_id) {
          return {
            ...p,
            user_event_role_type_id: action.data?.user_event_role_type_id,
          };
        }
        return p;
      });

      return {
        ...state,
        participants: updatedParticipants,
      };
    }
    default:
      return state;
  }
}

function channel(state = {}, action) {
  switch (action.type) {
    case SET_EVENT_CHANNEL:
      return { ...action.channel };

    default:
      return state;
  }
}

function judges(state = { loading: true, judges: [] }, action) {
  switch (action.type) {
    case SET_EVENT_JUDGES:
      return { ...state, loading: false, judges: [...action.data] };
    case SET_JUDGE_BIO_PUBLISHED_STATE: {
      const updateObjectInArray = (array, data) => {
        return array.map((item) => {
          if (item.user_event_role_id !== data.id) {
            return item;
          }
          return {
            ...item,
            is_published: data.is_judge_bio_published,
          };
        });
      };
      return {
        ...state,
        loading: false,
        judges: updateObjectInArray(state.judges, action.data),
      };
    }

    case 'SET_AVATAR': {
      const updateObjectInArray = (array, actionData) => {
        return array.map((item) => {
          if (item.id !== actionData.userId) {
            return item;
          }
          return {
            ...item,
            avatar_url: actionData.data,
          };
        });
      };
      return {
        ...state,
        loading: false,
        judges: updateObjectInArray(state.judges, action),
      };
    }

    case UPDATE_JUDGE_BIO_SUCCESS: {
      const updateObjectInArray = (array, data) => {
        return array.map((item) => {
          if (item.id === data.user_id) {
            return { ...item, title: data.title, bio: data.bio };
          }
          return { ...item };
        });
      };
      return {
        ...state,
        loading: false,
        judges: updateObjectInArray(state.judges, action.data),
      };
    }
    default:
      return state;
  }
}

function projects(state = { loading: true, projects: [] }, action) {
  const checkUserExists = (id) => {
    let result = false;
    state.projects.forEach((project) => {
      if (project.user_ids.includes(id)) {
        result = true;
      }
    });
    return result;
  };
  const checkCountOne = (id) => {
    let result = false;
    state.projects.forEach((project) => {
      if (project.user_ids.includes(id) && project.user_ids.length === 1) {
        result = true;
      }
    });
    return result;
  };

  const checkProjectExists = (id) => {
    let result = false;
    state.projects.forEach((project) => {
      if (project.id === id) {
        result = true;
      }
    });
    return result;
  };
  switch (action.type) {
    case SET_EVENT_PROJECTS:
      return { ...state, loading: false, projects: [...action.data] };

    case UPDATE_EVENT_PROJECTS: {
      if (checkProjectExists(action.data.project_id)) {
        const filteredProjects = state.projects.filter(
          (project) =>
            !(
              project.user_ids.includes(action.data.user_id) &&
              project.user_ids.length === 1
            )
        );

        const updateObjectInArray = (array, data) => {
          return array.map((item) => {
            if (item.id === data.project_id) {
              return {
                ...item,
                user_ids: [...item.user_ids, data.user_id],
              };
            }

            return {
              ...item,
              user_ids: [...item.user_ids.filter((id) => id !== data.user_id)],
            };
          });
        };
        return {
          ...state,
          loading: false,
          projects: updateObjectInArray(filteredProjects, action.data),
        };
      }
      if (!state.projects.length || !checkUserExists(action.data.user_id)) {
        const data = {
          user_ids: [action.data.user_id],
          name: action.data.project.name,
          id: action.data.project.id,
          logo_url: action.data.project.logo_url,
          description: action.data.project.description,
        };
        return {
          ...state,
          loading: false,
          projects: [...state.projects, data],
        };
      }

      if (
        state.projects.length &&
        checkUserExists(action.data.user_id) &&
        checkCountOne(action.data.user_id)
      ) {
        const filteredProjects = state.projects.filter((project) => {
          if (
            project.user_ids.includes(action.data.user_id) &&
            project.user_ids.length === 1
          ) {
            return false;
          }
          return true;
        });
        const data = {
          user_ids: [action.data.user_id],
          name: action.data.project.name,
          id: action.data.project.id,
          logo_url: action.data.project.logo_url,
          description: action.data.project.description,
        };
        // return {
        //   ...state,
        //   loading: false,
        //   projects: [...state.projects, data],
        // };
        return {
          ...state,
          loading: false,
          projects: [...filteredProjects, data],
        };
      }

      if (state.projects.length && checkUserExists(action.data.user_id)) {
        const filteredProjects = state.projects.map((project) => {
          if (
            project.user_ids.includes(action.data.user_id) &&
            project.user_ids.length > 1
          ) {
            // eslint-disable-next-line no-param-reassign
            project.user_ids = project.user_ids.filter(
              // eslint-disable-next-line no-shadow
              (project) => project !== action.data.user_id
            );
            return project;
          }
          return project;
        });
        const data = {
          user_ids: [action.data.user_id],
          name: action.data.project.name,
          id: action.data.project.id,
          logo_url: action.data.project.logo_url,
          description: action.data.project.description,
        };
        // return {
        //   ...state,
        //   loading: false,
        //   projects: [...state.projects, data],
        // };
        return {
          ...state,
          loading: false,
          projects: [...filteredProjects, data],
        };
      }

      const updateObjectInArray = (array, data) => {
        return array.map((item) => {
          if (
            item.user_ids.includes(data.user_id) &&
            item.user_ids.length === 1
          ) {
            return {
              ...item,
              name: data.project.name,
              id: data.project.id,
              logo_url: data.project.logo_url,
              description: data.project.description,
            };
          }
          return { ...item };
        });
      };
      return {
        ...state,
        loading: false,
        projects: updateObjectInArray(state.projects, action.data),
      };
    }
    default:
      return state;
  }
}

function awards(
  state = { loading: true, data: [], is_published: false },
  action
) {
  switch (action.type) {
    case SET_EVENT_AWARDS:
      return {
        ...state,
        loading: false,
        data: [...action.data],
        is_published: action.data.length > 0,
      };
    case ADD_NEW_AWARD:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.data],
        is_published: action.data.length >= 0,
      };

    default:
      return state;
  }
}

function rounds(state = [], action) {
  switch (action.type) {
    case SET_EVENT_ROUNDS:
      return action.data;

    case 'SET_HUB_EVENT_CHANNEL_SCORING_ROUND':
    case UPDATE_ROUND: {
      const updateObjectInArray = (array, data) => {
        return array.map((item) => {
          if (item.id !== data.id) {
            return item;
          }
          return {
            ...item,
            are_scores_published: data.are_scores_published,
            is_open: data.is_open,
            is_finished: data.is_finished,
            project_ids: data.project_ids,
            judge_user_ids: data.judge_user_ids,
          };
        });
      };
      return updateObjectInArray(state, action.data);
    }

    default:
      return state;
  }
}

function hubInfo(
  state = { loading: true, hub_info: {}, host_user: {} },
  action
) {
  try {
    switch (action.type) {
      case SET_EVENT_HUB_INFO:
        return {
          ...state,
          loading: false,
          hub_info: { ...action.data.hub_info },
          host_user: { ...action.data.host_user },
        };
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
}

function quill(state = { loading: true, delta: {}, html: '' }, action) {
  try {
    switch (action.type) {
      case SET_EVENT_QUILL_DELTA:
        return {
          ...state,
          loading: false,
          delta: { ...action.data },
        };

      case SET_EVENT_QUILL_HTML:
        return {
          ...state,
          loading: false,
          html: action.data,
        };
      // case SAVE_EVENT_QUILL:
      //   return {
      //     ...state,
      //     loading: false,
      //     quill_data: [...action.data],
      //     quill_delta: []
      //   };
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
  }

  return false;
}

function eventInfo(state = {}, action) {
  switch (action.type) {
    case SET_EVENT_INFO:
      return {
        ...state,
        ...action.data,
      };

    case UPDATE_HUB_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    default:
      return state;
  }
}

function isProjectCreatedFromHubGroup(state = null, action) {
  switch (action.type) {
    case SET_PROJECT_CREATED_FROM_HUB_GROUP:
      return action.data;
    case RESET_PROJECT_CREATED_FROM_HUB_GROUP:
      return null;
    default:
      return state;
  }
}

const eventPosts = (
  state = { loading: true, error: false, posts: [], eventId: null },
  action
) => {
  switch (action.type) {
    case SET_EVENT_POSTS:
      return {
        ...state,
        loading: false,
        error: false,
        posts: action.data.posts,
        eventId: action.data.eventId,
      };
    case SET_EVENT_POSTS_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case SET_POSTS_ERROR:
      return {
        ...state,
        error: action.data,
      };
    case SET_EVENT_POST: {
      const updatedPosts = state.posts.filter(
        (post) => post.id !== action.data.id
      );
      return {
        ...state,
        posts: [...updatedPosts, action.data],
      };
    }
    case REMOVE_EVENT_POST: {
      const { postId, userId } = action.data;
      const updatedPosts = state.posts.map((post) => {
        if (postId === post.id) {
          return {
            ...post,
            is_deleted: true,
            deleted_by_id: userId,
            text: '[[deleted]]',
            is_sticky: false,
          };
        }
        return post;
      });
      return {
        ...state,
        posts: updatedPosts,
      };
    }
    case SET_EVENT_POST_BOOST: {
      const { hub_event_post_id } = action.data;
      const updatedPosts = state.posts.map((post) => {
        if (post.id === hub_event_post_id) {
          if (
            post.post_boosts.data
              .map((boost) => boost.id)
              .includes(action.data.id)
          )
            return post;
          const boosts = [...post.post_boosts.data, action.data];
          return {
            ...post,
            post_boosts: {
              data: boosts,
            },
          };
        }
        return post;
      });
      return {
        ...state,
        posts: updatedPosts,
      };
    }
    case REMOVE_EVENT_POST_BOOST: {
      const postId = action.data.postId || action.data.hub_event_post_id;
      const userId = action.data.userId || action.data.user.id;
      const updatedPosts = state.posts.map((post) => {
        if (post.id === postId) {
          const boosts = post.post_boosts.data.filter(
            (b) => b.user?.id !== userId
          );
          return {
            ...post,
            post_boosts: {
              data: boosts,
            },
          };
        }
        return post;
      });
      return {
        ...state,
        posts: updatedPosts,
      };
    }
    default:
      return state;
  }
};

const eventInvitations = (
  state = { loading: true, error: false, invitations: [], eventId: null },
  action
) => {
  switch (action.type) {
    case SET_EVENT_INVITATIONS:
      return {
        ...state,
        loading: false,
        error: false,
        invitations: action.data.invitations,
        eventId: action.data.eventId,
      };
    case SET_EVENT_INVITATIONS_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case SET_EVENT_INVITATIONS_ERROR:
      return {
        ...state,
        error: action.data,
      };
    case ADD_EVENT_INVITATIONS:
      return {
        ...state,
        invitations: [...state.invitations, ...action.data],
      };
    case REMOVE_EVENT_INVITATION:
      return {
        ...state,
        invitations: state.invitations.filter(
          (invitation) => invitation.id !== action.data
        ),
      };
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  error,
  sponsors,
  users,
  hubInfo,
  eventInfo,
  judges,
  projects,
  awards,
  rounds,
  quill,
  channel,
  isProjectCreatedFromHubGroup,
  eventPosts,
  eventInvitations,
});
