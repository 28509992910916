/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import { Drawer, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { SidebarWidth } from 'theme/Theme-variable';
import './Sidebar.scss';
import { isMobileDevice } from 'functions';
import { modalCss } from 'utils/modalCss';
import {
  hideSideNav,
  openProjectTeammateInvite,
} from 'store/sideNav/sideNavActions';
import { doLogout } from 'store/logout/logoutActions';
import LanguagePicker from 'components/Common/LanguagePicker/LanguagePicker';
import { useTranslation } from 'react-i18next';
import CustomModal from 'components/Common/CustomModal/CustomModal';
import InviteTeammate from 'Pages/Project/components/InviteTeammate';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import usePrevious from 'hooks/usePrevious';
import Tooltip from '@mui/material/Tooltip';
import { setMyDefaultProject } from 'store/auth/authActions';

import BrandHeader from './BrandHeader/BrandHeader';
import ProjectMenu from './ProjectMenu/ProjectMenu';
import MainMenu from './MainMenu/MainMenu';
import HubMenu from './HubMenu/HubMenu';
import MyEventsMenu from './MyEvents/MyEventsMenu';
import DesignGuide from './DesignGuide/DesignGuide';
import DashboardMenu from './DashboardMenu/DashboardMenu';
import CreateProjectMenu from './CreateProjectMenu/CreateProjectMenu';

const Sidebar = (props) => {
  const { t } = useTranslation(['translation.navigation']);

  const dispatch = useDispatch();

  const history = useHistory();

  // TODO clean up this code
  const [navState, setNavState] = useState({
    subscriptionModal: false,
    openChangeDefaultProjectModal: false,
    defaultProjectOpened: false,
    inviteProjectTeammateModal: false,
    inviteTeammateProjectId: null,
  });

  const { sideNav, user } = useSelector((state) => state);

  const isDevEnvironment = window.env.REACT_APP_TYPE === 'DEVELOPMENT';

  const authUser = useSelector((state) => state.authUser);

  const defaultProjectId = authUser?.default_project_id || null;

  const location = useLocation();

  const [userProjects, setUserProjects] = useState([]);

  const [clientHubs, setClientHubs] = useState([]);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const isLoggedIn = !!user.get('jwt');

  // adjust page css depending on whether the side nav is open or not
  const isSideNavOpen = sideNav.get('menuOpen');

  const inviteTeammateModalForProject = sideNav.get('projectInviteTeammate');
  const prevInviteTeammateModalForProject = usePrevious(
    inviteTeammateModalForProject
  );

  const close = () => {
    dispatch(hideSideNav());
  };

  const onCreateNewProject = () => {
    history.push(`/project/create-project`);
  };

  const handleMenuItem = () => {
    if (isMobileDevice()) {
      close();
    }
  };

  useEffect(() => {
    const projectList = authUser?.projects?.map((p) => {
      const { id, name, role_type, level, ...rest } = p;
      const { pathname } = location;
      const isOwner = role_type?.toLowerCase() === 'owner';
      let members = [];
      if (isOwner) {
        members.push({
          firstName: user?.get('first_name'),
          lastName: user?.get('last_name'),
          userId: user?.get('id'),
        });
      }
      members = [
        ...members,
        ...rest.teammates?.map((tm) => {
          return {
            firstName: tm.first_name,
            lastName: tm.lastName,
            userId: tm.user_id,
          };
        }),
      ];
      return {
        id,
        level,
        projectName: name,
        roleType: role_type,
        members,
        hasPermission: role_type !== 'Support Member',
        isDefault: defaultProjectId === id,
        selected:
          defaultProjectId === id || pathname.startsWith(`/project/${id}`),
      };
    });
    setUserProjects(
      projectList.sort((first, second) => {
        // eslint-disable-next-line no-nested-ternary
        return first.isDefault ? -1 : second.isDefault ? 1 : 0;
      })
    );
  }, [authUser?.projects, authUser?.default_project_id, location?.pathname]);

  useEffect(() => {
    setClientHubs(
      authUser?.hubs?.map((p) => {
        const { id, name, role_level } = p;
        const { pathname } = location;
        return {
          id,
          hubName: name,
          roleLevel: role_level,
          selected: pathname.startsWith(`/hubs/${id}`),
        };
      })
    );
  }, [authUser?.hubs, location?.pathname]);

  useEffect(() => {
    if (
      inviteTeammateModalForProject &&
      inviteTeammateModalForProject !== prevInviteTeammateModalForProject
    ) {
      setNavState({
        ...navState,
        inviteProjectTeammateModal: true,
        inviteTeammateProjectId: inviteTeammateModalForProject,
      });
    }
  }, [inviteTeammateModalForProject]);

  const logout = () => {
    dispatch(doLogout());
  };

  const changeDefaultProject = (project_id) => {
    dispatch(setMyDefaultProject(project_id));
  };

  const clearProjectInviteTeammate = () => {
    dispatch(openProjectTeammateInvite(null));
  };

  const handleCustomModalClose = () => {
    setNavState({
      ...navState,
      subscriptionModal: false,
    });
  };

  const handleFirstChoice = () => {
    props.history.push('/contact-us');
  };

  const handleClickChangeDefaultProject = (e) => {
    e.stopPropagation();
    setNavState({
      ...navState,
      openChangeDefaultProjectModal: true,
    });
  };

  const handleCloseChangeDefaultProject = () => {
    setNavState({
      ...navState,
      openChangeDefaultProjectModal: false,
    });
  };

  const handleChangeDefaultProject = (project_id) => {
    changeDefaultProject(project_id);
    setNavState({
      ...navState,
      openChangeDefaultProjectModal: false,
    });
  };

  const handleInviteProjectTeammate = (state = false, pId = null) => {
    if (!state && inviteTeammateModalForProject) {
      clearProjectInviteTeammate();
    }
    setNavState({
      ...navState,
      inviteProjectTeammateModal: state,
      inviteTeammateProjectId: pId,
    });
  };

  const addTeamMember = (e, projectId) => {
    e.stopPropagation();
    handleInviteProjectTeammate(true, projectId);
  };

  const renderMobileMenu = () => {
    return (
      <div className="more">
        <input type="checkbox" id="more-check" className="more__checkbox" />
        <label htmlFor="more-check" className="more__label">
          {t('More')}
        </label>
        <div className="more__links">
          <Link
            to="/settings"
            onClick={() => {
              handleMenuItem();
            }}
            className="more__btn-link"
          >
            {t('settings')}
          </Link>
          <a
            href="https://rebelbase.zendesk.com/hc/en-us"
            onClick={handleMenuItem}
            className="more__btn-link"
          >
            {t('help')}
          </a>
          <LanguagePicker />
          <button
            to="/settings"
            onClick={() => logout()}
            className="more__btn-link"
          >
            {t('logOut')}
          </button>
        </div>
      </div>
    );
  };

  const renderChangeDefaultProjectModal = () => {
    const iDefaultProject = userProjects.find((p) => p.isDefault);

    return (
      <Modal
        isOpen={navState.openChangeDefaultProjectModal}
        contentLabel="Contact Us Modal"
        onRequestClose={() => handleCloseChangeDefaultProject()}
        shouldCloseOnOverlayClick
        style={modalCss}
      >
        <div className="selectProj">
          <div className="selectProj__head">
            <h3>{t('changeDefaultProject')}</h3>
            <button
              type="button"
              onClick={() => handleCloseChangeDefaultProject()}
              className="btn__modalx"
            />
          </div>
          <div className="selectProj__notes">{t('defaultProjectNote')}</div>
          {iDefaultProject && (
            <div className="selectProj__current">
              <span>{t('currentDefault')}</span> {iDefaultProject.projectName}
            </div>
          )}
          <ul>
            {userProjects.map((p) => {
              return (
                <li
                  aria-hidden="true"
                  key={`project-${p.id}`}
                  onClick={() => handleChangeDefaultProject(p.id)}
                  className={
                    p.id === defaultProjectId && 'selectProj__defaultProj'
                  }
                >
                  {p.projectName}
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    );
  };

  const MenuContent = (
    <>
      <Card
        className="sideNav"
        id="sideNav"
        key="sideNav"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => theme.palette.secondary.main,
          color: (theme) => theme.palette.background.default,
          borderRadius: 0,
        }}
      >
        <CardContent
          sx={{
            '&:last-child': {
              padding: 0,
            },
            '&::-webkit-scrollbar': {
              width: '10px',
              '&-track': {
                background: 'transparent',
              },
              '&-thumb': {
                background: (theme) => theme.palette.secondary.main,
                '&:hover': {
                  background: (theme) => theme.palette.secondary.main,
                },
              },
            },
            padding: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            flex: 1,
          }}
        >
          <BrandHeader isSmallScreen={isSmallScreen} collapse={close} />

          <DashboardMenu />

          <MainMenu
            onItemClick={onCreateNewProject}
            label={<>{t('Project')}</>}
            endIcon={
              <Tooltip title={t('addProject')}>
                <AddIcon />
              </Tooltip>
            }
          />
          {userProjects?.length > 0 ? (
            userProjects?.map((m) => {
              return (
                <ProjectMenu
                  key={m?.id}
                  {...m}
                  addTeamMember={addTeamMember}
                  setMyDefaultProject={handleClickChangeDefaultProject}
                  onMenuClick={handleMenuItem}
                />
              );
            })
          ) : (
            <CreateProjectMenu />
          )}

          {clientHubs?.length > 0 && (
            <>
              <MainMenu label={<>{t('Hub')}</>} />
              {clientHubs?.map((hub) => {
                return <HubMenu key={hub?.id} {...hub} onMenuClick={handleMenuItem} />;
              })}
            </>
          )}

          <MainMenu label={<>{t('MyEvents')}</>} />
          <MyEventsMenu />

          {isDevEnvironment && (
            <>
              <MainMenu label="Design Guides" />
              <DesignGuide />
            </>
          )}

          <div className="sideNav__content">{renderMobileMenu()}</div>
          {renderChangeDefaultProjectModal()}
        </CardContent>
      </Card>
    </>
  );

  const SidebarContent = (
    <>
      {MenuContent}
      {/*TODO migrate below modal popup to material*/}
      <CustomModal
        header={t('SubscriptionExpired')}
        message={t('SubscriptionExpiredMessage')}
        firstChoice={t('FirstChoice')}
        cancel={t('Cancel')}
        isOpen={navState.subscriptionModal}
        handleCancel={handleCustomModalClose}
        handleFirstChoice={handleFirstChoice}
      />
      <InviteTeammate
        isOpen={navState.inviteProjectTeammateModal}
        projectId={navState.inviteTeammateProjectId}
        close={handleInviteProjectTeammate}
      />
    </>
  );

  if (!isLoggedIn) {
    return null;
  }

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={isSideNavOpen}
        variant="persistent"
        // TODO - remove this styles once material modal popup integrated
        sx={{
          '& .MuiDrawer-paper': {
            zIndex: 11,
          },
        }}
        PaperProps={{
          sx: {
            width: SidebarWidth,
            borderRight: 0,
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="right"
      open={isSideNavOpen}
      onClose={close}
      // TODO - remove this styles once material modal popup integrated
      sx={{
        zIndex: 900,
        '& .MuiDrawer-paper': {
          zIndex: 11,
        },
      }}
      PaperProps={{
        sx: {
          width: SidebarWidth,
          borderRight: 0,
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
};

export default React.memo(Sidebar);
