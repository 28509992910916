import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SET_EMAIL_ADDRESS } from 'store/login/loginActions';
import LoadingComponent from 'components/Loading/loadingComponent';
import { processEmailVerification } from 'store/emailVerification/emailVerificationActions';
import { VERIFYING_EMAIL } from 'store/metadata/metadataActions';

const EmailVerification = ({
  isLoaded,
  token,
  process,
  setIsVerifyingEmail,
  isVerifyingEmail,
  emailAddress,
  saveEmail,
}) => {
  useEffect(() => {
    if (emailAddress) saveEmail(emailAddress);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoaded && !isVerifyingEmail) {
    setIsVerifyingEmail();
    process(token);
  }
  return <LoadingComponent />;
};

const mapDispatchToProps = (dispatch) => ({
  setIsVerifyingEmail() {
    dispatch({ type: VERIFYING_EMAIL });
  },
  process(token) {
    dispatch(processEmailVerification(token));
  },
  saveEmail(emailAddress) {
    dispatch({ type: SET_EMAIL_ADDRESS, data: emailAddress });
  },
});

const mapStateToProps = (state, props) => {
  const { metadata, user } = state;
  const isUserLoggedIn = user.get('id');
  const isLoaded =
    isUserLoggedIn ||
    (metadata.loading.get('userLoaded') &&
      metadata.loading.get('appDataLoaded'));

  const token = props.location.search
    ?.replace('?token=', '')
    .split('&email_address')[0];

  // get email if in params
  const params = props.location.search.split('&');
  let emailAddress = null;
  if (params.length > 1) {
    emailAddress = params[1].replace('email_address=', '');
  }

  return {
    isVerifyingEmail: metadata.isVerifyingEmail,
    isLoaded,
    token,
    emailAddress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
