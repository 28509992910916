/* eslint-disable import/no-cycle */
import Cookie from 'js-cookie';
import { API_GET, API_POST } from 'store/middleware/middlewareConstants';
import { SET_JWT, SET_USER_ID } from '../user/userActions';
import { initializeApp } from '../actions';
import { doLogout } from '../logout/logoutActions';
import { RESET_USER_LOADED } from '../metadata/metadataActions';

export const DOING_LOGIN = 'DOING_LOGIN';

// Save email address from params
export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';
export const RESET_EMAIL_ADDRESS = 'RESET_EMAIL_ADDRESS';

// populate jwt and userid in store
export function setStoreLogin(jwt) {
  return (dispatch) => {
    dispatch({
      type: SET_JWT,
      jwt,
    });
  };
}

export const loginSuccessful = (data) => {
  return (dispatch) => {
    if (data.jwt) {
      Cookie.set('token', data.jwt, { expires: 30 });
    }
    dispatch({ type: RESET_EMAIL_ADDRESS });
    dispatch({ type: RESET_USER_LOADED });
    dispatch({ type: SET_USER_ID, userId: data.user_id });
    dispatch(initializeApp(data.jwt));
  };
};

// Post Login Info
export const doLogin = (values, onError, onSuccess) => {
  return {
    type: API_POST,
    payload: {
      url: `${(window.env || process.env).REACT_APP_API_ENDPOINT}/api/auth/login`,
      body: {
        user: {
          email_address: values.email,
          password: values.password,
        },
      },
      success: loginSuccessful,
      onSuccess,
      onError,
    },
  };
};

export const validateLogin = (jwt) => {
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/validate-login`,
      success: null,
      onSuccess: null,
      identifier: 'validateLogin',
      onError: doLogout,
      token: jwt,
    },
  };
};
