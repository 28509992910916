import { lazy } from 'react';

export const Browse = lazy(() => import('Pages/Browse'));
export const Settings = lazy(() => import('Pages/Settings/Settings'));
export const Notifications = lazy(() => import('Pages/Notifications'));
export const Invitations = lazy(() => import('Pages/Invitations'));

// Rebel Events Components
export const MyEvents = lazy(() => import('Pages/MyEvents/MyEvents'));
export const HubEvent = lazy(() => import('Pages/Hubs/Events/HubEvent'));
export const ScoringContainer = lazy(() => import('Pages/Hubs/Events/Scoring'));

// Rebel Projects Components
export const MyProjectBuilders = lazy(() => import('Pages/MyProject'));
export const BuilderOverview = lazy(() =>
    import('Pages/MyProject/BuilderOverview')
);
export const CreateProject = lazy(() => import('Pages/Project/CreateProject'));
export const ProjectProfile = lazy(() => import('Pages/Project'));
export const BuilderTopic = lazy(() => import('Pages/Project/Builders/Topic'));
export const BuilderIntro = lazy(() => import('Pages/Project/Builders/Intro'));
export const BuilderReview = lazy(() =>
  import('Pages/Project/Builders/Review')
);
export const BuilderIndex = lazy(() => import('Pages/Project/Builders'));
export const SelectTrack = lazy(() =>
  import('Pages/Project/Builders/SelectTrack')
);

// Hub Components

export const HubLibraryLayout = lazy(() => import('Pages/Hubs/HubLibrary/HubLibraryLayout'));

export const HubMembers = lazy(() => import('Pages/Hubs/Members'));
export const HubManageMembers = lazy(() => import('Pages/Hubs/Members/Manage'));
export const BrainLayout = lazy(() => import('Pages/Hubs/Brain/BrainLayout'));
export const HubGroups = lazy(() => import('Pages/Hubs/Groups'));
export const HubGroupSchedule = lazy(() =>
  import('Pages/Hubs/Groups/HubGroupSchedule/HubGroupSchedule')
);
export const HubGroupLayout = lazy(() =>
  import('Pages/Hubs/Groups/Views/HubGroupLayout')
);
export const HubEvents = lazy(() => import('Pages/Hubs/Events'));
export const SingleActivity = lazy(() =>
  import('Pages/Hubs/Activities/SingleActivity')
);
export const HubActivity = lazy(() => import('Pages/Hubs/Activities'));
export const HubJoinModal = lazy(() => import('Pages/Hubs/HubJoinModal'));
export const HubMember = lazy(() => import('Pages/Hubs/Members/HubMember'));
export const EditHubMembership = lazy(() =>
  import('Pages/Hubs/Members/EditHubMembership')
);
export const HubSelectTrack = lazy(() =>
  import('components/HubViewBuilders/SelectTrackForHubs/SelectTrackForHubs')
);

// Auth Components
export const SignUp = lazy(() => import('Pages/Auth/SignUp/SignUp'));


// Design Guides
export const CustomTypography = lazy(() => import('DesignGuides/CustomTypography/CustomTypography'));
export const CustomButtons = lazy(() => import('DesignGuides/CustomButtons/CustomButtons'));
export const CustomForm = lazy(() => import('DesignGuides/CustomForm/CustomForm'));
export const CustomSpacing = lazy(() => import('DesignGuides/Spacing-Example/SpacingExample'));
