import { removePopups } from 'store/popup/popupActions';
import { doRedirect } from 'store/metadata/metadataActions';
import { triggerInvalidTokenPopup } from 'functions/popup/triggerInvalidTokenPopup';
import { request } from '../../functions';
import {
  processInvitationToken,
  invitationTypes,
  RESET_ACTIVE_PROJECT_INVITATION_ON_DECLINE,
} from '../invitations/invitationActions';

export const SET_NOTIFICATIONS = 'notifications/SET_NOTIFICATIONS';
export const SET_NOTIFICATION = 'notifications/SET_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'notifications/UPDATE_NOTIFICATION';
export const SET_ALL_NOTIFICATIONS_READ =
  'notifications/SET_ALL_NOTIFICATIONS_READ';

export const SET_PROJECT_INVITATION_NOTIFICATIONS =
  'notifications/SET_PROJECT_INVITATION_NOTIFICATIONS';
export const SET_PROJECT_INVITATION_NOTIFICATION =
  'notifications/SET_PROJECT_INVITATION_NOTIFICATION';
export const UPDATE_PROJECT_INVITATION_NOTIFICATION =
  'notifications/UPDATE_PROJECT_INVITATION_NOTIFICATION';

export function updateProjectInvitationNotification(
  projectInvitationNotificationId,
  is_ignored,
  is_accepted
) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const payload = {
      project_invitation_notification: {
        is_ignored,
        is_accepted,
      },
    };
    const redirectTo = jwt ? `/profile/${user.get('id')}` : '/';
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/notifications/project-invitation-notifications/${projectInvitationNotificationId}`;
    request(url, jwt, 'PUT', payload)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: UPDATE_PROJECT_INVITATION_NOTIFICATION,
            data: response.data,
          });
          dispatch({
            type: RESET_ACTIVE_PROJECT_INVITATION_ON_DECLINE,
            data: response.data,
          });
          dispatch(removePopups());
        }

        if (response.status === 404) {
          triggerInvalidTokenPopup(dispatch);
          return dispatch(doRedirect(redirectTo));
        }
        return '';
      })
      .catch((err) => console.log(err));
  };
}

export function updateProjectInvitationNotificationOnAccept(token) {
  return (dispatch, getState) => {
    const { invitations, notifications } = getState();

    // get the invitation
    const invitation = invitations.activeProjectInvitations.find(
      (i) => i.get('token') === token
    );

    // get the project invitation notification
    const notification =
      notifications.projectInvitationNotifications.find(
        (n) => n.get('project_invitation_id') === invitation.get('id')
      ) &&
      notifications.projectInvitationNotifications
        .find((n) => n.get('project_invitation_id') === invitation.get('id'))
        .set('is_ignored', false)
        .set('is_accepted', true);

    if (notification)
      dispatch({
        type: UPDATE_PROJECT_INVITATION_NOTIFICATION,
        data: notification.toJS(),
      });
    // .then(() => dispatch(pendingInvitationPopup()));
  };
}

export function acceptProjectInvitation(projectInvitationId) {
  return (dispatch, getState) => {
    const { invitations } = getState();

    const token =
      invitations.activeProjectInvitations.find(
        (i) => i && i.get('id') === projectInvitationId
      ) &&
      invitations.activeProjectInvitations
        .find((i) => i && i.get('id') === projectInvitationId)
        .get('token');
    dispatch(processInvitationToken(token, invitationTypes.projectInvitation, true));
  };
}

export const SET_HUB_EVENT_INVITATION_NOTIFICATIONS =
  'notifications/SET_HUB_EVENT_INVITATION_NOTIFICATIONS';
export const SET_HUB_EVENT_INVITATION_NOTIFICATION =
  'notifications/SET_HUB_EVENT_INVITATION_NOTIFICATION';
export const UPDATE_HUB_EVENT_INVITATION_NOTIFICATION =
  'notifications/UPDATE_HUB_EVENT_INVITATION_NOTIFICATION';

export function acceptHubEventInvitation(notificationId, invitationId) {
  return (dispatch, getState) => {
    const { invitations, notifications } = getState();
    const token = invitations.hubEventInvitations
      .find((i) => i.get('id') === invitationId)
      .get('token');

    dispatch(
      processInvitationToken(token, invitationTypes.hubEventInvitation, false)
    );

    const hubEventInvitationNotification =
      notifications.hubEventInvitationNotifications.find(
        (n) => n.get('id') === notificationId
      );
    if (hubEventInvitationNotification) {
      dispatch({
        type: UPDATE_HUB_EVENT_INVITATION_NOTIFICATION,
        data: hubEventInvitationNotification
          .set('is_accepted', true)
          .set('is_declined', false)
          .toJS(),
      });
    }
  };
}

export function declineHubEventInvitation(notificationId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/notifications/hub-event-invitation-notifications/${notificationId}`;
    const jwt = user.get('jwt');
    const payload = {
      hub_event_invitation_notification: {
        is_accepted: false,
        is_declined: true,
      },
    };
    request(url, jwt, 'PUT', payload).then((response) =>
      dispatch({
        type: UPDATE_HUB_EVENT_INVITATION_NOTIFICATION,
        data: response.data,
      })
    );
  };
}

export const SET_HUB_INVITATION_NOTIFICATIONS =
  'notifications/SET_HUB_INVITATION_NOTIFICATIONS';
export const SET_HUB_INVITATION_NOTIFICATION =
  'notifications/SET_HUB_INVITATION_NOTIFICATION';
export const UPDATE_HUB_INVITATION_NOTIFICATION =
  'notifications/UPDATE_HUB_INVITATION_NOTIFICATION';

export function acceptHubInvitation(notificationId, invitationId) {
  return (dispatch, getState) => {
    const { invitations, notifications } = getState();
    const token = invitations.hubInvitations
      .find((i) => i.get('id') === invitationId)
      .get('token');

    dispatch(processInvitationToken(token, invitationTypes.hubInvitation, true));

    const hubInvitationNotification =
      notifications.hubInvitationNotifications.find(
        (n) => n.get('id') === notificationId
      );
    if (hubInvitationNotification) {
      dispatch({
        type: UPDATE_HUB_INVITATION_NOTIFICATION,
        data: hubInvitationNotification
          .set('is_accepted', true)
          .set('is_declined', false)
          .toJS(),
      });
    }
  };
}

export function declineHubInvitation(notificationId) {
  return (dispatch, getState) => {
    const { user } = getState();
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/notifications/hub-invitation-notifications/${notificationId}`;
    const jwt = user.get('jwt');
    const payload = {
      hub_invitation_notification: {
        is_accepted: false,
        is_declined: true,
      },
    };
    request(url, jwt, 'PUT', payload).then((response) =>
      dispatch({
        type: UPDATE_HUB_INVITATION_NOTIFICATION,
        data: response.data,
      })
    );
  };
}
