import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './app.scss';
import './i18n'; // multi language support config file
import DoRedirect from 'components/DoRedirect';
import Popup from 'components/AppLayout/partials/Popup';
import CssBaseline from '@mui/material/CssBaseline';
import ScrollToTop from 'components/AppLayout/MainLayout/components/ScrollToTop';
import RouterComponent from './RouterComponent';
import 'moment/min/locales.min';
import { initializeApp } from './store/actions';
import useThemeSettings from './hooks/useThemeSettings';
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper/ServiceWorkerWrapper";

const App = (props) => {
  const { history } = props;
  const theme = useThemeSettings();

  useEffect(() => {
    props.initializeApp();
  }, []);

  // TODO move LocalizationProvider inside the component to take locales advantage
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <Router history={history}>
          <ScrollToTop />
          <RouterComponent />
          <Popup />
          <DoRedirect />
          <ServiceWorkerWrapper />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const mapDispatchToProps = {
  initializeApp,
};

export default connect(null, mapDispatchToProps)(React.memo(App));
