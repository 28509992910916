import { List, fromJS } from 'immutable';
import { combineReducers } from 'redux';
import {
  RESET_INVITATION_TOKEN,
  SET_ACTIVE_PROJECT_INVITATIONS,
  SET_CREATE_HUB_INVITATION_TOKEN,
  SET_HUB_EVENT_INVITATION_TOKEN,
  SET_USER_HUB_EVENT_INVITATIONS,
  SET_HUB_INVITATIONS,
  SET_HUB_INVITATION_TOKEN,
  SET_PROJECT_INVITATION_TOKEN,
  SET_CREATE_HUB_INVITATION,
  RESET_ACTIVE_PROJECT_INVITATION_ON_DECLINE,
} from './invitationActions';

const initActiveProjectInvitations = List();
export function activeProjectInvitations(
  state = initActiveProjectInvitations,
  action
) {
  switch (action.type) {
    case SET_ACTIVE_PROJECT_INVITATIONS:
      return initActiveProjectInvitations.concat(fromJS(action.data));
    case RESET_ACTIVE_PROJECT_INVITATION_ON_DECLINE:
  
      return state.set(
        state.findIndex(
          (n) => n.get('id') === action.data.project_invitation_id
        ),
        fromJS(action.data)
      );
    default:
      return state;
  }
}

const initCreateHubInvitations = List();
export function createHubInvitations(state = initCreateHubInvitations, action) {
  switch (action.type) {
    case SET_CREATE_HUB_INVITATION:
      return state
        .filterNot((chi) => chi.get('id') === action.data.id)
        .push(fromJS(action.data));
    default:
      return state;
  }
}

export function hubInvitations(state = List(), action) {
  switch (action.type) {
    case SET_HUB_INVITATIONS:
      return List().concat(fromJS(action.data));
    default:
      return state;
  }
}

export function hubEventInvitations(state = List(), action) {
  switch (action.type) {
    case SET_USER_HUB_EVENT_INVITATIONS:
      return List().concat(fromJS(action.data));
    default:
      return state;
  }
}

function createHubInvitationToken(state = null, action) {
  switch (action.type) {
    case SET_CREATE_HUB_INVITATION_TOKEN:
      return action.token;
    case RESET_INVITATION_TOKEN:
      return null;
    default:
      return state;
  }
}

function hubEventInvitationToken(state = null, action) {
  switch (action.type) {
    case SET_HUB_EVENT_INVITATION_TOKEN:
      return action.token;
    case RESET_INVITATION_TOKEN:
      return null;
    default:
      return state;
  }
}

function hubInvitationToken(state = null, action) {
  switch (action.type) {
    case SET_HUB_INVITATION_TOKEN:
      return action.token;
    case RESET_INVITATION_TOKEN:
      return null;
    default:
      return state;
  }
}

function projectInvitationToken(state = null, action) {
  switch (action.type) {
    case SET_PROJECT_INVITATION_TOKEN:
      return action.token;
    case RESET_INVITATION_TOKEN:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  activeProjectInvitations,
  createHubInvitationToken,
  createHubInvitations,
  hubInvitationToken,
  projectInvitationToken,
  hubEventInvitationToken,
  hubEventInvitations,
  hubInvitations,
});
