import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { withTranslation } from 'react-i18next';

import moment from 'moment';
import momentLocaliser from 'react-widgets-moment';

import 'react-widgets/dist/css/react-widgets.css';

import {
  submitExperience,
  editExperience,
  toggleAddExperience,
  toggleEditExperience,
  deleteExperience,
} from '../../../store/profile/profileActions';

momentLocaliser(moment);

const renderDatePicker = ({ input: { onChange, value } }) => (
  <DateTimePicker
    onChange={onChange}
    format="MMM DD, YYYY"
    placeholder="MMM DD, YYYY"
    value={!value ? null : new Date(value)}
    time={false}
  />
);

const ExperienceForm = (props) => {

  const { save, edit, closeAdd, closeEdit, t } = props;

  // const required = valid => (valid ? undefined : 'Required');

  const deleteButton = (
    <button
      className="userProfile__delete-btn"
      onClick={e => props.deleteExperience(e, props.formId)}
    > {t('Delete')}
    </button>);

  const endDate = (
    <Field
      name="end_date"
      component={renderDatePicker}
      autoComplete="date_picker"
      autoCorrect="off"
      spellCheck="off"
      className="experience__sect__input"
    />

  );

  return (
    <form
      className="experience__sect__editing"
      onSubmit={props.formType === 'new' ? e => save(e) : e => edit(e, props.formId)}
    >
      <div className="experience__sect__editTitle">
        <h4> {props.formType === 'new' ? t('Add') : t('Edit')} {t('Experience')}</h4>
        {props.formType === 'new' ?
          <button onClick={e => closeAdd(e)} className="btn-x">x</button>
          :
          <button onClick={e => closeEdit(e)} className="btn-x">x</button>
        }
      </div>

      <div>
        <div className="experience__fields__date-wrap">
          <div className="experience__fields">
            <label htmlFor="start_date" className="experience__sect__label">{t('StartDate')}</label>
            <Field
              name="start_date"
              component={renderDatePicker}
              autoComplete="date_picker"
              autoCorrect="off"
              spellCheck="off"
              className="experience__sect__input"
            />
          </div>
          <div className="experience__fields experience__fields--end-date">
            <div className="experience__enddate">
              <label htmlFor="end_date" className="experience__sect__label">{t('EndDate')}</label>
            </div>
            {props.present_bool ? null : endDate}
            <div className="experience__enddate__present__wrap">
                <Field
                  className="experience__sect__input experience__enddate__present__box"
                  name="present"
                  id="present"
                  type="checkbox"
                  component="input"
                />
                <label htmlFor="present" className="experience__enddate__present__label">
                  <span className="experience__enddate__present__label__check">&#9744;</span>
                  {t('Present')}</label>
              </div>
          </div>
        </div>
      
        
        <div className="experience__fields">
          <label
            htmlFor="title"
            className="experience__sect__label"
          >
            <span className="required">{t('Title')}</span>
            <Field
              className="experience__sect__input"
              name="title"
              type="text"
              component="input"
              placeholder={t('ExperienceTitle')}
              required
            />
          </label>
        </div>

        <div className="experience__fields">
          <label
            htmlFor="organization_name"
            className="experience__sect__label"
          ><span className="required">{t('CompanyOrSchool')}</span>
            <Field
              className="experience__sect__input"
              name="organization_name"
              type="text"
              component="input"
              placeholder={t('AffiliatedCompanyOrSchool')}
              required
            />
          </label>
        </div>

        <div className="experience__fields">
          <label
            htmlFor="description"
            className="experience__sect__label"
          >{t('Description')}</label>
          <Field
            className="experience__sect__textarea"
            name="description"
            type="text"
            component="textarea"
            placeholder={t('ShortDescription')}
          />
        </div>
        <div className="userProfile__save">
          {props.formType === 'edit' ? deleteButton : null}
          <button className="userProfile__save-btn"> {t('save')} </button>
        </div>
      </div>
      <hr />
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  closeAdd: (e) => {
    e.preventDefault();
    dispatch(toggleAddExperience());
  },
  closeEdit: (e) => {
    e.preventDefault();
    dispatch(toggleEditExperience(null));
  },
  save: (e) => {
    e.preventDefault();
    dispatch(submitExperience());
    dispatch(toggleAddExperience());
  },
  edit: (e, formId) => {
    e.preventDefault();
    dispatch(editExperience(formId));
    dispatch(toggleEditExperience(null));
  },
  deleteExperience: (e, formId) => {
    e.preventDefault();
    dispatch(deleteExperience(formId));
    dispatch(toggleEditExperience(null));
  },
  toggleEditExperience: formId => dispatch(toggleEditExperience(formId)),
});

const mapStateToProps = (state, ownProps) => {
  const defaultEntry = {
    start_date: null,
    end_date: null,
    present: null,
    title: null,
    organization_name: null,
    description: null,
  };
  const selector = formValueSelector(ownProps.form);
  const value = selector(state, 'present') || false;
  return {
    initialValues: ownProps.entry || defaultEntry,
    formType: ownProps.formType || 'new',
    present_bool: value,
  };
};

export default withTranslation(['translation.Profile']) (connect(mapStateToProps, mapDispatchToProps)(reduxForm({ enableReinitialize: true })(ExperienceForm)));
