export const SET_DELTA = 'quillDelta/SET_DELTA';

// Helpers

/**
 *  Convert a string of text to a Quill.Delta compatible object
 */
export const convertText2Delta = (text) => {
  const splitBodyByLines = text.split('\n');
  const operations = splitBodyByLines.map((t) => {
    if (t === '') {
      return { insert: '\n' };
    }
    return { insert: `${t}\n` };
  });
  return { ops: operations };
};

/**
 * Convert a Quill.Delta object to a string of text
 */
export const convertDelta2Text = (delta) => {
  delta.ops.map(operation => (
    operation.insert.replace('\n', '')
  ))
    .join('\n');
};

// Action Creators

/**
 * Set Presences received from server in to redux state
 */
export function setDelta(name, delta) {
  return {
    type: SET_DELTA,
    name,
    delta,
  };
}

/**
 *  Handles taking a Blurb (ChallengeResultBlurb or ChallengeHypothesisBlurb)
 *  and setting our reducer.
 *  
 *  If blurb.delta exists and is not empty, we can simply set the value
 *  otherwise, we need to convert to blurb
 *  
 */
export function setDeltaFromBlurb(blurb) {
  return (dispatch) => {
    let delta = null;
    if (blurb.delta.ops) {
      // blurb has the `ops` key which means data has been set
      delta = blurb.delta;
    } else {
      // we need to parse the blurb and set the delta
      delta = convertText2Delta(blurb.body || '');
    }
    return dispatch(setDelta(blurb.id, delta));
  };
}

export const SET_TEXT = 'quillText/SET_TEXT';

/**
 * Store the Text Value of of quill Delta.
 * Pull from this immutable.js store when we need the text values
 */

export function setQuillText(name, textValue) {
  return {
    type: SET_TEXT,
    name,
    textValue,
  };
}


// reset quill delta
export const FLUSH_DELTAS = 'quillDelta/FLUSH_DELTAS';
export const FLUSH_TEXTS = 'quillText/FLUSH_TEXTS';
