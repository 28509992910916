import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Link as MatLink,
    OutlinedInput,
    Typography
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { RebelbaseLogoIcon } from 'assets';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import LanguagePicker from 'components/Common/LanguagePicker/LanguagePicker';
import { LoginFormData } from './LoginForm';

const SignInForm = (props: LoginFormData & { redirectUrl : string | null }) => {

    const {
        onSubmit,
        reset,
        formHandleSubmit,
        register,
        errors,
        language,
        setValue,
        getValues,
        redirectUrl
    } = props;

    const history = useHistory();

    const [loaded, setLoaded] = useState(false);

    // TODO remove any type
    const { isRequesting, emailAddressFromParams, isVerifyingEmail } =
        useSelector((state: any) => state.metadata);

    const reCaptchaRef = useRef();

    const reCaptchaKey = (window.env || process.env).REACT_APP_RECAPTCHA_KEY;

    const enableReCaptcha =
        (window.env || process.env).ENABLE_RECAPTCHA === 'true';

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (emailAddressFromParams) {
            reset({ email: emailAddressFromParams });
        }
    }, [emailAddressFromParams])

    const { t } = useTranslation(['translation.login']);

    const baseUrl = language === 'ESP' ? '/es' : '';

    const signup = () => {
        if (sessionStorage.getItem('scrollDetails')) {
            const sessionData = JSON.parse(sessionStorage.getItem('scrollDetails') || '{}');
            sessionStorage.setItem(
                'scrollDetails',
                JSON.stringify({ ...sessionData, path: '/auth/sign-up' })
            );
        }
        let url = '/auth/sign-up';
        if (redirectUrl) {
            url += `?redirect_url=${redirectUrl}`;
        }
        history.push(url);
    };

    const forgotPasswordPage = (event: React.SyntheticEvent) => {
        event?.preventDefault();
        history.push('/forgot-password');
    }

    return (
        <form
            onSubmit={formHandleSubmit(onSubmit)}
            noValidate
        >
            <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{
                    maxHeight: 581,
                    width: { xs: 328, md: 572 },
                    background: (theme) => theme?.palette?.common?.white,
                    borderRadius: 3,
                    padding: (theme: Theme) => theme.spacing(3, 4),
                    position: 'relative'
                }} display="flex" alignItems="center" flexDirection="column">
                    <Box sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16
                    }}>
                        <LanguagePicker withLabel={false} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <MatLink href={`https://rebelbase.co${baseUrl}`} sx={{
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}>
                            <RebelbaseLogoIcon sx={{
                                height: 30,
                                width: 206,
                                color: (theme: Theme) => theme?.palette?.primary[100],
                                '&:hover': {
                                    color: (theme: Theme) => theme?.palette?.primary[100]
                                }
                            }} />
                        </MatLink>
                    </Box>
                    <Typography sx={{
                        pt: 3,
                        pb: 4,
                        typography: { xs: 'h2', md: 'h1'}
                    }} color="secondary">
                        {t('WelcomeBack')}, Rebel!
                    </Typography>
                    <Typography variant="h2" color="secondary">
                        {t('Login')}
                    </Typography>
                    <Box
                        sx={{ pt: 3, width: { xs: 'auto', md: '100%' } }}
                    >

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel error={Boolean(errors?.email)} htmlFor="email">{t('Email')}</InputLabel>
                            <OutlinedInput
                                id="email"
                                fullWidth
                                {...register("email", {
                                    required: "Required"
                                })}
                                label={t('Email')}
                                error={Boolean(errors?.email)}
                            />
                            {
                                errors?.email && (
                                    <FormHelperText error={Boolean(errors?.email)} id="outlined-email-helper-text">
                                        {errors?.email?.message}
                                    </FormHelperText>
                                )
                            }
                            {emailAddressFromParams !== '' &&
                            !isVerifyingEmail &&
                            !errors.email &&
                            getValues("email") !== emailAddressFromParams && (
                                <FormHelperText error={Boolean(errors?.email)} id="outlined-email-helper-text">
                                    {t('UnableToAcceptInvitation')}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl sx={{ mt: 4 }} variant="outlined" fullWidth>
                            <InputLabel error={Boolean(errors?.password)} htmlFor="password">
                                {t('Password')}
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                type="password"
                                fullWidth
                                label={t('Password')}
                                {...register("password", {
                                    required: "Required"
                                })}
                                error={Boolean(errors?.password)}
                            />
                            {
                                errors?.password && (
                                    <FormHelperText error={Boolean(errors?.password)} id="outlined-password-helper-text">
                                        {errors?.password?.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>

                    </Box>

                        {loaded && enableReCaptcha && (
                            <Box sx={{ mt: 1, width: '100%' }} display="flex" justifyContent="center">
                                <ReCAPTCHA
                                    name="reCaptcha"
                                    ref={reCaptchaRef}
                                    {...register("reCaptcha", {
                                        required: "Required"
                                    })}
                                    onChange={(e: string) => setValue('reCaptcha', e)}
                                    sitekey={reCaptchaKey}
                                />
                            </Box>
                        )}
                    <Box sx={{ mt: 1, width: '100%' }} display="flex" justifyContent="flex-end">
                        <MatLink underline="none" color="primary" onClick={forgotPasswordPage} sx={{
                            '& :hover': {
                                color: (theme: Theme) => theme?.palette?.primary?.main
                            }
                        }}>
                            <Typography variant="body1" color="primary">
                                {t('ForgotPassword')}
                            </Typography>
                        </MatLink>
                    </Box>
                    <Box sx={{
                        pt: 3,
                        pb: 2,
                        width: '100%'
                    }} display="flex" alignItems="center" justifyContent="center">
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            startIcon={ isRequesting ? <CircularProgress size={20} sx={{
                                color: (theme: Theme) => theme?.palette?.common?.white
                            }} /> : null}
                        >
                            {t('Login')}
                        </Button>
                    </Box>
                    <Box sx={{
                        width: '100%'
                    }} display="flex" alignItems="center" justifyContent="center">
                        <Typography align='left' variant='body1' color="secondary">
                            {t('NoAccount')}
                        </Typography>
                        <MatLink underline="none" color="primary" onClick={signup} sx={{
                            pl: 1,
                            '& :hover': {
                                color: (theme: Theme) => theme?.palette?.primary?.main
                            }
                        }}>
                            <Typography variant="body1" color="primary">
                                {t('Signup')}
                            </Typography>
                        </MatLink>
                    </Box>
                </Box>
            </Box>
        </form>
    );
}


export default React.memo(SignInForm);