

import i18n from '../../i18n';
import { successPopup } from './successPopup';

export const hubDetailsUpdatedPopup = (dispatch) => {
         dispatch(
           successPopup(
             `${
               i18n.getDataByLanguage(i18n.language)['translation.notification']
                 .HubCreated
             }`
           )
         );
       };
