export const industries = [
  { label: 'Accounting', id: 1 },
  { label: 'Advertising', id: 2 },
  { label: 'Agriculture and Fishing', id: 3 },
  { label: 'Animal Services', id: 4 },
  { label: 'Automotive', id: 5 },
  { label: 'Banking', id: 6 },
  { label: 'Biotech', id: 7 },
  { label: 'Business', id: 8 },
  { label: 'Charity', id: 9 },
  { label: 'Construction', id: 10 },
  { label: 'Creative Design', id: 11 },
  { label: 'Customer Service', id: 12 },
  { label: 'Editorial', id: 13 },
  { label: 'Education', id: 14 },
  { label: 'Energy', id: 15 },
  { label: 'Engineering', id: 16 },
  { label: 'Environment', id: 17 },
  { label: 'Executive', id: 18 },
  { label: 'Finance', id: 19 },
  { label: 'Food Services', id: 20 },
  { label: 'Government', id: 21 },
  { label: 'Healthcare', id: 22 },
  { label: 'Hospitality', id: 23 },
  { label: 'Human Resources', id: 24 },
  { label: 'Human Services', id: 25 },
  { label: 'Insurance', id: 26 },
  { label: 'International', id: 27 },
  { label: 'Internet', id: 28 },
  { label: 'IT', id: 29 },
  { label: 'Legal', id: 30 },
  { label: 'Logistics', id: 31 },
  { label: 'Maintenance', id: 32 },
  { label: 'Manufacturing', id: 33 },
  { label: 'Marketing', id: 34 },
  { label: 'Media', id: 35 },
  { label: 'Personal Services', id: 36 },
  { label: 'Production and Ops', id: 37 },
  { label: 'Quality Assurance', id: 38 },
  { label: 'R & D', id: 39 },
  { label: 'Real Estate', id: 40 },
  { label: 'Religious', id: 41 },
  { label: 'Retail', id: 42 },
  { label: 'Sales', id: 43 },
  { label: 'Science', id: 44 },
  { label: 'Security', id: 45 },
  { label: 'Skilled Trades', id: 46 },
  { label: 'Sports', id: 47 },
  { label: 'Technology', id: 48 },
  { label: 'Telecommunications', id: 49 },
  { label: 'Transportation', id: 50 },
  { label: 'Travel', id: 51 },
  { label: 'Web', id: 52 },
];
