import { combineReducers } from 'redux';
import { Map, fromJS } from 'immutable';
import {
  SET_AVATAR,
  CLOSE_AVATAR_UPLOAD,
  OPEN_AVATAR_UPLOAD,
  SAVE_AVATAR,
  SAVED_AVATAR,
} from 'store/avatar-v1/avatarConstants';
import {
  // profile
  FETCHING_PROFILE,
  SET_PROFILE_ID,

  // summary
  SET_SUMMARY,
  EDIT_SUMMARY,
  SAVE_SUMMARY,

  // social links
  SET_SOCIAL_LINKS,
  EDIT_SOCIAL_LINKS,
  SAVE_SOCIAL_LINKS,

  // experience
  //SET_EXPERIENCE,

  // location
  SET_LOCATION,

  // purpose
  SET_PURPOSE,

  // Metadata
  SET_META_DATA,

  // completion
  PROFILE_COMPLETED,

  // Experience
  EDIT_EXPERIENCE,
  SAVE_EXPERIENCE,
  RECEIVE_EXPERIENCE,
  ADD_EXPERIENCE,
  DELETE_EXPERIENCE,
  TOGGLE_PRESENT,
  SET_REBEL_JUDGE_BIO,
  SET_PROFILE_VISIBILITY,
  RESET_EDITING_SOCIAL_LINKS,
  RESET_EDITING_SUMMARY,
} from './profileActions';
import { SET_PROJECTS } from '../project/projectActions';

function profileId(state = null, action) {
  switch (action.type) {
    case SET_PROFILE_ID:
      return action.data;
    default:
      return state;
  }
}

// Visibility
function isPublic(state = null, action) {
  switch (action.type) {
    case SET_PROFILE_VISIBILITY:
      return action.value;
    default:
      return state;
  }
}

// Avatar
function avatar(state = null, action) {
  switch (action.type) {
    case SET_AVATAR:
      return action.data;
    default:
      return state;
  }
}

// Summary
function summary(state = null, action) {
  switch (action.type) {
    case SET_SUMMARY:
      return action.data;
    default:
      return state;
  }
}

// location

function location(state = null, action) {
  switch (action.type) {
    case SET_LOCATION:
      return Map(fromJS(action.data));
    default:
      return state;
  }
}

// Social Links
const initSocialLinks = Map({
  twitter: null,
  facebook: null,
  linkedin: null,
});
function socialLinks(state = initSocialLinks, action) {
  switch (action.type) {
    case SET_SOCIAL_LINKS:
      return state.merge(action.data);
    default:
      return state;
  }
}

// purpose

function purpose(state = null, action) {
  switch (action.type) {
    case SET_PURPOSE:
      return Map(fromJS(action.data));
    default:
      return state;
  }
}

// Page Meta Data
const initPageMetaData = Map({
  // loading
  summaryLoaded: false,
  socialLinksLoaded: false,
  experienceLoaded: false,
  projectsLoaded: false,
  profileCompletionLoaded: false,
  judgeBioLoaded: false,
  // saving
  isSavingSocialLinks: false,
  isSavingSummary: false,
  isSavingAvatar: false,
  // toggles
  isEditingSummary: false,
  isEditingSocialLinks: false,
  isUploadingAvatar: false,
  isEditingExperience: false,
  isAddingExperience: false,
  // completion
  isProfileComplete: false,
});
function pageMetaData(state = initPageMetaData, action) {
  switch (action.type) {
    // meta data
    case SET_META_DATA:
      return state.merge(action.data).set('profileCompletionLoaded', true);
    // loading cases
    case FETCHING_PROFILE:
      return state
        .set('summaryLoaded', false)
        .set('avatarLoaded', false)
        .set('isUploadingAvatar', false)
        .set('isSavingAvatar', false)
        .set('purposeLoaded', false)
        .set('purposeLaded', false);
    case SET_PROJECTS:
      return state.set('projectsLoaded', true);
    case SET_SUMMARY:
      return state
        .set('summaryLoaded', true)
        .set('isSavingSummary', false)
        .set('isEditingSummary', false);
    case RESET_EDITING_SUMMARY:
      return state.set('isEditingSummary', false);
    case SET_SOCIAL_LINKS:
      return state
        .set('socialLinksLoaded', true)
        .set('isSavingSocialLinks', false)
        .set('isEditingSocialLinks', false);
    // case SET_EXPERIENCE:
    //   return state.set('experienceLoaded', true);
    // saving cases
    case SAVE_SOCIAL_LINKS:
      return state.set('isSavingSocialLinks', false);
    case SAVE_AVATAR:
      return state.set('isSavingAvatar', true);
    case SAVED_AVATAR:
      return state.set('isSavingAvatar', false);
    case SAVE_SUMMARY:
      return state.set('isSavingSummary', true);
    // toggle cases
    case EDIT_SUMMARY:
      return state.set('isEditingSummary', true);
    case OPEN_AVATAR_UPLOAD:
      return state.set('isUploadingAvatar', true);
    case CLOSE_AVATAR_UPLOAD:
      return state.set('isUploadingAvatar', false);
    case EDIT_SOCIAL_LINKS:
      return state.set('isEditingSocialLinks', true);
    case RESET_EDITING_SOCIAL_LINKS:
      return state.set('isEditingSocialLinks', false);
    // profile completion case
    case PROFILE_COMPLETED:
      return state
        .set('isProfileComplete', true)
        .set('profileCompletionLoaded', true);
    case SET_REBEL_JUDGE_BIO:
      return state.set('judgeBioLoaded', true);
    default:
      return state;
  }
}

// OLD STUFF:

// *** SOCIAL LINKS ***
/**
 * reducer to handle if user is editing the page
 */

// *** EXPERIENCE ***
export function experience(
  state = {
    data: [],
  },
  action
) {
  switch (action.type) {
    case RECEIVE_EXPERIENCE: {
      const newData = { ...action.data };
      newData.data.new = { present: false };
      return { ...state, ...newData };
    }
    case TOGGLE_PRESENT: {
      const newState = { ...state };
      newState.data[action.index].present = action.present;
      return newState;
    }
    default:
      return state;
  }
}

export function isAddingExperience(state = false, action) {
  switch (action.type) {
    case ADD_EXPERIENCE:
      return !state;
    case SAVE_EXPERIENCE:
    case DELETE_EXPERIENCE:
      return false;
    default:
      return state;
  }
}

export function isEditingExperience(state = false, action) {
  switch (action.type) {
    case EDIT_EXPERIENCE:
      return action.editId;
    case SAVE_EXPERIENCE:
    case DELETE_EXPERIENCE:
      return false;
    default:
      return state;
  }
}

export function judgeBio(state = Map({ title: null, bio: null }), action) {
  switch (action.type) {
    case SET_REBEL_JUDGE_BIO:
      return state.merge(fromJS(action.data));
    default:
      return state;
  }
}

// *** COMBINE REDUCERS ***
export default combineReducers({
  // REWORKED
  pageMetaData,
  avatar,
  summary,
  profileId,
  location,
  purpose,
  socialLinks,
  judgeBio,
  isPublic,
  experience,
  isAddingExperience,
  isEditingExperience,
});
