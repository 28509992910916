import { combineReducers } from 'redux';
import { reducer as popups } from 'react-notification-system-redux';
import { reducer as form } from 'redux-form';
import appData from './appData/appDataReducers';
import builderData from './builderData/builderDataReducers';
import browse from './browse/browseReducers';
import channels from './channels/channelReducers';
import createProject from './createProject/createProjectReducers';
import events from './events/eventReducers';
import eventsv1 from './events-v1/eventReducers';
import hubs from './hubs/hubReducers';
import hubGroup from './hubGroups/reducers';
import invitations from './invitations/invitationReducers';
import metadata from './metadata/metadataReducers';
import notifications from './notifications/notificationReducers';
import profile from './profile/profileReducers';
import project from './project/projectReducers';
import projectData from './projectData/projectDataReducers';
import projectProfilev1 from './projectProfile-v1/projectProfileReducers';
import hubActivity from './hubActivity-v1/hubActivityReducers';
import quill from '../shared/quill/quillReducers';
import settingsv1 from './settings-v1/settingReducers';
import themeReducer from './theme/themeReducer';
import sideNav from './sideNav/sideNavReducers';
import user from './user/userReducers';
import userEmailAddresses from './userEmailAddresses/userEmailAddressReducers';
import { RESET_TO_PUBLIC_DATA } from './actions';
import alertsReducer from './alerts/alertsReducer';
import authUser from './auth/authReducer';
import rebelAlert from './rebel-alert/rebelALertReducer';

const rootReducer = combineReducers({
  appData,
  builderData,
  browse,
  channels,
  createProject,
  events,
  eventsv1,
  hubGroup,
  form,
  hubs,
  invitations,
  metadata,
  notifications,
  popups,
  profile,
  project,
  projectData,
  projectProfilev1,
  quill,
  settingsv1,
  sideNav,
  user,
  userEmailAddresses,
  hubActivity,
  themeReducer,
  alertsReducer,
  authUser,
  rebelAlert
});

const createStateWithPublicData = (state, action) => {
  const {
    industries,
    challengeBucketBlueprints,
    challengeTrackBlueprints,
    challengeBlueprints,
    topicBlueprints,
    answerBlueprints,
    userProjectRoleTypes,
    organizationTypes,
    userEventRoleTypes,
  } = state.appData;

  const publicAppData = {
    industries,
    challengeBucketBlueprints,
    challengeTrackBlueprints,
    challengeBlueprints,
    topicBlueprints,
    answerBlueprints,
    userProjectRoleTypes,
    organizationTypes,
    userEventRoleTypes,
  };

  return rootReducer(
    {
      appData: publicAppData,
    },
    action
  );
};

export default (state, action) => {
  if (action.type === RESET_TO_PUBLIC_DATA) {
    return createStateWithPublicData(state, action);
  }
  return rootReducer(state, action);
};
