import React, { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Collapse, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import NavLink from "components/AppLayout/partials/sidebar/NavLink/NavLink";

const ExploreMore = () => {

    const userPreferredLanguage = useSelector(
        (state: any) =>
            state.user &&
            state.user.get('user_preference') &&
            state.user.get('user_preference').get('language')
    );

    const theme = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const externalLinks = useMemo(() => {
        const languagePath = userPreferredLanguage === 'Español' ? 'es/' : '';
        return {
            homepage: `https://rebelbase.co/${languagePath}`,
            caseStudies: `https://rebelbase.co/new-home-page#enable-change-section`,
            contactSales: `https://rebelbase.co/${languagePath}contact-us/`,
            forEducators: `https://rebelbase.co/${languagePath}for-educators/`,
            about: `https://rebelbase.co/${languagePath}about/`,
            blog: `https://rebelbase.co/${languagePath}blog/`,
        };
    }, [userPreferredLanguage]);

    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{
            width: { xs: '100%', md: 375 },
            display: 'flex',
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            pt: { xs: 7, md: 0 }
        }}>
            <Typography variant="h2" fontWeight="bold" textTransform="uppercase" sx={{
                color: theme?.palette?.common?.white,
                display: "flex",
                alignItems: "center"
            }}>
                Explore
                <ExpandMoreIcon
                    sx={{ display: { xs: 'block', md: 'none'}}}
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                />
            </Typography>

            <Collapse sx={{
                width: { xs: '100%', md: 'auto' }
            }} in={expanded} timeout="auto" unmountOnExit>
                <Stack columnGap={10} rowGap={5} sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    mt: 2,
                    color: theme?.palette?.common?.white
                }}>
                    <Box
                        sx={{
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        component="a"
                        href={externalLinks?.caseStudies}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: { xs: '100%', md: 119 },
                            textAlign: { xs: 'center', md: 'left'}
                        }}>
                            Case Studies
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        component={NavLink}
                        to="/browse"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: { xs: '100%', md: 130 },
                            textAlign: { xs: 'center', md: 'left'}
                        }}>
                            Browse Projects
                        </Typography>
                    </Box>
                </Stack>

                <Stack columnGap={10} rowGap={5} sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    mt: 5,
                    color: theme?.palette?.common?.white
                }}>
                    <Box
                        sx={{
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        component="a"
                        href={externalLinks?.blog}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: { xs: '100%', md: 119 },
                            textAlign: { xs: 'center', md: 'left'}
                        }}>
                            Blog
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: 'transparent',
                            color: `${theme?.palette?.common?.white} !important`,
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        component="a"
                        href={externalLinks?.about}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Typography variant="h3" sx={{
                            width: { xs: '100%', md: 'auto' },
                            textAlign: { xs: 'center', md: 'left'}
                        }}>
                            About
                        </Typography>
                    </Box>
                </Stack>
            </Collapse>
        </Box>
    )
}

export default ExploreMore;