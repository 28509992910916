import _ from 'lodash';
import { useSelector } from 'react-redux';
import { createTheme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { Theme } from '@mui/material/styles';
import {PaletteColorOptions} from "@mui/material/styles/createPalette";
import typography from './Typography';
import shadows from './Shadows';
import { REBEL_BLUE } from '../store/theme/themeAction';
import getThemedComponents from './Override';

const SidebarWidth = 224;
const TopbarHeight = 50;

// TODO moved typed components. then only we can avoid un necessary property configurations here
const baseTheme: ThemeOptions = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#2374A5',
      light: '#1EC5D5',
      dark: '#004653',
      contrastText: '#ffffff',
      50: '#0094A4',
      // TODO yet to decide how we've to use this color in other places
      // This is specifically for brand logo. Refer login page
      100: '#1E74A6',
      200: '#3F92A2',
      300: '#14A4B1',
      400: '#609FAC'
    } as PaletteColorOptions,
    secondary: {
      main: '#222f30',
      light: '#4a5859',
      dark: '#000507',
      contrastText: '#ffffff',
    },
    success: {
      main: '#83cbb5',
      light: '#b5fee7',
      dark: '#539a85',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2274a5',
      light: '#5ea2d7',
      dark: '#004976',
      contrastText: '#ffffff',
      50: '#19A0AC'
    },
    error: {
      main: '#ef6464',
      light: '#ff9692',
      dark: '#b7313a',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#f6b679',
      light: '#ffe8a9',
      dark: '#c1864c',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#222f30',
      secondary: '#1ec5d5',
      disabled: '#a5a5a5',
    },
    grey: {
      /**
       * To be used as the default screen background color for both desktop and mobile)
       */
      100: '#F6F6F6',
      /**
       * To be used as the default background color for any “selected” states
       */
      200: '#F1F1F1',
      /**
       * To be used as the default background color for any “disabled” states
       */
      300: '#E5E5E5',
      /**
       * To be used as the default border/line color for any “disabled” states
       */
      400: '#C3C3C3',
      /**
       * To be used as the default text color for any “disabled” states
       */
      500: '#A5A5A5',
      /**
       * use this color to scroll bar which more close to existing scroll color
       */
      600: '#707070',
      /**
       * Chips background color for date range selector
       */
      700: '#F4F4F4'
    },
    background: {
      default: '#F6F6F6',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    action: {
      selected: '#f1f1f1',
      disabledBackground: '#e5e5e5',
      disabled: '#a5a5a5',
      hoverOpacity: 0.02,
      hover: 'rgba(0, 0, 0, 0.03)',
    },
  },
  shape: {
    borderRadius: 5, // for example 1 * 5 = 5px
  },
  spacing: [
    0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128,
  ],
  mixins: {
    toolbar: {
      '@media(min-width:1280px)': {
        minHeight: TopbarHeight,
        padding: '0 30px',
      },
      '@media(max-width:1280px)': {
        minHeight: '50px',
      },
    },
  },
  /**
   * A breakpoint is the range of predetermined screen sizes that have specific layout requirements.
   * How it works:
   *
   *  - xs (extra-small): 0px or larger
   *  - sm (small): 475px or larger
   *  - md (medium): 725px or larger
   *  - lg (large): 1050px or larger
   *  - xl (extra-large): 1250px or larger
   */
  breakpoints: {
    values: {
      xs: 0,
      sm: 475,
      md: 725,
      lg: 1050,
      xl: 1250,
    },
  },
  typography,
  shadows,
};

const themesOptions = [
  {
    name: REBEL_BLUE,
    palette: {
      primary: {
        main: '#007380',
        light: '#1EC5D5',
        dark: '#004653',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#222f30',
      },
    },
  },
  // TODO extend your theme options when multiple themes required
];

export const BuildTheme = (
  config: { theme?: string; direction?: string } = {}
): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);
  // TODO find a way to remove any type
  const customizer = useSelector((state) => (state as any).themeReducer);
  const baseMode = {
    palette: {
      mode: customizer.themeReducer.activeMode,
      background: {
        default:
          customizer.themeReducer.activeMode === 'dark' ? '#282C34' : '#f1f1f1',
      },
      text: {
        primary:
          customizer.themeReducer.activeMode === 'dark'
            ? '#e6e5e8'
            : 'rgba(0, 0, 0, 0.87)',
        secondary:
          customizer.themeReducer.activeMode === 'dark' ? '#adb0bb' : '#222f30',
      },
    },
  };
  if (!themeOptions) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }
  const brandTheme: Theme = createTheme(
    _.merge({}, baseTheme, baseMode, themeOptions, {
      direction: config.direction,
    })
  );
  /**
   * Enable Support theme spacing, colors and etc inside override components
   */
  return deepmerge(brandTheme, getThemedComponents(brandTheme));
};

export { TopbarHeight, SidebarWidth, baseTheme };
