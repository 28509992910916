import React from 'react';
import { experimentalStyled } from '@mui/material';

const EditPenButton = ({
  content = '',
  onClickFunction,
  style,
  afterContent = '',
  className = '',
  width='30%'
}) => {
  const HoverText = experimentalStyled('button')(() => (`
      width: ${width};
     :hover::before {
      content: '${content}';
      cursor: pointer;
    }
    :hover::after {
      content: '${afterContent}';
      cursor: pointer;
    }
  `));

  return (
    <HoverText
      onClick={() => {
        onClickFunction();
      }}
      style={style}
      className={`edit-pen__btn ${className}`}
    >
      <span className="edit-pen" />
    </HoverText>
  );
};

export default EditPenButton;
