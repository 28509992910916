import Axios, { AxiosError } from 'axios';
import Cookie from 'js-cookie';

/**
 * created axios instance with default base url and JWT token in header for authentication
 */
const axios = Axios.create({
  baseURL: ((window as any).env || process.env).REACT_APP_API_ENDPOINT,
  transformRequest: [
    (data, headers) => {
      const jwt = Cookie.get('token');
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = jwt ? `Bearer ${jwt}` : '';
      return data;
    },
  ],
});

/**
 * Reusable function to return error based on type of error object
 * @param error error object from the request made
 * @returns the reponse body in case there is an error from the server else returns the error object
 */
const returnError = (error: AxiosError | any) => {
  if (Axios.isAxiosError(error)) return error.response;
  return error;
};

// TODO to make it more generic we need to use `request` option
const makeAjaxCall = async (
  url: string,
  type: string,
  params?: {
    [key: string]:
      | string
      | number
      | boolean
      | null
      | Array<string | number | boolean>;
  },
  queryParams?: {
    [key: string]:
      | string
      | number
      | boolean
      | null
      | Array<string | number | boolean>;
  }
) => {
  try {
    let response;
    switch (type) {
      case 'post':
        response = await axios.post(url, JSON.stringify(params), {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json',
          },
        });
        break;
      case 'put':
        response = params
          ? await axios.put(url, JSON.stringify(params), {
              headers: {
                'Content-Type': 'application/json',
              },
              params: queryParams,
            })
          : await axios.put(url);
        break;
      case 'delete':
        response = await axios.delete(url, params);
        break;
      default:
        response = await axios.get(url, {
          params,
        });
        break;
    }
    const { data } = response;
    return data;
  } catch (error: AxiosError | any) {
    // eslint-disable-next-line no-console
    console.error(error);
    return returnError(error);
  }
};

/**
 * Reusable function to make GET requests using axios
 * @param {string} endpoint the API endpoint to which the GET request needs to be made
 * @param {object} params an object with key value pair which may be needed, e.g.: {ID: 5, page: 1}
 * @returns the response data or error
 */
export const GET = async (
  endpoint: string,
  params: {
    [key: string]: string | number | boolean | Array<string | number | boolean>;
  } = {}
) => makeAjaxCall(endpoint, 'get', params);

export const PUT = async (
  endpoint: string,
  params: {
    [key: string]: string | number | boolean | Array<string | number | boolean>;
  } = {},
  queryParams: {
    [key: string]: string | number | boolean | Array<string | number | boolean>;
  } = {}
) => makeAjaxCall(endpoint, 'put', params, queryParams);

export const DELETE = async (
  endpoint: string,
  params?: {
    [key: string]: string | number | boolean | Array<string | number | boolean>;
  }
) => makeAjaxCall(endpoint, 'delete', params);

export const POST = async (
  endpoint: string,
  params: {
    [key: string]: string | number | boolean | Array<string | number | boolean>;
  } = {}
) => makeAjaxCall(endpoint, 'post', params);

export const getEvent = async (eventId: number) =>
  GET(`/api/events/${eventId}`);

export const getHubEvents = async (hubId: number) =>
  GET(`/api/hubs/${hubId}/events`);

export const getMyEvents = async () => GET('/api/events');

export const getEventPosts = async (eventId: number) =>
  GET(`/api/events/${eventId}/posts`);

export const getEventInvitations = async (eventId: number) =>
  GET(`/api/events/${eventId}/invitations`);

export const getHubActivities = async (
  hubId: number,
  type: string | null = null,
  own: boolean = false,
  offset: number = 0,
  limit: number = 10
) => {
  let params: { [key: string]: string | number | boolean } = {
    own,
    offset,
    limit,
  };
  if (type)
    params = {
      ...params,
      type,
    };
  return GET(`/api/hubs/${hubId}/activities`, params);
};

export const getHubActivity = async (
  hubId: number | string,
  activityId: number | string
) => GET(`/api/hubs/${hubId}/activities/${activityId}`);

export const getHubMetaData = async (hubId: number) => {
  return GET(`/api/hubs/${hubId}`);
};

export const getPendingHubInvites = async (hubId: number, params: any) => {
  const defaultParams: any = {
    period: 7,
    offset: 0,
    limit: 20,
    order_by: 'desc',
    term: null,
  };
  return GET(`api/hubs/${hubId}/invitations`, { ...defaultParams, ...params });
};

export const sendHubInvites = async (
  hubId: number,
  inviteIds: number | Array<number>
) => {
  if (inviteIds instanceof Array) {
    return POST(`api/hubs/${hubId}/invitations/bulk_ops`, {
      resend_invitations: inviteIds,
    });
  }
  return PUT(`api/hubs/${hubId}/invitations/${inviteIds}`);
};

export const deleteHubInvites = async (
  hubId: number,
  inviteIds: number | Array<number>
) => {
  if (inviteIds instanceof Array) {
    return POST(`api/hubs/${hubId}/invitations/bulk_ops`, {
      delete_invitations: inviteIds,
    });
  }
  return DELETE(`api/hubs/${hubId}/invitations/${inviteIds}`);
};

export const getHubGroupMembers = async (
  hubId: number,
  groupId: number,
  params: { [key: string]: string | number } = {}
) => GET(`api/hubs/${hubId}/groups/${groupId}/members`, params);

export const deleteHubGroupMember = async (
  hubId: number,
  groupId: number,
  memberId: number
) => DELETE(`api/hubs/${hubId}/groups/${groupId}/members/${memberId}`);

export const updateHubGroupMemberRole = async (
  hubId: number,
  groupId: number,
  memberId: number,
  roleTypeId: number
) =>
  PUT(
    `api/hubs/${hubId}/groups/${groupId}/members/${memberId}`,
    {},
    {
      role_type_id: roleTypeId,
    }
  );
export const createHubInvitations = async (
  hubId: number,
  email_list: Array<string>
) => {
  return POST(`api/hubs/${hubId}/invitations`, { email_list });
};

export const createHubGroupInvitations = async (
  hubId: number,
  groupId: number,
  email_list: Array<string>
) => {
  return POST(`api/hubs/${hubId}/groups/${groupId}/invitations`, {
    email_list,
  });
};

export const deleteHubGroupMemberInvitation = async (
  hubId: number,
  groupId: number,
  invitationId: number
) => DELETE(`api/hubs/${hubId}/groups/${groupId}/invitations/${invitationId}`);

export const resendAllHubGroupInvitations = async (
  hubId: number,
  groupId: number
) =>
  POST(`api/hubs/${hubId}/invitations/bulk_ops?group_id=${groupId}`, {
    resend_all_invitations: true,
  });

export const getDashboard = async (key?: string, limitParam: number = 6) => {
  const limit = Math.max(6, limitParam);
  let requestBody: { [key: string]: any } = {
    limit,
  };
  if (key)
    requestBody = {
      key,
      ...requestBody,
    };
  return GET('api/dashboard', requestBody);
};

export const createProjectInvitation = async (
  projectId: number,
  email_list: Array<string>,
  role_type_id: number
) => {
  return POST(`api/projects/${projectId}/invitations`, {
    email_list,
    role_type_id,
  });
};
