import React, { useEffect, useMemo } from 'react';
import { Box, Typography, Link as MenuLink } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAlerts,
  markAsReadAlerts,
  fetchInitialUserAlerts,
} from 'store/alerts/alertsAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationsBody from 'components/Notifications';

const Notifications = (props) => {
  const { t, isDropdown = false, variant } = props;
  const history = useHistory();

  const showAllAlerts = (event) => {
    event?.preventDefault();
    history.push('/notifications');
    props.close();
  };

  const dispatch = useDispatch();
  const { alerts, loading, totalAlertsCount } = useSelector(
    (state) => state.alertsReducer
  );

  const hasMoreItems = (alerts || []).length < totalAlertsCount;
  const user = useSelector((state) => state.user.toJS());
  const { filterType, ...others } = props;
  const alertsList = React.useMemo(() => alerts, [alerts]);
  // const alertsList = React.useMemo(() => {
  //   return (alerts ?? [])?.filter(alert => {
  //     // TODO enable ProjectTopicKudosAlert and ProjectTopicNoteAlert after get confirmation from Tuba
  //     return !([3,4,5]?.indexOf(alert?.alertTypeId) > -1);
  //   });
  // }, [alerts]);

  useEffect(() => {
    if (user && user.id && (alerts || []).length === 0 && !loading) {
      dispatch(fetchInitialUserAlerts(user.id));
    }
  }, [user.id]);

  useEffect(() => {
    if (alerts?.length > 0 && props?.isMenuOpened && user && user.id) {
      const hasUnreadCount = alerts?.filter((alert) => !alert.isSeen)?.length;
      if (hasUnreadCount > 0) {
        dispatch(markAsReadAlerts(user.id));
      }
    }
  }, [alerts?.length, props.isMenuOpened, user.id]);

  const loadMore = () => {
    if (hasMoreItems && !loading) {
      const page = (alerts || []).length / 10 + 1;
      dispatch(fetchAlerts(user.id, page, 10));
    }
  };

  const scrollableTarget = useMemo(() => {
    if (variant === 'dashboard')
      return 'scrollable-dashboard-notifications-card';
    if (isDropdown) return 'alerts-container';
    return 'scrollableBody';
  }, [variant, isDropdown]);

  return (
    <Stack
      direction="column">
      {variant !== 'dashboard' && (
          <Box sx={{
            px: 3,
            py: 2,
            borderBottom: 1,
            borderBottomColor: theme => theme?.palette?.grey?.A400,
          }} display="flex" alignItems="center">
            <Typography variant="h2">
              {t('Notifications')}
            </Typography>
            {
              isDropdown && (
                  <Box sx={{ ml: 'auto'}}>
                    <MenuLink to="#" onClick={showAllAlerts}>
                      {t('SeeAll')}
                    </MenuLink>
                  </Box>
              )
            }
          </Box>
      )}
      <Stack direction="column">
        <Box
          {...others}
          id="alerts-container"
          sx={{
            maxHeight: !isDropdown ? 'calc(100% - 10px)' : '360px',
            height: !isDropdown ? 'calc(100% - 10px)' : '360px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px',
              '&-track': {
                background: 'transparent',
              },
              '&-thumb': {
                background: (theme) => theme.palette.grey['600'],
                '&:hover': {
                  background: (theme) => theme.palette.grey['600'],
                },
              },
            }
          }}
        >
          {loading}
          <InfiniteScroll
            dataLength={alertsList?.length}
            next={loadMore}
            hasMore={hasMoreItems}
            scrollableTarget={scrollableTarget}
            loader={
              loading && (
                <Box
                  sx={{ pt: 2, pb: 2 }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )
            }
          >
            <NotificationsBody
              alertsList={alertsList}
              close={props.close}
              user={user}
            />
          </InfiniteScroll>
        </Box>
      </Stack>
    </Stack>
  );
};

export default withTranslation(['translation.navigation'])(
  React.memo(Notifications)
);
