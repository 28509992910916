const defaultWidth = 320;
const defaultColors = {
  success: {
    rgb: '94, 164, 0',
    hex: '#5ea400',
  },
  error: {
    rgb: '236, 61, 61',
    hex: '#ec3d3d',
  },
  warning: {
    rgb: '235, 173, 23',
    hex: '#ebad1a',
  },
  info: {
    rgb: '54, 156, 199',
    hex: '#369cc7',
  },
};
// const defaultShadowOpacity = '0.9';

const STYLES = {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      fontFamily: 'inherit',
      position: 'fixed',
      width: defaultWidth,
      padding: '0 10px 10px 10px',
      zIndex: 9998,
      WebkitTransform: 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      //transform: 'translateZ(1000px)',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
      height: 'auto',
    },

    tl: {
      top: '0px',
      bottom: 'auto',
      left: '0px',
      right: 'auto',
    },

    tr: {
      top: '0px',
      bottom: 'auto',
      left: 'auto',
      right: '0px',
    },

    tc: {
      top: '0px',
      bottom: 'auto',
      margin: '0 auto',
      left: '50%',
      marginLeft: -(defaultWidth / 2),
    },

    bl: {
      top: 'auto',
      bottom: '0px',
      left: '0px',
      right: 'auto',
    },

    br: {
      top: 'auto',
      bottom: '0px',
      left: 'auto',
      right: '0px',
    },

    bc: {
      top: 'auto',
      bottom: '0px',
      margin: '0 auto',
      left: '50%',
      marginLeft: -(defaultWidth / 2),
    },
  },

  NotificationItem: {
    DefaultStyle: {
      position: 'relative',
      width: '100%',
      cursor: 'pointer',
      fontSize: '13px',
      margin: '10px 0 0',
      padding: '10px',
      display: 'block',
      borderRadius: '0',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
      opacity: 0,
      transition: '0.3s ease-in-out',
      WebkitTransform: 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      willChange: 'transform, opacity',

      isHidden: {
        opacity: 0,
      },

      isVisible: {
        opacity: 1,
      },
    },

    success: {
      borderTop: '5px solid #83cbb5',
      backgroundColor: '#f3f3f4',
      color: '#4b583a',
      WebkitBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      MozBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      boxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
    },

    error: {
      borderTop: '5px solid #f16b5c',
      backgroundColor: '#f3f3f4',
      color: '#412f2f',
      WebkitBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      MozBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      boxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
    },

    warning: {
      borderTop: '5px solid #f6b679',
      backgroundColor: '#f3f3f4',
      color: '#5a5343',
      WebkitBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      MozBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      boxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
    },

    info: {
      borderTop: '5px solid #073a51',
      backgroundColor: '#f3f3f4',
      color: '#41555d',
      WebkitBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      MozBoxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
      boxShadow: '8px 11px 19px 3px rgba(179,178,179,0.65)',
    },
  },

  Title: {
    DefaultStyle: {
      fontSize: '14px',
      margin: '0 0 5px 0',
      padding: '0',
      textAlign: 'center',
    },

    success: {
      color: '#83cbb5',
    },

    error: {
      color: '#f16b5c',
    },

    warning: {
      color: '#f6b679',
    },

    info: {
      color: '#073a51',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: 'Arial',
      fontSize: '17px',
      position: 'absolute',
      top: '4px',
      right: '5px',
      lineHeight: '15px',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
    },

    success: {
      color: '#6b6671',
    },

    error: {
      color: '#6b6671',
    },

    warning: {
      color: '#6b6671',
    },

    info: {
      color: '#6b6671',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#ffffff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0,
    },

    success: {
      backgroundColor: defaultColors.success.hex,
      color: '#ffffff',
    },

    error: {
      backgroundColor: defaultColors.error.hex,
      color: '#ffffff',
    },

    warning: {
      backgroundColor: defaultColors.warning.hex,
      color: '#ffffff',
    },

    info: {
      backgroundColor: defaultColors.info.hex,
      color: '#ffffff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};

export default STYLES;
