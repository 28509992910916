import React from 'react';
import { setTellUsModalState } from 'store/metadata/metadataActions';
import i18n from '../../i18n';
import { warningPopup } from './warningPopup';

export const triggerWarningPopup = (subject, dispatch) => {
  dispatch(
    warningPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification'][
          subject
        ]
      }`
    )
  );
};

export const triggerTellUsWarning = (message, dispatch) => {
  dispatch(
    warningPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification'][
          message
        ]
      }`,
      0,
      null,
      <button
        type="button"
        onClick={() => dispatch(setTellUsModalState(true))}
      >{`${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .ClickHere
      }`}</button>
    )
  );
};
