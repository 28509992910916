/* eslint-disable no-console */
import { request } from 'functions';
import { FINISH_REQUEST, BEGIN_REQUEST } from 'store/metadata/metadataActions';
import { API_DELETE } from './middlewareConstants';

const apiDeleteMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type !== API_DELETE) {
    return next(action);
  }

  const {
    payload: { url, success, id, onSuccess, token },
  } = action;

  dispatch({ type: BEGIN_REQUEST });

  request(url, token, 'DELETE', null)
    .then((res) => {
      if (success !== null) {
        if (res.status === 204) dispatch(success(id));
        else dispatch(success(res.data));
      }

      if (onSuccess !== null) {
        onSuccess(id, true);
      }
    })
    .then(() => dispatch({ type: FINISH_REQUEST }))
    .catch((err) => {
      if (onSuccess !== null) {
        onSuccess(id, false);
      }
      console.error('err', err);
      dispatch({ type: FINISH_REQUEST });
    });
  return false;
};

export default apiDeleteMiddleware;
