import React from 'react';
import { connect } from 'react-redux';
import SEO from 'components/SEO';
import NoMatch from 'Pages/NoMatch';

import CreateHubStepOne from './createHubStepOne';
import CreateHubStepTwo from './createHubStepTwo';

const CreateHub = ({ match, hubExists, isStart }) => {
  if (!hubExists) {
    return <NoMatch />;
  }

  if (isStart) {
    return (
      <SEO>
        <CreateHubStepOne />
      </SEO>
    );
  }

  switch (match.params.step) {
    case 'step-one':
      return (
        <SEO>
          <CreateHubStepOne />
        </SEO>
      );
    case 'step-two':
      return (
        <SEO>
          <CreateHubStepTwo />
        </SEO>
      );
    default:
      return <NoMatch />;
  }
};

function mapStateToProps(state, props) {
  const { hubs, invitations } = state;
  const isStart = props.match.params.h_id === 'start';
  const isValid = !!invitations.createHubInvitations.find(
    (chi) => chi.get('token') === invitations.createHubInvitationToken
  );

  return {
    isStart,
    hubExists:
      isValid ||
      !!hubs.userHubs.find(
        (h) => h.get('id') === parseInt(props.match.params.h_id, 10)
      ),
  };
}
export default connect(mapStateToProps)(CreateHub);
