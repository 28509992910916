import React from 'react';
import moment from 'moment';

export default ({ experience, editExperience, isUsersProfile }) => {
  return (
    <div className="experience__sect">
      <div className="hexagon experience__sect__hexagon" />
      <div className="experience__edit">
        <p className="experience__sect__date">{moment.utc(experience.start_date).local().format('MMM DD, YYYY')} - {experience.present ? 'Present' : moment.utc(experience.end_date).local().format('MMM DD, YYYY')}</p>
        {isUsersProfile &&
          <button className="edit-pen__btn edit-pen__experience-edit" onClick={() => editExperience(experience.id)}>
            <span className="edit-pen" />
          </button>
        }
      </div>
      <p className="experience__sect__title">{experience.title}</p>
      <p className="experience__sect__company">{experience.organization_name}</p>
      <p className="experience__sect__descript">{experience.description}</p>
    </div>
  );
};
