import {
    SUCCESS_ALERT,
    DANGER_ALERT,
    INFO_ALERT,
    WARNING_ALERT,
    HIDE_ALERT
} from "./rebelAlertActions";

const INIT_STATE = {
    message: '',
    open: false,
    severity: 'success',
    hideDuration: 5000,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    }
};

const rebelAlert = (
    state = INIT_STATE,
    action
) => {
    switch (action.type) {
        case SUCCESS_ALERT:
            return {...state, ...{...action?.payload , ...{ severity: 'success', open: true }}};
        case DANGER_ALERT:
            return {...state, ...{...action?.payload , ...{ severity: 'error', open: true }}};
        case INFO_ALERT:
            return {...state, ...{...action?.payload , ...{ severity: 'info', open: true }}};
        case WARNING_ALERT:
            return {...state, ...{...action?.payload , ...{ severity: 'warning', open: true }}};
        case HIDE_ALERT:
            return {...state, ...{ message: '', open: false }};
        default:
            return state;
    }
};

export default rebelAlert;
