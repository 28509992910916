import React from "react";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { DashboardIcon } from "assets";
import { useTranslation } from "react-i18next";
import NavLink from "../NavLink/NavLink";

const DashboardMenu = () => {

    const { t } = useTranslation(['translation.navigation']);

    return (
        <>
            <MenuList sx={{
                '& .MuiMenuItem-root': {
                    minHeight: '40px !important',
                    alignItems: 'center',
                    color: (theme) => theme?.palette?.primary?.light,
                    '&:hover': {
                        backgroundColor: (theme: Theme) =>
                            theme.palette.secondary.light,
                    },
                    '&.Mui-selected': {
                        backgroundColor: (theme: Theme) => theme?.palette?.secondary?.dark
                    }
                },
                '& .MuiListItemIcon-root': {
                    minWidth: 'auto !important',
                    color: (theme) => theme?.palette?.primary?.light,
                },
                '& .MuiListItemText-root': {
                    '.MuiTypography-root': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }
            }}>

                <MenuItem
                    component={NavLink}
                    to="/dashboard"
                    exact
                    sx={{ p: (theme: Theme) => theme?.spacing(0, 2 , 0, 1)}}>
                    <ListItemIcon
                        sx={{ px: 1 }}>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="h5">
                            {t('DashBoard')}
                        </Typography>
                    </ListItemText>
                </MenuItem>
            </MenuList>
        </>
    );
};

export default React.memo(DashboardMenu);