import { List, fromJS } from 'immutable';
import { combineReducers } from 'redux';

import {
  SET_ANSWER_BLUEPRINTS,
  SET_CHALLENGE_BLUEPRINTS,
  SET_CHALLENGE_BUCKET_BLUEPRINTS,
  SET_EVENT_TYPES,
  SET_INDUSTRIES,
  SET_ORGANIZATION_TYPES,
  SET_TOPIC_BLUEPRINTS,
  SET_USER_HUB_ROLE_TYPES,
  SET_USER_PROJECT_ROLE_TYPES,
  SET_USER_EVENT_ROLE_TYPES,
  SET_CHALLENGE_TRACK_BLUEPRINTS,
  SET_SECTION_BLUEPRINTS,
  SET_TOPIC_SECTIONS,
} from './appDataActions';

const initIndustries = List();
export function industries(state = initIndustries, action) {
  switch (action.type) {
    case SET_INDUSTRIES: {
      return initIndustries.concat(fromJS(action.data));
    }
    default:
      return state;
  }
}

const initChallengeBucketBlueprints = List();
export function challengeBucketBlueprints(
  state = initChallengeBucketBlueprints,
  action
) {
  switch (action.type) {
    case SET_CHALLENGE_BUCKET_BLUEPRINTS:
      return initChallengeBucketBlueprints.concat(fromJS(action.data));
    default:
      return state;
  }
}

const initChallengeTrackBlueprints = List();
export function challengeTrackBlueprints(
  state = initChallengeTrackBlueprints,
  action
) {
  switch (action.type) {
    case SET_CHALLENGE_TRACK_BLUEPRINTS:
      return initChallengeTrackBlueprints.concat(fromJS(action.data));
    default:
      return state;
  }
}

const initChallengeBlueprints = List();
export function challengeBlueprints(state = initChallengeBlueprints, action) {
  switch (action.type) {
    case SET_CHALLENGE_BLUEPRINTS:
      return initChallengeBlueprints.concat(fromJS(action.data));
    default:
      return state;
  }
}

const initTopicBlueprints = List();
export function topicBlueprints(state = initTopicBlueprints, action) {
  switch (action.type) {
    case SET_TOPIC_BLUEPRINTS:
      return initTopicBlueprints.concat(fromJS(action.data));
    default:
      return state;
  }
}

const initAnswerBlueprints = List();
export function answerBlueprints(state = initAnswerBlueprints, action) {
  switch (action.type) {
    case SET_ANSWER_BLUEPRINTS:
      return initAnswerBlueprints.concat(fromJS(action.data));
    default:
      return state;
  }
}

const initSectionBlueprints = List();
export function sectionBlueprints(state = initSectionBlueprints, action) {
  switch (action.type) {
    case SET_SECTION_BLUEPRINTS:
      return List(fromJS(action.data));
    default:
      return state;
  }
}

const initTopicSections = List();
export function topicSections(state = initTopicSections, action) {
  switch (action.type) {
    case SET_TOPIC_SECTIONS:
      return List(fromJS(action.data));
    default:
      return state;
  }
}

const initUserProjectRoleTypes = List();
export function userProjectRoleTypes(state = initUserProjectRoleTypes, action) {
  switch (action.type) {
    case SET_USER_PROJECT_ROLE_TYPES:
      return initUserProjectRoleTypes.concat(fromJS(action.data));
    default:
      return state;
  }
}

const initOrganizationTypes = List();
export function organizationTypes(state = initOrganizationTypes, action) {
  switch (action.type) {
    case SET_ORGANIZATION_TYPES:
      return List().concat(fromJS(action.data));
    default:
      return state;
  }
}

export function userHubRoleTypes(state = List(), action) {
  switch (action.type) {
    case SET_USER_HUB_ROLE_TYPES:
      return List().concat(fromJS(action.data));
    default:
      return state;
  }
}

export function eventTypes(state = List(), action) {
  switch (action.type) {
    case SET_EVENT_TYPES:
      return List().concat(fromJS(action.data));
    default:
      return state;
  }
}

export function userEventRoleTypes(state = List(), action) {
  switch (action.type) {
    case SET_USER_EVENT_ROLE_TYPES:
      return List().concat(fromJS(action.data));
    default:
      return state;
  }
}

export default combineReducers({
  answerBlueprints,
  challengeBlueprints,
  challengeBucketBlueprints,
  challengeTrackBlueprints,
  eventTypes,
  industries,
  organizationTypes,
  sectionBlueprints,
  topicBlueprints,
  topicSections,
  userEventRoleTypes,
  userHubRoleTypes,
  userProjectRoleTypes,
});
