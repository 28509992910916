import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { DashboardCard } from 'Pages/Dashboard/dashboardConfig';
import './carousel.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CarouselSkeletonWithoutHeading } from './CarouselSkeleton';

export const Header = ({
  title,
  actionText,
  onClickAction = () => {},
  tooltip,
  placement = 'bottom',
}) => (
  <Stack
    direction="row"
    gap={2}
    sx={{ mb: 1, mx: { xs: 2, sm: 0 }, alignItems: 'center' }}
  >
    <Typography
      variant="h3"
      sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
    >
      {title}
      {tooltip && (
        <Tooltip
          title={tooltip}
          enterTouchDelay={0}
          PopperProps={{
            placement,
          }}
        >
          <InfoOutlinedIcon
            sx={{
              height: 24,
              width: 24,
              ml: 1,
              color: (theme) => theme?.palette?.info?.main,
            }}
          />
        </Tooltip>
      )}
    </Typography>
    {actionText && (
      <Button
        sx={{
          p: 0,
          minHeight: 28,
          minWidth: 'auto',
          ml: 'auto',
        }}
        disableRipple
        onClick={onClickAction}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            '&:hover': {
              color: (theme) => theme.palette.primary.main,
            },
          }}
          variant="subtitle1"
        >
          {actionText}
        </Typography>
      </Button>
    )}
  </Stack>
);

const Carousel = ({
  title = 'Title',
  infiniteScroll = false,
  children,
  tooltip,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(null);
  const [slidesToScroll, setSlidesToScroll] = useState(null);
  const [carouselContainerWidth, setCarouselContainerWidth] = useState(null);
  const carouselContainer = useRef(null);
  const [showCarouselArrow, setCarouselArrow] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const observer = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      setCarouselContainerWidth(entries[0].contentRect.width);
    });
  });
  useEffect(() => {
    if (carouselContainer.current) observer.observe(carouselContainer.current);
  }, [carouselContainer.current]);

  useEffect(() => {
    const width = carouselContainer.current?.clientWidth;
    const slides = width / (DashboardCard.width + 24); //12px spacing on each side
    if (slides > 1) {
      if (infiniteScroll) {
        setSlidesToShow(Math.ceil(slides));
        const slickList =
          carouselContainer.current.getElementsByClassName('slick-list')[0];
        if (slickList)
          slickList.style.width = `${
            (DashboardCard.width + 24) * Math.ceil(slides)
          }px`;
      } else setSlidesToShow(slides);
      setSlidesToScroll(Math.floor(slides));
    } else setSlidesToShow(1);
  }, [carouselContainerWidth]);

  const settings = {
    dots: false,
    infinite: infiniteScroll,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    lazyLoad: true,
    draggable: false,
    responsive: [
      {
        breakpoint: (theme) => theme.breakpoints.values.sm,
        settings: {
          arrows: false,
          draggable: true,
        },
      },
    ],
  };

  return (
    <Box
      sx={{ mb: 4, mx: { xs: -2, sm: 0 }, overflow: 'hidden' }}
      ref={carouselContainer}
    >
      <Header
        title={title}
        tooltip={tooltip}
        placement={isSmallScreen ? 'top' : 'right-end'}
      />
      {slidesToShow ? (
        <Card
          sx={{
            background: { xs: 'transparent', sm: 'white' },
            boxShadow: { xs: 'none', sm: '1px 2px 10px rgb(0, 0, 0, 0.08)' },
          }}
        >
          <CardContent
            onMouseEnter={() => setCarouselArrow(true)}
            onMouseLeave={() => setCarouselArrow(false)}
            sx={{
              p: (theme) => theme.spacing(0),
              ':last-child': {
                pb: (theme) => theme.spacing(0),
              },
            }}
          >
            <Slider {...{ ...settings, arrows: showCarouselArrow }}>
              {children}
            </Slider>
          </CardContent>
        </Card>
      ) : (
        <CarouselSkeletonWithoutHeading />
      )}
    </Box>
  );
};

export default Carousel;
