import React, { useEffect, useState } from "react";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
import {
    ActivityIcon,
    BrainIcon,
    GroupIcon,
    EventIcon,
    MemberIcon,
    HubLibraryIcon
} from "assets";
import NavLink from '../NavLink/NavLink';

export interface HubMenuProps {
    id: number | string;
    hubName: string;
    roleLevel: number;
    selected: boolean;
    onMenuClick: () => void;
}

const HubMenu = (props: HubMenuProps) => {

    const [open, setOpen] = useState(false);

    const { t } = useTranslation(['translation.navigation']);

    const {
        id,
        hubName,
        roleLevel,
        onMenuClick
    } = props;

    const userHasAdminRights = roleLevel >= 90;

    useEffect(() => {
        if (props?.selected) {
            setOpen(props?.selected);
        }
    }, [props?.selected]);

    return (
        <>
            <MenuList key={id} sx={{
                '& .MuiMenuItem-root': {
                    minHeight: '40px !important',
                    alignItems: 'center',
                    '&:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.secondary.light,
                    },
                    '&.Mui-selected': {
                        backgroundColor: (theme: Theme) => theme?.palette?.secondary?.dark
                    }
                },
                '& .MuiListItemIcon-root': {
                    minWidth: 'auto !important',
                    color: (theme) => theme?.palette?.common?.white,
                },
                '& .MuiListItemText-root': {
                    '.MuiTypography-root': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }
            }}>
                <MenuItem key="hub-name"
                    onClick={() => setOpen(!open)}
                    sx={{ p: (theme: Theme) => theme?.spacing(0, 2 , 0, 1)}}>
                    <ListItemIcon
                        sx={{ px: 1 }}>
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="h5">
                            {hubName}
                        </Typography>
                    </ListItemText>
                </MenuItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <MenuList
                        component="div"
                        disablePadding>

                        <MenuItem key="hub-activities"
                            onClick={onMenuClick}
                            component={NavLink}
                            to={`/hubs/${id}/activities`}
                            exact
                            sx={{
                                pl: 3,
                                color: (theme) => theme?.palette?.primary?.light
                            }}>
                            <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                <ActivityIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h5">
                                    {t('Activities')}
                                </Typography>
                            </ListItemText>
                        </MenuItem>

                        {
                            userHasAdminRights && (
                                <MenuItem key="hub-brain"
                                    onClick={onMenuClick}
                                    component={NavLink}
                                    to={`/hubs/${id}/brain`}
                                    exact
                                    sx={{
                                        pl: 3,
                                        color: (theme) => theme?.palette?.primary?.light
                                    }}>
                                    <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                        <BrainIcon />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="h5">
                                            {t('Brain')}
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            )
                        }

                        <MenuItem key="hub-groups"
                            onClick={onMenuClick}
                            component={NavLink}
                            to={`/hubs/${id}/groups`}
                            exact
                            sx={{
                                pl: 3,
                                color: (theme) => theme?.palette?.primary?.light
                            }}>
                            <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h5">
                                    {t('Groups')}
                                </Typography>
                            </ListItemText>
                        </MenuItem>

                        <MenuItem key="hub-events"
                            onClick={onMenuClick}
                            component={NavLink}
                            to={`/hubs/${id}/events`}
                            exact
                            sx={{
                                pl: 3,
                                color: (theme) => theme?.palette?.primary?.light
                            }}>
                            <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                <EventIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h5">
                                    {t('Events')}
                                </Typography>
                            </ListItemText>
                        </MenuItem>

                        <MenuItem key="hub-members"
                            onClick={onMenuClick}
                            component={NavLink}
                            to={`/hubs/${id}/members`}
                            exact
                            sx={{
                                pl: 3,
                                color: (theme) => theme?.palette?.primary?.light
                            }}>
                            <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                <MemberIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h5">
                                    {t('Members')}
                                </Typography>
                            </ListItemText>
                        </MenuItem>

                        {
                            userHasAdminRights && (
                                <MenuItem
                                    onClick={onMenuClick}
                                    component={NavLink}
                                    to={`/hubs/${id}/library`}
                                    exact
                                    sx={{
                                        pl: 3,
                                        color: (theme) => theme?.palette?.primary?.light
                                    }}>
                                    <ListItemIcon sx={{ pr: 1, color: 'inherit !important' }}>
                                        <HubLibraryIcon />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="h5">
                                            {t('HubLibrary')}
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            )
                        }

                    </MenuList>
                </Collapse>
            </MenuList>
        </>
    );
};

export default React.memo(HubMenu);