import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { sortByName } from 'functions/sortFunctions/sortFunctions';
import { withTranslation } from 'react-i18next';
import { SET_FOCUSED_PROJECT } from 'store/sideNav/sideNavActions';
import { doRedirect } from 'store/metadata/metadataActions';

class UserProjects extends React.Component {
  // when loading
  static renderLoadingState() {
    return (
      <div className="project">
        <p className="userProfile__title">Projects</p>
        loading...
      </div>
    );
  }

  // when there are no projects
  renderEmptyState() {
    const { isUsersProfile, t } = this.props;

    if (!isUsersProfile) {
      return (
        <div className="bioInfo__wrap">
          <div className="project">
            <p className="bioInfo__title">{t('Projects')}</p>
            <p className="project__empty">{t('NoProjects')}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="bioInfo__wrap  bioInfo__wrap--small">
        <div className="project">
          <p className="bioInfo__title">{t('Projects')}</p>
          <p className="project__empty">{t('Boohoo')}</p>
          <Link to="/project/create-project">{t('CreateProject')}</Link>
        </div>
      </div>
    );
  }

  // when there are projects
  renderViewState() {
    const { projects, clickProject, isUsersProfile, t } = this.props;
    return (
      <div className="bioInfo__wrap  bioInfo__wrap--small">
        <div className="project">
          <p className="bioInfo__title">{t('Project')}&#40;s&#41;</p>
          <div className="project__container">
            {sortByName(projects).map((p) => (
              <div key={`${p.id}-${p.name}`}>
                <button
                  type="button"
                  className="project__link btn-link"
                  onClick={() => clickProject(p.id)}
                >
                  <span>{p.name}</span>
                </button>
              </div>
            ))}
          </div>
          {isUsersProfile && (
            <Link className="project__link--new" to="/project/create-project">
              {t('CreateNewProject')}
            </Link>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { projects, projectsHaveLoaded } = this.props;
    if (projectsHaveLoaded) {
      if (projects.length > 0) {
        // making sure there are projects, else render inital state
        return this.renderViewState();
      }
      return this.renderEmptyState();
    }
    return this.constructor.renderLoadingState();
  }
}
const mapDispatchToProps = (dispatch) => ({
  clickProject(projectId) {
    dispatch({
      type: SET_FOCUSED_PROJECT,
      projectId,
    });

    dispatch(doRedirect(`/project/${projectId}`));
  },
});

const mapStateToProps = (state, props) => {
  const { project, browse } = state;

  if (props.isUsersProfile) {
    const { pageMetaData } = state.profile;
    const projectsHaveLoaded = pageMetaData.get('projectsLoaded');
    return {
      projects: project.toJS(),
      projectsHaveLoaded,
    };
  }

  return {
    projects: browse.browseProfile.get('projects').toJS(),
    projectsHaveLoaded: true,
  };
};

export default withTranslation(['translation.Profile'])(
  connect(mapStateToProps, mapDispatchToProps)(UserProjects)
);
