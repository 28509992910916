/* eslint-disable no-console */
import Cookie from 'js-cookie';
import { request } from 'functions';
import {
  areDeltasDifferent,
} from 'shared/quill/quillFunctions';
import { FLUSH_DELTAS, setDelta } from 'shared/quill/quillActions';
import {
  SET_REDIRECT,
  SET_CHALLENGE_DATA_LOADED,
  SET_CHALLENGE_DATA_LOADING,
  REQUEST_PROCESSED,
  SET_PROJECT_ANSWERS_LOADED,
  CLOSE_UPLOAD_LOGO,
  CLOSE_UPLOAD_BACKDROP,
  FINISH_REQUEST,
  SET_VIEWING_PROJECT_DESCRIPTION,
  SET_VIEWING_PROJECT_ABOUT,
  SET_PAGE_NOT_FOUND,
} from 'store/metadata/metadataActions';
import {
  API_PUT,
  API_GET,
  API_PUT_POST_IMAGE,
  API_POST,
  API_DELETE,
} from 'store/middleware/middlewareConstants';

// import {
//   fetchUserCompletedTopics,
//   fetchUserCompletedBuilders,
// } from 'store/builderData/builderDataActions';
import { removePopups } from 'store/popup/popupActions';
import { triggerSuccessPopup } from 'functions/popup/triggerSuccessPopup';
import { triggerErrorPopup } from 'functions/popup/triggerErrorPopup';
import {
  SET_PROJECTPROFILEABOUT_QUILL_DELTA,
  SET_PROJECTPROFILEABOUT_QUILL_HTML,
  SET_PROJECT_TOPIC_KUDOS,
  SET_PROJECT_ALL_TOPIC_KUDOS,
  REMOVE_PROJECT_TOPIC_KUDOS,
  SET_PROJECT_TOPIC_NOTES,
  DELETE_PROJECT_TOPIC_NOTE,
  SET_PROJECT_CHALLENGES,
  SET_CHALLENGES,
  SET_PROJECT_TOPICS,
  SET_TOPICS,
  SET_TOPIC,
  SET_ANSWER,
  SET_ANSWERS,
  SET_PROJECT_ANSWERS,
  SET_CONFLICTING_ANSWERS,
  RESOLVE_CONFLICT,
  SET_BROWSE_ALL_PROJECTS,
  SET_FILTERED_PROJECTS,
  SET_FILTER,
  REMOVE_FILTER,
  CLEAR_FILTERS,
  SET_PROJECT_DETAILS,
  SET_PROJECT_TEAM,
  UPDATE_CHALLENGE,
  SET_PROJECT_TOPIC_NOTE, SET_FILTER_QUERY,
} from './projectProfileConstants';

// Project About

export const saveProjectProfileAboutQuillDelta = (data) => ({
  type: SET_PROJECTPROFILEABOUT_QUILL_DELTA,
  data,
});

export const saveProjectProfileAboutQuillHtml = (data) => ({
  type: SET_PROJECTPROFILEABOUT_QUILL_HTML,
  data,
});

export const settingProjectProfileData = (data) => {
  return (dispatch) => {
    dispatch(saveProjectProfileAboutQuillDelta(data.about));
    dispatch(saveProjectProfileAboutQuillHtml(data.about_html));
  };
};

export const settingProjectAbout = () => {
  return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
    triggerSuccessPopup('ProjectAboutUpdated', dispatch);
    dispatch({ type: SET_VIEWING_PROJECT_ABOUT });
  };
};

export const updateProjectProfileAbout = (projectId, aboutHtml) => ({
  type: API_PUT,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/projects/${projectId}`,
    body: {
      project: {
        about: { ops: [aboutHtml] },
      },
    },
    success: settingProjectAbout,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

export const getProjectProfileAbout = (projectId) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/projects/${projectId}`,
    success: settingProjectProfileData,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

// Project Backdrop

export const settingProjectBackdrop = () => {
  return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
    triggerSuccessPopup('ProjectBackdropUploaded', dispatch);
    dispatch({ type: CLOSE_UPLOAD_BACKDROP });
    dispatch({ type: FINISH_REQUEST });
  };
};

export const updateProjectBackdrop = (projectId, formData, callback) => {
  if (
    !formData.get('project_backdrop[backdrop]') ||
    formData.get('project_backdrop[backdrop]') === 'undefined'
  ) {
    return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
      triggerErrorPopup('SelectAnImage', dispatch);
      dispatch({ type: FINISH_REQUEST });
    };
  }

  return {
    type: API_PUT_POST_IMAGE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v1/rebel/projects/${projectId}`,
      body: formData,
      success: settingProjectBackdrop,
      onSuccess: callback,
      token: Cookie.get('token'),
    },
  };
};

// Project Logo

export const settingProjectLogo = () => {
  return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
    triggerSuccessPopup('ProjectLogoUploaded', dispatch);
    dispatch({ type: CLOSE_UPLOAD_LOGO });
    dispatch({ type: FINISH_REQUEST });
  };
};

export const updateProjectLogo = (projectId, formData, callback) => {
  if (
    !formData.get('project_logo[logo]') ||
    formData.get('project_logo[logo]') === 'undefined'
  ) {
    return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
      triggerErrorPopup('SelectAnImage', dispatch);
      dispatch({ type: FINISH_REQUEST });
    };
  }

  return {
    type: API_PUT_POST_IMAGE,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v1/rebel/projects/${projectId}`,
      body: formData,
      success: settingProjectLogo,
      onSuccess: callback,
      token: Cookie.get('token'),
    },
  };
};

// Project Description

export const settingProjectDescription = () => {
  return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
    triggerSuccessPopup('ProjectDescriptionUpdated', dispatch);
    dispatch({ type: SET_VIEWING_PROJECT_DESCRIPTION });
  };
};

export const updateProjectProfileDescription = (projectId, description) => ({
  type: API_PUT,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/rebel/projects/${projectId}`,
    body: { project: { description } },
    success: settingProjectDescription,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

// Project Kudos

export const settingProjectKudos = (data) => ({
  type: SET_PROJECT_ALL_TOPIC_KUDOS,
  data,
});

export const fetchProjectTopicKudos = (projectId) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/challenges/project-topic-kudos/${projectId}`,
    success: settingProjectKudos,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

export const creatingProjectTopicKudos = (data) => ({
  type: SET_PROJECT_TOPIC_KUDOS,
  data: [data],
});

export const createProjectTopicKudos = (projectTopicId) => ({
  type: API_POST,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-topic-kudos`,
    body: {
      project_topic_kudos: { project_topic_id: projectTopicId },
    },
    success: creatingProjectTopicKudos,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

export const removingProjectTopicKudos = (projectTopicKudosId) => ({
  type: REMOVE_PROJECT_TOPIC_KUDOS,
  projectTopicKudosId,
});

export const removeProjectTopicKudos = (projectTopicKudosId) => ({
  type: API_DELETE,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-topic-kudos/${projectTopicKudosId}`,
    success: removingProjectTopicKudos,
    id: projectTopicKudosId,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

// Project Notes

export const settingProjectNotes = (data) => ({
  type: SET_PROJECT_TOPIC_NOTES,
  data,
});

export const fetchProjectTopicNotes = (projectId) => {
  const token = Cookie.get('token');
  const url = token
    ? `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/challenges/project-topic-notes/${projectId}`
    : `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/public/challenges/project-topic-notes/${projectId}`;

  return {
    type: API_GET,
    payload: {
      url,
      success: settingProjectNotes,
      onSuccess: null,
      token,
    },
  };
};

export const creatingProjectTopicNotes = (payload) => ({
  type: SET_PROJECT_TOPIC_NOTE,
  data: [payload],
});

export function createProjectTopicNote(note, projectTopicId) {
  if (!note) {
    return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
      triggerErrorPopup('EnterNote', dispatch);
    };
  }

  return {
    type: API_POST,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/challenges/project-topic-notes`,
      body: {
        project_topic_note: {
          project_topic_id: projectTopicId,
          note,
        },
      },
      success: creatingProjectTopicNotes,
      onSuccess: null,
      token: Cookie.get('token'),
    },
  };
}

export const removingProjectTopicNote = (id) => ({
  type: DELETE_PROJECT_TOPIC_NOTE,
  projectTopicNoteId: id,
});

export const removeProjectTopicNote = (projectTopicNoteId) => ({
  type: API_DELETE,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-topic-notes/${projectTopicNoteId}`,
    success: removingProjectTopicNote,
    onSuccess: null,
    id: projectTopicNoteId,
    token: Cookie.get('token'),
  },
});

// Project Details
export const setProjectDetails = (data) => ({
  type: SET_PROJECT_DETAILS,
  data,
});

export const pageNotFound = () => {
  return (dispatch) => {
    dispatch({ type: SET_PAGE_NOT_FOUND });
  };
};

export const fetchProjectDetails = (projectId) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v1/public/projects/${projectId}`,
    success: setProjectDetails,
    onSuccess: null,
    token: Cookie.get('token'),
    onError: pageNotFound,
  },
});

// Topics

export const setTopics = (data) => ({
  type: SET_TOPICS,
  data,
});

export function setTopic(data) {
  return {
    type: SET_TOPIC,
    data,
  };
}

export const fetchTopics = (jwt) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-topics`,
    success: setTopics,
    onSuccess: null,
    token: jwt,
  },
});

export function fetchProjectTopicAnswers(topicId) {
  return (dispatch) => {
    const token = Cookie.get('token');
    if (token) {
      const url = `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/challenges/project-answers/${topicId}`;
      return new Promise((resolve, reject) => {
        request(url, token)
          .then((response) => {
            dispatch({ type: SET_PROJECT_ANSWERS, data: response.data });
            resolve();
          })
          .catch(() => reject());
      });
    }
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/challenges/project-answers/${topicId}`;
    return new Promise((resolve, reject) => {
      request(url)
        .then((response) => {
          dispatch({ type: SET_PROJECT_ANSWERS, data: response.data });
          resolve();
        })
        .catch(() => reject());
    });
  };
}

export function fetchTopicAnswersFromList(topicAnswersList) {
  return (dispatch) => {
    if (topicAnswersList.length) {
      dispatch(fetchProjectTopicAnswers(topicAnswersList.shift())).then(() => {
        dispatch(fetchTopicAnswersFromList(topicAnswersList));
      });
    }
  };
}

export function fetchProjectTopics(projectId) {
  return (dispatch, getState) => {
    const state = getState();
    const token = Cookie.get('token');

    const url = token
      ? `${
          (window.env || process.env).REACT_APP_API_ENDPOINT
        }/api/v2/rebel/challenges/project-topics/${projectId}`
      : `${
          (window.env || process.env).REACT_APP_API_ENDPOINT
        }/api/v2/public/challenges/project-topics/${projectId}`;

    request(url, token).then((response) => {
      if (response.status === 404) {
        return dispatch({ type: SET_PAGE_NOT_FOUND });
      }
      dispatch({ type: SET_PROJECT_TOPICS, data: response.data });
      const topicList = response.data
        .map((topic) => {
          const topicBlueprint = state.appData.topicBlueprints.find(
            (tb) => tb.get('id') === topic.topic_blueprint_id
          );
          const challengeBlueprint = state.appData.challengeBlueprints.find(
            (cb) =>
              cb.get('id') === topicBlueprint.get('challenge_blueprint_id')
          );
          const challengeBucketBlueprint =
            state.appData.challengeBucketBlueprints.find((cbb) => {
              if (!challengeBlueprint) return false;
              return (
                cbb.get('id') ===
                challengeBlueprint.get('challenge_bucket_blueprint_id')
              );
            });
          return {
            id: topic.id,
            cbbSortOrder: challengeBucketBlueprint
              ? challengeBucketBlueprint.get('sort_order')
              : null,
            tbSortOrder: topicBlueprint.get('sort_order'),
            cbSortOrder: challengeBlueprint
              ? challengeBlueprint.get('bucket_sort_order')
              : null,
          };
        })
        .sort((topicA, topicB) => {
          if (topicA.cbbSortOrder === topicB.cbbSortOrder) {
            if (topicA.cbSortOrder === topicB.cbSortOrder) {
              return topicA.tbSortOrder - topicB.tbSortOrder;
            }
            return topicA.cbSortOrder - topicB.cbSortOrder;
          }
          return topicA.cbbSortOrder - topicB.cbbSortOrder;
        })
        .map((topic) => topic.id);
      dispatch(fetchTopicAnswersFromList(topicList));
      return null;
    });
  };
}

export function processChannelTopicUpdate(payload) {
  return (dispatch, getState) => {
    const { quill, appData, user, projectProfilev1 } = getState();
    const { projectAnswers } = projectProfilev1;

    // check for conflict
    let hasConflict = false;

    // check if user is on the topic page
    const isOnTopicPage = window.location.pathname.endsWith(
      `${payload.topicId}/answer`
    );

    // dont do conflict resolution for the user who updated
    if (user.get('id') !== payload?.userId) {
      if (isOnTopicPage) {
        // for each answer in the payload, check if the user is editing
        payload.answers.data.forEach((answer) => {
          const answerBlueprint = appData.answerBlueprints.find(
            (ab) => ab.get('id') === answer.answer_blueprint_id
          );

          switch (answerBlueprint.get('type')) {
            case 'Quill Editor': {
              const answerDelta = projectAnswers
                .find((a) => a.get('id') === answer.id)
                .get('answer_map');
              const quillDelta = quill.delta.get(
                answer.answer_blueprint_id.toString()
              );
              if (quillDelta && answerDelta) {
                if (areDeltasDifferent(quillDelta.toJS(), answerDelta.toJS())) {
                  hasConflict = true;
                }
              } else if (quillDelta) {
                if (areDeltasDifferent(quillDelta.toJS(), answer.answer_map)) {
                  hasConflict = true;
                }
              }
              break;
            }
            default:
              break;
          }
        });
      }
    }

    if (hasConflict) {
      dispatch({ type: SET_CONFLICTING_ANSWERS, data: payload.answers.data });
    } else {
      // if no conflict, update the state with answers
      payload.answers.data.map((answer) =>
        dispatch({ type: SET_ANSWER, id: answer.id, data: answer })
      );
      dispatch({ type: FLUSH_DELTAS });

      if (isOnTopicPage) {
        payload.answers.data.forEach((answer) => {
          const answerBlueprint = appData.answerBlueprints.find(
            (ab) => ab.get('id') === answer.answer_blueprint_id
          );

          switch (answerBlueprint.get('type')) {
            case 'Quill Editor': {
              dispatch(
                setDelta(
                  answer.answer_blueprint_id.toString(),
                  answer.answer_map
                )
              );
              break;
            }
            default:
              break;
          }
        });
      }
    }
    dispatch(setTopic(payload.topic.data));
  };
}

// Answers

export const setAnswers = (data) => ({
  type: SET_ANSWERS,
  data,
});

export function fetchAnswers() {
  return (dispatch) => {
    const token = Cookie.get('token');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-answers`;
    return request(url, token).then((response) =>
      dispatch(setAnswers(response.data))
    );
  };
}

export function updateTopicAnswers(values, topicId, projectId, builderId, callback = () => {}, redirectTo = null) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch
  ) => {
    const answerPayload = {
      ops: [
        values && values[topicId]
            ? values[topicId]
            : null,
      ]
    };

    const token = Cookie.get('token');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/projects/${projectId}/foundry/builders/${builderId}/topics/${topicId}`;
    const payload = {
      answer: answerPayload
    };

    return request(url, token, 'PUT', payload)
      .then((response) => {
        dispatch({ type: FLUSH_DELTAS });
        // TODO add correct functionality for this section
        if (answerPayload && false) {
          dispatch({
            type: SET_ANSWER,
            data: answerPayload
          }) ;
        }
        dispatch({ type: SET_TOPIC, data: response?.data });
        triggerSuccessPopup('AnswerSaved', dispatch);
        dispatch({ type: REQUEST_PROCESSED });
        // redirect to overview
        if (redirectTo) {
          dispatch({ type: SET_REDIRECT, url: redirectTo });
        }
        if (typeof callback === 'function') {
          callback(
              projectId, builderId, topicId
          );
        }
      })
      .catch((err) => console.log(err));
  };
}

export function syncAndResolveConflict() {
  return (dispatch, getState) => {
    getState().projectProfilev1.conflictingAnswers.forEach((a) =>
      dispatch({ type: SET_ANSWER, id: a.get('id'), data: a })
    );
    dispatch({ type: RESOLVE_CONFLICT });
  };
}

// Challenges

export const setChallenges = (data) => ({
  type: SET_CHALLENGES,
  data,
});

export const fetchChallenges = (jwt) => {
  return {
    type: API_GET,
    payload: {
      url: `${
        (window.env || process.env).REACT_APP_API_ENDPOINT
      }/api/v2/rebel/challenges/project-challenges`,
      success: setChallenges,
      onSuccess: null,
      token: jwt,
    },
  };
};

export const setProjectChallenges = (data) => ({
  type: SET_PROJECT_CHALLENGES,
  data,
});

export const fetchProjectChallenges = (projectId) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/challenges/project-challenges/${projectId}`,
    success: setProjectChallenges,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

export const settingChallengeIntroComplete = (data) => ({
  type: UPDATE_CHALLENGE,
  data,
});

export const setChallengeIntroComplete = (challengeId) => ({
  type: API_PUT,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-challenges/${challengeId}`,
    body: { project_challenge: { intro_viewed: true } },
    success: settingChallengeIntroComplete,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

export const publishingChallengeFromProjectProfile = (data) => {
  return (dispatch) => {
    dispatch(removePopups());
    dispatch({ type: UPDATE_CHALLENGE, data });
  };
};

export const publishChallengeFromProjectProfile = (formData, challengeId) => ({
  type: API_PUT,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/challenges/project-challenges/${challengeId}`,
    body: {
      publish_challenge: {
        is_published: true,
        published_for: formData,
      },
    },
    success: publishingChallengeFromProjectProfile,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

export function publishChallenge(projectId, builderId, cb) {
  return /*withTranslation(['translation.notification']) (*/ (
    dispatch,
    getState
  ) => {
    dispatch(removePopups());
    const token = Cookie.get('token');
    const state = getState();
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/projects/${projectId}/foundry/builders/${builderId}`;
    const challengeReviewForm = state.form.BuilderReviewForm;

    // validate
    if (!challengeReviewForm.values.publishedFor) {
      triggerErrorPopup('SelectWhoCanView', dispatch);
      return;
    }

    const payload = {
      is_published: true,
      published_for: challengeReviewForm.values.publishedFor,
    };
    request(url, token, 'PUT', payload)
      .then((response) => {
        dispatch({ type: UPDATE_CHALLENGE, data: response.data });
        if (typeof cb === 'function') {
          cb();
        }
      })
      .then(() => triggerSuccessPopup('SuccessfullyPublished', dispatch));
    // todo: error handling
    // .then(response => dispatch(setUserSettings(response.data)))
  };
}

export function fetchChallengeData() {
  return (dispatch) => {
    // const { user } = getState();
    // const jwt = user.get('jwt');
    Promise.all([
      // dispatch(fetchChallenges(jwt)),
      // dispatch(fetchTopics(jwt)),
      // dispatch(fetchUserCompletedBuilders()),
      // dispatch(fetchUserCompletedTopics())
    ]).then(() => dispatch({ type: SET_CHALLENGE_DATA_LOADED }));
    // dispatch(fetchAnswers()).then(() =>
      dispatch({ type: SET_PROJECT_ANSWERS_LOADED });
    // );
  };
}

// Team

export const setProjectTeam = (data) => ({
  type: SET_PROJECT_TEAM,
  data,
});

export const fetchProjectTeam = (projectId) => ({
  type: API_GET,
  payload: {
    url: `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/public/projects/teammates/${projectId}`,
    success: setProjectTeam,
    onSuccess: null,
    token: Cookie.get('token'),
  },
});

// Filter

export function applyFilters() {
  return (dispatch, getState) => {
    const { projectProfilev1 } = getState();
    const { filters, allProjects } = projectProfilev1;
    const query = filters.get('query') || '';
    // eslint-disable-next-line no-debugger
    if (
      filters.get('industries').isEmpty() &&
      !query
    ) {
      return dispatch({
        type: SET_FILTERED_PROJECTS,
        filteredProjects: allProjects,
      });
    }

    let filteredProjects = allProjects;

    // industry filter
    if (!filters.get('industries').isEmpty()) {
      filteredProjects = filteredProjects.filter((p) =>
        filters.get('industries').includes(p.get('industry_id'))
      );
    }

    // search filter
    if (query) {
      const searchTerm = (query || '').toLowerCase();

      filteredProjects = filteredProjects.filter((p) => {
        const name = p.get('name').toLowerCase();
        return name.indexOf(searchTerm) >= 0;
      });
    }

    return dispatch({ type: SET_FILTERED_PROJECTS, filteredProjects });
  };
}

export function clearFilters() {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTERS });
    dispatch(applyFilters());
  };
}

export function setFilterQuery(value) {
  return (dispatch) => {
    dispatch({ type: SET_FILTER_QUERY, value });
    dispatch(applyFilters());
  };
}

export function setFilter(filterType, value) {
  return (dispatch, getState) => {
    const { filters } = getState().projectProfilev1;

    // if filter is already applied, remove it
    if (filters.get(filterType).includes(value)) {
      dispatch({ type: REMOVE_FILTER, filterType, value });
    } else {
      dispatch({ type: SET_FILTER, filterType, value });
    }

    dispatch(applyFilters());
  };
}

// Fetch Project

export function fetchProject(projectId) {
  return (dispatch, getState) => {
    // make sure user is not a member of the project (members already have the necessary data in the store)
    const { project } = getState();
    if (!project.find((p) => p.get('id') === parseInt(projectId, 10))) {
      // fetch project details
      dispatch(fetchProjectDetails(projectId));
      // fetch challenges
      // dispatch(fetchProjectChallenges(projectId));
      // fetch topics and answers
      // dispatch(fetchProjectTopics(projectId));
      // fetch teammates
      dispatch(fetchProjectTeam(projectId));
    }
    // fetch kudos
    // dispatch(fetchProjectTopicKudos(projectId));
    // fetch notes
    // dispatch(fetchProjectTopicNotes(projectId));
  };
}

// Get all the projects

export const fetchAllProjects = (
  url = `${
    (window.env || process.env).REACT_APP_API_ENDPOINT
  }/api/v1/public/projects`
) => {
  return (dispatch) => {
    request(url).then((response) => {
      dispatch({ type: SET_BROWSE_ALL_PROJECTS, data: response.data });
      dispatch(applyFilters());
    });
  };
};

// Beta Projects

export function migrateProject(projectId, shouldMigrate) {
  return /*withTranslation(['translation.notification']) (*/ (dispatch) => {
    dispatch({ type: SET_CHALLENGE_DATA_LOADING });
    const token = Cookie.get('token');
    const url = `${
      (window.env || process.env).REACT_APP_API_ENDPOINT
    }/api/v2/rebel/misc/beta-projects`;
    const payload = {
      project: { project_id: projectId, should_migrate: shouldMigrate },
    };
    return request(url, token, 'POST', payload)
      .then(() => {
        dispatch(fetchChallengeData());
      })
      .catch((err) => {
        triggerErrorPopup('SendSupportRequest', dispatch);
        console.log(err);
      });
  };
}
