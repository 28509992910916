import React from 'react';
import { useSelector } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import STYLES from './styles';
import './styles.scss';

const Popup = () => {
  const notifications = useSelector((state) => state.popups);
  return <Notifications notifications={notifications} style={STYLES} />;
};

export default Popup;
