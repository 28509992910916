import React from 'react';
import Modal from 'react-modal';
import { modalCss } from 'utils/modalCss';

import './customModal.scss';

const CustomModal = ({
  header,
  message,
  logo,
  hint,
  firstChoice,
  secondChoice,
  cancel,
  isOpen,
  handleFirstChoice = () => {},
  handleSecondChoice = () => {},
  handleCancel = () => {},
  GroupName,
  isFirstChoiceDisabled = false,
}) => {
  return (
    <Modal isOpen={isOpen} style={modalCss} className="custom-modal">
      {header && (
        <h3>
          {header}
          <br />
          {GroupName}
          {/* {headerName} */}
        </h3>
      )}
      {message && (
        <>
          {logo && (
            <img src={logo} className="logo custom-modal__logo" alt="logo" />
          )}{' '}
          <p className="custom-modal__message">{message}</p>
        </>
      )}
      {hint && <p className="custom-modal__hint">{hint}</p>}

      <div className="btn-wrap">
        {cancel && (
          <button type="button" onClick={handleCancel} className="btn-cancel">
            {cancel}
          </button>
        )}

        {secondChoice && (
          <button
            type="button"
            onClick={handleSecondChoice}
            className="btn-second-choice"
          >
            {secondChoice}
          </button>
        )}

        {firstChoice && (
          <button
            type="button"
            disabled={isFirstChoiceDisabled}
            data-testid="customModal_firstChoice"
            onClick={handleFirstChoice}
            className="btn-first-choice"
          >
            {firstChoice}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
