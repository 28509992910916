import React, { FC } from 'react';
import { Button, Snackbar, Typography } from "@mui/material";
import { register } from '../../ServiceWorkerRegistration';

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [installingWorker, setInstallingWorker] = React.useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setInstallingWorker(registration.installing);
    };

    React.useEffect(() => {
        register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        installingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    return (
        <Snackbar
            open={showReload}
            message={(
                <>
                    <Typography
                        variant='h5' sx={{ color: (theme) => theme?.palette?.common?.white }}>
                        A new version is available!
                    </Typography>
                </>
            )}
            onClick={reloadPage}
            data-test-id="screens-new-version-snackbar"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            action={
                <Button
                    color="warning"
                    size="small"
                    onClick={reloadPage}
                >
                    Reload
                </Button>
            }
        />
    );
}

export default ServiceWorkerWrapper;