import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import SEO from 'components/SEO';
import { useForm } from 'react-hook-form';
import { UseFormProps } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { yupResolver } from 'utils/yup';
import { doLogin, DOING_LOGIN } from 'store/login/loginActions';
import { errorPopup } from 'functions/popup/errorPopup';
import { useHistory, useLocation } from "react-router-dom";
import SignInForm from './SignInForm';
import { LoginForm } from './LoginForm';

const SignInContainer = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    const urlParams = new URLSearchParams(location?.search);

    const redirectUrl = urlParams?.get("redirect_url") ? `${urlParams?.get("redirect_url")}${location?.hash || ''}` : null;

    const [isSubmitting, setSubmitting] = useState<boolean>(true);

    // TODO remove any type
    const { user_preference: userPreference } = useSelector((state: any) => state.user.toJS());

    const language = userPreference?.language === 'ESP' ? '/es' : '';

    const enableReCaptcha =
        (window.env || process.env).ENABLE_RECAPTCHA === 'true';

    const { t } = useTranslation(['translation.login']);

    const validationSchema: ObjectSchema<ObjectShape> = Yup.object().shape({
        email: Yup.string()
            .email(t('EnterValidEmail'))
            .required(t('EnterValidEmail')),
        password: Yup.string()
            .required(t('PasswordMinLength'))
            .min(6, t('PasswordMinLength')),
        reCaptcha: enableReCaptcha
            ? Yup.string().required('Required')
            : Yup.string(),
    });

    const formOptions: UseFormProps = {
        // onBlur, onChange, and etc...
        mode: 'all',
        resolver: yupResolver(validationSchema)
    };

    const { register, reset, handleSubmit, formState, setValue, getValues } = useForm(formOptions);

    const { errors } = formState;

    const onError = (result: any) => {
        if (result.error === 422 || result.error === 500) {
            dispatch(errorPopup(t('IncorrectEmailOrPassword', '0', 'auth_failed')));
        }
    };

    const login = (form: LoginForm) => {
        setSubmitting(true);
        dispatch({ type: DOING_LOGIN });
        if (!form || !form.email || !form.password) {
            // eslint-disable-next-line no-console
            console.error('Error While Login');
        } else {
            dispatch(doLogin(form, onError, () => {
                if (redirectUrl) {
                  history?.push(redirectUrl)
                }
            }));
        }
        setSubmitting(false);
    };

    return (
        <SEO title={t('Login')}>
            <SignInForm
                redirectUrl={redirectUrl}
                onSubmit={login}
                register={register}
                formHandleSubmit={handleSubmit}
                errors={errors}
                language={language}
                setValue={setValue}
                reset={reset}
                isSubmitting={isSubmitting}
                getValues={getValues}
            />
        </SEO>
    )
}

export default React.memo(SignInContainer);