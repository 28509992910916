import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { profileCompleted } from '../../../store/profile/profileActions';

class ProfileProgressBar extends React.Component {
  handleProfileCompleted() {
    const { complete } = this.props;
    complete();
    return null;
  }

  renderView() {
    const { progressPercent, t } = this.props;
    const styles = { width: `${progressPercent}%` };
    return (
      <section className="profile-prog">
        <div className="profile-prog__wrap">
          <div className="profile-prog__cont">
            <div className="profile-prog__bar">
              <div style={styles} className="profile-prog__bar-fill" />
            </div>
            <h4 className="profile-prog__percent">{`${progressPercent}% ${t('Completed')}`}</h4>
          </div>
        </div>
      </section>
    );
  }

  render() {
    const { isProfileComplete, hasLoaded, progressPercent } = this.props;

    if (hasLoaded) {
      if (!isProfileComplete) {
        if (progressPercent === 100) return this.handleProfileCompleted();
        return this.renderView();
      }
    }
    return null;
  }
}


const mapDispatchToProps = dispatch => ({
  complete() {
    dispatch(profileCompleted());
  },
});

const mapStateToProps = (state) => {
  const { pageMetaData, summary, socialLinks, avatar, experience } = state.profile;
  const isProfileComplete = pageMetaData.get('isProfileComplete');

  const hasLoaded = pageMetaData.get('profileCompletionLoaded');

  // calculate completion percentage
  const totalItems = 4;
  let completedItems = 0;

  if (summary) completedItems += 1;
  if (avatar) completedItems += 1;
  if (experience.data.length) completedItems += 1;
  if (socialLinks.get('facebook') || socialLinks.get('twitter') || socialLinks.get('linkedin')) completedItems += 1;

  const progressPercent = (completedItems / totalItems) * 100;

  return {
    hasLoaded,
    isProfileComplete,
    progressPercent,
  };
};


export default withTranslation(['translation.Profile']) (connect(mapStateToProps, mapDispatchToProps)(ProfileProgressBar));
