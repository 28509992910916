import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { configureStore, history } from './store/configureStore';

import App from './App';

export const store = configureStore();

const loadHeap = () => {
  const heap_id = window.env.REACT_APP_HEAP;

  const url = ` window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
  heap.load(${heap_id});`;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.textContent = url;
  document.head.appendChild(script);
};

const loadRaven = () => {
  const url = 'https://cdn.ravenjs.com/3.26.4/raven.min.js';

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.crossOrigin = 'anonymous';
  script.src = url;
  document.head.appendChild(script);
};

const tracesSampleRate = window.env.REACT_APP_TYPE === 'PRODUCTION' ? 1.0 : 0.5;

Sentry.init({
  dsn: 'https://c636f76fd293431c9cb3cc90bfba38ae@o180946.ingest.sentry.io/5931668',
  integrations: [new Integrations.BrowserTracing()],
  environment: window.env.REACT_APP_TYPE,
  tracesSampleRate,
});

if (window.env.REACT_APP_TYPE === 'PRODUCTION') {
  loadHeap();
  loadRaven();
}

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);

// initialize GA4
ReactGA.initialize(window.env.GA_MEASUREMENT_ID);

ReactGA.send('pageview');
ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname + window.location.search,
});

history.listen((location) => {
  // set google analytics page hit
  ReactGA.set({ page: location.pathname });
  ReactGA.send('pageview');
});

// TODO use mui media query
window.addEventListener('resize', () => {
  store.dispatch({
    type: 'metadata/SET_SCREEN_RESIZE',
    screenSize:
      typeof window.orientation !== 'undefined'
        ? document.documentElement.clientWidth
        : window.innerWidth,
  });
});

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
