import { combineReducers } from 'redux';
import { Map } from 'immutable';
import {
  SET_REBEL_USER_CHANNEL,
  SET_HUB_CHANNEL,
} from './channelActions';

export function rebelChannel(state = null, action) {
  switch (action.type) {
    case SET_REBEL_USER_CHANNEL:
      return action.data;
    default:
      return state;
  }
}

export function hubChannels(state = Map(), action) {
  switch (action.type) {
    case SET_HUB_CHANNEL:
      return state.set(action.hubId, action.channel);
    default:
      return state;
  }
}

export default combineReducers({
  rebelChannel,
  hubChannels,
});
