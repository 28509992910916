export const userProjectRoleTypes = [
  {
    description:
      'Owners can do pretty much what they want with your project. Once assigned, ownership cannot be revoked.',
    id: 1,
    is_assignable: true,
    label: 'Owner',
    level: 100,
    name: 'Owner',
  },
  {
    description: 'Admins can manage users and project settings.',
    id: 2,
    is_assignable: true,
    label: 'Admin',
    level: 90,
    name: 'Admin',
  },
  {
    description: 'Members can take challenges and strengthen your project.',
    id: 3,
    is_assignable: true,
    label: 'Member',
    level: 50,
    name: 'Member',
  },
  {
    description: 'Supporters can see your content before you make it public.',
    id: 4,
    is_assignable: true,
    label: 'Support Member',
    level: 30,
    name: 'Supporter',
  },
];
